import { ITableColumn } from 'components/table';

export const constants = {
  columns: [
    { accessor: 'number', label: '№', withSort: true },
    { accessor: 'date', withSort: true },
    'playlist',
    { accessor: 'status', withSort: true },
    { accessor: 'period', withSort: true },
    'monitor',
    {
      accessor: 'playlistChange',
      restrictedFrom: ['advertiser'],
      messageId: 'ApplicationQueue',
    },
    'address',
    { accessor: 'actions', restrictedFrom: ['advertiser'] },
  ] as ITableColumn[],
};
