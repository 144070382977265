import React from 'react';
import { videoEditorStore } from '../store';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import './style.scss';

export interface IEditorPreview extends React.HTMLAttributes<HTMLDivElement> {}

const EditorPreviewComponent: React.FC<IEditorPreview> = ({
  className,
  ...restProps
}) => {
  const canvasSizes = React.useMemo(
    () => ({
      width: videoEditorStore.project?.width,
      height: videoEditorStore.project?.height,
    }),
    [videoEditorStore.project],
  );

  return (
    <div {...restProps} className={cx('preview-canvas', className)}>
      <canvas
        ref={videoEditorStore.previewCanvasRef}
        className="preview-canvas__canvas"
        {...canvasSizes}
      />
    </div>
  );
};

export const EditorPreview = observer(EditorPreviewComponent);
