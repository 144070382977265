import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { capitalize } from 'lodash';
import cx from 'classnames';

import { mediaStore } from 'stores/media';
import { IMediaItem } from '_types/stores';

import { VideoType } from 'utils/api/api';

import {
  DRAFT_PROJECT_ID,
  videoEditorStore,
} from 'modules/video-editor-module';

import { EditorLibraryItem } from './editor-library-item';
import { ChangeNameForm } from '../change-name-form';

export interface IEditorLibrary extends React.HTMLAttributes<HTMLDivElement> {
  timelineRef: React.RefObject<HTMLDivElement>;
}

const MEDIA_TYPES = [VideoType.Video, VideoType.Image, VideoType.Audio];

const EditorLibraryComponent: React.FC<IEditorLibrary> = ({
  timelineRef,
  className,
  ...restProps
}) => {
  const intl = useIntl();

  const localStore = useLocalObservable(() => ({
    currentType: VideoType.Video,
    changeType(type: IMediaItem['videoType']) {
      this.currentType = type;
    },
    selectedMedia: [] as IMediaItem[],
    selectMedia(mediaItem: IMediaItem) {
      this.selectedMedia = this.selectedMedia
        .filter((m) => m.id !== mediaItem.id)
        .concat(mediaItem);
    },
    isSelectedMedia(mediaId: IMediaItem['id']) {
      return this.selectedMedia.some((m) => m.id === mediaId);
    },
    unSelectMedia(mediaIds: Array<IMediaItem['id']>) {
      this.selectedMedia = this.selectedMedia.filter(
        (m) => !mediaIds.includes(m.id),
      );
    },
    unSelectAllMedia() {
      this.selectedMedia = [];
    },
    selectAllMedia() {
      this.selectedMedia = mediaStore.mediaList
        .filter((m) => m.videoType === this.currentType)
        .concat();
    },
  }));

  const [folderListIsOpen, setFolderListIsOpen] = React.useState(false);

  React.useEffect(() => {
    const source = mediaStore.getMediaList();

    return () => {
      source.cancel();
    };
  }, [mediaStore.currentFolderId]);

  React.useEffect(() => {
    if (!mediaStore.foldersList.length || mediaStore.currentFolderId !== null) {
      return;
    }

    mediaStore.setupNavigation(mediaStore.foldersList[0]);
  }, [mediaStore.foldersList]);

  React.useEffect(() => {
    return () => {
      mediaStore.clearStore();
    };
  }, []);

  const selectAll = React.useCallback(() => {
    const currentTypeMediaList = mediaStore.mediaList.filter(
      (m) => m.videoType === localStore.currentType,
    );

    if (localStore.selectedMedia.length === currentTypeMediaList.length) {
      localStore.unSelectAllMedia();
    } else {
      localStore.selectAllMedia();
    }
  }, []);

  const [changeNameFormIsOpen, setChangeNameFormIsOpen] = React.useState(false);

  const handleChangeNameFormClose = React.useCallback(() => {
    setChangeNameFormIsOpen(false);
  }, [setChangeNameFormIsOpen]);

  const handleChangeNameFormSave = React.useCallback(
    (name: string) => {
      const mediaItems = localStore.selectedMedia.filter(
        (m) => m.videoType === localStore.currentType,
      );

      videoEditorStore.addMedia(name, mediaItems);
      localStore.unSelectMedia(mediaItems.map((m) => m.id));

      setChangeNameFormIsOpen(false);
    },
    [localStore, setChangeNameFormIsOpen],
  );

  const renderChangeNameForm = () => {
    if (!videoEditorStore.project) {
      return null;
    }

    return (
      <ChangeNameForm
        open={changeNameFormIsOpen}
        status="create"
        value={videoEditorStore.project.name}
        canBeClosed={false}
        onClose={handleChangeNameFormClose}
        onSave={handleChangeNameFormSave}
      />
    );
  };

  const addMediaToTimeline = React.useCallback(() => {
    if (!videoEditorStore.project) {
      return;
    }

    const mediaItems = localStore.selectedMedia.filter(
      (m) => m.videoType === localStore.currentType,
    );

    if (mediaItems.length === 0) {
      return;
    }

    if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
      setChangeNameFormIsOpen(true);
      return;
    }

    videoEditorStore.addMedia(null, mediaItems);
    localStore.unSelectMedia(mediaItems.map((m) => m.id));
  }, [localStore.selectedMedia, localStore.currentType]);

  const handleMediaItemMouseDown = React.useCallback(
    (mediaItem: IMediaItem) => {
      if (videoEditorStore.isReadOnly) return;
      const onMouseMove = (moveEvent: MouseEvent) => {
        moveEvent.preventDefault();

        if (!localStore.isSelectedMedia(mediaItem.id)) {
          localStore.selectMedia(mediaItem);
        }
      };

      const onMouseUp = (upEvent: MouseEvent) => {
        const mouseOnTimeline = timelineRef.current?.contains(
          document.elementFromPoint(upEvent.clientX, upEvent.clientY),
        );
        if (mouseOnTimeline) {
          addMediaToTimeline();
        }
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [
      addMediaToTimeline,
      timelineRef,
      localStore.selectedMedia,
      videoEditorStore.isReadOnly,
    ],
  );

  const handleMediaItemClick = React.useCallback(
    (mediaItem: IMediaItem) => {
      if (videoEditorStore.isReadOnly) return;
      if (localStore.isSelectedMedia(mediaItem.id)) {
        localStore.unSelectMedia([mediaItem.id]);
      } else {
        localStore.selectMedia(mediaItem);
      }
    },
    [videoEditorStore.isReadOnly],
  );

  const mediaList = React.useMemo(
    () =>
      mediaStore.mediaList
        .filter((mediaItem) => mediaItem.videoType === localStore.currentType)
        .map((mediaItem) => (
          <EditorLibraryItem
            key={mediaItem.id}
            mediaItem={mediaItem}
            selected={localStore.isSelectedMedia(mediaItem.id)}
            onMouseDown={() => handleMediaItemMouseDown(mediaItem)}
            onClick={() => handleMediaItemClick(mediaItem)}
          />
        )),
    [
      mediaStore.mediaList,
      localStore.currentType,
      handleMediaItemMouseDown,
      handleMediaItemClick,
    ],
  );

  const typeTab = React.useMemo(
    () =>
      MEDIA_TYPES.map((type) => (
        <button
          key={type}
          className={cx('editor-content__item', {
            'editor-content__item--active': localStore.currentType === type,
          })}
          onClick={() => localStore.changeType(type)}
        >
          {intl.formatMessage({
            id: capitalize(type),
          })}
        </button>
      )),
    [localStore.currentType, intl.locale],
  );

  const renderFolderSelect = React.useMemo(() => {
    return (
      <div className="video-library__folders-wrapper">
        <div className="video-library__folders-layout">
          <div
            className={
              folderListIsOpen
                ? 'video-library__folders video-library__folders--active'
                : 'video-library__folders'
            }
            onClick={() => setFolderListIsOpen(!folderListIsOpen)}
          >
            Выбор папки
          </div>

          {folderListIsOpen &&
            mediaStore.foldersList.map((folder) => {
              const handleFolderClick = () => {
                mediaStore.navigateForward(folder);
                setFolderListIsOpen(false);
              };

              return (
                <div
                  className="video-library__folder"
                  key={folder.id}
                  onClick={handleFolderClick}
                >
                  <span className="video-library__folder-name">
                    {folder.name}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    );
  }, [mediaStore.foldersList, folderListIsOpen]);

  const renderBreadcrumbs = React.useMemo(() => {
    if (!mediaStore.breadcrumbs) {
      return null;
    }

    return (
      <div className="video-library__path">
        {mediaStore.breadcrumbs.map((breadcrumb, i, breadcrumbs) => {
          const handleClick = () => {
            if (i + 1 === breadcrumbs.length) {
              return;
            }

            mediaStore.navigateBackwardTo(i + 1);
          };

          if (breadcrumb.parentFolderId === null) {
            return (
              <button
                className="video-library__path-item"
                key={breadcrumb.id}
                onClick={handleClick}
                type="button"
              >
                <FormattedMessage id="Library" defaultMessage="Library" />
              </button>
            );
          }

          return (
            <button
              className="video-library__path-item"
              key={breadcrumb.id}
              onClick={handleClick}
              type="button"
            >
              &nbsp;/&nbsp;{breadcrumb.name}
            </button>
          );
        })}
      </div>
    );
  }, [mediaStore.breadcrumbs]);

  return (
    <div
      {...restProps}
      className={cx('editor-content editor-content__layout', className)}
    >
      {renderChangeNameForm()}
      <div className="editor-content__bar">
        <button className="editor-content__upload" />
        {typeTab}
      </div>
      <div className="video-library">
        <div className="video-library__path-layout">
          <button
            className="video-library__path-circle"
            onClick={mediaStore.navigateBackward}
            type="button"
          />
          {renderBreadcrumbs}
        </div>
        {renderFolderSelect}
        <div className="video-library__layout">{mediaList}</div>
        <div className="video-library__actions">
          <button
            disabled={videoEditorStore.isReadOnly}
            className="video-library__action"
            onClick={selectAll}
          >
            <FormattedMessage id="Select All" defaultMessage="Select All" />
          </button>
          <button
            disabled={videoEditorStore.isReadOnly}
            className="video-library__action"
            onClick={addMediaToTimeline}
          >
            <FormattedMessage
              id="On the timeline"
              defaultMessage="On the timeline"
            />
          </button>
          {/* <button className="video-library__action">*/}
          {/*  <FormattedMessage id="Delete" defaultMessage="Delete" />*/}
          {/* </button>*/}
        </div>
      </div>
    </div>
  );
};

export const EditorLibrary = observer(EditorLibraryComponent);
