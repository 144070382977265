import GeoJSON, { FeatureCollection } from 'geojson';

export function arrayToCollection<T extends unknown[]>(
  arr: T,
  key: keyof T[number],
  include?: Array<keyof T[number]>,
): FeatureCollection {
  // @ts-ignore
  return GeoJSON.parse(
    arr
      .filter((item): item is Record<keyof T[number], unknown> => Boolean(item))
      .filter((item) => item[key]),
    { GeoJSON: key, include },
  );
}
