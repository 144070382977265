import { Layout } from 'components/common';
import { FC } from 'react';
import { authStore } from 'stores/auth';
import './styles.scss';

const Email: FC = () => {
  const email = authStore.user?.email;

  if (!authStore.user) {
    return null;
  }

  return (
    <Layout flex row className="navigation__email">
      <div>{email}</div>
    </Layout>
  );
};

export default Email;
