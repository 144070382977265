import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import useResizeObserver from 'use-resize-observer';

import { IFolderItem, mediaStore } from 'stores/media';
import { IMediaItem } from '_types/stores';

import { LibraryFolder, NewFolder } from 'components/library-folder';
import { MediaListItem } from 'components/media-list';
import { Confirmation } from 'components/common';

const Actions: React.FC<{ item: IMediaItem }> = observer(({ item }) => {
  const handleDownloadClick = React.useCallback(
    () => mediaStore.download(item),
    [item],
  );

  const isChecked = mediaStore.selectedMedia.fileIds.includes(item.id);

  const handleChange = React.useCallback(() => {
    mediaStore.selectFiles(item.id);
  }, [mediaStore.selectFiles]);

  return (
    <div className="library__item-actions">
      <span className="library__item-download" onClick={handleDownloadClick}>
        <FormattedMessage id="Download" defaultMessage="Download" />
      </span>
      <input
        className="library__item-checkbox"
        type="checkbox"
        onChange={handleChange}
        checked={isChecked}
      />
    </div>
  );
});

const Library: React.FC = () => {
  const intl = useIntl();
  const { ref: libraryRef, width: libraryWidth = 0 } =
    useResizeObserver<HTMLDivElement>();

  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

  useEffect(() => {
    const source = mediaStore.getMediaList();

    return () => {
      source.cancel();
    };
  }, [mediaStore.currentFolderId]);

  useEffect(() => {
    if (!mediaStore.foldersList.length || mediaStore.currentFolderId !== null) {
      return;
    }

    mediaStore.setupNavigation(mediaStore.foldersList[0]);
  }, [mediaStore.foldersList]);

  useEffect(() => {
    return () => {
      mediaStore.clearStore();
    };
  }, []);

  const checkboxes = useLocalObservable<Record<string, boolean>>(() => ({}));
  // Callbacks
  const handleItemCheckbox = useCallback(
    (item: IMediaItem, isChecked: boolean) =>
      runInAction(() => {
        checkboxes[item.id] = isChecked;
      }),
    [checkboxes],
  );

  const newFolder = React.useMemo(() => {
    if (mediaStore.folderCreation.isActive) {
      return <NewFolder />;
    }

    return null;
  }, [mediaStore.folderCreation.isActive]);

  const folderList = React.useMemo(
    () =>
      mediaStore.foldersList.map((folder) => {
        const handleFolderClick = () => {
          mediaStore.navigateForward(folder);
        };

        return (
          <LibraryFolder
            folder={folder}
            key={folder.id}
            onClick={handleFolderClick}
            customContextMenu
          />
        );
      }),
    [mediaStore.foldersList],
  );

  const emptyFolderList = React.useMemo(() => {
    const columnWidth = 140;
    const gapWidth = 20;

    let foldersAmount = mediaStore.foldersList.length;

    if (mediaStore.folderCreation.isActive) {
      foldersAmount += 1;
    }

    const fittingCellsAmount = Math.floor(
      (libraryWidth + gapWidth) / (columnWidth + gapWidth),
    );

    if (fittingCellsAmount === 0) {
      return null;
    }

    if (foldersAmount % fittingCellsAmount === 0) {
      return null;
    }

    const emptyItemsAmount =
      fittingCellsAmount - (foldersAmount % fittingCellsAmount);

    return Array.from(Array(emptyItemsAmount)).map((_, i) => (
      <div className="library__item--empty" key={i} />
    ));
  }, [
    mediaStore.foldersList,
    mediaStore.folderCreation.isActive,
    libraryWidth,
  ]);

  const emptyMediaList = React.useMemo(() => {
    const columnWidth = 140;
    const gapWidth = 20;

    const mediaAmount = mediaStore.mediaList.length;

    const fittingCellsAmount = Math.floor(
      (libraryWidth + gapWidth) / (columnWidth + gapWidth),
    );

    if (fittingCellsAmount === 0) {
      return null;
    }

    if (mediaAmount % fittingCellsAmount === 0) {
      return null;
    }

    const emptyItemsAmount =
      fittingCellsAmount - (mediaAmount % fittingCellsAmount);

    return Array.from(Array(emptyItemsAmount)).map((_, i) => (
      <div className="library__item--empty" key={`library__item-${i}`} />
    ));
  }, [mediaStore.mediaList, libraryWidth]);

  const mediaList = React.useMemo(
    () =>
      mediaStore.mediaList.map((media) => (
        <MediaListItem
          key={media.id}
          item={media}
          action={Actions}
          customContextMenu
        />
      )),
    [mediaStore.mediaList, checkboxes, handleItemCheckbox],
  );

  const renderBreadcrumbs = React.useMemo(() => {
    if (!mediaStore.breadcrumbs) {
      return null;
    }

    return (
      <div className="library__folder-path">
        {mediaStore.breadcrumbs.map((breadcrumb, i, breadcrumbs) => {
          const handleClick = () => {
            if (i + 1 === breadcrumbs.length) {
              return;
            }

            mediaStore.navigateBackwardTo(i + 1);
          };

          if (breadcrumb.parentFolderId === null) {
            return (
              <button
                className="library__folder-path-item"
                key={breadcrumb.id}
                onClick={handleClick}
                type="button"
              >
                <FormattedMessage id="Library" defaultMessage="Library" />
              </button>
            );
          }

          return (
            <button
              className="library__folder-path-item"
              key={breadcrumb.id}
              onClick={handleClick}
              type="button"
            >
              &nbsp;/&nbsp;{breadcrumb.name}
            </button>
          );
        })}
      </div>
    );
  }, [mediaStore.breadcrumbs]);

  const renderConfirmation = () => {
    if (!confirmationIsOpen) {
      return null;
    }

    const allEmpty = mediaStore.selectedMedia.folderIds
      .map((folderId) => {
        return mediaStore.foldersList.find((folder) => {
          return folder.id === folderId;
        });
      })
      .filter((folder): folder is IFolderItem => {
        return Boolean(folder);
      })
      .every((folder) => folder.empty);

    const message = allEmpty
      ? 'Are you sure to delete the selected one?'
      : 'Among the deleted folders there are folders with nested elements that will be lost when deleted. Continue deleting?';

    const handleConfirm = () => {
      mediaStore.deleteSelected();

      setConfirmationIsOpen(false);
    };

    const handleCancel = () => {
      setConfirmationIsOpen(false);
    };

    return (
      <Confirmation
        message={message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  };

  return (
    <div className="library__wrapper">
      {renderConfirmation()}
      <div className="library__head">
        <button className="library__sort">
          <FormattedMessage id="Sort" defaultMessage="Sort" />
        </button>
        <button
          className="library__create-folder"
          onClick={mediaStore.turnFolderCreationOn}
          title={intl.formatMessage({
            id: 'Create directory',
            defaultMessage: 'Create directory',
          })}
        />
        <button
          className="library__copy-media"
          title={intl.formatMessage({
            id: 'Copy',
            defaultMessage: 'Copy',
          })}
          onClick={mediaStore.copyToClipboard}
        />
        <button
          className="library__cut-media"
          title={intl.formatMessage({
            id: 'Cut',
            defaultMessage: 'Cut',
          })}
          onClick={mediaStore.cutToClipboard}
        />
        <button
          className="library__paste-media"
          title={intl.formatMessage({
            id: 'Paste',
            defaultMessage: 'Paste',
          })}
          onClick={mediaStore.pasteFromClipboard}
        />
        <button
          className="library__delete-media"
          onClick={() => setConfirmationIsOpen(true)}
          title={intl.formatMessage({
            id: 'Delete',
            defaultMessage: 'Delete',
          })}
        />
      </div>
      <div className="library__folder-path-layout">
        <button
          className="library__path-circle"
          onClick={mediaStore.navigateBackward}
          type="button"
        />
        {renderBreadcrumbs}
      </div>
      <div className="library__list" ref={libraryRef}>
        {folderList}
        {newFolder}
        {emptyFolderList}
        {mediaList}
        {emptyMediaList}
      </div>
    </div>
  );
};

export default observer(Library);
