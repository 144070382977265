import cx from 'classnames';
import { forwardRef, HTMLProps, memo, PropsWithChildren } from 'react';
import 'styles/layout.scss';

interface ILayoutProps {
  className?: string;
  flex?: boolean;
  wrap?: boolean;
  column?: boolean;
  row?: boolean;
  justifyContent?: string;
  style?: Record<string, string | number>;
}

const Layout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<
    Omit<HTMLProps<HTMLDivElement>, keyof ILayoutProps> & ILayoutProps
  >
>(
  (
    {
      className,
      flex,
      wrap = flex,
      row,
      column = flex && !row,
      justifyContent,
      children,
      style,
      ...props
    },
    ref,
  ) => (
    <div
      {...props}
      className={cx(
        className,
        flex && 'layout__flex',
        wrap && 'layout__flex-wrap',
        column && !row && 'layout__flex-column',
        row && !column && 'layout__flex-row',
      )}
      style={{ ...style, justifyContent }}
      ref={ref}
    >
      {children}
    </div>
  ),
);

export default memo(Layout);
