import React, { FC, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { observer } from 'mobx-react-lite';

import { Button, Layout } from 'components/common';
import { InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';

import VerifyIcon from 'assets/icons/confirm-email.svg';
import './styles.scss';

const VerifyRegisterEmailComponent: FC = () => {
  const location = useLocation();
  const { key } = React.useMemo(
    () => queryString.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    authStore.confirmEmail(key as string);
  }, [key]);

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <AuthWrapper title="Confirmed Email" subtitle="Your email is confirmed!">
      <Layout className="form" flex>
        <InputField>
          <img src={VerifyIcon} className="page-image auth__icon" alt="Sent" />
        </InputField>
        <InputField>
          {authStore.error ? (
            <Button
              onClick={handleReload}
              primary
              className="auth__button auth__button--margin"
            >
              <FormattedMessage id="Reload" defaultMessage="Reload" />
            </Button>
          ) : (
            <RouteLink
              routeKey="Login"
              className="button button-primary auth__button auth__button--margin"
            >
              <FormattedMessage id="Sign In" defaultMessage="Sign In" />
            </RouteLink>
          )}
        </InputField>
      </Layout>
    </AuthWrapper>
  );
};

export const VerifyRegisterEmail = observer(VerifyRegisterEmailComponent);
