import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { videoEditorStore } from 'modules/video-editor-module';
import { IEditorTrack } from 'modules/video-editor-module/types';

import { VideoType } from 'utils/api/api';

// @ts-ignore
momentDurationFormatSetup(moment);

export interface IFileTrack extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  track: IEditorTrack;
}

const FileTrackComponent: React.FC<IFileTrack> = ({
  name,
  style,
  track,
  onMouseDown,
  ...restProps
}) => {
  const { duration, start, type } = React.useMemo(() => track, [track]);

  const time = React.useMemo(
    () => moment.duration(duration, 'seconds').format('HH:mm:ss'),
    [duration],
  );

  const width = React.useMemo(
    () => `${duration * videoEditorStore.secondsToPixelFactor}px`,
    [duration, videoEditorStore.secondsToPixelFactor],
  );

  const isSelect = React.useMemo(
    () => videoEditorStore.selectedTrackIds.includes(track.id),
    [videoEditorStore.selectedTrackIds],
  );

  const extraShiftX = React.useMemo(() => {
    let shiftX = 0;

    if (isSelect) {
      shiftX += videoEditorStore.tracksDraggableShift;
    }

    if (
      videoEditorStore.trackDurationStretch &&
      track.index > videoEditorStore.trackDurationStretch.track.index
    ) {
      shiftX += videoEditorStore.trackDurationStretchShiftX;
    }

    return shiftX;
  }, [
    isSelect,
    videoEditorStore.tracksDraggableShift,
    videoEditorStore.trackDurationStretchShiftX,
  ]);

  const transform = React.useMemo(() => {
    const startPx = start * videoEditorStore.secondsToPixelFactor;

    return `translateX(${startPx + extraShiftX}px)`;
  }, [extraShiftX, videoEditorStore.secondsToPixelFactor]);

  const handleMouseDown = React.useCallback(
    (downEvent: React.MouseEvent<HTMLDivElement>) => {
      if (videoEditorStore.isReadOnly) return;
      downEvent.preventDefault();
      const { currentTarget } = downEvent;
      const { width: startWidth, right: rightEdge } =
        currentTarget.getBoundingClientRect();
      const wasPlayed = videoEditorStore.isPlayed;
      let startPointX = downEvent.clientX;
      let forcedSelection = false;
      let movedShiftX = 0;

      const isRightEdgeClick =
        track.type === 'image' &&
        startPointX >= rightEdge - videoEditorStore.secondsToPixelFactor;

      if (onMouseDown) {
        onMouseDown(downEvent);
      }

      if (!isSelect) {
        videoEditorStore.selectTrack(track);
        forcedSelection = true;
      }

      const onMouseMove = (moveEvent: MouseEvent) => {
        const shiftX = moveEvent.clientX - startPointX;

        if (videoEditorStore.isPlayed) {
          videoEditorStore.pause();
        }

        if (isRightEdgeClick) {
          runInAction(() => {
            if (videoEditorStore.trackDurationStretch) {
              videoEditorStore.trackDurationStretch.shiftX = shiftX;
            } else {
              videoEditorStore.trackDurationStretch = {
                track,
                shiftX,
              };
            }
          });

          currentTarget.style.width = `${startWidth + shiftX}px`;
        } else if (
          movedShiftX ||
          Math.abs(shiftX) > videoEditorStore.secondsToPixelFactor
        ) {
          if (!movedShiftX) {
            videoEditorStore.unSelectTracks(undefined, {
              type:
                track.type === VideoType.Audio
                  ? VideoType.Video
                  : VideoType.Audio,
            });
          }
          movedShiftX = videoEditorStore.moveTrack(shiftX);
          if (shiftX !== movedShiftX) {
            startPointX = moveEvent.clientX - movedShiftX;
          }
        }
      };

      const onMouseUp = () => {
        if (!isSelect) {
          videoEditorStore.selectTrack(track);
        } else if (!forcedSelection && !movedShiftX) {
          videoEditorStore.unSelectTracks([track.id]);
        }
        // Clear
        if (movedShiftX) {
          videoEditorStore.postTrackDragging();
        }
        if (isRightEdgeClick) {
          videoEditorStore.postTrackDurationStretch();
        }
        if (wasPlayed && !videoEditorStore.isPlayed) {
          videoEditorStore.play();
        }
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [
      isSelect,
      onMouseDown,
      track,
      videoEditorStore.isPlayed,
      videoEditorStore.secondsToPixelFactor,
      videoEditorStore.isReadOnly,
      videoEditorStore.trackDurationStretch,
    ],
  );

  const { isNext, isPrev } = React.useMemo(() => {
    const isAudio = videoEditorStore.selectedTracks.some(
      (t) => t.type === VideoType.Audio,
    );
    const { nextTrack, prevTrack } =
      videoEditorStore.notSelectedTracks[
        isAudio ? VideoType.Audio : VideoType.Video
      ];

    return {
      isNext: nextTrack ? nextTrack.id === track.id : false,
      isPrev: prevTrack ? prevTrack.id === track.id : false,
    };
  }, [
    track,
    videoEditorStore.notSelectedTracks,
    videoEditorStore.selectedTracks,
  ]);

  const handleVolumeClick = React.useCallback(() => {
    if (track.type === 'image') return;
    videoEditorStore.toggleVolume(track);
  }, [track]);

  return (
    <div
      className={cx(
        'file-track__layout',
        {
          'file-track__layout--prev': videoEditorStore.tracksDragging && isPrev,
        },
        {
          'file-track__layout--next': videoEditorStore.tracksDragging && isNext,
        },
        { 'file-track__layout--select': isSelect },
      )}
      {...restProps}
      onMouseDown={handleMouseDown}
      style={{
        ...style,
        transform,
        width,
      }}
    >
      <div className="file-track__group file-track__group-left">
        <div
          className={cx(
            'file-track__file-icon',
            { 'file-track__file-icon--video': type === VideoType.Video },
            { 'file-track__file-icon--audio': type === VideoType.Audio },
            { 'file-track__file-icon--image': type === VideoType.Image },
          )}
        />
        <span className="file-track__name">{name}</span>
      </div>
      <div className="file-track__group file-track__group-right">
        <span className="file-track__time">{time}</span>
        <div
          onClick={handleVolumeClick}
          className={cx({
            'file-track__audio': [VideoType.Audio, VideoType.Video].includes(
              type,
            ),
            'file-track__audio--off': !track.mixVolume,
          })}
        />
      </div>
    </div>
  );
};

export const FileTrack = observer(FileTrackComponent);
