import React, { ComponentProps } from 'react';
import cx from 'classnames';

import { Input } from './input';

import './styles.scss';

export const Checkbox: React.FC<
  Omit<ComponentProps<typeof Input>, 'type' | 'label' | 'placeholder'> &
    Partial<{
      colorModifier: 'green' | 'dark' | 'gray';
      sizeModifier: 'small' | 'big';
      className: string;
    }> & {
      label: React.ReactNode;
    }
> = ({
  label = 'Remember me',
  colorModifier = 'green',
  sizeModifier = 'big',
  className,
  ...props
}) => (
  <Input
    {...props}
    type="checkbox"
    placeholder={
      <>
        <div
          className={cx(
            'form__checkbox-mask',
            `form__checkbox-mask--${colorModifier}`,
            `form__checkbox-mask--${sizeModifier}`,
            className,
          )}
        />
        <span>{label}</span>
      </>
    }
  />
);
