import equal from 'deep-equal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { withYMaps } from 'react-yandex-maps';
import { useEffectOnceWhen } from 'rooks';
import cx from 'classnames';
import { Feature, Point } from 'geojson';

import {
  ABBREVIATIONS,
  ABBREVIATIONS_REVERSE,
  GREEN_COLOR,
  FIELD_NAME_SEPARATOR,
} from '../../constants';
import {
  categoryOptions,
  MAP_RADIUS_KM,
  MAP_RADIUS_WALKABLE_KM,
  MOSCOW_COORDS,
  RUSSIA,
} from './constants';
import { IMonitorItem } from '_types/stores';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { MonitorCategory } from 'utils/api/api';
import { handleError, toast } from 'utils';
import { arrayToCollection, stringToCoords } from 'utils/geojson';
import {
  geoSearch,
  searchInside,
  createCircle,
  MAX_YANDEX_SEARCH_RESULT_COUNT,
  yandexSearchTextByMonitorCategory,
  createGeoObjects,
  throttledGeoSearch,
  checkFeatureKind,
} from 'utils/yandex-api';
import {
  IGeoSearchParams,
  TYMaps,
  FeatureWithProps,
} from 'utils/yandex-api/types';

import {
  CustomMap,
  TMapEvent,
  MapInitCallback,
  MIN_SEARCH_TEXT_LENGTH,
} from 'components/custom-map';
import { Button } from 'components/common';
import { Checkbox, Form } from 'components/forms';

export type TFilterStatus =
  | 'submitProcess'
  | 'resetProcess'
  | 'wasSubmit'
  | 'initial';

export interface IMapFilterData {
  selectedCategories: MonitorCategory[];
  address?: string;
  radius?: number;
  latitude?: number;
  longitude?: number;
}

export interface IMonitorsMapProps {
  onFilter: (monitorIds: Array<IMonitorItem['id']> | null) => void;
  onMonitorSelect: (id: string) => void;
  ymaps: TYMaps;
}

const MonitorsMapComponent: React.FC<IMonitorsMapProps> = ({
  onFilter,
  onMonitorSelect,
  ymaps,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = React.useState<Parameters<MapInitCallback>[0] | null>(
    null,
  );

  const [geoLocations, setGeoLocations] = React.useState<
    Array<FeatureWithProps>
  >([]);
  const [selectAddressIsOpen, setSelectAddressIsOpen] = React.useState(false);
  const [selectCategoryIsOpen, setSelectCategoryIsOpen] = React.useState(false);
  const [filterStatus, setFilterStatus] =
    React.useState<TFilterStatus>('initial');
  const [filterData, setFilterData] = React.useState<IMapFilterData>(() => {
    if (location.search && filterStatus === 'initial') {
      const searchParams = new URLSearchParams(location.search);
      const searchData = Object.fromEntries(
        [...searchParams.entries()]
          .filter(([abbr]) => abbr in ABBREVIATIONS_REVERSE)
          .map(([abbr, v]) => [ABBREVIATIONS_REVERSE[abbr], v]),
      );

      ['latitude', 'longitude', 'radius'].forEach((k) => {
        if (k in searchData) {
          // @ts-ignore
          searchData[k] = +searchData[k];
        }
      });

      return {
        ...searchData,
        selectedCategories: searchData.selectedCategories
          ? searchData.selectedCategories
              .split(',')
              .map((abbr) => ABBREVIATIONS_REVERSE[abbr] as MonitorCategory)
          : [],
      };
    }

    return {
      selectedCategories: [],
    };
  });

  const loadGeoLocations = React.useCallback(
    (text: IGeoSearchParams['text']) => {
      void throttledGeoSearch(
        {
          text,
          results: 15,
        },
        ({ features }) => {
          const locations = features.filter((f): f is FeatureWithProps =>
            checkFeatureKind(f, ['locality', 'province']),
          );

          setGeoLocations((state) => {
            if (equal(state, locations, { strict: true })) {
              return state;
            }

            return locations;
          });
        },
      );
    },
    [setGeoLocations],
  );

  const handleCategorySelectClick = React.useCallback(
    () => setSelectCategoryIsOpen((state) => !state),
    [setSelectCategoryIsOpen],
  );

  const handleCoordsPaste = React.useCallback(
    (e: React.ClipboardEvent) => {
      const clipboardString = e.clipboardData.getData('text/plain');
      const [latitude, longitude] = stringToCoords(clipboardString);

      if (latitude && longitude) {
        e.preventDefault();
        setFilterData((state) => ({
          ...state,
          latitude,
          longitude,
        }));
      }
    },
    [setFilterData],
  );

  const handleFormChange = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) =>
      setFilterData((state) => {
        const { name, type, value } = e.target;
        let updatedData: Partial<IMapFilterData> = {
          [name]: value,
        };

        if (type === 'number' || name === 'radius') {
          // @ts-ignore
          updatedData[name] = value && +value;
        }

        if (name === 'latitude') {
          if (-90 > value || value > 90) {
            return state;
          }
        }

        if (name === 'longitude') {
          if (-180 > value || value > 180) {
            return state;
          }
        }

        if (name === 'address') {
          if (value === state.address) {
            setSelectAddressIsOpen(false);
          }
          switch (type) {
            case 'text':
              {
                if (value.length >= MIN_SEARCH_TEXT_LENGTH) {
                  loadGeoLocations(value);
                  setSelectAddressIsOpen(true);
                } else {
                  setGeoLocations([]);
                  setSelectAddressIsOpen(false);
                }
              }
              break;

            case 'radio': {
              setSelectAddressIsOpen(false);
            }
          }
        }

        if (name.includes(`category${FIELD_NAME_SEPARATOR}`)) {
          const [, category] = name.split(FIELD_NAME_SEPARATOR) as [
            'category',
            MonitorCategory,
          ];

          updatedData = {
            selectedCategories: state.selectedCategories.includes(category)
              ? state.selectedCategories.filter((c) => category !== c)
              : state.selectedCategories.concat(category),
          };
        }

        return {
          ...state,
          ...updatedData,
        };
      }),
    [setFilterData, loadGeoLocations],
  );

  const onlyCoords = React.useMemo(
    () => Boolean(filterData.latitude && filterData.longitude),
    [filterData.latitude, filterData.longitude],
  );

  const selectedCategoriesCount = React.useMemo(
    () => filterData.selectedCategories.length,
    [filterData.selectedCategories],
  );

  const filterDisabled = React.useMemo(() => {
    let isDisabled = false;

    if (filterStatus === 'submitProcess') {
      return true;
    }

    if (selectedCategoriesCount) {
      return false;
    }

    if (onlyCoords) {
      isDisabled = !filterData.radius; // центр круга без радиуса
    } else {
      const filledFields = Object.entries(filterData).filter(
        ([k, v]) =>
          !['selectedCategories', 'latitude', 'longitude'].includes(k) && v,
      );
      isDisabled = !filledFields.length;

      if (filledFields.length === 1) {
        const nameToValue = Object.fromEntries(filledFields);

        if (nameToValue.radius) {
          // радиус без какого-либо центра
          isDisabled = true;
        }
      }
    }

    return isDisabled;
  }, [filterData, onlyCoords, selectedCategoriesCount, filterStatus]);

  const parseMonitorList = React.useCallback(async () => {
    if (monitorsStore.count !== monitorsStore.list.length) {
      await monitorsStore.getList();
    }
    const monitorCollection = arrayToCollection(
      monitorsStore.list,
      'location',
      ['id', 'category'],
    );

    return {
      monitorCollection,
      monitorGeoObjects: createGeoObjects(ymaps)({
        collection: monitorCollection,
      }),
    };
  }, [ymaps, monitorsStore.list, monitorsStore.count]);

  const showAllMonitors = async () => {
    if (!map) return;

    try {
      map.geoObjects.removeAll();

      const { monitorGeoObjects } = await parseMonitorList();

      // @ts-ignore
      monitorGeoObjects.addEvents('click', (e) => {
        const id = e.get('target').properties.get('id');
        onMonitorSelect(id);
      });

      // @ts-ignore
      monitorGeoObjects.setOptions('iconColor', GREEN_COLOR).addToMap(map);
    } catch (e) {
      toast.error(handleError(e));
      console.error(e);
    }
  };

  useEffectOnceWhen(() => {
    showAllMonitors();
  }, Boolean(map && monitorsStore.list.length > 0));

  React.useEffect(() => {
    showAllMonitors();
  }, [map]);

  const handleFormReset = React.useCallback(() => {
    if (map) {
      map.geoObjects.removeAll();
    }

    setFilterStatus('resetProcess');
    onFilter(null);
    showAllMonitors();
  }, [map, onFilter, setFilterStatus]);

  const handleFormSubmit = React.useCallback(async () => {
    if (filterDisabled || !map) return;
    const { address, selectedCategories, latitude, longitude, radius } =
      filterData;
    let filteredIds: Parameters<IMonitorsMapProps['onFilter']>[0] = [];

    const getAndSetRadius = (defaultRadiusKm: number) => {
      let circleRadius = radius;

      if (!circleRadius) {
        circleRadius = defaultRadiusKm;
        setFilterData((state) => ({
          ...state,
          radius: circleRadius,
        }));
      }

      return circleRadius;
    };

    setFilterStatus('submitProcess');

    try {
      map.geoObjects.removeAll();

      let { monitorGeoObjects } = await parseMonitorList();

      // @ts-ignore
      monitorGeoObjects.addEvents('click', (e) => {
        const id = e.get('target').properties.get('id');
        onMonitorSelect(id);
      });

      if (latitude && longitude && radius) {
        const circle = createCircle(ymaps)(
          [latitude, longitude],
          radius * 1000,
        );

        map.geoObjects.add(circle);

        monitorGeoObjects = searchInside(monitorGeoObjects, circle);
      } else {
        let filteredObjects = createGeoObjects(ymaps)({});

        if (selectedCategoriesCount) {
          const searchAddress = address || RUSSIA,
            searchCircleRadius = getAndSetRadius(MAP_RADIUS_WALKABLE_KM);
          let outsideObjects = createGeoObjects(ymaps)({
            objects: monitorGeoObjects,
          });

          const categorySearch = (category: MonitorCategory) =>
            new Promise((resolve) => {
              geoSearch(
                {
                  text: `${yandexSearchTextByMonitorCategory[category]}, ${searchAddress}`,
                  type: 'biz',
                  results: MAX_YANDEX_SEARCH_RESULT_COUNT,
                },
                ({ features }) => {
                  features
                    .map((f) => {
                      const geometry = f.geometry as Point;

                      return createCircle(ymaps)(
                        geometry.coordinates,
                        // @ts-ignore
                        searchCircleRadius * 1000,
                      );
                    })
                    .forEach((circle) => {
                      map.geoObjects.add(circle);

                      const objectsOnCircle = searchInside(
                        outsideObjects,
                        circle,
                      );

                      if (!objectsOnCircle.getLength()) {
                        map.geoObjects.remove(circle);
                      }
                      // @ts-ignore
                      filteredObjects = filteredObjects.add(objectsOnCircle);
                      // @ts-ignore
                      outsideObjects = outsideObjects.remove(objectsOnCircle);
                    });

                  resolve(true);
                },
              );
            });

          await Promise.all(selectedCategories.map(categorySearch));
        } else if (address) {
          const circleRadius = getAndSetRadius(MAP_RADIUS_KM[0]);
          let feature: FeatureWithProps | Feature | undefined =
            geoLocations.find(
              (f) =>
                `${f.properties.name}, ${f.properties.description}` === address,
            );

          if (!feature) {
            await new Promise((resolve, reject) => {
              geoSearch({ text: address, results: 1 }, ({ features }) => {
                if (features.length) {
                  feature = features[0];
                  resolve(true);
                } else {
                  reject('Address not found');
                }
              });
            });
          }
          if (feature) {
            const geometry = feature.geometry as Point;

            const circle = createCircle(ymaps)(
              geometry.coordinates,
              // @ts-ignore
              circleRadius * 1000,
            );

            map.geoObjects.add(circle);

            filteredObjects = searchInside(monitorGeoObjects, circle);
          }
        }

        monitorGeoObjects = filteredObjects;
      }

      monitorGeoObjects.each((obj) => {
        const monitorId = obj.properties.get(
          'id',
          {},
        ) as unknown as IMonitorItem['id'];

        // @ts-ignore
        filteredIds.push(monitorId);
      });
      // @ts-ignore
      monitorGeoObjects.setOptions('iconColor', GREEN_COLOR).addToMap(map);

      const bounds = map.geoObjects.getBounds();
      if (bounds) {
        await map.setBounds(bounds, {
          checkZoomRange: true,
        });
      }
    } catch (e) {
      filteredIds = null;
      toast.error(handleError(e));
      console.error(e);
    } finally {
      onFilter(filteredIds);
      setFilterStatus('wasSubmit');
    }
  }, [
    map,
    filterData,
    setFilterData,
    filterDisabled,
    setFilterStatus,
    onFilter,
    ymaps,
    selectedCategoriesCount,
    geoLocations,
  ]);

  const categories = React.useMemo(
    () =>
      categoryOptions.map((category) => (
        <label className="monitor__filter-category-option" key={category}>
          <Checkbox
            name={`category${FIELD_NAME_SEPARATOR}${category}`}
            label=""
            colorModifier="gray"
            checked={filterData.selectedCategories.includes(category)}
            onChange={() => null}
          />
          <FormattedMessage id={category} defaultMessage={category} />
        </label>
      )),
    [filterData.selectedCategories],
  );

  const addressList = React.useMemo(
    () =>
      geoLocations
        .filter(
          (
            f,
          ): f is typeof f & {
            properties: NonNullable<typeof f.properties>;
          } => Boolean(f.properties),
        )
        .map(({ properties, geometry }) => {
          const address = `${properties.name}, ${properties.description}`;
          const checked = address === filterData.address;

          return (
            <label
              key={JSON.stringify(geometry)}
              className={cx(['monitor__filter-address-option', { checked }])}
            >
              <input
                name="address"
                type="radio"
                value={address}
                checked={checked}
                onChange={() => null}
              />
              {address}
            </label>
          );
        }),
    [geoLocations, filterData.address],
  );

  const radiusList = React.useMemo(
    () =>
      MAP_RADIUS_KM.map((km) => {
        const checked = km === filterData.radius;

        return (
          <label
            key={`radius-${km}`}
            className={cx(['monitor__filter-radius-select', { checked }])}
          >
            <input
              name="radius"
              type="radio"
              value={km}
              checked={checked}
              onChange={() => null}
            />
            {km} <FormattedMessage id="km" defaultMessage="km" />
          </label>
        );
      }),
    [filterData.radius],
  );

  const handleMapClick = React.useCallback(
    (e: TMapEvent) => {
      const [lat, lng] = e.get('coords');

      setFilterData((state) => ({
        ...state,
        latitude: lat,
        longitude: lng,
      }));
    },
    [setFilterData],
  );

  React.useEffect(() => {
    switch (filterStatus) {
      case 'resetProcess':
        {
          setFilterData({
            selectedCategories: [],
          });
          navigate('?');
          setFilterStatus('initial');
        }
        break;

      case 'submitProcess': {
        const searchParams = new URLSearchParams();

        Object.entries(filterData)
          .filter(([, v]) => v)
          .forEach(([k, v]) => {
            if (k === 'selectedCategories') {
              if (!v.length) return;
              v = v.map((category: MonitorCategory) => ABBREVIATIONS[category]);
            }
            // @ts-ignore
            searchParams.append(ABBREVIATIONS[k], v);
          });

        navigate(`?${searchParams}`);
      }
    }
  }, [history, filterStatus, filterData, setFilterData]);

  return (
    <div className="monitor__map">
      <CustomMap
        onInit={(map) => {
          setMap(map);
        }}
        defaultState={{
          center: MOSCOW_COORDS,
          zoom: 8,
        }}
        events={[['click', handleMapClick]]}
        width="100%"
        height="100%"
      />
      <Form
        className="monitor__filter"
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
      >
        <div
          className={cx([
            'monitor__filter-address-layout',
            {
              'monitor__filter-address-layout--open':
                selectAddressIsOpen && !onlyCoords,
            },
            { 'monitor__filter-address-layout--disabled': onlyCoords },
          ])}
        >
          <input
            autoComplete="off"
            disabled={onlyCoords}
            name="address"
            className="monitor__filter-input monitor__filter-address"
            placeholder={intl.formatMessage({
              id: 'Locality (address)',
              defaultMessage: 'Locality (address)',
            })}
            value={filterData.address || ''}
          />
          <div className="list">
            {selectAddressIsOpen && !onlyCoords ? addressList : null}
          </div>
        </div>
        <div
          className={cx([
            'monitor__filter-category-layout',
            {
              'monitor__filter-category-layout--open':
                selectCategoryIsOpen && !onlyCoords,
            },
            { 'monitor__filter-category-layout--disabled': onlyCoords },
          ])}
        >
          <button
            type="button"
            disabled={onlyCoords}
            className={cx([
              'monitor__filter-category',
              { 'monitor__filter-category--open': selectCategoryIsOpen },
            ])}
            onClick={handleCategorySelectClick}
          >
            {selectedCategoriesCount ? (
              <FormattedMessage
                id="Multiple selected"
                values={{
                  count: selectedCategoriesCount,
                }}
              />
            ) : (
              <FormattedMessage
                id="Select an object"
                defaultMessage="Select an object"
              />
            )}
          </button>
          <div className="list">
            {selectCategoryIsOpen && !onlyCoords ? categories : null}
          </div>
        </div>
        <div className="monitor__filter-coordinates">
          <FormattedMessage id="W./L." defaultMessage="W./L." />
          <div>
            <input
              name="latitude"
              className="monitor__filter-input monitor__filter-coordinate monitor__filter-coordinate--first"
              placeholder="55.727639"
              type="number"
              step={'any'}
              value={filterData.latitude || ''}
              onPaste={handleCoordsPaste}
            />
            <input
              name="longitude"
              className="monitor__filter-input monitor__filter-coordinate"
              placeholder="37.476842"
              type="number"
              step={'any'}
              value={filterData.longitude || ''}
              onPaste={handleCoordsPaste}
            />
          </div>
        </div>
        <div className="monitor__filter-radius-layout">
          <FormattedMessage
            id="Search radius (km)"
            defaultMessage="Search radius (km)"
          />
          <div className="monitor__filter-radius-decor" />
          <input
            name="radius"
            className="monitor__filter-input monitor__filter-radius"
            placeholder="000.00"
            type="number"
            step={'any'}
            value={filterData.radius || ''}
          />
          <div className="monitor__filter-radius-decor monitor__filter-radius-decor--second" />
        </div>
        <div className="monitor__filter-radius-select-layout">{radiusList}</div>
        <Button
          disabled={filterDisabled}
          type="submit"
          primary
          className={cx([
            'monitor__filter-search',
            { 'monitor__filter-search--disabled': filterDisabled },
          ])}
        >
          <FormattedMessage id="Search" defaultMessage="Search" />
        </Button>
        {filterStatus === 'wasSubmit' ? (
          <button type="reset" className="monitor__filter-clean">
            <FormattedMessage
              id="Clear the search result"
              defaultMessage="Clear the search result"
            />
          </button>
        ) : null}
        {filterStatus === 'submitProcess' ? (
          <span className="monitor__filter-status">
            <FormattedMessage id="Search Process" />
          </span>
        ) : null}
      </Form>
    </div>
  );
};

export const MonitorsMap = withYMaps<Omit<IMonitorsMapProps, 'ymaps'>>(
  // @ts-ignore
  observer(MonitorsMapComponent),
  true,
  ['geoQuery', 'Circle'],
);
