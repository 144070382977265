import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { YMaps } from 'react-yandex-maps';

import { appStore } from './stores/app';

import { AppRouteSwitch } from 'modules/routing-module';

import 'styles/toasts.scss';

const AppComponent: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * @todo remove this
   */
  useEffect(() => {
    appStore.setIntl(intl);
    appStore.setHistory(navigate, location);
  }, [intl]);

  return (
    <YMaps
      query={{
        lang: 'ru_RU',
        apikey: process.env.REACT_APP_YMAPS_GEOCODER_KEY,
      }}
    >
      <AppRouteSwitch />
      <ToastContainer
        closeButton={<button className="toast__close-button" />}
        position="top-right"
        hideProgressBar
      />
    </YMaps>
  );
};

export const App = observer(AppComponent);
