import { AxiosError } from 'axios';
import { appStore } from 'stores/app';

export interface IApiErrorData<T = unknown> {
  status: number;
  statusCode: number;
  message: string;
  errors: { body: Error[] };
  data: T;
}

export const handleError = (error?: AxiosError | unknown): string => {
  if (!error) {
    return 'Unknown Error';
  }
  const err = error as AxiosError<IApiErrorData>;
  const { response, message } = err;
  if (!response) {
    return message;
  }

  if (response.status >= 500) {
    return 'Server error';
  }

  if (response.data.errors) {
    const { body } = response.data.errors;
    return body
      .map((_error: Error) =>
        appStore.intl.formatMessage({
          id: _error.message,
          defaultMessage: _error.message,
        }),
      )
      .join('\n');
  }

  return response.data.message || message;
};
