import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { formatBytes, formatSeconds } from 'utils';
import { IMediaItem } from '_types/stores';

import { Checkbox } from 'components/forms';
import { FilePreview } from 'components/file-preview';
import { VideoType } from 'utils/api/api';

export interface IEditorLibraryItem
  extends React.HTMLAttributes<HTMLDivElement> {
  mediaItem: IMediaItem;
  selected: boolean;
}

export const EditorLibraryItem: React.FC<IEditorLibraryItem> = ({
  mediaItem,
  className,
  selected,
  ...restProps
}) => {
  return (
    <div {...restProps} className={cx('video-library__item', className)}>
      <div className="video-library__format">{mediaItem.extension}</div>
      <Checkbox
        name="video_checkbox"
        label=""
        fieldClass="video-library__checkbox"
        sizeModifier="small"
        colorModifier="dark"
        onClick={restProps.onClick}
        checked={selected}
        readOnly={true}
      />
      <div className="video-library__preview">
        <FilePreview
          file={mediaItem}
          className="video-library__preview-image"
        />
      </div>
      <div className="video-library__info">
        <span className="video-library__name">{mediaItem.name}</span>
        <div className="video-library__status">
          <FormattedMessage id="Active" defaultMessage="Active" />
        </div>
        <div className="video-library__info-container">
          {mediaItem.videoType !== VideoType.Image && (
            <span className="video-library__time">
              {mediaItem.duration ? formatSeconds(mediaItem.duration) : null}
            </span>
          )}
          <span className="video-library__file-size">
            {mediaItem.filesize ? formatBytes(+mediaItem.filesize) : null}
          </span>
        </div>
        {mediaItem.videoType !== VideoType.Audio && (
          <span className="video-library__size">
            {mediaItem.width && mediaItem.height
              ? `${mediaItem.width}x${mediaItem.height} pix`
              : null}
          </span>
        )}
      </div>
    </div>
  );
};
