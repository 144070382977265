import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import {
  formatSeconds,
  summarizeDurationFromArray,
  summarizeFilesizeFromArray,
} from 'utils';
import { IPlaylistItem } from '_types/stores';

import { Layout } from 'components/common';

import { playlistsStore } from 'stores/playlists';
import { IPlaylistAddEditStore } from 'pages/playlists/add-edit';

import './styles.scss';

const TimelineFooter: React.FC<{
  localStore: IPlaylistAddEditStore;
  playlistMediaItems: IPlaylistItem['files'];
}> = ({ localStore, playlistMediaItems }) => {
  const { totalDuration, totalFilesize } = React.useMemo(
    () => ({
      totalDuration: formatSeconds(
        summarizeDurationFromArray(playlistMediaItems, 'duration'),
      ),
      totalFilesize: summarizeFilesizeFromArray(
        playlistMediaItems.map((m) => m.filesize || 0),
      ),
    }),
    [playlistMediaItems],
  );

  const handleDeleteSelected = React.useCallback(() => {
    const { editedPlaylist } = playlistsStore;

    if (!editedPlaylist) return;

    const checkedMediaIds = localStore.checkedMediaItems.map((m) => m.id);

    playlistsStore.updatePlaylist(
      {
        files: editedPlaylist.files.filter(
          (file) => !checkedMediaIds.includes(file.id),
        ),
      },
      true,
    );

    localStore.unCheckMediaItems(checkedMediaIds);
  }, [
    playlistsStore.editedPlaylist,
    playlistsStore.editedPlaylistMediaIds,
    localStore.checkedMediaItems,
  ]);

  const buttons = React.useMemo(
    () =>
      playlistMediaItems.length > 1 &&
      localStore.checkedMediaItems.length &&
      !playlistMediaItems.every((m) =>
        localStore.checkedMediaItems.some((cM) => cM.id === m.id),
      ) ? (
        <button
          type="button"
          className="add__edit__timeline__footer_item add__edit__timeline__button"
          onClick={handleDeleteSelected}
        >
          <div className="add__edit__timeline__button-content">
            <FormattedMessage
              id="Delete selected"
              defaultMessage="Delete selected"
            />
          </div>
        </button>
      ) : null,
    [
      playlistMediaItems.length,
      localStore.checkedMediaItems.length,
      handleDeleteSelected,
    ],
  );

  return (
    <Layout flex row className="add__edit__timeline__footer">
      {buttons}
      <span className="add__edit__timeline__footer_item ml-auto">
        <FormattedMessage id="The total time of the playlist" />:{' '}
        {totalDuration}
      </span>
      <span className="add__edit__timeline__footer_item">
        <FormattedMessage id="Overall size" />: {totalFilesize}
      </span>
    </Layout>
  );
};

export default observer(TimelineFooter);
