import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { authStore } from '../../stores/auth';
import { Layout } from 'components/common';
import AddMoneyIcon from 'assets/icons/plus-add.svg';
import { RouteLink } from '../../modules/routing-module';

import './styles.scss';

export const Balance: FC = observer(() => {
  const intl = useIntl();

  return (
    <Layout flex row>
      <div className="navigation__balance">
        {intl.formatNumber(authStore.user?.wallet?.total || 0, {
          style: 'currency',
          currency: 'RUB',
        })}
      </div>
      <RouteLink
        routeKey="Profile"
        className="button button-primary button-rounded navigation__add-money"
      >
        <img className="navigation__add-icon" src={AddMoneyIcon} alt="+" />
        <FormattedMessage
          id="app.button.depositWallet"
          defaultMessage="Пополнить"
        />
      </RouteLink>
    </Layout>
  );
});
