import { UserRole } from '_types/constants';

export const tableConstants = {
  columns: [
    'ID',
    { accessor: 'name', withSort: true },
    { accessor: 'surname', withSort: true },
    { accessor: 'middleName', withSort: true },
    { accessor: 'email', withSort: true },
    { accessor: 'role', withSort: true },
    'createdAt',
    'updatedAt',
    'actions',
  ],

  userRoles: [
    UserRole.Advertiser,
    UserRole.MonitorOwner,
    UserRole.Administrator,
  ],
};
