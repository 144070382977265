import { errorHandlerStore } from 'stores/error-handler/error-handler';
import { RequestStatus } from '../api/components';

export const checkPaginationPage = (
  requestStatus: RequestStatus,
  count: number,
  currentPage: number,
) => {
  if ([RequestStatus.Idle, RequestStatus.Request].includes(requestStatus)) {
    if (count > 0 && currentPage > Math.ceil(count / 10)) {
      errorHandlerStore.setError('404', 'Page does not exist');
    }
  }
};
