import React from 'react';

import { FileResponse, VideoType } from 'utils/api/api';
import { useFilePreview } from 'hooks/use-file-preview';

export interface IFilePreview {
  file: FileResponse;
  className: string;
}

export const FilePreview: React.FC<IFilePreview> = ({ file, className }) => {
  const previewLink = useFilePreview(file);

  const setAutoPlay = React.useCallback<
    React.MouseEventHandler<HTMLVideoElement>
  >((e) => {
    if (e.currentTarget.paused) {
      e.currentTarget.play();
    } else {
      e.currentTarget.pause();
    }
  }, []);

  const preview = React.useMemo(() => {
    if (file.videoType === VideoType.Video) {
      return (
        <video
          className={className}
          src={previewLink}
          muted={true}
          loop={true}
          onMouseLeave={setAutoPlay}
          onMouseEnter={setAutoPlay}
        />
      );
    }
    return <img src={previewLink} className={className} alt={file.name} />;
  }, [previewLink, file.videoType, file.name, setAutoPlay]);

  return preview;
};
