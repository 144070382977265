import { EditorLayerResponse, EditorResponse } from 'utils/api/api';
import { IEditorProject, IEditorTrack } from './types';

export function normalizeLayer(
  serverLayer: EditorLayerResponse | IEditorTrack,
): IEditorTrack {
  return {
    ...serverLayer,
    mediaId: serverLayer.file.id,
    type: serverLayer.file.videoType,
  };
}

export function normalizeProject(
  serverProject: EditorResponse | IEditorProject,
): IEditorProject {
  const lastAudio =
    serverProject.audioLayers &&
    serverProject.audioLayers[serverProject.audioLayers.length - 1];
  const lastLayer =
    serverProject.videoLayers &&
    serverProject.videoLayers[serverProject.videoLayers.length - 1];
  let audio_duration = 0,
    layer_duration = 0;

  if (lastAudio) {
    audio_duration = lastAudio.start + lastAudio.duration;
  }
  if (lastLayer) {
    layer_duration = lastLayer.start + lastLayer.duration;
  }

  return {
    ...serverProject,
    audioLayers: serverProject.audioLayers
      ? serverProject.audioLayers.map(normalizeLayer)
      : [],
    videoLayers: serverProject.videoLayers
      ? serverProject.videoLayers.map(normalizeLayer)
      : [],
    totalDuration:
      audio_duration > layer_duration ? audio_duration : layer_duration,
  };
}
