import { ITable } from 'components/table';

const timelineColumns: ITable['columns'] = [
  {
    accessor: 'order',
    label: ' ',
  },
  {
    accessor: 'preview',
    label: ' ',
  },
  {
    accessor: 'checkbox',
    label: ' ',
  },
  'extension',
  'name',
  'duration',
  'filesize',
];

export default timelineColumns;
