import React, { FC, PropsWithChildren, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { toast } from 'utils';
import { RouteRedirect } from 'modules/routing-module';

const AuthWrapper: FC<
  PropsWithChildren<{
    title?: string;
    subtitle?: string;
  }>
> = ({ title, subtitle, children }) => {
  useEffect(() => {
    authStore.resetFields();
  }, [title]);

  useEffect(() => {
    if (authStore.error) {
      toast.error(authStore.error);
    }
  }, [authStore.error]);

  return (
    <div className="container__boxed" key="auth-wrapper">
      <div className="app-logo" key="app-logo-boxed" />
      <div className="content content--authentication" key="app-content">
        {title && (
          <h2
            className={cx('container__boxed__title', 'container__title-margin')}
          >
            <FormattedMessage id={title} defaultMessage={title} />
          </h2>
        )}
        {subtitle && (
          <p
            className={cx(
              'container__boxed__subtitle',
              'container__text-margin',
            )}
          >
            <FormattedMessage id={subtitle} defaultMessage={subtitle} />
          </p>
        )}

        {children}
      </div>
      {authStore.wasAuthenticated && authStore.user ? (
        <RouteRedirect routeKey="Home" />
      ) : null}
    </div>
  );
};

export default observer(AuthWrapper);
