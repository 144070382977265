import React from 'react';
import { formatSeconds } from 'utils';
import { observer } from 'mobx-react-lite';

import { playlistsStore } from 'stores/playlists';

import {
  IPlaylistAddEditStore,
  TMediaChangeHandler,
} from 'pages/playlists/add-edit';

import { Layout } from 'components/common';
import { Checkbox } from 'components/forms';
import { MediaListItemPreview } from 'components/media-list';
import { Table } from 'components/table';
import TimelineColumns from './timeline-columns';
import TimelineFooter from './timeline-footer';
import TimelineHeader from './timeline-header';

import './styles.scss';
import { IMediaItem } from '_types/stores';
import { mediaStore } from 'stores/media';

export interface IPlaylistTimeline {
  localStore: IPlaylistAddEditStore;
  handleMediaChange: TMediaChangeHandler;
}

const PlaylistTimeline: React.FC<IPlaylistTimeline> = ({
  localStore,
  handleMediaChange,
}) => {
  const handleItemOrderChange = React.useCallback(
    (item: IMediaItem, diff: 1 | -1) => {
      playlistsStore.changeMediaOrder(item, diff);
    },
    [playlistsStore.changeMediaOrder],
  );

  const playlistMediaItems = React.useMemo(
    () => playlistsStore.editedPlaylist?.files || [],
    [mediaStore.mediaList, playlistsStore.editedPlaylistMediaIds],
  );

  const table = React.useMemo(() => {
    const lastIdx = playlistMediaItems.length - 1;

    const data = playlistMediaItems.map((m, index) => ({
      ...m,
      order: (
        <div className="add__edit__timeline__order">
          <button
            type="button"
            className="add__edit__timeline__order-button top"
            onClick={() => handleItemOrderChange(m, -1)}
            disabled={index === 0}
          >
            &nbsp;
          </button>
          {index + 1}
          <button
            type="button"
            className="add__edit__timeline__order-button bottom"
            onClick={() => handleItemOrderChange(m, 1)}
            disabled={index === lastIdx}
          >
            &nbsp;
          </button>
        </div>
      ),
      preview: <MediaListItemPreview item={m} />,
      checkbox: (
        <Checkbox
          checked={localStore.isCheckedMediaItem(m)}
          onChange={(e) => handleMediaChange(m, e)}
          label=" "
          colorModifier="dark"
        />
      ),
      extension: (
        <div className="add__edit__timeline__content-wrapper">
          <span className="add__edit__timeline_ext">{m.extension}</span>
        </div>
      ),
      name: (
        <div className="add__edit__timeline__content-wrapper">
          <span>{m.name}</span>
        </div>
      ),
      duration: (
        <div className="add__edit__timeline__content-wrapper">
          <span>{m.duration ? formatSeconds(m.duration) : 0}</span>
        </div>
      ),
      filesize: (
        <div className="add__edit__timeline__content-wrapper">
          <span>{m.filesize}</span>
        </div>
      ),
    }));

    return data.length ? <Table columns={TimelineColumns} data={data} /> : null;
  }, [
    mediaStore.mediaList,
    playlistMediaItems,
    localStore.checkedMediaItems,
    handleItemOrderChange,
    handleMediaChange,
  ]);

  return (
    <Layout flex column className="add__edit__timeline">
      <TimelineHeader />
      {table}
      <TimelineFooter
        localStore={localStore}
        playlistMediaItems={playlistMediaItems}
      />
    </Layout>
  );
};

export default observer(PlaylistTimeline);
