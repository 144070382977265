import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { IPlaylistItem } from '_types/stores';

import { Button } from 'components/common';
import { Form, Input, IFormProps } from 'components/forms';

import './styles.scss';
import IconPlaylistAdd from 'assets/icons/playlist-added.svg';
import IconPlaylist from 'assets/icons/playlist.svg';

const data = {
  className: 'playlist__form',
  placeholder: 'enter the name of the new playlist',
  inputIcon: IconPlaylist,
  inputProps: { minLength: 6 },
  submitIcon: IconPlaylistAdd,
  submitProps: { primary: false },
  noIntl: undefined,
  hint: undefined,
};

export interface IAddPlaylistForm extends Omit<IFormProps, 'onSubmit'> {
  onSubmit(draftPlaylist: Partial<IPlaylistItem>): void;
}

const AddPlaylist: React.FC<IAddPlaylistForm> = ({
  onSubmit,
  className,
  ...restProps
}) => {
  const intl = useIntl();
  const [value, setValue] = React.useState('');
  // Callbacks
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [],
  );
  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (value.length) {
        onSubmit({ name: value });
      }
    },
    [value],
  );

  return (
    <Form
      {...restProps}
      onSubmit={handleSubmit}
      className={cx('big-input__form', data.className, className)}
    >
      <Input
        type="text"
        {...data.inputProps}
        value={value}
        onChange={handleChange}
        rawPlaceholder={
          data.noIntl
            ? data.placeholder
            : intl.formatMessage({ id: data.placeholder })
        }
        className="big-input__form__input big-input__playlist-input"
        required
        fieldElement={
          <img
            src={data.inputIcon}
            alt="Input icon"
            className="big-input__form__input-icon"
          />
        }
        noIntl={data.noIntl}
      />
      {data.submitIcon && (
        <Button
          type="submit"
          {...data.submitProps}
          className="big-input__playlist-submit"
        >
          <img
            src={data.submitIcon}
            alt="Icon submit"
            className="big-input__playlist-submit-icon"
          />
        </Button>
      )}
      {data.hint && <span className="big-input__form_hint">{data.hint}</span>}
    </Form>
  );
};

export default AddPlaylist;
