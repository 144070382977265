import React from 'react';
import { FormattedMessage } from 'react-intl';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { playlistsStore } from 'stores/playlists';

import { Layout } from 'components/common';
import { Input } from 'components/forms';

import './styles.scss';

const TimelineHeader: React.FC = () => {
  const buttonDisabled = React.useMemo(
    () => !playlistsStore.canSavePlaylist,
    [playlistsStore.canSavePlaylist],
  );

  const handleInputChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) =>
      runInAction(() => {
        const input = e.target as HTMLInputElement;

        if (playlistsStore.editedPlaylist) {
          playlistsStore.editedPlaylist.name = input.value;
        }
      }),
    [playlistsStore.editedPlaylist],
  );

  return (
    <Layout flex row className="add__edit__timeline__header">
      <span className="add__edit__timeline__header_item add__edit__timeline__header__playlist-name">
        <FormattedMessage
          id="The name of the playlist"
          defaultMessage="The name of the playlist"
        />
        :
      </span>
      <Input
        rawPlaceholder="Playlist name"
        type="text"
        name="name"
        onChange={handleInputChange}
        value={playlistsStore.editedPlaylistName}
        required
      />
      <button
        type="submit"
        className={cx(
          'add__edit__timeline__header_item',
          'add__edit__timeline__button',
          {
            add__edit__timeline__button_disabled: buttonDisabled,
          },
        )}
        disabled={buttonDisabled}
      >
        <div className="add__edit__timeline__button-content">
          <FormattedMessage id="Save" defaultMessage="Save" />
        </div>
      </button>
    </Layout>
  );
};

export default observer(TimelineHeader);
