import { action, makeObservable, observable } from 'mobx';
import { LimitRequest, Order, RequestParams } from '../api';
import { AxiosResponse } from 'axios';

export type TOrderAccessor = string;

export enum RequestStatus {
  Failure = 'failure',
  Idle = 'idle',
  Success = 'success',
  Request = 'request',
}

export interface IListOrder {
  count: number;
  order: Record<TOrderAccessor, Order>;
  requestStatus: RequestStatus;

  setOrder(accessor: TOrderAccessor): void;

  getSortedList<
    T extends (data: U, params?: RequestParams) => ReturnType<T>,
    U extends Partial<{
      where: Record<
        string,
        // @typescript-eslint/no-explicit-any
        any
      >;
      scope: LimitRequest;
      // @typescript-eslint/no-explicit-any
      select: any[];
    }>,
  >(
    apiListGet: T,
    data: U,
  ): Promise<ReturnType<T>>;
}

export const DEFAULT_COUNT = 0;

export class ListOrder implements IListOrder {
  @observable count = DEFAULT_COUNT;
  @observable order: IListOrder['order'] = {
    createdAt: Order.DESC,
  }; /* DEFAULT ORDER */
  @observable requestStatus = RequestStatus.Idle;

  constructor() {
    makeObservable(this);
  }

  @action.bound setOrder: IListOrder['setOrder'] = (accessor) => {
    this.order = {
      [accessor]: this.order[accessor] === Order.DESC ? Order.ASC : Order.DESC,
    };
  };

  @action.bound getSortedList: IListOrder['getSortedList'] = async (
    apiListGet,
    data,
  ) => {
    try {
      this.requestStatus = RequestStatus.Request;

      const response = await apiListGet({
        ...data,
        scope: {
          order: this.order,
          ...data.scope,
        },
      });

      this.count = (
        response as AxiosResponse<{
          count: IListOrder['count'];
        }>
      ).data.count;

      this.requestStatus = RequestStatus.Success;

      return response;
    } catch (e) {
      this.requestStatus = RequestStatus.Failure;
      this.count = DEFAULT_COUNT;

      throw e;
    }
  };
}
