import { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { mediaStore } from 'stores/media';
import DragDropFileUploader from 'components/drag-drop-uploader';
import { FileCategory } from 'utils/api/api';
import { toast } from 'utils';

const VideoUpload: FC = () => {
  const [state, setState] = useState<'active' | 'uploading' | 'done' | 'error'>(
    'active',
  );
  const [fileName, setFileName] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = useCallback((files: File[] | null) => {
    if (!files) {
      return;
    }

    const fileSizesSum = files.reduce((sum, file) => {
      return sum + file.size;
    }, 0);

    const mb = 1024 * 1024;

    if (fileSizesSum > 500 * mb) {
      toast.error('The size of uploaded files should not exceed 500Mb');
      return;
    }

    setState('uploading');

    const fileName = files.length === 1 ? files[0].name : null;

    setFileName(fileName);

    mediaStore
      .uploadMedia(
        [...files],
        { category: FileCategory.Media },
        {
          onUploadProgress({ loaded, total }) {
            if (total) {
              setProgress(Math.floor((loaded * 100) / total));
            }
          },
        },
      )
      .then(() => {
        setState('done');
        setProgress(0);
        mediaStore.getMediaList();
      })
      .catch(() => {
        setState('error');
        setProgress(0);
      });
  }, []);

  return (
    <DragDropFileUploader
      allowedFiles={[
        'avi',
        'mpg',
        'mp3',
        'mp4',
        'mov',
        'wmv',
        'jpg',
        'bmp',
        'png',
      ]}
      progress={progress}
      state={state}
      onChange={handleFileChange}
      fileName={fileName}
    />
  );
};

export default observer(VideoUpload);
