export function filterObject<
  DataType extends Record<never, unknown>,
  ReturnType = DataType,
>(
  data: DataType,
  options: Partial<{
    includedKeys: string[];
    excludedKeys: string[];
  }> = {},
): ReturnType {
  const { includedKeys = [], excludedKeys = [] } = options;

  const returnKeys = (
    includedKeys.length ? includedKeys : Object.keys(data)
  ).filter((k) => !excludedKeys.includes(k));

  return Object.fromEntries(
    Object.entries(data).filter(([k]) => returnKeys.includes(k)),
  ) as ReturnType;
}
