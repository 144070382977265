import React from 'react';
import cx from 'classnames';

import useDynamicHeight from 'hooks/use-dynamic-height';
import { RouteLink } from 'modules/routing-module';

import { IMenu, IMenuItem } from './menu';

export interface ISubmenu
  extends Omit<IMenu, 'routingSchema' | 'SubItemComponent'> {
  appear: boolean;
  menuItems: IMenuItem[];
}

export const Submenu: React.FC<ISubmenu> = ({
  appear,
  menuItems,
  ItemComponent,
  className,
}) => {
  const [ref] = useDynamicHeight<HTMLDivElement>(appear);

  const subMenuList = React.useMemo(() => {
    return menuItems.map(({ routeKey, ...menuItem }) => {
      return (
        <RouteLink
          key={routeKey}
          routeKey={routeKey}
          className="menu__item__list-item"
          renderComponent={(props, ref) => (
            <ItemComponent ref={ref} {...props} {...menuItem} />
          )}
        />
      );
    });
  }, [menuItems]);

  return (
    <div ref={ref} className={cx('menu__item__list', className)}>
      {subMenuList}
    </div>
  );
};
