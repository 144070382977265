import DateRangePicker, {
  DateRangePickerProps,
} from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DatePicker, { DatePickerProps } from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { simulateChange } from 'utils/interceptors/simulate-change';

import { TDateRangeValue } from '../filters/date-picker';

import 'styles/date-picker.scss';
import './styles.scss';

export type TDateValue = Parameters<
  NonNullable<DatePickerProps['onChange']>
>[0];

export interface IDateInputProps extends DatePickerProps {}

export interface IDateRangeInputProps
  extends DateRangePickerProps,
    PropsWithChildren {}

export const DateInput: React.FC<IDateInputProps> = ({
  className,
  ...restProps
}) => {
  const intl = useIntl();
  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleChange = React.useCallback((value: TDateValue) => {
    if (Array.isArray(value)) {
      throw new Error(`Invalid DateInput value type: ${value}`);
    }
    simulateChange(ref, value ? value.toISOString() : '');
  }, []);

  return (
    <div className={cx('date-input', className)}>
      <input type="hidden" name={restProps.name} ref={ref} />
      <div className="date-input__date-layout">
        <DatePicker
          nextLabel={<button className="filters__date-next-month" />}
          next2Label={<button className="filters__date-next-year" />}
          prevLabel={<button className="filters__date-prev-month" />}
          prev2Label={<button className="filters__date-prev-year" />}
          clearIcon={null}
          calendarIcon={null}
          format="dd/MM/y"
          {...restProps}
          onChange={handleChange}
          locale={intl.locale}
        />
      </div>
    </div>
  );
};

export const DateRangeInput: React.FC<IDateRangeInputProps> = ({
  className,
  ...restProps
}) => {
  const intl = useIntl();
  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleChange = React.useCallback((value: TDateRangeValue) => {
    if (Array.isArray(value)) {
      simulateChange(
        ref,
        value.map((v) => (v ? v.toISOString() : '')),
      );
    } else {
      simulateChange(ref, restProps.value);
    }
  }, []);

  return (
    <div className={cx('date-input', className)}>
      <input type="hidden" name={restProps.name} ref={ref} />
      <div className="date-input__date-layout">
        {restProps.children}
        <DateRangePicker
          nextLabel={<button className="filters__date-next-month" />}
          next2Label={<button className="filters__date-next-year" />}
          prevLabel={<button className="filters__date-prev-month" />}
          prev2Label={<button className="filters__date-prev-year" />}
          clearIcon={null}
          calendarIcon={null}
          format="dd/MM/y"
          {...restProps}
          onChange={handleChange}
          locale={intl.locale}
        />
      </div>
    </div>
  );
};
