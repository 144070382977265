import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { errorHandlerStore } from 'stores/error-handler/error-handler';
import Error from 'components/error';
import { Button } from 'components/common';

const ErrorHandler: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  if (errorHandlerStore.error.isError) {
    return (
      <Error
        title={
          <>
            <FormattedMessage id="Error" defaultMessage="Error" /> 404
          </>
        }
        renderButton={() => {
          return (
            <Button className="error__link" tag="a" href="/" secondary>
              <FormattedMessage id="Home page" defaultMessage="Home page" />
            </Button>
          );
        }}
      />
    );
  }

  return <>{children}</>;
};

export default observer(ErrorHandler);
