import React from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { authStore } from 'stores/auth';

import { Layout } from 'components/common';
import { IMenu, IMenuItemProps, Menu } from '../menu';

import './styles.scss';
import { UserRoleResponse } from '../../utils/api/api';

const ProfileMenuItem = React.forwardRef<HTMLAnchorElement, IMenuItemProps>(
  (
    {
      activity,
      displayTitle,
      className,
      iconClassName,
      iconClassNameActive,
      ...restProps
    },
    linkRef,
  ) => {
    return (
      <Link
        ref={linkRef}
        {...restProps}
        className={cx('profile-menu__item', iconClassName)}
      >
        {displayTitle}
      </Link>
    );
  },
);

const ProfileComponent: React.FC = () => {
  const [popupIsOpen, setPopupIsOpen] = React.useState(false);

  const profileMenuSchema = React.useMemo<IMenu['routingSchema']>(
    () =>
      authStore.userRole === UserRoleResponse.Accountant
        ? []
        : [
            { routeKey: 'CurrentUser', displayTitle: authStore.userFullName },
            'Profile',
            'DesktopStatistics',
            'AccountantInvoices',
            'Logout',
          ],
    [authStore.userFullName, authStore.userRole],
  );

  return (
    <div className="navigation__profile-block">
      <OutsideClickHandler onOutsideClick={() => setPopupIsOpen(false)}>
        <Layout flex row className="navigation__profile">
          <button
            className="navigation__profile-button"
            onClick={() => setPopupIsOpen((popupIsOpen) => !popupIsOpen)}
          />
        </Layout>
        {popupIsOpen && (
          <Menu
            className="navigation__profile-menu"
            routingSchema={profileMenuSchema}
            ItemComponent={ProfileMenuItem}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};

export const Profile = observer(ProfileComponent);
