import cx from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler, {
  Props as OutsideClickHandlerProps,
} from 'react-outside-click-handler';

import './styles.scss';

export interface IPopupFullscreenProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<OutsideClickHandlerProps, 'onOutsideClick'> {
  contentNode?: Element | null;
  withCloseButton?: boolean;
}

export const PopupFullscreen: React.FC<IPopupFullscreenProps> = ({
  children,
  contentNode,
  onOutsideClick,
  className,
  withCloseButton = true,
}) => {
  if (!contentNode) {
    contentNode = document.querySelector('.main__content') || document.body;
  }

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape': {
          if (onOutsideClick) {
            onOutsideClick(e as unknown as MouseEvent);
          }
        }
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    contentNode &&
    ReactDOM.createPortal(
      <div className={cx('popup-fullscreen__shadow', className)}>
        <div className="popup-fullscreen__container">
          <OutsideClickHandler onOutsideClick={onOutsideClick}>
            {children}
          </OutsideClickHandler>
        </div>
        {withCloseButton && (
          <button aria-label="X" className="popup-fullscreen__close-button" />
        )}
      </div>,
      contentNode,
    )
  );
};
