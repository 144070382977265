export function throttle<T extends (...args: never[]) => unknown>(
  func: T,
  ms: number,
) {
  let isThrottled = false,
    savedArgs: Parameters<T> | null,
    savedThis: unknown | null;

  return function wrapper(this: unknown, ...args: Parameters<T>): void {
    if (isThrottled) {
      // (2)
      savedArgs = args;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      savedThis = this;
      return;
    }

    func.apply(this, args); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  };
}
