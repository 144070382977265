import { action, makeObservable, observable } from 'mobx';

import { appStore } from 'stores/app';
import { IPlaylistsStore } from '_types/stores';
import { filterObject, handleError, swaggerApi, toast } from 'utils';
import { ListOrder } from 'utils/api/components';
import { fileIdsToFiles } from '../media';
import { PlaylistStatus } from '../../utils/api/api';

const LOCAL_PLAYLIST_ID = 'LOCAL_PLAYLIST';
const playlistRequestKeys = ['files', 'name'];

class PlaylistsStore extends ListOrder implements IPlaylistsStore {
  @observable editedPlaylist: IPlaylistsStore['editedPlaylist'] = null;
  @observable list: IPlaylistsStore['list'] = [];
  @observable mode: IPlaylistsStore['mode'] = 'list';

  columns: IPlaylistsStore['columns'] = [
    {
      accessor: 'name',
      label: 'The name of the playlist',
      withSort: true,
    },
    { accessor: 'createdAt', withSort: true },
    { accessor: 'updatedAt', withSort: true },
    'status',
    'actions',
  ];

  constructor() {
    super();
    makeObservable(this);
  }

  get canSavePlaylist() {
    return Boolean(
      this.editedPlaylistName && this.editedPlaylistMediaIds.length,
    );
  }

  get editedPlaylistMediaIds() {
    return this.editedPlaylist
      ? this.editedPlaylist.files.map((f) => f.id)
      : [];
  }

  get editedPlaylistName() {
    return this.editedPlaylist ? this.editedPlaylist.name : '';
  }

  @action getList: IPlaylistsStore['getList'] = async (
    limit,
    page,
    where = {},
  ) => {
    appStore.isLoading = true;

    try {
      const { data: playlistData } = await this.getSortedList(
        swaggerApi.api.playlistsGet,
        {
          where: Object.fromEntries(
            Object.entries(where).map(([key, value]) => {
              if (key === 'name') {
                return [key, `%${value}%`];
              }

              return [key, value];
            }),
          ),
          scope: {
            limit,
            page,
          },
        },
      );

      this.list = playlistData.data;

      return this.list;
    } catch (error) {
      toast.error(handleError(error));
      return this.list;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action loadPlaylist: IPlaylistsStore['loadPlaylist'] = async (
    id: string,
  ) => {
    appStore.isLoading = true;

    try {
      const { data: playlistsData } = await swaggerApi.api.playlistGet(id);
      this.editedPlaylist = playlistsData.data;

      return this.editedPlaylist;
    } catch (error) {
      toast.error(handleError(error));

      return this.editedPlaylist;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action changeMediaOrder: IPlaylistsStore['changeMediaOrder'] = (
    mediaItem,
    diff,
  ) => {
    if (!this.editedPlaylist) return;
    const idx = this.editedPlaylistMediaIds.findIndex(
      (vId) => vId === mediaItem.id,
    );
    const changedIdx = idx + diff;

    const videoIds = this.editedPlaylist.files.splice(idx, 1);

    this.editedPlaylist.files.splice(changedIdx, 0, ...videoIds);
  };

  @action createPlaylist: IPlaylistsStore['createPlaylist'] = async (
    draftPlaylist,
    isLocalChanges,
  ) => {
    if (isLocalChanges) {
      this.editedPlaylist = {
        id: LOCAL_PLAYLIST_ID,
        description: '',
        createdAt: '',
        updatedAt: '',
        status: PlaylistStatus.Offline,
        // @ts-ignore
        monitors: [],
        ...draftPlaylist,
        files: fileIdsToFiles(draftPlaylist.files),
      };

      return this.editedPlaylist;
    }

    appStore.isLoading = true;

    try {
      const { data: playlistData } = await swaggerApi.api.playlistCreate(
        filterObject(draftPlaylist, {
          includedKeys: playlistRequestKeys,
        }),
      );
      const playlistItem = playlistData.data;
      this.list = this.list
        .filter((p) => p.id !== playlistItem.id)
        .concat(playlistItem);

      return playlistItem;
    } catch (error) {
      toast.error(handleError(error));

      return null;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action updatePlaylist: IPlaylistsStore['updatePlaylist'] = async (
    playlistData,
    isLocalChanges,
  ) => {
    if (!this.editedPlaylist) {
      throw new Error(
        `The "editedProject" property is required: ${this.editedPlaylist}`,
      );
    }

    if (isLocalChanges) {
      this.editedPlaylist = {
        ...this.editedPlaylist,
        ...playlistData,
      };

      return this.editedPlaylist;
    }

    appStore.isLoading = true;

    try {
      if (this.editedPlaylist.id === LOCAL_PLAYLIST_ID) {
        this.editedPlaylist = await this.createPlaylist({
          ...this.editedPlaylist,
          files: this.editedPlaylistMediaIds,
        });

        if (!this.editedPlaylist) {
          return null;
        }
      }

      const { data: playlistsData } = await swaggerApi.api.playlistUpdate(
        this.editedPlaylist.id,
        {
          name: this.editedPlaylist.name,
          files: this.editedPlaylist.files.map((file) => file.id),
        },
      );

      this.editedPlaylist = playlistsData.data;

      return this.editedPlaylist;
    } catch (error) {
      toast.error(handleError(error));

      return this.editedPlaylist;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action deletePlaylist: IPlaylistsStore['deletePlaylist'] = async (
    id: string,
  ) => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.playlistDelete(id);

      await this.getList();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  findPlaylist: IPlaylistsStore['findPlaylist'] = async (where, scope = {}) => {
    appStore.isLoading = true;

    try {
      const { data: playlistData } = await swaggerApi.api.playlistsGet({
        where,
        scope,
      });

      return playlistData.data;
    } catch (e) {
      toast.error(handleError(e));
      return [];
    } finally {
      appStore.isLoading = false;
    }
  };
}

export const playlistsStore = new PlaylistsStore();
