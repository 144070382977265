import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Layout } from 'components/common';
import './styles.scss';

const defaultRenderButton = () => {
  return (
    <Button
      className="error__reload"
      onClick={() => window.location.reload()}
      secondary
    >
      <FormattedMessage id="Try Again" defaultMessage="Try Again" />
    </Button>
  );
};

const Error: React.FC<{
  title: React.ReactNode;
  message?: React.ReactNode;
  renderButton?: () => React.ReactNode;
}> = (props) => {
  const { title, renderButton = defaultRenderButton } = props;

  return (
    <Layout flex className="error__wrapper">
      <Layout flex row>
        <Layout flex className="error__content">
          <h1 className="error__heading">
            <span>{title}</span>
          </h1>
          <p className="error__message">
            <FormattedMessage
              id="Oops! Something went wrong."
              defaultMessage="Oops! Something went wrong."
            />
          </p>
          <p className="error__message">
            <FormattedMessage
              id="Please again or check out our"
              defaultMessage="Please again or check out our"
            />
          </p>
        </Layout>
        <div className="error__icon" />
      </Layout>
      {renderButton()}
    </Layout>
  );
};

export default Error;
