import { observable, makeObservable, action } from 'mobx';

interface Error {
  isError: boolean;
  code?: string;
  message?: string;
}

export class ErrorHandler {
  @observable error: Error = {
    isError: false,
  };

  constructor() {
    makeObservable(this);
  }

  @action setError = (code: string, message: string) => {
    this.error = {
      isError: true,
      code,
      message,
    };
  };
}

const errorHandlerStore = new ErrorHandler();
export { errorHandlerStore };
