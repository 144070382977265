import { FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import paymentData from './constants';
import cx from 'classnames';

import { Layout } from 'components/common';
import { Table } from 'components/table';

import './styles.scss';

const PaymentList: FC = () => {
  const intl = useIntl();

  const data = paymentData.list.map((payment) => ({
    ...payment,
    id: payment.ID,
    sum: intl.formatNumber(payment.sum, { style: 'currency', currency: 'RUB' }),
  }));

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <div className="payment__top-field payment__name">
        <div className="payment__light" />
        <span>Имя плательщика/компании</span>
      </div>
      <div className="payment__table">
        <Table columns={paymentData.columns} data={data} />
      </div>
    </Layout>
  );
};

export default observer(PaymentList);
