import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { IntlShape } from 'react-intl';

import { IAppStore, IWebSocketData } from '_types/stores';
import { Language } from '_types/constants';
import { refreshToken, swaggerApi, toast } from 'utils';
import { getAuthStorage, isExpiredToken } from 'utils/api/with-jwt';
import { MonitorResponse, MonitorStatus } from 'utils/api/api';

import { monitorsStore } from '../monitors/monitor-list';

export const WS_RESTART_MS = 5000;

class App implements IAppStore {
  @observable windowWidth: string | number = 0;

  @observable windowHeight = 0;

  @observable pageActive = true;

  @observable isLoading = true;

  @observable language: Language =
    (localStorage.getItem('language') as Language) || Language.RU;

  @observable ws: IAppStore['ws'] = null;

  /**
   * @todo remove globally
   */
  @computed get pathname(): string {
    return this.location.pathname;
  }

  intl!: IntlShape;
  /**
   * @todo remove globally
   */
  navigate!: IAppStore['navigate'];
  location!: IAppStore['location'];

  constructor() {
    makeObservable(this);

    swaggerApi.instance.interceptors.request.use((config) => {
      config.headers.set('accept-language', this.language);

      return config;
    });
  }

  isCreatedFor(target: 'promo' | 'default') {
    if (target === 'default' && process.env.REACT_APP_TARGET === undefined) {
      return true;
    }
    return process.env.REACT_APP_TARGET === target;
  }

  /**
   * @todo remove globally
   */
  @action.bound handleNavSearch = async (
    searchValue: string,
  ): Promise<void> => {
    console.info('Search from navigation', searchValue);
  };

  setIntl = (intl: IntlShape): void => {
    this.intl = intl;
  };

  setLanguage = (language: Language) => {
    this.language = language;
    localStorage.setItem('language', language);
  };

  /**
   * @todo remove globally
   */
  setHistory: IAppStore['setHistory'] = (navigate, location): void => {
    this.navigate = navigate;
    this.location = location;
  };

  initWebSocket = () => {
    if (this.ws) return;
    const ws = new WebSocket(process.env.REACT_APP_WS_URL as string);

    ws.onopen = async () => {
      const authStorage = getAuthStorage();
      if (authStorage && !isExpiredToken(authStorage.token)) {
        const data: IWebSocketData = {
          event: 'auth/token',
          data: { token: authStorage.token, date: new Date() },
        };

        ws.send(JSON.stringify(data));
      } else {
        await refreshToken();
        ws.close();
      }
    };

    ws.onmessage = (msg) => {
      if (!msg.data) {
        throw new Error('Invalid message data for the WebSocket');
      }
      const parsedMessageData = JSON.parse(msg.data) as IWebSocketData[];

      if (Array.isArray(parsedMessageData)) {
        parsedMessageData.forEach(this._handleWebSocketData);
      } else {
        this._handleWebSocketData(msg.data);
      }
    };

    ws.onclose = () => {
      this.ws = null;
      setTimeout(() => {
        this.initWebSocket();
      }, WS_RESTART_MS);
    };

    ws.onerror = (e) => {
      console.error('WS ERROR', e);
    };

    this.ws = ws;
  };

  _handleWebSocketData: IAppStore['_handleWebSocketData'] = (wsData) => {
    if (!this.ws) return;

    switch (wsData.event) {
      case 'monitor':
        {
          if (wsData.error) return;
          const monitor = wsData.data as MonitorResponse;
          if (monitor.status === MonitorStatus.Online) {
            let messageId, renderToast;

            if (monitor.playlistPlayed) {
              messageId = 'Playlist is played';
              renderToast = toast.success;
            } else {
              messageId = 'Playlist was stopped';
              renderToast = toast.error;
            }

            renderToast(
              this.intl.formatMessage(
                {
                  id: messageId,
                },
                {
                  monitorName: monitor.name,
                  playlistName: monitor.playlist?.name,
                },
              ),
              {
                autoClose: false,
                position: 'bottom-right',
              },
            );
          }
        }
        break;

      case 'monitorStatus':
        {
          const monitorDataArray = wsData.data as Array<{
            id: MonitorResponse['id'];
            status: MonitorStatus;
          }>;

          const monitorIdToStatus = Object.fromEntries(
            monitorDataArray.map(({ id, status }) => [id, status]),
          );

          runInAction(() => {
            monitorsStore.list = monitorsStore.list.map((monitor) =>
              monitor.id in monitorIdToStatus
                ? {
                    ...monitor,
                    status: monitorIdToStatus[monitor.id],
                  }
                : monitor,
            );
          });
        }
        break;

      case 'auth/token': {
        if (wsData.error) {
          refreshToken();
          this.ws.close();
          console.error(wsData.error);
        }
      }
    }
  };
}

const appStore = new App();

export { appStore };
