import React from 'react';
import { observer } from 'mobx-react-lite';
import lodashGet from 'lodash.get';
import cx from 'classnames';

import { ITable, ITableColumn } from './table';

import './styles.scss';

export interface ITableBody {
  columns: ITableColumn[];
  data: ITable['data'];
  renderRow?: ITable['renderRow'];
  renderColumn?: ITable['renderColumn'];
}

const TableBodyComponent: React.FC<ITableBody> = (props) => {
  const { columns, data } = props;

  const defaultRenderColumn: ITableBody['renderColumn'] = (props) => {
    const { row, column } = props;

    return (
      <td key={`table_row${row.id}_${column.accessor}`}>
        <span className={cx({ text: column.withSort })}>
          {lodashGet(row, column.accessor)}
        </span>
      </td>
    );
  };

  const { renderColumn = defaultRenderColumn } = props;

  const defaultRenderRow: ITableBody['renderRow'] = (props) => {
    const { row } = props;

    return (
      <tr key={`table_row_${row.id}`}>
        {columns.map((column) => {
          return renderColumn({
            columns,
            row,
            column,
          });
        })}
      </tr>
    );
  };

  const { renderRow = defaultRenderRow } = props;

  return (
    <tbody className="table__body">
      {data.map((row) => {
        return renderRow({
          columns,
          row,
          renderColumn,
        });
      })}
    </tbody>
  );
};

export const TableBody = observer(TableBodyComponent);
