import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import cx from 'classnames';

import { checkPaginationPage } from 'utils';
import { Layout } from 'components/common';
import { Pagination } from 'modules/pagination-module';
import { AddPlaylist } from 'components/register-forms';
import { Table } from 'components/table';
import { IAddPlaylistForm } from 'components/register-forms/add-playlist';
import { Actions, NameWithIcon, Status } from './list-components';

import { playlistsStore } from 'stores/playlists';

const FIRST_PAGE = 1;

const PlaylistsList: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();

  const [redirectPath, setRedirectPath] = React.useState<string>('');
  const [page, setPage] = React.useState(Number(currentPage));

  const handlePaginate = React.useCallback(
    (paginatePage: number) => {
      setPage((state) => {
        if (paginatePage === state) {
          return state;
        }
        navigate(`/playlists/list/${paginatePage}`);
        return paginatePage;
      });
    },
    [history],
  );

  React.useEffect(() => {
    playlistsStore.getList(10, page);
  }, [page, playlistsStore.order]);

  React.useEffect(() => {
    const { requestStatus, count } = playlistsStore;

    checkPaginationPage(requestStatus, count, Number(currentPage));
  }, [playlistsStore.count, playlistsStore.requestStatus]);

  const data = React.useMemo(
    () =>
      playlistsStore.list.map((playlist) => ({
        ...toJS(playlist),
        createdAt: intl.formatDate(playlist.createdAt),
        updatedAt: intl.formatDate(playlist.updatedAt),
        name: <NameWithIcon playlist={playlist} />,
        actions: <Actions playlist={playlist} />,
        status: <Status playlist={playlist} />,
      })),
    [playlistsStore.list],
  );

  const handleAddPlaylistSubmit = React.useCallback<
    IAddPlaylistForm['onSubmit']
  >(({ name }) => {
    setRedirectPath(`/playlists/add/${name}`);
  }, []);

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      {redirectPath && <Navigate to={redirectPath} />}
      <AddPlaylist onSubmit={handleAddPlaylistSubmit} />
      <Layout className="playlists__table">
        <Table
          columns={playlistsStore.columns}
          data={data}
          onSortClick={playlistsStore.setOrder}
          order={playlistsStore.order}
        />
      </Layout>
      <Pagination
        limit={10}
        totalItems={playlistsStore.count}
        currentPage={+currentPage}
        onPageChange={handlePaginate}
      />
    </Layout>
  );
};

export default observer(PlaylistsList);
