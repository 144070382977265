import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import DateRangePicker, {
  DateRangePickerProps,
} from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '../../decs.d.ts';

import 'styles/date-picker.scss';
import './styles.scss';

export type TDateRangeValue = Parameters<
  NonNullable<DateRangePickerProps['onChange']>
>[0];

export interface IDatePickerProps {
  isOpen: boolean;
  onSearch: (value: TDateRangeValue) => void;
}

const currentDate: TDateRangeValue = [new Date(), new Date()];

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { isOpen, onSearch } = props;

  const [draftValue, setDraftValue] =
    React.useState<TDateRangeValue>(currentDate);

  const intl = useIntl();

  const handleChange = React.useCallback((value: TDateRangeValue) => {
    setDraftValue(value);
  }, []);

  const handleReset = React.useCallback(() => {
    setDraftValue(currentDate);
    onSearch(null);
  }, [currentDate]);

  const handleSearch = React.useCallback(() => {
    onSearch(draftValue);
  }, [onSearch, draftValue]);

  if (!isOpen) return null;

  return (
    <div className="filters__date-layout">
      <div className="filters__date-labels">
        <div className="filters__date-label">
          <FormattedMessage id="from" defaultMessage="from" />
        </div>
        <div className="filters__date-label">
          <FormattedMessage id="to" defaultMessage="to" />
        </div>
      </div>
      <DateRangePicker
        onChange={handleChange}
        value={draftValue}
        nextLabel={<button className="filters__date-next-month" />}
        next2Label={<button className="filters__date-next-year" />}
        prevLabel={<button className="filters__date-prev-month" />}
        prev2Label={<button className="filters__date-prev-year" />}
        clearIcon={null}
        calendarIcon={null}
        minDetail="decade"
        format="dd/MM/y"
        locale={intl.locale}
      />
      <div className="filters__date-actions">
        <button className="filters__search-button" onClick={handleSearch}>
          <FormattedMessage id="Search" defaultMessage="Search" />
        </button>
        <button className="filters__reset-button" onClick={handleReset}>
          <FormattedMessage id="Reset" defaultMessage="Reset" />
        </button>
      </div>
    </div>
  );
};
