import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteRedirect } from 'modules/routing-module';

import './styles.scss';

const LogoutComponent: React.FC = () => {
  useEffect(() => {
    authStore.logout();
  }, []);

  return authStore.user ? null : <RouteRedirect routeKey="Login" />;
};

export const Logout = observer(LogoutComponent);
