import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';

import { Button } from 'components/common';
import { Form, Input, InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import './styles.scss';

const ResetPasswordComponent: FC = () => (
  <AuthWrapper
    title="Update Password"
    subtitle="Enter your new password to reset"
  >
    <Form onSubmit={authStore.updatePassword}>
      <Input
        type="password"
        name="password"
        value={authStore.password}
        onChange={authStore.setField}
        placeholder={
          <FormattedMessage id="Password" defaultMessage="Password" />
        }
        minLength={8}
        fieldClass="auth__reset-field"
        required
      />
      <InputField>
        <Button
          type="submit"
          primary
          className="auth__button auth__button--margin"
        >
          <FormattedMessage
            id="Confirm Password"
            defaultMessage="Confirm Password"
          />
        </Button>
      </InputField>
    </Form>
  </AuthWrapper>
);

export const UpdateResetPassword = observer(ResetPasswordComponent);
