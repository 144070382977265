import React, { useState, MouseEvent, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { IFolderItem, mediaStore } from 'stores/media';
import { Confirmation } from 'components/common';

import './styles.scss';

const LibraryFolder: React.FC<{
  folder: IFolderItem;
  onClick: () => void;
  customContextMenu?: boolean;
}> = (props) => {
  const { folder, onClick, customContextMenu = false } = props;

  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

  const [state, setState] = useState({
    menuIsActive: false,
    renaming: {
      isActive: false,
      value: folder.name,
    },
  });

  const saveNameEffect = () => {
    if (state.renaming.value !== folder.name) {
      mediaStore.updateFolder(folder.id, state.renaming.value);
    }
  };

  useEffect(saveNameEffect, [state.renaming.isActive]);

  const turnMenuOn = (event: MouseEvent<HTMLDivElement>) => {
    if (!customContextMenu) {
      return;
    }

    event.preventDefault();
    setState({
      ...state,
      menuIsActive: true,
    });
  };

  const turnMenuOff = () => {
    setState({
      ...state,
      menuIsActive: false,
    });
  };

  const turnRenamingOn = () => {
    setState({
      ...state,
      menuIsActive: false,
      renaming: {
        ...state.renaming,
        isActive: true,
      },
    });
  };

  const turnRenamingOff = () => {
    setState({
      ...state,
      renaming: {
        ...state.renaming,
        isActive: false,
      },
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({
      ...state,
      renaming: {
        ...state.renaming,
        value: event.target.value,
      },
    });
  };

  const handleNameKeydown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.code === 'Enter') {
      event.preventDefault();

      setState({
        ...state,
        renaming: {
          ...state.renaming,
          isActive: false,
        },
      });
    }
  };

  const handleClick = () => {
    if (state.menuIsActive || state.renaming.isActive) {
      return;
    }

    onClick();
  };

  const handleDelete = React.useCallback(() => {
    setConfirmationIsOpen(true);
  }, [folder]);

  const renderMenu = () => {
    if (state.menuIsActive) {
      return (
        <OutsideClickHandler onOutsideClick={turnMenuOff}>
          <div className="folder__menu">
            <div className="folder__action" onClick={turnRenamingOn}>
              <FormattedMessage id="Rename" defaultMessage="Rename" />
            </div>
            <div className="folder__action" onClick={handleDelete}>
              <FormattedMessage id="Delete" defaultMessage="Delete" />
            </div>
          </div>
        </OutsideClickHandler>
      );
    }

    return null;
  };

  const renderFolderName = () => {
    if (state.renaming.isActive) {
      return (
        <OutsideClickHandler onOutsideClick={turnRenamingOff}>
          <TextareaAutosize
            className="folder__textarea"
            value={state.renaming.value}
            onChange={handleNameChange}
            onKeyDown={handleNameKeydown}
            maxRows={2}
          />
        </OutsideClickHandler>
      );
    }

    return (
      <div className="folder__name" title={folder.name}>
        {folder.name}
      </div>
    );
  };

  const renderConfirmation = () => {
    if (!confirmationIsOpen) {
      return null;
    }

    const message = folder.empty
      ? 'Are you sure to delete the selected one?'
      : 'Among the deleted folders there are folders with nested elements that will be lost when deleted. Continue deleting?';

    const handleConfirm = () => {
      mediaStore.deleteFolder(folder.id);

      setConfirmationIsOpen(false);
    };

    const handleCancel = () => {
      setConfirmationIsOpen(false);
    };

    return (
      <Confirmation
        message={message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  };

  const isChecked = mediaStore.selectedMedia.folderIds.includes(folder.id);

  const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    mediaStore.selectFolder(folder.id);
  };

  return (
    <>
      {renderConfirmation()}
      <div className="folder__layout" onClick={handleClick}>
        <div className="folder__item library__item" onContextMenu={turnMenuOn}>
          <div className="folder__icon" />
          {renderFolderName()}
          <input
            className="folder__checkbox"
            type="checkbox"
            onClick={handleCheckboxClick}
            checked={isChecked}
          />
        </div>
        {renderMenu()}
      </div>
    </>
  );
};

export default observer(LibraryFolder);
