import React from 'react';
import { Button } from 'components/common';
import './styles.scss';

const demoPositions = [
  { text: 'Position 1', key: 1 },
  { text: 'Position 1', key: 2 },
  { text: 'Position 1', key: 3 },
];

const proPositions = [
  { text: 'Position 1', key: 1 },
  { text: 'Position 1', key: 2 },
  { text: 'Position 1', key: 3 },
  { text: 'Position 1', key: 4 },
  { text: 'Position 1', key: 5 },
];

export const SubscriptionOptions: React.FC = () => {
  return (
    <div className="sub-options__layout">
      <div className="sub-options__option">
        <div className="sub-options__title">Demo</div>
        <div className="sub-options__price">
          0<span>$</span>
        </div>
        <Button className="sub-options__button" primary>
          Подключиться
        </Button>
        <div className="sub-options__positions">
          {demoPositions.map((position) => {
            return (
              <div className="sub-options__position" key={position.key}>
                <span>{position.text}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="sub-options__option">
        <div className="sub-options__title sub-options__title--pro">Pro</div>
        <div className="sub-options__price">
          99,9<span>$</span>
        </div>
        <Button className="sub-options__button" primary>
          Подключиться
        </Button>
        <div className="sub-options__positions">
          {proPositions.map((position) => {
            return (
              <div className="sub-options__position" key={position.key}>
                <span>{position.text}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
