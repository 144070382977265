import { observer } from 'mobx-react-lite';
import { ComponentProps, FC } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'components/common';
import IconMonitorUpload from 'assets/icons/file-load-display.svg';
import IconLoadFile from 'assets/icons/load-file.svg';
import './styles.scss';

const AllowedFileTypes: FC<{
  allowedFiles: Array<
    'avi' | 'mpg' | 'mov' | 'mp3' | 'mp4' | 'wmv' | 'jpg' | 'bmp' | 'png'
  >;
}> = ({ allowedFiles }) => (
  <Layout row wrap className="drag-and-drop__file_uploader_formats">
    {allowedFiles.map((fileFormat) => (
      <span
        className="drag-and-drop__file_uploader_formats_format"
        key={`file_format${fileFormat}`}
      >
        {fileFormat}
      </span>
    ))}
  </Layout>
);

const DragDropFileUploadActive: FC<ComponentProps<typeof AllowedFileTypes>> = ({
  allowedFiles,
}) => (
  <Layout flex column className="drag-and-drop__file_uploader">
    <img
      src={IconMonitorUpload}
      alt="Monitor upload"
      className="drag-and-drop__file_uploader-icon drag-and-drop__file_uploader-icon-xm"
    />
    <span className="drag-and-drop__file_uploader-text">
      <FormattedMessage
        id="Move the media file to this area or download the video from disk"
        defaultMessage="Move the media file to this area or download the video from disk"
      />
    </span>
    <AllowedFileTypes allowedFiles={allowedFiles} />
    <Popup
      trigger={
        <button className="link-underline drag-and-drop__file_uploader__requirements-button">
          <FormattedMessage
            id="see the file requirements"
            defaultMessage="see the file requirements"
          />
        </button>
      }
      modal
    >
      <div className="drag-and-drop__file_uploader__requirements">
        <p className="drag-and-drop__file_uploader__requirements-name">
          Файлы, загружаемые Вами на сайт, должны отвечать следующим критериям:
        </p>
        <ul>
          <li className="drag-and-drop__file_uploader__requirements-element">
            - формат файла – JPEG, PNG, MP4, MOV, AVI, MP3;
          </li>
          <li className="drag-and-drop__file_uploader__requirements-element">
            - разрешение фото и видео контента должно быть не менее 0.9 МП (1280
            х 720 пикселей);
          </li>
          <li className="drag-and-drop__file_uploader__requirements-element">
            - содержание контента не должно нарушать авторских прав;
          </li>
          <li className="drag-and-drop__file_uploader__requirements-element">
            - контент не должен содержать товары или услуги запрещенные законом.
          </li>
        </ul>
      </div>
    </Popup>
    <img
      src={IconLoadFile}
      alt="Load file"
      className="drag-and-drop__file_uploader-icon drag-and-drop__file_uploader-icon-m"
    />
  </Layout>
);

export default observer(DragDropFileUploadActive);
