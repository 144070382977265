import { TCoords } from './coords-to-feature';

export const COORDS_SEPARATOR = ',';

export function stringToCoords(str: string): TCoords {
  const coords = str.split(COORDS_SEPARATOR).map((l: string) => +l);
  coords.length = 2;

  return coords as TCoords;
}
