import { paginate } from './engine';
import { FC, useCallback } from 'react';
import { NumberButton, NumberItem } from './components/pagination-buttons';
import './styles.scss';

export const DEFAULT_PAGINATION_LIMIT = 20;

/**
 * @param currentPage current page index
 * @param totalItems total items
 * @param limit items per page
 * @param onPageChange handle page number changes
 * @returns React.Component
 */
const Pagination: FC<{
  currentPage: number;
  totalItems: number;
  limit?: number;
  onPageChange: (page: number) => void;
}> = ({
  currentPage,
  totalItems,
  limit = DEFAULT_PAGINATION_LIMIT,
  onPageChange,
}) => {
  /**
   * @returns number of active pages based on store.count
   */
  const countActivePages = () =>
    totalItems % limit === 0
      ? totalItems / limit
      : // eslint-disable-next-line radix
        parseInt((totalItems / limit + 1).toString());

  const pagesList = paginate(currentPage, countActivePages());

  const gotoPage = useCallback(
    (page: number | '+1' | '-1'): void => {
      let newPageIndex = page as number;

      if (page === '+1') {
        newPageIndex = currentPage + 1;
      } else if (page === '-1') {
        newPageIndex = currentPage - 1;
      }

      onPageChange(newPageIndex);
    },
    [onPageChange, currentPage],
  );

  return (
    <nav className="pagination__wrapper">
      <ul className="pagination">
        <li className="pagination__item">
          <NumberButton
            className="pagination__btn-first"
            onClick={(): void => gotoPage(1)}
            tabIndex={-1}
            page="first"
            disabled={currentPage <= 1}
          />
        </li>
        <li className="pagination__item">
          <NumberButton
            className="pagination__btn-prev"
            onClick={(): void => gotoPage('-1')}
            tabIndex={-1}
            page="-1"
            disabled={currentPage <= 1}
          />
        </li>
        <div className="pagination__pages">
          {pagesList.map((page, index) => (
            <NumberItem
              page={page}
              key={`pageIndex${page === '...' ? `${page}${index}` : page}`}
              currentPage={currentPage}
              isDisabled={countActivePages()}
              onClick={(): void => gotoPage(page as number)}
            />
          ))}
        </div>
        <li className="pagination__item">
          <NumberButton
            className="pagination__btn-next"
            onClick={(): void => gotoPage('+1')}
            tabIndex={-1}
            page="+1"
            disabled={currentPage + 1 > countActivePages()}
          />
        </li>
        <li className="pagination__item">
          <NumberButton
            className="pagination__btn-last"
            onClick={(): void => gotoPage(countActivePages())}
            tabIndex={-1}
            page="last"
            disabled={currentPage + 1 > countActivePages()}
          />
        </li>
      </ul>
    </nav>
  );
};

export { Pagination };
