/**
 * @desc Makes the optional field is required.
 */
export type RequireOne<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & {
  [P in K]-?: T[P];
};

export function fromEntries<K extends PropertyKey, T>(
  entries: Iterable<readonly [K, T]>,
) {
  return Object.fromEntries(entries) as { [k in K]: T };
}
