import React from 'react';
import { observer } from 'mobx-react-lite';

import { useFileLink } from 'hooks/use-file-link';
import { IFileItem } from '_types/stores';

import { Layout } from 'components/common';

import { IEditFilesListProps } from './add-edit-files';

import IconMiniDelete from 'assets/icons/mini-delete.svg';
import './styles.scss';

const DocumentItem: React.FC<{
  document: IFileItem;
  onDelete: IEditFilesListProps['onDelete'];
}> = observer(({ document, onDelete }) => {
  const fileLink = useFileLink(document.id);

  const handleDelete = React.useCallback(
    () => onDelete(document.id),
    [document.id],
  );

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      window.open(fileLink, '_blank', 'fullscreen=yes');
    },
    [fileLink],
  );

  return (
    <Layout flex row className="add__edit__documents__item">
      <span className="add__edit__documents__item-ext">
        {document.extension}
      </span>
      <a
        className="add__edit__documents__item-link"
        href="#"
        rel="noreferrer"
        onClick={handleClick}
      >
        {document.name}
      </a>
      <button
        type="button"
        className="add__edit__documents__item-remove"
        onClick={handleDelete}
      >
        <img src={IconMiniDelete} alt="Remove icon" />
      </button>
    </Layout>
  );
});

const DocumentsList: React.FC<IEditFilesListProps> = ({ files, onDelete }) => (
  <Layout flex column className="add__edit__documents__wrapper">
    {files.map((document) => (
      <DocumentItem key={document.id} document={document} onDelete={onDelete} />
    ))}
  </Layout>
);

export default observer(DocumentsList);
