import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, ChangeEvent, FormEvent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { tableConstants } from '../../constants';

import { userStore } from 'stores/users';
import { UserRoleReverse } from '_types/constants';

import { Form } from 'components/forms';
import { Button, Layout } from 'components/common';
import { Input, Select } from 'components/forms';

const UserAddEdit: FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id) {
      userStore.getUser(id);
    }
  }, [id]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    userStore.setField(e.target.name, e.target.value);
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await userStore.updateUser();
    navigate('/users/list');
  };

  const intl = useIntl();

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <h1 className="user-edit__heading">Редактирование пользователя</h1>
      <Form className="user-edit__form" onSubmit={handleSubmit}>
        <div className="user-edit__input-layout">
          <label htmlFor="surname">Фамилия:</label>
          <Input
            name="surname"
            id="surname"
            type="text"
            fieldClass="add__edit__form__field user-edit__input"
            value={userStore.user?.surname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="user-edit__input-layout">
          <label htmlFor="name">
            <FormattedMessage id="Name" defaultMessage="Name" />:
          </label>
          <Input
            name="name"
            id="name"
            type="text"
            fieldClass="add__edit__form__field user-edit__input"
            value={userStore.user?.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="user-edit__input-layout">
          <label htmlFor="middlename">Отчество:</label>
          <Input
            name="middleName"
            id="middlename"
            type="text"
            fieldClass="add__edit__form__field user-edit__input"
            value={userStore.user?.middleName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="user-edit__input-layout">
          <label htmlFor="email">E-mail:</label>
          <Input
            name="email"
            id="email"
            type="text"
            fieldClass="add__edit__form__field user-edit__input"
            value={userStore.user?.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="user-edit__input-layout">
          <label htmlFor="role">
            <FormattedMessage id="Role" defaultMessage="Role" />:
          </label>
          <Select
            name="role"
            id="role"
            fieldClass="add__edit__form__field user-edit__input"
            className="form__input_select"
            onChange={(e) => handleChange(e as never)}
            value={userStore.user?.role}
          >
            {tableConstants.userRoles.map((r) => (
              <option key={r} value={r}>
                {intl.formatMessage({
                  id: UserRoleReverse[r] || 'Выбрать функции',
                })}
              </option>
            ))}
          </Select>
        </div>
        <Button type="submit" className="user-edit__submit" primary>
          <FormattedMessage id="Save" defaultMessage="Save" />
        </Button>
      </Form>
    </Layout>
  );
});

export { UserAddEdit };
