import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import './styles.scss';

export const InputField: FC<
  PropsWithChildren & {
    className?: string;
    type?:
      | 'email'
      | 'text'
      | 'number'
      | 'password'
      | 'checkbox'
      | 'radio'
      | 'file';
  }
> = ({ className, children, type }) => (
  <div
    className={cx(
      'form__field',
      type === 'checkbox' && 'form__checkbox',
      type === 'radio' && 'form__radio',
      type === 'file' && 'form__file',
      (type === 'text' ||
        type === 'number' ||
        type === 'email' ||
        type === 'password') &&
        'form__native',
      className,
    )}
  >
    {children}
  </div>
);
