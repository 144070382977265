import React from 'react';
import { FormattedMessage } from 'react-intl';

const Toast: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <div className="toast__text">
      <p className="toast__title">
        <FormattedMessage id={title} defaultMessage={title} />
      </p>
      <p className="toast__description">
        <FormattedMessage id={description} defaultMessage={description} />
      </p>
    </div>
  );
};

export default Toast;
