import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { Button } from 'components/common';
import { Form } from 'components/forms';
import { BigInput } from 'components/add-edit-form';

import IconMonitorSearch from 'assets/icons/search-monitor.svg';

const RegisterMonitor: FC = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLFormElement>) => {
    if (e.target.name === 'code') {
      setValue(e.target.value);
    }
  }, []);
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      navigate(`/monitors/add/${value}`);
    },
    [value],
  );

  return (
    <Form
      onSubmit={handleSubmit}
      onChange={handleChange}
      className={cx('big-input__form')}
    >
      <BigInput name="code" value={value} noHint />
      {IconMonitorSearch && (
        <Button type="submit" primary className="big-input__form__submit-btn">
          <img
            src={IconMonitorSearch}
            alt="Icon submit"
            className="big-input__form__submit-btn-icon"
          />
        </Button>
      )}
      <span className="big-input__form_hint">
        <FormattedMessage
          id="Enter the ID from the device screen"
          defaultMessage="Enter the ID from the device screen"
        />
      </span>
    </Form>
  );
};

export default RegisterMonitor;
