import { runInAction } from 'mobx';
import { FC, PropsWithChildren, ReactElement, useEffect } from 'react';
import { appStore } from 'stores/app';

const WindowResize: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const onResize = (): void =>
      runInAction(() => {
        appStore.windowWidth = window.innerWidth;
        appStore.windowHeight = window.innerHeight;
      });
    window.addEventListener('resize', onResize);

    return (): void => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return children as ReactElement | null;
};

export default WindowResize;
