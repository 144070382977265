import React, { ComponentProps, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';

import { Layout, Confirmation } from 'components/common';
import MediaListItemPreview from './item-preview';

import { formatBytes, formatSeconds } from 'utils';
import { VideoType } from 'utils/api/api';
import { mediaStore } from 'stores/media';
import { IMediaItem } from '_types/stores';

import IconFileEnable from 'assets/icons/file-enable.svg';
import './styles.scss';

const MediaListItem: React.FC<
  ComponentProps<typeof MediaListItemPreview> & {
    action?: React.FC<{ item: IMediaItem }>;
    actionElement?: ReactElement;
    customContextMenu?: boolean;
  }
> = (props) => {
  const {
    item,
    action: ActionComponent,
    actionElement,
    customContextMenu = false,
  } = props;

  const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false);

  const [state, setState] = React.useState({
    menuIsActive: false,
    renaming: {
      isActive: false,
      value: item.name,
    },
  });

  const saveNameEffect = () => {
    if (state.renaming.value !== item.name) {
      mediaStore.updateMedia(item.id, state.renaming.value);
    }
  };

  React.useEffect(saveNameEffect, [state.renaming.isActive]);

  const turnMenuOn = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!customContextMenu) {
      return;
    }

    event.preventDefault();
    setState({
      ...state,
      menuIsActive: true,
    });
  };

  const turnMenuOff = () => {
    setState({
      ...state,
      menuIsActive: false,
    });
  };

  const turnRenamingOn = () => {
    setState({
      ...state,
      menuIsActive: false,
      renaming: {
        ...state.renaming,
        isActive: true,
      },
    });
  };

  const turnRenamingOff = () => {
    setState({
      ...state,
      renaming: {
        ...state.renaming,
        isActive: false,
      },
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({
      ...state,
      renaming: {
        ...state.renaming,
        value: event.target.value,
      },
    });
  };

  const handleNameKeydown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.code === 'Enter') {
      event.preventDefault();

      setState({
        ...state,
        renaming: {
          ...state.renaming,
          isActive: false,
        },
      });
    }
  };

  const handleDelete = React.useCallback(() => {
    setConfirmationIsOpen(true);
  }, [item]);

  const renderMenu = () => {
    if (state.menuIsActive) {
      return (
        <OutsideClickHandler onOutsideClick={turnMenuOff}>
          <div className="media-list__item-menu">
            <div className="media-list__item-action" onClick={turnRenamingOn}>
              <FormattedMessage id="Rename" defaultMessage="Rename" />
            </div>
            <div className="media-list__item-action" onClick={handleDelete}>
              <FormattedMessage id="Delete" defaultMessage="Delete" />
            </div>
          </div>
        </OutsideClickHandler>
      );
    }

    return null;
  };

  const renderItemName = () => {
    if (state.renaming.isActive) {
      return (
        <OutsideClickHandler onOutsideClick={turnRenamingOff}>
          <TextareaAutosize
            className="media-list__item-textarea"
            value={state.renaming.value}
            onChange={handleNameChange}
            onKeyDown={handleNameKeydown}
            maxRows={2}
          />
        </OutsideClickHandler>
      );
    }

    return (
      <div className="media-list__item-filename" title={item.name}>
        {item.name}
      </div>
    );
  };

  const renderConfirmation = () => {
    if (!confirmationIsOpen) {
      return null;
    }

    const handleConfirm = () => {
      mediaStore.deleteMedia(item.id);

      setConfirmationIsOpen(false);
    };

    const handleCancel = () => {
      setConfirmationIsOpen(false);
    };

    return (
      <Confirmation
        message={'Are you sure to delete the selected one?'}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  };

  return (
    <>
      {renderConfirmation()}
      <Layout
        flex
        column
        className="media-list__item"
        onContextMenu={turnMenuOn}
      >
        <MediaListItemPreview item={item} />
        {renderItemName()}
        <span className="media-list__item-status">
          <img src={IconFileEnable} alt="Video status" />
          <FormattedMessage id="Active" defaultMessage="Active" />
        </span>
        <div className="media-list__item-file-info">
          {item.videoType !== VideoType.Image && (
            <span className="media-list__item-file-info_duration">
              {item.duration ? formatSeconds(item.duration) : null}
            </span>
          )}
          <span className="media-list__item-file-info_size">
            {item.filesize ? formatBytes(item.filesize) : null}
          </span>
        </div>
        {ActionComponent && !actionElement && <ActionComponent item={item} />}
        {!ActionComponent && actionElement}
        {renderMenu()}
      </Layout>
    </>
  );
};

export default observer(MediaListItem);
