import { action, makeObservable, observable, computed } from 'mobx';
import axios, { AxiosError } from 'axios';

import { downloadFile, handleError, swaggerApi, toast } from 'utils';
import {
  FolderResponse,
  PlaylistResponse,
  EditorResponse,
  FileResponse,
} from 'utils/api/api';
import { appStore } from 'stores/app';
import { IMediaItem, IMediaStore } from '_types/stores';
import { IApiErrorData } from '../../utils/api/handle-error';

export interface IFolderItem extends FolderResponse {}

export interface IFolderStore {
  foldersList: IFolderItem[];
}

export interface IMediaIds {
  fileIds: string[];
  folderIds: string[];
}

export interface IClipboard {
  fileIds: string[];
  folderIds: string[];
  mode: 'copy' | 'cut';
}

export type TMediaError = IApiErrorData<
  Partial<{
    playlist: Array<PlaylistResponse & { file: FileResponse }>;
    video: Array<EditorResponse & { file: FileResponse }>;
  }>
>;

class MediaStore implements IMediaStore {
  @observable mediaList: IMediaItem[] = [];

  @observable selectedMedia: IMediaIds = {
    fileIds: [],
    folderIds: [],
  };

  @observable foldersList: IFolderItem[] = [];

  @observable breadcrumbs: null | IFolderItem[] = null;

  @observable clipboard: null | IClipboard = null;

  @computed get currentFolderId() {
    if (!this.breadcrumbs) {
      return null;
    }

    return this.breadcrumbs[this.breadcrumbs.length - 1].id;
  }

  @observable folderCreation: {
    isActive: boolean;
    value?: string;
  } = {
    isActive: false,
  };

  constructor() {
    makeObservable(this);
  }

  @action clearSelected = () => {
    this.selectedMedia = {
      fileIds: [],
      folderIds: [],
    };
  };

  @action clearClipboard = () => {
    this.clipboard = null;
  };

  @action clearStore = () => {
    this.mediaList = [];
    this.foldersList = [];
    this.breadcrumbs = null;
    this.clearSelected();
    this.clearClipboard();
  };

  @action getMediaList = () => {
    appStore.isLoading = true;

    const source = axios.CancelToken.source();

    Promise.all([
      swaggerApi.api.filesGet(
        {
          where: this.currentFolderId ? { folderId: this.currentFolderId } : undefined,
          scope: {},
        },
        {
          cancelToken: source.token,
        },
      ),
      swaggerApi.api.foldersGet(
        {
          where: this.currentFolderId
            ? { parentFolderId: this.currentFolderId }
            : {},
          scope: {},
        },
        {
          cancelToken: source.token,
        },
      ),
    ])
      .then(([fileResponse, folderResponse]) => {
        this.mediaList = fileResponse.data.data;
        this.foldersList = folderResponse.data.data;
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        toast.error(handleError(error));
      })
      .finally(() => {
        appStore.isLoading = false;
      });

    return source;
  };

  @action uploadMedia: IMediaStore['uploadMedia'] = async (
    files,
    param,
    axiosProps,
  ) => {
    if (!(axiosProps && 'onUploadProgress' in axiosProps)) {
      appStore.isLoading = true;
    }

    try {
      await swaggerApi.api.fileUpload(
        {
          files,
          param: this.currentFolderId
            ? { ...param, folderId: this.currentFolderId }
            : param,
        },
        {
          headers: {
            accept: 'application/json',
          },
          timeout: 0,
          ...(axiosProps || {}),
        },
      );
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action updateMedia = async (id: string, newName: string): Promise<void> => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.fileUpdate(id, {
        name: newName,
      });
      await this.getMediaList();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action selectFiles = (id: string) => {
    if (this.selectedMedia.fileIds.includes(id)) {
      this.selectedMedia.fileIds.splice(
        this.selectedMedia.fileIds.indexOf(id),
        1,
      );
    } else {
      this.selectedMedia.fileIds.push(id);
    }
  };

  @action deleteMedia: IMediaStore['deleteMedia'] = async (id) => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.fileDelete(id);
      this.getMediaList();
    } catch (error) {
      const errorData = (error as AxiosError<TMediaError>).response?.data;

      if (errorData?.statusCode === 409) {
        if (errorData.data.playlist) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in playlists: {playlistNames}',
            },
            {
              fileName: errorData.data.playlist[0].file.name,
              playlistNames: errorData.data.playlist
                .map((playlist: PlaylistResponse) => playlist.name)
                .join(', '),
            },
          );

          toast.error(message);
        }

        if (errorData.data.video) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in projects: {projectNames}',
            },
            {
              fileName: errorData.data.video[0].file.name,
              projectNames: errorData.data.video
                .map((video: EditorResponse) => video.name)
                .join(', '),
            },
          );

          toast.error(message);
        }
      } else {
        toast.error(handleError(error));
      }
    } finally {
      appStore.isLoading = false;
    }
  };

  download: IMediaStore['download'] = async (item) => {
    const blob = await this.getFileS3(item.id);

    if (blob) {
      downloadFile(URL.createObjectURL(blob), item.name);
    }
  };

  getFileS3: IMediaStore['getFileS3'] = async (fileId) => {
    try {
      const res = await swaggerApi.api.fileDownload(fileId, {
        timeout: 0,
      });
      return res.data as unknown as Blob;
    } catch (e) {
      toast.error(handleError(e));
      return null;
    }
  };

  getFilePreview: IMediaStore['getFilePreview'] = async (fileId) => {
    try {
      const res = await swaggerApi.api.fileDownloadPreview(fileId);
      return res.data as unknown as Blob;
    } catch (e) {
      return null;
    }
  };

  @action createFolder = async (name: string): Promise<void> => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.folderCreate({
        name,
        ...(this.currentFolderId
          ? { parentFolderId: this.currentFolderId }
          : {}),
      });

      this.getMediaList();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action turnFolderCreationOn = () => {
    this.folderCreation = {
      isActive: true,
      value: appStore.intl.formatMessage({ id: 'New folder' }),
    };
  };

  @action turnFolderCreationOff = () => {
    if (this.folderCreation.value) {
      this.folderCreation.isActive = false;
      this.createFolder(this.folderCreation.value);
    }
  };

  @action updateFolder = async (id: string, newName: string): Promise<void> => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.folderUpdate(id, {
        name: newName,
      });

      this.getMediaList();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action selectFolder = (id: string) => {
    if (this.selectedMedia.folderIds.includes(id)) {
      this.selectedMedia.folderIds.splice(
        this.selectedMedia.folderIds.indexOf(id),
        1,
      );
    } else {
      this.selectedMedia.folderIds.push(id);
    }
  };

  @action deleteFolder = async (id: string): Promise<void> => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.folderDelete(id);
      this.getMediaList();
    } catch (error) {
      const errorData = (error as AxiosError<TMediaError>).response?.data;

      if (errorData?.statusCode === 409) {
        if (errorData.data.playlist) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in playlists: {playlistNames}',
            },
            {
              fileName: errorData.data.playlist[0].file.name,
              playlistNames: errorData.data.playlist
                .map((playlist: PlaylistResponse) => playlist.name)
                .join(', '),
            },
          );

          toast.error(message);
        }

        if (errorData.data.video) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in projects: {projectNames}',
            },
            {
              fileName: errorData.data.video[0].file.name,
              projectNames: errorData.data.video
                .map((video: EditorResponse) => video.name)
                .join(', '),
            },
          );

          toast.error(message);
        }
      } else {
        toast.error(handleError(error));
      }
    } finally {
      appStore.isLoading = false;
    }
  };

  @action deleteSelected = async (): Promise<void> => {
    appStore.isLoading = true;

    try {
      const requests = [];

      if (this.selectedMedia.folderIds.length) {
        requests.push(
          swaggerApi.api.foldersDelete({
            foldersId: this.selectedMedia.folderIds,
          }),
        );
      }

      if (this.selectedMedia.fileIds.length) {
        requests.push(
          swaggerApi.api.filesDelete({
            filesId: this.selectedMedia.fileIds,
          }),
        );
      }

      await Promise.all(requests);

      this.getMediaList();

      this.clearSelected();
    } catch (error) {
      const errorData = (error as AxiosError<TMediaError>).response?.data;

      if (errorData?.statusCode === 409) {
        if (errorData.data.playlist) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in playlists: {playlistNames}',
            },
            {
              fileName: errorData.data.playlist[0].file.name,
              playlistNames: errorData.data.playlist
                .map((playlist: PlaylistResponse) => playlist.name)
                .join(', '),
            },
          );

          toast.error(message);
        }

        if (errorData.data.video) {
          const message = appStore.intl.formatMessage(
            {
              id: 'The {fileName} file is used in projects: {projectNames}',
            },
            {
              fileName: errorData.data.video[0].file.name,
              projectNames: errorData.data.video
                .map((video: EditorResponse) => video.name)
                .join(', '),
            },
          );

          toast.error(message);
        }
      } else {
        toast.error(handleError(error));
      }
    } finally {
      appStore.isLoading = false;
    }
  };

  @action setupNavigation = (rootFolder: IFolderItem) => {
    if (this.breadcrumbs) {
      return;
    }

    this.breadcrumbs = [rootFolder];
  };

  @action navigateForward = (folder: IFolderItem) => {
    if (!this.breadcrumbs) {
      return;
    }

    if (folder.id === this.currentFolderId) {
      return;
    }

    this.breadcrumbs = [...this.breadcrumbs, folder];
    this.clearSelected();
  };

  @action navigateBackward = () => {
    if (!this.breadcrumbs) {
      return;
    }

    if (this.breadcrumbs.length === 1) {
      return;
    }

    this.breadcrumbs = this.breadcrumbs.slice(0, -1);
    this.clearSelected();
  };

  @action navigateBackwardTo = (n: number) => {
    if (!this.breadcrumbs) {
      return;
    }

    this.breadcrumbs = this.breadcrumbs.slice(0, n);
    this.clearSelected();
  };

  @action copyToClipboard = () => {
    if (
      !this.selectedMedia.fileIds.length &&
      !this.selectedMedia.folderIds.length
    ) {
      return;
    }

    this.clipboard = {
      fileIds: this.selectedMedia.fileIds,
      folderIds: this.selectedMedia.folderIds,
      mode: 'copy',
    };

    this.clearSelected();

    toast.success('Copied');
  };

  @action cutToClipboard = () => {
    if (
      !this.selectedMedia.fileIds.length &&
      !this.selectedMedia.folderIds.length
    ) {
      return;
    }

    this.clipboard = {
      fileIds: this.selectedMedia.fileIds,
      folderIds: this.selectedMedia.folderIds,
      mode: 'cut',
    };

    this.clearSelected();

    toast.success('Cut out');
  };

  @action pasteFromClipboard = async (): Promise<void> => {
    const { clipboard, currentFolderId, breadcrumbs } = this;

    if (!clipboard || !currentFolderId || !breadcrumbs) {
      return;
    }

    appStore.isLoading = true;

    if (clipboard.mode === 'copy') {
      try {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const requests: Promise<any>[] = [];

        if (clipboard.folderIds.length) {
          requests.push(
            swaggerApi.api.foldersCopy({
              folders: clipboard.folderIds.map((folderId) => {
                return {
                  id: folderId,
                };
              }),
              toFolder: currentFolderId,
            }),
          );
        }

        if (clipboard.fileIds.length) {
          requests.push(
            swaggerApi.api.filesCopy({
              files: clipboard.fileIds.map((fileId) => {
                return {
                  id: fileId,
                };
              }),
              toFolder: currentFolderId,
            }),
          );
        }

        await Promise.all(requests);

        this.getMediaList();

        this.clearClipboard();
      } catch (error) {
        toast.error(handleError(error));
      } finally {
        appStore.isLoading = false;
      }
    }

    if (clipboard.mode === 'cut') {
      try {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const requests: any[] = [];

        if (clipboard.folderIds.length) {
          const isNested = breadcrumbs
            .map((breadcrumb) => breadcrumb.id)
            .some((breadcrumb) => clipboard.folderIds.includes(breadcrumb));

          if (isNested) {
            toast.error('Cannot be inserted into a cut folder');
            return;
          }

          requests.push(
            swaggerApi.api.foldersUpdate({
              folders: clipboard.folderIds.map((folderId) => {
                return {
                  id: folderId,
                  parentFolderId: currentFolderId,
                };
              }),
            }),
          );
        }

        if (clipboard.fileIds.length) {
          requests.push(
            swaggerApi.api.filesUpdate({
              files: clipboard.fileIds.map((fileId) => {
                return {
                  id: fileId,
                  folderId: currentFolderId,
                };
              }),
            }),
          );
        }

        await Promise.all(requests);

        this.getMediaList();

        this.clearClipboard();
      } catch (error) {
        toast.error(handleError(error));
      } finally {
        appStore.isLoading = false;
      }
    }
  };
}

export const mediaStore = new MediaStore();

export function fileIdsToFiles(fileIds: Array<IMediaItem['id']>) {
  return fileIds
    .map((fId) => mediaStore.mediaList.find((m) => m.id === fId))
    .filter((f): f is IMediaItem => Boolean(f));
}
