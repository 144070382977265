import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Layout } from 'components/common';

import './styles.scss';

const data = {
  labels: [
    '10:20:05, 28.05.2021',
    '10:20:05, 29.05.2021',
    '10:20:05, 30.05.2021',
    '10:20:05, 31.05.2021',
    '10:20:05, 01.06.2021',
    '10:20:05, 02.06.2021',
    '10:20:05, 03.06.2021',
    '10:20:05, 04.06.2021',
    '10:20:05, 05.06.2021',
    '10:20:05, 06.06.2021',
  ],
  series: [[0, 2, 1, 5, 2, 2, 1, 4, 2, 3]],
};

const options: Chartist.ILineChartOptions = {
  width: '1000px',
  height: '400px',
  showArea: true,
  fullWidth: true,
  chartPadding: {
    right: 60,
  },
};

const type = 'Line';

const Graphics: FC = () => (
  <Layout className={cx('content', 'content--with-padding')}>
    <div className={cx('graphics__wrapper')}>
      <ChartistGraph data={data} options={options} type={type} />
    </div>
  </Layout>
);

export default observer(Graphics);
