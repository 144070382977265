import cx from 'classnames';
import React from 'react';
import { TButtonLink, TButtonRaw } from '_types/common';

import './styles.scss';

export const Button: React.FC<TButtonRaw | TButtonLink> = ({
  type,
  tag,
  className,
  primary,
  secondary,
  rounded,
  children,
  ...props
}) => {
  return React.createElement(
    tag || 'button',
    {
      ...props,
      className: cx(
        'button',
        className,
        primary && 'button-primary',
        secondary && 'button-secondary',
        rounded && 'button-rounded',
      ),
      ...(!tag ? { type } : {}),
    },
    children,
  );
};
