import { runInAction } from 'mobx';
import { FC, PropsWithChildren, ReactElement, useEffect } from 'react';
import { appStore } from 'stores/app';

const WindowVisibility: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const onFocus = (): void =>
      runInAction(() => {
        appStore.pageActive = true;
      });
    const onBlur = (): void =>
      runInAction(() => {
        appStore.pageActive = true;
      });
    const handleVisibilityChange = (): void =>
      runInAction(() => {
        appStore.pageActive = !document.hidden;
      });

    // Events
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('visibilitychange', handleVisibilityChange);
    handleVisibilityChange();

    return (): void => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return children as ReactElement | null;
};

export default WindowVisibility;
