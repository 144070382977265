import React from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';
import cx from 'classnames';

import { randomize } from 'utils';
import { InputField } from '../forms';

import IconMonitor from 'assets/icons/monitor.svg';

export interface IBigInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  noHint?: boolean;
}

const BigInput: React.FC<IBigInputProps> = ({
  noHint = false,
  ...restProps
}) => {
  const [inputId] = React.useMemo(() => randomize(), []);

  const fieldELement = (
    <img
      src={IconMonitor}
      alt="Input icon"
      className="big-input__form__input-icon"
    />
  );

  const fieldHint = noHint ? null : (
    <span className="big-input__form_hint">
      <FormattedMessage
        id="Enter the ID from the device screen"
        defaultMessage="Enter the ID from the device screen"
      />
    </span>
  );

  const inputElement = (
    <InputMask
      {...restProps}
      id={inputId}
      className={cx(
        'form__input',
        'form__input__text',
        'big-input__form__input',
      )}
      type="text"
      mask="999-999-999"
      required
      placeholder="999-999-999"
    />
  );

  return (
    <InputField type="text" className="add__edit__form__big_field">
      {fieldELement}
      {inputElement}
      {fieldHint}
    </InputField>
  );
};

export default observer(BigInput);
