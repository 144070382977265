import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { authStore } from '../../stores/auth';
import { UserPlan } from '../../utils/api/api';

import Container from '../container';
import { MainWrapper } from '../wrapper';
import { DemoBar } from '../demo-bar';
import { Sidebar } from '../sidebar';
import { NavigationBar } from '../navigation';

import './main.scss';
import { appStore } from '../../stores/app';

const MainComponent: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container
      className={cx('main', {
        'main--with-demobar': authStore.user?.plan === UserPlan.DEMO,
      })}
    >
      <DemoBar className="main__demobar" />
      {appStore.isCreatedFor('default') && (
        <Sidebar className="main__sidebar" />
      )}
      <NavigationBar className="main__navbar" />
      <MainWrapper className="main__content">{children}</MainWrapper>
    </Container>
  );
};

export const Main = observer(MainComponent);
