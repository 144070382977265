import '../../components/page-plate/styles.scss';
import icons from '../../assets/icons/icons.module.scss';
import { FC } from 'react';
import { Layout } from '../../components/common';
import { PagePlate } from '../../components/page-plate/page-plate';
import { Form } from '../../components/forms';

export const Stats: FC = () => {
  return (
    <Layout className="content content--with-padding">
      <PagePlate iconClassName={icons.Report} name={<h1>Выгрузка отчетов</h1>}>
        <Form className="page-plate__form">
          <fieldset className="page-plate__fieldset">
            <legend className="page-plate__fieldset-legend">
              Выбрать период:
            </legend>
            <label className="page-plate__row">
              <div className="page-plate__row-item">
                <span>Начало</span>
              </div>
              <select>
                <option value="12">12</option>
              </select>
              <select>
                <option value="Jan">январь</option>
              </select>
              <select>
                <option value="2023">2023</option>
              </select>
            </label>
            <label className="page-plate__row">
              <div className="page-plate__row-item">
                <span>Окончание</span>
              </div>
              <select>
                <option value="12">12</option>
              </select>
              <select>
                <option value="Jan">январь</option>
              </select>
              <select>
                <option value="2023">2023</option>
              </select>
            </label>
          </fieldset>
          <fieldset className="page-plate__fieldset">
            <legend className="page-plate__fieldset-legend">
              Выбрать устройство:
            </legend>
            <div className="page-plate__row">
              <select>
                <option value="12">Устройство 1</option>
              </select>
            </div>
          </fieldset>
          <fieldset className="page-plate__fieldset">
            <legend className="page-plate__fieldset-legend">Выгрузка:</legend>
            <div
              className="page-plate__row"
              style={{
                // @ts-expect-error
                '--gap': '6px',
              }}
            >
              <div className="page-plate__row-item">
                <span>Отчет по заказам</span>
              </div>
              <input
                type="submit"
                className="button button-secondary button-rounded page-plate__button"
                name="orderReport"
                value=".pdf"
              />
              <input
                type="submit"
                className="button button-primary button-rounded page-plate__button"
                name="orderReport"
                value=".xlsx"
              />
              <div className="page-plate__row-item">
                <span>Отчет по статусу устройства</span>
              </div>
              <input
                type="submit"
                className="button button-secondary button-rounded page-plate__button"
                name="deviceReport"
                value=".pdf"
              />
              <input
                type="submit"
                className="button button-primary button-rounded page-plate__button"
                name="deviceReport"
                value=".xlsx"
              />
            </div>
          </fieldset>
        </Form>
      </PagePlate>
    </Layout>
  );
};
