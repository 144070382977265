import React, { ComponentProps } from 'react';
import { Input } from './input';
import './styles.scss';

export const Radio: React.FC<
  Omit<ComponentProps<typeof Input>, 'type' | 'label' | 'placeholder'> & {
    label: React.ReactNode;
  }
> = ({ label, ...props }) => (
  <Input {...props} type="radio" placeholder={label} />
);
