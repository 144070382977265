import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { IUser } from '_types/stores';
import { IListOrder } from 'utils/api/components';

import { Layout } from 'components/common';
import { TableHead } from './table-head';
import { TableBody } from './table-body';

import './styles.scss';

export interface ITableRow {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ITableColumn {
  accessor: string;
  messageId?: string;
  label?: string;
  withSort?: boolean;
  renderFilter?: () => React.ReactNode;
  restrictedFrom?: IUser['role'][];
}

export type RenderColumn = (props: {
  columns: ITableColumn[];
  row: ITableRow;
  column: ITableColumn;
}) => React.ReactNode;

export type RenderRow = (props: {
  columns: ITableColumn[];
  row: ITableRow;
  renderColumn: RenderColumn;
}) => React.ReactNode;

export interface ITable {
  columns: Array<ITableColumn | ITableColumn['accessor']>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<ITableRow>;
  onSortClick?: (
    accessor: ITableColumn['accessor'],
    e: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  order?: IListOrder['order'];
  renderRow?: RenderRow;
  renderColumn?: RenderColumn;
}

const TableComponent: FC<ITable> = (props) => {
  const { columns, data, onSortClick, order, renderRow, renderColumn } = props;

  const normalizedColumns = React.useMemo(() => {
    const { user } = authStore;

    const normalizedColumns = columns.map((c) =>
      typeof c === 'object' ? c : { accessor: c },
    );

    return user
      ? normalizedColumns.filter(
          (c) => !c.restrictedFrom || !c.restrictedFrom.includes(user.role),
        )
      : normalizedColumns;
  }, [columns, authStore.userRole]);

  return (
    <Layout className="table__wrapper">
      <table className="table">
        <TableHead
          columns={normalizedColumns}
          onSortClick={onSortClick}
          order={order}
        />
        <TableBody
          columns={normalizedColumns}
          data={data}
          renderRow={renderRow}
          renderColumn={renderColumn}
        />
      </table>
    </Layout>
  );
};

export const Table = observer(TableComponent);
