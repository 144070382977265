import { Component } from 'react';
import { toast } from 'utils';

import Error from 'components/error';

type Props = {
  children: React.ReactNode;
};
type State = { isError: boolean; error: Error | null };
class Catcher extends Component<Props, State> {
  static getDerivedStateFromError(error: Error): {
    isError: boolean;
    error: Error;
  } {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { isError: true, error };
  }

  constructor(props: Props) {
    super(props);

    this.state = { isError: false, error: null };
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок

    toast.error('MobX error?');
    // eslint-disable-next-line no-console
    console.warn('MobX error?', error, errorInfo);
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { isError, error } = this.state;

    return isError && error ? (
      <Error title={'Error'} message={error.message} />
    ) : (
      children
    );
  }
}

export default Catcher;
