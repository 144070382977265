import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { computed, runInAction, toJS } from 'mobx';
import cx from 'classnames';

import { Layout } from 'components/common';
import { Checkbox, Form, Radio } from 'components/forms';
import { LinkPlaylistMonitor } from 'components/register-forms';
import { ITableColumn, Table } from 'components/table';
import { DatePicker, TextFilter, RadioFilter } from 'components/filters';
import * as PlaylistListComponents from './list-components';
import * as MonitorListComponents from 'pages/monitors/list-components';

import { monitorsStore } from 'stores/monitors/monitor-list';
import { playlistsStore } from 'stores/playlists';
import { MONITOR_TABLE_COLUMNS } from '../monitors/constants';
import { MonitorStatus, PlaylistStatus } from 'utils/api/api';
import { monitorAddress } from 'utils/formatters/monitor-address';
import { ApplicationSendForm } from '../applications/application-send';

import './styles.scss';

type Columns = 'name' | 'createdAt' | 'updatedAt' | 'status';

interface IFilterValues {
  name?: string;
  createdAt?: [Date, Date];
  updatedAt?: [Date, Date];
  status?: PlaylistStatus;
}

const MapMonitorsList: React.FC<{
  checkboxes: Record<string, boolean>;
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = observer(({ checkboxes, handleCheckbox }) => {
  const intl = useIntl();

  useEffect(() => {
    monitorsStore.getList();
  }, [monitorsStore.order]);

  const data =
    !monitorsStore.list || monitorsStore.list.length === 0
      ? []
      : monitorsStore.list.map((monitor) => ({
          ...toJS(monitor),
          checkbox: (
            <Checkbox
              name="monitor__checkbox"
              data-id={monitor.id}
              label=" "
              checked={checkboxes[monitor.id] || false}
              onChange={handleCheckbox}
              colorModifier={
                monitor.status === MonitorStatus.Online ? 'green' : 'dark'
              }
            />
          ),
          favorite: <MonitorListComponents.FavoriteIcon monitor={monitor} />,
          name: (
            <MonitorListComponents.NameWithIcon
              id={monitor.id}
              name={monitor.name}
              enabled={monitor.status === MonitorStatus.Online}
            />
          ),
          category: (
            <MonitorListComponents.CategoryOption
              id={monitor.id}
              category={monitor.category}
            />
          ),
          price1s: monitor.price1s
            ? intl.formatNumber(monitor.price1s, {
                style: 'currency',
                currency: 'RUB',
              })
            : intl.formatMessage({ id: 'PriceFree' }),
          minWarranty:
            monitor.minWarranty || intl.formatMessage({ id: 'NoLimit' }),
          maxDuration:
            monitor.maxDuration || intl.formatMessage({ id: 'NoLimit' }),
          status: (
            <MonitorListComponents.StatusIcon
              id={monitor.id}
              enabled={monitor.status === MonitorStatus.Online}
            />
          ),
          coordinate: (
            <MonitorListComponents.Coordinate
              id={monitor.id}
              location={monitor.location}
            />
          ),
          address: monitorAddress(monitor),
          actions: <MonitorListComponents.Actions id={monitor.id} />,
        }));

  return (
    <Layout className="monitor__table map">
      <Table
        columns={[
          { accessor: 'checkbox', label: ' ' },
          ...MONITOR_TABLE_COLUMNS,
        ]}
        data={data}
        onSortClick={monitorsStore.setOrder}
        order={monitorsStore.order}
      />
    </Layout>
  );
});

const MapPlaylistList: React.FC<{
  playlistId: string | null;
  handleRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = observer(({ playlistId, handleRadio }) => {
  const [filterColumn, setFilterColumn] = React.useState<Columns | null>(null);
  const [filterValues, setFilterValues] = React.useState<IFilterValues>({});

  const intl = useIntl();

  useEffect(() => {
    playlistsStore.getList(undefined, undefined, filterValues);
  }, [playlistsStore.order, filterValues]);

  const createToggleFilter = React.useCallback(
    (filterName: Columns) => {
      return () => {
        if (filterColumn === filterName) {
          setFilterColumn(null);

          return;
        }

        setFilterColumn(filterName);
      };
    },
    [filterColumn, setFilterColumn],
  );

  const createChangeHandler = React.useCallback(
    (key: Columns) => {
      return (value: unknown) => {
        setFilterColumn(null);

        if (value === null) {
          setFilterValues((values) => {
            const { [key]: deletedValue, ...otherValues } = values;

            return otherValues;
          });

          return;
        }

        setFilterValues((values) => ({
          ...values,
          [key]: value,
        }));
      };
    },
    [setFilterValues],
  );

  const createCloseHandler = React.useCallback(
    (filterName: Columns) => {
      return () => {
        if (filterColumn === filterName) {
          setFilterColumn(null);
        }
      };
    },
    [filterColumn, setFilterColumn],
  );

  const calcToFilter = React.useMemo<
    Record<string, ITableColumn['renderFilter']>
  >(() => {
    return {
      name: () => (
        <div className="playlists__filter-layout">
          <OutsideClickHandler onOutsideClick={createCloseHandler('name')}>
            <button
              className="playlists__filter-name"
              onClick={createToggleFilter('name')}
            />
            <TextFilter
              isOpen={filterColumn === 'name'}
              placeholder={intl.formatMessage({
                id: 'Search name',
                defaultMessage: 'Search name',
              })}
              onSearch={createChangeHandler('name')}
            />
          </OutsideClickHandler>
        </div>
      ),
      createdAt: () => (
        <OutsideClickHandler onOutsideClick={createCloseHandler('createdAt')}>
          <div className="playlists__filter-layout">
            <button
              className={cx('playlists__filter-date', {
                'playlists__filter-date--active': filterColumn === 'createdAt',
              })}
              onClick={createToggleFilter('createdAt')}
            />
            <DatePicker
              isOpen={filterColumn === 'createdAt'}
              onSearch={createChangeHandler('createdAt')}
            />
          </div>
        </OutsideClickHandler>
      ),
      updatedAt: () => (
        <OutsideClickHandler onOutsideClick={createCloseHandler('updatedAt')}>
          <div className="playlists__filter-layout">
            <button
              className={cx('playlists__filter-date', {
                'playlists__filter-date--active': filterColumn === 'updatedAt',
              })}
              onClick={createToggleFilter('updatedAt')}
            />
            <DatePicker
              isOpen={filterColumn === 'updatedAt'}
              onSearch={createChangeHandler('updatedAt')}
            />
          </div>
        </OutsideClickHandler>
      ),
      status: () => (
        <OutsideClickHandler onOutsideClick={createCloseHandler('status')}>
          <div className="playlists__filter-layout">
            <button
              className="playlists__filter-name"
              onClick={createToggleFilter('status')}
            />
            <RadioFilter
              options={[
                {
                  label: 'Is not used',
                  value: PlaylistStatus.Offline,
                },
                {
                  label: 'On the broadcast',
                  value: PlaylistStatus.Broadcast,
                },
                {
                  label: 'No broadcast',
                  value: PlaylistStatus.NoBroadcast,
                },
              ]}
              isOpen={filterColumn === 'status'}
              onSearch={createChangeHandler('status')}
            />
          </div>
        </OutsideClickHandler>
      ),
    };
  }, [
    createCloseHandler,
    createToggleFilter,
    createChangeHandler,
    filterColumn,
  ]);

  const playlistsColumns = React.useMemo(() => {
    return playlistsStore.columns.map((c) =>
      typeof c === 'object'
        ? {
            ...c,
            renderFilter: calcToFilter[c.accessor],
          }
        : { accessor: c, renderFilter: calcToFilter[c] },
    );
  }, [calcToFilter]);

  const data = React.useMemo(() => {
    return playlistsStore.list?.length
      ? playlistsStore.list.map((playlist) => ({
          ...playlist,
          radio: (
            <Radio
              name="playlist_radio"
              data-id={playlist.id}
              checked={playlistId === playlist.id}
              onChange={handleRadio}
              label={<div className="form__radio-mask" />}
            />
          ),
          createdAt: intl.formatDate(playlist.createdAt),
          updatedAt: intl.formatDate(playlist.updatedAt),
          name: <PlaylistListComponents.NameWithIcon playlist={playlist} />,
          actions: <PlaylistListComponents.Actions playlist={playlist} />,
          status: <PlaylistListComponents.Status playlist={playlist} />,
        }))
      : [];
  }, [playlistsStore.list, playlistId, handleRadio]);

  return (
    <Layout className="playlists__table map">
      <Table
        columns={[{ accessor: 'radio', label: ' ' }, ...playlistsColumns]}
        data={data}
        onSortClick={playlistsStore.setOrder}
        order={playlistsStore.order}
      />
    </Layout>
  );
});

const MapPlaylistMonitorList: React.FC = () => {
  const [open, setOpen] = useState(false);
  const monitorCheckboxes = useLocalObservable<Record<string, boolean>>(
    () => ({}),
  );
  const [playlistId, setPlaylistId] = useState<string | null>(null);
  const monitorIds = computed(() =>
    Object.keys(monitorCheckboxes).filter((key) => monitorCheckboxes[key]),
  ).get();

  const disabled = React.useMemo(
    () => !(playlistId && monitorIds),
    [playlistId, monitorIds],
  );

  // Callbacks
  const handleMonitorCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      runInAction(() => {
        monitorCheckboxes[event.target.dataset.id as string] =
          event.target.checked;
      }),
    [monitorCheckboxes],
  );
  const handlePlaylistRadio = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      runInAction(() => {
        setPlaylistId(event.target.dataset.id as string);
      }),
    [],
  );
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (!disabled) {
        setOpen(true);
      }
    },
    [setOpen, disabled],
  );

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <MapMonitorsList
        checkboxes={monitorCheckboxes}
        handleCheckbox={handleMonitorCheckbox}
      />
      <Form onSubmit={handleSubmit} noPadding>
        <LinkPlaylistMonitor disabled={!(monitorIds.length && playlistId)} />
      </Form>
      <MapPlaylistList
        playlistId={playlistId}
        handleRadio={handlePlaylistRadio}
      />
      {open && (
        <ApplicationSendForm
          playlistId={playlistId}
          monitorIds={monitorIds}
          onOutsideClick={() => setOpen(false)}
        />
      )}
    </Layout>
  );
};

export default observer(MapPlaylistMonitorList);
