import { formatBytes } from 'utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
const summarizeDurationFromArray = <T>(arr: T[], field: string): number =>
  arr.reduce(
    (total, curr) =>
      (curr as any) ? total + ((curr as any)[field] as number) : 0,
    0,
  );

const summarizeFilesizeFromArray = (filesSizes: number[]) => {
  if (!filesSizes || !filesSizes.length) return '0 B';

  const bytes = filesSizes.reduce((total, cur) => total + (cur || 0));

  return formatBytes(bytes);
};

export { summarizeDurationFromArray, summarizeFilesizeFromArray };
