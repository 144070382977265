import { Layout } from 'components/common';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import IconLoadFile from 'assets/icons/load-file.svg';
import './styles.scss';

const DragDropFileUploadInProgress: FC<{ progress: number }> = ({
  progress,
}) => (
  <Layout
    flex
    column
    className="drag-and-drop__file_uploader drag-and-drop__file_uploader-loading"
  >
    <img
      src={IconLoadFile}
      alt="Monitor upload"
      className="drag-and-drop__file_uploader-icon drag-and-drop__file_uploader-icon-xm"
    />
    <div className="drag-and-drop__file_uploader__progress-bar">
      <div
        className="drag-and-drop__file_uploader__progress-bar-active"
        style={{ width: `${progress}%` }}
      />
    </div>
    <span className="drag-and-drop__file_uploader__progress-text">
      {progress} %
    </span>
  </Layout>
);

export default observer(DragDropFileUploadInProgress);
