import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';

import {
  DRAFT_PROJECT_ID,
  videoEditorStore,
} from 'modules/video-editor-module';
import { TProjectId } from 'modules/video-editor-module/types';

import { monitorsStore } from 'stores/monitors/monitor-list';

import { Layout } from 'components/common';
import { EditorLibrary } from './components/editor-library';
import { PlayerContainer } from './components/player/player-container';
import { Timeline } from './components/timeline';

import './styles.scss';

const EditorProjectComponent: React.FC = () => {
  const timelineRef = React.useRef<HTMLDivElement>(null);
  const { id } = useParams<{ id: TProjectId }>();
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    videoEditorStore.initProject({ projectId: id || DRAFT_PROJECT_ID });
  }, [id]);

  React.useEffect(() => {
    return () => {
      // cleanup
      videoEditorStore.initProject({});
    };
  }, []);

  React.useEffect(() => {
    monitorsStore.getList();
  }, [monitorsStore.order]);

  React.useEffect(() => {
    if (videoEditorStore.project && videoEditorStore.project.id) {
      const path = `/editor/project/${videoEditorStore.project.id}`;

      if (location.pathname !== path) {
        navigate(path, { replace: true });
      }
    }
  }, [videoEditorStore.project]);

  return (
    <Layout className={cx('content')}>
      <div className="editor__layout">
        <EditorLibrary timelineRef={timelineRef} />
        <div className="player__layout">
          <PlayerContainer />
        </div>
        <Timeline innerRef={timelineRef} />
      </div>
    </Layout>
  );
};

export const EditorProject = observer(EditorProjectComponent);
