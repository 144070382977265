export function downloadFile(path: string, filename: string) {
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.append(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  anchor.remove();
}
