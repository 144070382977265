import React from 'react';
import cx from 'classnames';

import { Layout } from 'components/common';

import { Profile } from './profile';
import { SearchBar } from './search-bar';
import Email from './email';
import LanguageSwitcher from './language-switcher';
import { Balance } from './balance';
import { Monitors } from './monitors';

import './styles.scss';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../stores/auth';
import { UserRoleResponse } from '../../utils/api/api';
import { appStore } from '../../stores/app';
import { NavigationMenu } from './navigation-menu';

export interface INavigationProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const NavigationBar: React.FC<INavigationProps> = observer(
  ({ className }) => (
    <Layout
      flex
      row
      justifyContent="space-between"
      className={cx('navigation__wrapper', className)}
    >
      <div className="navigation__wrapper-left">
        <NavigationMenu />
      </div>
      <div className="navigation__wrapper-right">
        <SearchBar />
        <Email />
        {authStore.restrictFrom([UserRoleResponse.Accountant]) && (
          <>
            <Profile />
            {appStore.isCreatedFor('default') && (
              <>
                <Balance />
                <Monitors />
              </>
            )}
          </>
        )}
        <LanguageSwitcher />
      </div>
    </Layout>
  ),
);
