import React, { FC, useEffect } from 'react';
import { PagePlate } from '../../components/page-plate/page-plate';
import icons from '../../assets/icons/icons.module.scss';
import { Layout } from '../../components/common';
import { Table } from '../../components/table';
import { invoicesStore } from '../../stores/invoices';
import { Pagination } from '../../modules/pagination-module';
import { toJS } from 'mobx';
import { InvoiceActions, InvoiceTableStatus } from './list-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { checkPaginationPage } from '../../utils';
import { observer } from 'mobx-react-lite';

import './styles.scss';
import { authStore } from '../../stores/auth';
import { UserRoleResponse } from '../../utils/api/api';

const FIRST_PAGE = 1;
const INVOICES_GET_LIST_LIMIT = 20;

export const AccountantInvoices: FC = observer(() => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();
  const [page, setPage] = React.useState(Number(currentPage));

  const handlePaginate = React.useCallback(
    (paginatePage: number) => {
      setPage((state) => {
        if (paginatePage === state) {
          return state;
        }
        navigate(`/accountant/invoices/${paginatePage}`);
        return paginatePage;
      });
    },
    [history],
  );

  useEffect(() => {
    void invoicesStore.getList({
      scope: {
        limit: INVOICES_GET_LIST_LIMIT,
        page,
      },
    });
  }, [page, invoicesStore.order]);

  React.useEffect(() => {
    const { requestStatus, count } = invoicesStore;

    checkPaginationPage(requestStatus, count, Number(currentPage));
  }, [invoicesStore.count, invoicesStore.requestStatus]);

  const data = React.useMemo(
    () =>
      invoicesStore.list.map((invoice) => ({
        ...toJS(invoice),
        company: invoice.description,
        sum: intl.formatNumber(invoice.sum, {
          style: 'currency',
          currency: 'RUB',
        }),
        createdAt: intl.formatDate(invoice.createdAt),
        updatedAt: intl.formatDate(invoice.updatedAt),
        actions: <InvoiceActions invoice={invoice} />,
        status: <InvoiceTableStatus invoice={invoice} />,
      })),
    [invoicesStore.list],
  );

  return (
    <Layout className="content content--with-padding" id="accountantInvoices">
      <PagePlate iconClassName={icons.Report} name={<h1>Счета</h1>}>
        <Layout className="playlists__table">
          <Table
            columns={[
              'company',
              'createdAt',
              'sum',
              'status',
              {
                accessor: 'actions',
                messageId: authStore.restrictFrom([
                  UserRoleResponse.MonitorOwner,
                  UserRoleResponse.Advertiser,
                ])
                  ? 'app.table.invoiceActions'
                  : undefined,
              },
            ]}
            data={data}
            onSortClick={invoicesStore.setOrder}
            order={invoicesStore.order}
          />
        </Layout>
        <Pagination
          limit={INVOICES_GET_LIST_LIMIT}
          totalItems={invoicesStore.count}
          currentPage={+currentPage}
          onPageChange={handlePaginate}
        />
      </PagePlate>
    </Layout>
  );
});
