import equal from 'deep-equal';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames';

import { MONITOR_TABLE_COLUMNS } from './constants';
import { authStore } from 'stores/auth';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { Pagination } from 'modules/pagination-module';

import { checkPaginationPage, filterObject } from 'utils';
import { MonitorResponse, MonitorStatus } from 'utils/api/api';
import { monitorAddress } from 'utils/formatters/monitor-address';

import { Layout } from 'components/common';
import { Checkbox, DateRangeInput, Form } from 'components/forms';
import { RegisterMonitor } from 'components/register-forms';
import { ITable, Table } from 'components/table';
import { TDateRangeValue } from 'components/filters/date-picker';
import {
  Actions,
  CategoryOption,
  Coordinate,
  FavoriteIcon,
  NameWithIcon,
  StatusIcon,
} from './list-components';
import { IMonitorsMapProps, MonitorsMap } from './monitors-map';

import './styles.scss';
import { appStore } from '../../stores/app';
import { MonitorsPrecalcForm } from './monitors-precalc-form';

export interface IMonitorsTableFilterData {
  dateWhenApp?: string[];
}

const MonitorsList: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentPage = 1 } = useParams<{ currentPage?: string }>();
  const today = React.useMemo(() => new Date(), []);

  const [filterData, setFilterData] = React.useState<IMonitorsTableFilterData>(
    {},
  );
  const [page, setPage] = React.useState(Number(currentPage));
  const [monitorIds, setMonitorIds] = useState<MonitorResponse['id'][]>([]);
  const [mapMonitorIds, setMapMonitorIds] =
    React.useState<Parameters<IMonitorsMapProps['onFilter']>[0]>(null);
  const [selectedMonitorId, setSelectedMonitorId] = React.useState('');

  const handleMapFilter = React.useCallback<IMonitorsMapProps['onFilter']>(
    (monitorIds) =>
      setMapMonitorIds((state) =>
        equal(state, monitorIds, { strict: true }) ? state : monitorIds,
      ),
    [setMapMonitorIds],
  );

  const handlePaginate = React.useCallback(
    (paginatePage: number) => {
      setPage((state) => {
        if (paginatePage === state) {
          return state;
        }
        navigate(`/monitors/list/${paginatePage}`);
        return paginatePage;
      });
    },
    [history],
  );

  const handleMonitorSelect = (id: string) => {
    setSelectedMonitorId(id);
  };

  const monitorList = React.useMemo(
    () =>
      mapMonitorIds
        ? monitorsStore.list.filter((m) => mapMonitorIds.includes(m.id))
        : monitorsStore.list,
    [monitorsStore.list, mapMonitorIds],
  );

  const data = React.useMemo(
    () =>
      monitorList.map((monitor) => ({
        ...toJS(monitor),
        checkbox: (
          <Checkbox
            label=" "
            colorModifier={monitor.status === MonitorStatus.Online ? 'green' : 'dark'}
            onClick={() =>
              setMonitorIds((state) => {
                const filtered = state.filter((id) => monitor.id !== id);
                if (state.includes(monitor.id)) {
                  return filtered;
                }
                return filtered.concat(monitor.id);
              })
            }
            checked={monitorIds.includes(monitor.id)}
            readOnly={true}
          />
        ),
        favorite: <FavoriteIcon monitor={monitor} />,
        price1s: monitor.price1s
          ? intl.formatNumber(monitor.price1s, { style: 'currency', currency: 'RUB' })
          : intl.formatMessage({ id: 'PriceFree' }),
        minWarranty: monitor.minWarranty || intl.formatMessage({ id: 'NoLimit' }),
        maxDuration: monitor.maxDuration || intl.formatMessage({ id: 'NoLimit' }),
        name: (
          <NameWithIcon
            id={monitor.id}
            name={monitor.name}
            enabled={monitor.status === MonitorStatus.Online}
          />
        ),
        category: <CategoryOption id={monitor.id} category={monitor.category} />,
        address: monitorAddress(monitor),
        coordinate: <Coordinate id={monitor.id} location={monitor.location} />,
        status: (
          <StatusIcon id={monitor.id} enabled={monitor.status === MonitorStatus.Online} />
        ),
        actions: <Actions id={monitor.id} />,
      })),
    [monitorList, mapMonitorIds, monitorIds],
  );

  React.useEffect(() => {
    monitorsStore.getList({
      where: filterObject(filterData, { includedKeys: ['dateWhenApp'] }),
      scope: mapMonitorIds
        ? {}
        : {
            limit: 10,
            page,
          },
    });
  }, [page, mapMonitorIds, filterData.dateWhenApp, monitorsStore.order]);

  React.useEffect(() => {
    const { requestStatus, count } = monitorsStore;

    checkPaginationPage(requestStatus, count, page);
  }, [monitorsStore.requestStatus, monitorsStore.count, page]);

  const renderRow: ITable['renderRow'] = (props) => {
    const { columns, row, renderColumn } = props;

    return (
      <tr
        key={`table_row_${row.id}`}
        className={cx({ selected: row.id === selectedMonitorId })}
      >
        {columns.map((column) => {
          return renderColumn({
            columns,
            row,
            column,
          });
        })}
      </tr>
    );
  };

  const handleFilterChange = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      setFilterData((state) => {
        const { name, value } = e.target as unknown as {
          name: keyof IMonitorsTableFilterData | 'dateWhenApp';
          value: HTMLInputElement['value'];
          checked: HTMLInputElement['checked'];
        };
        let newStateData: Partial<typeof state>;

        if (name === 'dateWhenApp') {
          if (value) {
            newStateData = {
              [name]: JSON.parse(value),
            };
          } else {
            return filterObject(state, {
              excludedKeys: ['dateWhenApp'],
            });
          }
        } else {
          newStateData = {
            [name]: value,
          };
        }

        return {
          ...state,
          ...newStateData,
        };
      });
    },
    [setFilterData],
  );

  return (
    <Layout className="content content--with-padding">
      <MonitorsMap
        onMonitorSelect={handleMonitorSelect}
        onFilter={handleMapFilter}
      />
      {appStore.isCreatedFor('promo') ? (
        <MonitorsPrecalcForm monitorIds={monitorIds} />
      ) : authStore.userRole === 'advertiser' ? (
        <Form className="monitor__table-filter" onChange={handleFilterChange}>
          <fieldset>
            <span>
              <FormattedMessage
                id="AccessibleMonitorDates"
                defaultMessage="Доступные даты"
              />
              {': '}
            </span>
            <DateRangeInput
              className="inlined"
              minDate={today}
              name="dateWhenApp"
              value={
                filterData.dateWhenApp
                  ? (filterData.dateWhenApp.map(
                      (isoString) => new Date(isoString),
                    ) as TDateRangeValue)
                  : ''
              }
              clearIcon={filterData.dateWhenApp ? undefined : null}
            />
          </fieldset>
        </Form>
      ) : (
        <RegisterMonitor />
      )}
      {data.length > 0 && (
        <>
          <Layout className="monitor__table">
            <Table
              columns={
                appStore.isCreatedFor('promo')
                  ? [
                      { accessor: 'checkbox', label: ' ' },
                      ...MONITOR_TABLE_COLUMNS,
                    ]
                  : MONITOR_TABLE_COLUMNS
              }
              data={data}
              onSortClick={monitorsStore.setOrder}
              order={monitorsStore.order}
              renderRow={renderRow}
            />
          </Layout>
          {!mapMonitorIds && (
            <Pagination
              limit={10}
              totalItems={monitorsStore?.count}
              currentPage={+currentPage}
              onPageChange={handlePaginate}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default observer(MonitorsList);
