import React, { useRef } from 'react';
import { Map, MapProps } from 'react-yandex-maps';
import { Map as TMap, IEventManager } from 'yandex-maps';
import { FeatureCollection } from 'geojson';

import { throttledGeoSearch } from 'utils/yandex-api';
import { IGeoSearchParams } from 'utils/yandex-api/types';

export type MapEventCallback = IEventManager['add'];
export type TMapEvent = Parameters<Parameters<MapEventCallback>[1]>[0];
export type MapSearchCallback = (
  features: FeatureCollection['features'],
) => void;
export type MapInitCallback = (map: TMap) => void;

export interface ICustomMapProps extends MapProps {
  events?: Array<Parameters<MapEventCallback>>;
  onSearch?: MapSearchCallback;
  onInit?: MapInitCallback;
  geoSearchParams?: IGeoSearchParams;
}

export const MAP_COPYRIGHT = 'MyScreen |';
export const MIN_SEARCH_TEXT_LENGTH = 3;

export const CustomMap: React.FC<ICustomMapProps> = ({
  children,
  events,
  onSearch,
  onInit,
  geoSearchParams,
  ...restProps
}) => {
  const mapRef = useRef<TMap>();

  const initializeMap = React.useCallback(
    (map: TMap | null) => {
      if (mapRef.current || !map) return;

      if (events) {
        events.forEach(([types, callback]) => {
          map.events.add(types, callback);
        });
      }

      // Adds name of the organization.
      map.copyrights.get().then((arr) => {
        if (arr.includes(MAP_COPYRIGHT)) return;
        map.copyrights.add(MAP_COPYRIGHT);
      });

      // @ts-ignore
      map.copyrights.togglePromo(); // Deletes the "Как добраться?" button.

      mapRef.current = map;

      if (onInit) {
        onInit(mapRef.current);
      }
    },
    [onInit],
  );

  React.useEffect(() => {
    if (
      onSearch &&
      geoSearchParams &&
      geoSearchParams.text.length >= MIN_SEARCH_TEXT_LENGTH
    ) {
      throttledGeoSearch(
        geoSearchParams,
        (result) => {
          onSearch(result.features);
        },
        false,
      );
    }
  }, [geoSearchParams, onSearch]);

  return (
    <Map
      // @ts-ignore
      instanceRef={initializeMap}
      {...restProps}
    >
      {children}
    </Map>
  );
};
