import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Layout } from 'components/common';
import IconLoadFile from 'assets/icons/load-file.svg';
import './styles.scss';

const DragDropFileUploadResult: React.FC<{
  state: 'done' | 'error';
  fileName: null | string;
}> = ({ state, fileName }) => {
  const renderTextResult = React.useMemo(() => {
    if (!fileName) {
      if (state === 'error') {
        return (
          <span className="drag-and-drop__file_uploader__result">
            <FormattedMessage
              id="Failed to upload files, try again"
              defaultMessage="Failed to upload files, try again"
            />
          </span>
        );
      }

      return (
        <span className="drag-and-drop__file_uploader__result">
          <FormattedMessage
            id="Files uploaded successfully"
            defaultMessage="Files uploaded successfully"
          />
        </span>
      );
    }

    return (
      <>
        <span className="drag-and-drop__file_uploader__result">
          <FormattedMessage id="file" />
          <b title={fileName}>{fileName}</b>
          <FormattedMessage
            id={state === 'done' ? 'successfully uploaded' : 'failed to load'}
          />
        </span>

        <span className="drag-and-drop__file_uploader__result">
          <FormattedMessage
            id={
              state === 'done'
                ? '(it appears in the file list below)'
                : '(try downloading again)'
            }
          />
        </span>
      </>
    );
  }, [fileName, state]);

  return (
    <Layout
      flex
      column
      className="drag-and-drop__file_uploader drag-and-drop__file_uploader-result"
    >
      <img
        src={IconLoadFile}
        alt="Monitor upload"
        className="drag-and-drop__file_uploader-icon drag-and-drop__file_uploader-icon-xm"
      />
      {renderTextResult}
    </Layout>
  );
};

export default observer(DragDropFileUploadResult);
