import { UserRole } from '_types/constants';
import { Address, MonitorCategory } from 'utils/api/api';

import { ITable } from 'components/table';

export const ADDRESS_TEXT_PARAMS: (keyof Address)[] = [
  'city',
  'street',
  'house',
];

export const RUSSIA = 'Россия';
export const MOSCOW_COORDS = [55.75, 37.57];

export const MAP_RADIUS_WALKABLE_KM = 0.1;
export const MAP_RADIUS_METERS = [50000, 25000, 5000, 1000, 500];
export const MAP_RADIUS_KM = MAP_RADIUS_METERS.map((m) => m / 1000);

export const categoryOptions = [
  MonitorCategory.GAS_STATION,
  MonitorCategory.MALL,
  MonitorCategory.FOOD,
  MonitorCategory.CAFES_AND_RESTAURANTS,
  MonitorCategory.PHARMACY,
  MonitorCategory.ATM,
  MonitorCategory.HOTEL,
  MonitorCategory.AUTO_REPAIR,
  MonitorCategory.CAR_WASHING,
  MonitorCategory.HOSPITALS,
  MonitorCategory.CINEMAS,
  MonitorCategory.SAUNAS,
  MonitorCategory.BEAUTY_SALONS,
];

export const monitorResolutions = [
  '3840 x 2160 px',
  '2560 x 1440 px',
  '1920 x 1080 px',
  '1366 x 768 px',
];

export const monitorMatrixList = [
  'IPS',
  'TN',
  'ACE',
  'VA',
  'AHVA',
  'MVA',
  'PVA',
];

export const MONITOR_TABLE_COLUMNS: ITable['columns'] = [
  { accessor: 'favorite', label: ' ', withSort: true },
  {
    accessor: 'name',
    messageId: 'The name of the monitor',
    withSort: true,
  },
  { accessor: 'category', withSort: true },
  { accessor: 'price1s', withSort: true },
  { accessor: 'minWarranty', withSort: true },
  { accessor: 'maxDuration', withSort: true },
  { accessor: 'status', withSort: true },
  { accessor: 'address', withSort: true },
  'coordinate',
  { accessor: 'actions', restrictedFrom: [UserRole.Advertiser] },
];
