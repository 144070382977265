import React from 'react';

export const pad = (str: string | number) => {
  return `0${str}`.slice(-2);
};

export const format = (seconds: string | number) => {
  const date = new Date(Number(seconds) * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export interface IDurationProps
  extends Omit<React.TimeHTMLAttributes<HTMLTimeElement>, 'dateTime'> {
  seconds: number;
}

export const Duration: React.FC<IDurationProps> = ({
  seconds,
  ...restProps
}) => {
  return (
    <time {...restProps} dateTime={`P${Math.round(seconds)}S`}>
      {format(seconds)}
    </time>
  );
};
