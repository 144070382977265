import React, { HTMLAttributes, useMemo, useState } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';
import { UserPlan, UserRoleResponse } from '../../utils/api/api';

import { Layout } from 'components/common';
import { IMenu, Menu } from '../menu';
import { SidebarMenuItem, SidebarSubmenuItem } from './sidebar-menu-item';

import './styles.scss';

export const sidebarMenuSchema: IMenu['routingSchema'] = [
  ['Desktop', ['DesktopManagement', 'DesktopStatistics']],
  'Applications',
  ['Monitors', ['MonitorsAdd', 'MonitorsList']],
  'Library',
  ['Playlists', ['PlaylistsAdd', 'PlaylistsList', 'PlaylistsMapMonitorList']],
  'Users',
  'Graphics',
  ['Editor', ['EditorCreate', 'EditorProjects']],
  'Settings',
];

export interface ISidebar extends React.HTMLAttributes<HTMLDivElement> {}

const PlanTitle: React.FC<HTMLAttributes<HTMLDivElement>> = observer(
  ({ className, ...restProps }) => {
    const title = React.useMemo(() => {
      switch (authStore.user?.plan) {
        case UserPlan.FULL:
          return 'Pro';
        case UserPlan.VIP:
          return 'VIP';
        default:
          return 'Demo';
      }
    }, [authStore.user?.plan]);

    return (
      <div
        {...restProps}
        className={cx('sidebar__version', className, {
          'sidebar__version--demo': authStore.user?.plan === UserPlan.DEMO,
        })}
      >
        {title}
      </div>
    );
  },
);

const SidebarComponent: React.FC<ISidebar> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(true);

  const burgerOnClick = React.useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    [],
  );

  const routingSchema = useMemo<IMenu['routingSchema']>(() => {
    const accountantMenuItem: IMenu['routingSchema'][number] = [
      'Accountant',
      ['AccountantInvoices'],
    ];

    switch (authStore.userRole) {
      case UserRoleResponse.Administrator: {
        return [...sidebarMenuSchema, accountantMenuItem];
      }
      case UserRoleResponse.Accountant: {
        return [accountantMenuItem, 'Logout'];
      }
      default:
        return sidebarMenuSchema;
    }
  }, [authStore.userRole]);

  return (
    <Layout
      className={cx(
        'sidebar__wrapper',
        { 'sidebar__wrapper--closed': !isOpen },
        className,
      )}
    >
      <div className="sidebar__header">
        <button
          className={cx('sidebar__burger', {
            'sidebar__burger--closed': !isOpen,
          })}
          onClick={burgerOnClick}
        />
        <RouteLink routeKey="Dashboard">
          <div
            className={cx('sidebar__logo', {
              'sidebar__logo--closed': !isOpen,
            })}
          />
        </RouteLink>
        <PlanTitle
          className={cx({
            'sidebar__version--closed': !isOpen,
          })}
        />
      </div>
      <Menu
        className="menu__wrapper"
        routingSchema={routingSchema}
        ItemComponent={SidebarMenuItem}
        SubItemComponent={SidebarSubmenuItem}
      />
    </Layout>
  );
};

export const Sidebar = observer(SidebarComponent);
