import React from 'react';
import cx from 'classnames';
import { randomize } from 'utils';
import { FormattedMessage } from 'react-intl';
import { InputField } from './field';
import './styles.scss';

const headlessInput = ['checkbox', 'radio', 'file'];

export const Input: React.FC<
  Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'placeholder' | 'className' | 'value'
  > & {
    id?: string;
    type:
      | 'email'
      | 'text'
      | 'number'
      | 'password'
      | 'checkbox'
      | 'radio'
      | 'file';
    className?: string;
    placeholder?: React.ReactNode;
    rawPlaceholder?: string;
    fieldClass?: string;
    fieldElement?: React.ReactElement;
    fieldHint?: React.ReactElement;
    noIntl?: boolean;
    value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
  }
> = ({
  id,
  type,
  className,
  placeholder,
  rawPlaceholder,
  fieldClass,
  fieldElement,
  fieldHint,
  noIntl,
  value,
  ...props
}) => {
  const [inputId] = React.useState(() => id || randomize());
  const placeholderElement =
    typeof placeholder === 'string' && !noIntl ? (
      <FormattedMessage id={placeholder} defaultMessage={placeholder} />
    ) : (
      placeholder
    );
  return (
    <InputField type={type} className={fieldClass}>
      {fieldElement}
      <input
        onChange={() => null}
        {...props}
        value={value || undefined}
        className={cx(`form__input__${type}`, className, {
          form__input: !headlessInput.includes(type),
        })}
        type={type}
        id={inputId}
        {...(headlessInput.includes(type)
          ? {}
          : {
              type,
              placeholder: rawPlaceholder || ' ',
            })}
      />
      {placeholder && !rawPlaceholder && (
        <label htmlFor={inputId}>{placeholderElement}</label>
      )}
      {fieldHint}
    </InputField>
  );
};
