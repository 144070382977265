import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import IconUserDelete from 'assets/icons/trash-select.svg';
import IconMonitorEdit from 'assets/icons/monitor-edit.svg';
import { ReactComponent as IconUser } from 'assets/icons/user.svg';
import { observer } from 'mobx-react-lite';
import './styles.scss';

const NameWithIcon: FC<{ id: string; fill: string }> = observer(
  ({ id, fill }) => (
    <Fragment key={`table_row${id}_name_img`}>
      <IconUser fill={fill} className="table__icon user__icon" />
      {id}
    </Fragment>
  ),
);

const Actions: FC<{ id: string }> = observer(({ id }) => {
  return (
    <Fragment key={`table_row${id}_action`}>
      <Link to={`/users/edit/${id}`}>
        <img
          src={IconMonitorEdit}
          alt="User edit"
          className="table__icon table__icon__action"
        />
      </Link>
      <img
        src={IconUserDelete}
        alt="User delete"
        className="table__icon table__icon__action"
      />
    </Fragment>
  );
});

export { NameWithIcon, Actions };
