import React from 'react';
import { Link, LinkProps, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IAppRoute, TRouteKey } from '../types';
import { routingStore } from '../store';

export type TRouteLinkActivity = boolean;
export type TRouteLinkRef = HTMLAnchorElement;

export interface IRouteLinkComponentProps extends LinkProps {
  activity: TRouteLinkActivity;
}

export interface IRouteLink extends Omit<LinkProps, 'children'> {
  defaultActivity?: TRouteLinkActivity;
  onActive?: (routeKey: TRouteKey) => void;
  children?:
    | React.ReactNode
    | ((activity: TRouteLinkActivity) => React.ReactNode);
  renderComponent?: (
    props: IRouteLinkComponentProps,
    ref: React.ForwardedRef<TRouteLinkRef>,
  ) => JSX.Element;
  to: IAppRoute['path'];
  routeKey: TRouteKey;
}

/**
 * @description
 * A wrapper for <NavLink>. Used to forwarding active status to children.
 */
const RouteLinkComponent: React.FC<IRouteLink> = ({
  children,
  renderComponent,
  defaultActivity,
  onActive,
  routeKey,
  ...props
}) => {
  const match = useMatch(props.to);
  const activity = React.useMemo(() => {
    return defaultActivity !== undefined ? defaultActivity : Boolean(match);
  }, [defaultActivity, match]);

  const CustomComponent = React.useMemo(() => {
    return (
      renderComponent &&
      React.forwardRef<
        TRouteLinkRef,
        Omit<IRouteLinkComponentProps, 'activity'>
      >((props, ref) => {
        return renderComponent({ ...props, activity }, ref);
      })
    );
  }, [activity, renderComponent]);

  const childrenForRender = React.useMemo(() => {
    return typeof children === 'function' ? children(activity) : children;
  }, [activity, children]);

  React.useEffect(() => {
    if (!onActive) return;
    if (activity) {
      onActive(routeKey);
    }
  }, [activity, routeKey, onActive]);

  return CustomComponent ? (
    <CustomComponent {...props}>{childrenForRender}</CustomComponent>
  ) : (
    <Link {...props}>{childrenForRender}</Link>
  );
};

export const RouteLink = observer<Omit<IRouteLink, 'to'>>((props) => {
  const path = React.useMemo<IRouteLink['to'] | undefined>(() => {
    const route = routingStore.routes[props.routeKey];

    return route && route.path;
  }, [routingStore.routes, props.routeKey]);

  return path ? <RouteLinkComponent {...props} to={path} /> : null;
});
