import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useFilePreview } from 'hooks/use-file-preview';
import { IFileItem } from '_types/stores';

import { Layout } from 'components/common';
import { IEditFilesListProps } from './add-edit-files';

import IconRemove from 'assets/icons/delete.svg';
import './styles.scss';

const PhotoThumnailItem: FC<{
  photo: IFileItem;
  onDelete: IEditFilesListProps['onDelete'];
}> = observer(({ photo, onDelete }) => {
  const previewLink = useFilePreview(photo);

  const handleDelete = useCallback(() => onDelete(photo.id), [photo.id]);

  return (
    <Layout flex column className="add__edit__photos__item">
      <img
        src={previewLink}
        alt="Monitor photos"
        className="add__edit__photos__item-photo"
      />
      <span className="add__edit__photos__item-filename">{photo.name}</span>
      <span className="add__edit__photos__item-ext">{photo.extension}</span>
      <button
        type="button"
        className="add__edit__photos__item-remove"
        onClick={handleDelete}
      >
        <img src={IconRemove} alt="Remove icon" />
      </button>
    </Layout>
  );
});

const PhotoThumnailSlider: FC<IEditFilesListProps> = ({ files, onDelete }) => {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    if (files.length <= 3) {
      if (offset >= 1) {
        setOffset(0);
      }
    } else if (offset + 3 > files.length) {
      setOffset(offset - 1);
    }
  }, [files, offset]);
  const handlePrev = useCallback(() => {
    if (offset >= 1) {
      setOffset(offset - 1);
    }
  }, [offset]);
  const handleNext = useCallback(() => {
    if (offset + 3 < files.length) {
      setOffset(offset + 1);
    }
  }, [offset, files]);

  if (!files.length) {
    return null;
  }

  return (
    <Layout flex row className="add__edit__photos__wrapper">
      {files.slice(offset, offset + 3).map((photo) => (
        <PhotoThumnailItem key={photo.id} photo={photo} onDelete={onDelete} />
      ))}
      <button
        type="button"
        className="add__edit__photos__navigation add__edit__photos__navigation-prev"
        onClick={handlePrev}
        disabled={offset < 1}
      >
        Prev
      </button>
      <button
        type="button"
        className="add__edit__photos__navigation add__edit__photos__navigation-next"
        onClick={handleNext}
        disabled={offset + 3 >= files.length}
      >
        Next
      </button>
    </Layout>
  );
};

export default observer(PhotoThumnailSlider);
