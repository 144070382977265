import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { IMediaItem } from '_types/stores';

import { Layout } from 'components/common';
import { FilePreview } from 'components/file-preview';

const MediaListItemPreview: FC<{
  item: IMediaItem;
}> = ({ item }) => {
  return (
    item && (
      <Layout flex column className="media-list__item-preview">
        <FilePreview file={item} className="media-list__item-preview_image" />
        <span className="media-list__item-preview_ext">{item.extension}</span>
      </Layout>
    )
  );
};

export default observer(MediaListItemPreview);
