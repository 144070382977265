import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

// @ts-ignore
momentDurationFormatSetup(moment);

import { EditorPreview, videoEditorStore } from 'modules/video-editor-module';
import { Duration } from 'components/duration';

const PlayerComponent: React.FC = () => {
  const handlePlayClick = React.useMemo(
    () =>
      videoEditorStore.isPlayed
        ? videoEditorStore.pause
        : videoEditorStore.play,
    [videoEditorStore.isPlayed],
  );

  const elapsedSeconds = React.useMemo(
    () => videoEditorStore.currentSeconds,
    [videoEditorStore.currentSeconds],
  );

  return (
    <>
      <div className="player__video-container">
        <EditorPreview className="player__body" />
        <div className="player__time">
          <Duration seconds={elapsedSeconds} />
        </div>
      </div>
      <div className="player__control">
        <div className="player__control-layout">
          <button
            onClick={handlePlayClick}
            className={cx('player__control-button', {
              'player__control-button--active': videoEditorStore.isPlayed,
            })}
          />
        </div>
        {videoEditorStore.view === 'fullscreen' && (
          <div className="player__control-layout">
            <button
              onClick={videoEditorStore.toggleView}
              className="timeline-controls__select timeline-controls__button"
            />
          </div>
        )}
      </div>
    </>
  );
};

export const Player = observer(PlayerComponent);
