import {
  FC,
  HTMLAttributes,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import './styles.scss';

export interface IPagePlateProps extends HTMLAttributes<HTMLDivElement> {
  buttons?: ReactNode;
  name?: ReactNode;
  iconClassName?: string;
}

export const PagePlate: FC<IPagePlateProps> = ({
  buttons,
  name,
  iconClassName,
  className,
  children,
  ...restProps
}) => {
  return (
    <section {...restProps} className={cx('page-plate', className)}>
      {name && (
        <header className="page-plate__header">
          <div className="page-plate__name">
            {iconClassName && (
              <div className={cx('page-plate__icon', iconClassName)} />
            )}
            {name}
          </div>
          <div className="page-plate__controls">{buttons}</div>
        </header>
      )}
      <div className="page-plate__body">{children}</div>
    </section>
  );
};

export interface IAccountantPlateFieldProps
  extends InputHTMLAttributes<HTMLInputElement>,
    PropsWithChildren {
  desc?: ReactNode;
  placeholderMessageId?: string;
}

export const AccountantPlateField: FC<IAccountantPlateFieldProps> = ({
  className,
  children,
  desc,
  placeholderMessageId,
  required,
  ...inputProps
}) => {
  const intl = useIntl();

  return (
    <label className={cx('page-plate__field', className)}>
      <div className="page-plate__field-desc">
        <span>
          {desc || <FormattedMessage id={`app.field.${inputProps.name}`} />}
        </span>
        {required && '\u00A0(*)'}
      </div>
      {children || (
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: placeholderMessageId || `app.placeholder.${inputProps.name}`,
            defaultMessage: intl.formatMessage({
              id: `app.field.${inputProps.name}`,
              defaultMessage: ' ',
            }),
          })}
          className="page-plate__input"
          onChange={() => null}
          {...inputProps}
        />
      )}
    </label>
  );
};
