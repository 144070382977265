import React, { useState } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ToastContentProps } from 'react-toastify';

import {
  IEditorProject,
  IVideoEditorStore,
  TProjectId,
} from 'modules/video-editor-module/types';

export interface IDownload extends ToastContentProps {
  editorProjectId: TProjectId;
  renderingStatus: IVideoEditorStore['renderingStatus'];
  renderingPercent: IEditorProject['renderingPercent'];
}

const Download: React.FC<IDownload> = ({
  editorProjectId,
  renderingStatus,
  renderingPercent,
  closeToast,
}) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const [toProjectLink, toProjectLinkText] = React.useMemo(
    () => [
      `/editor/project/${editorProjectId}`,
      <>
        <FormattedMessage
          id="Go to project {projectId}"
          values={{ projectId: editorProjectId.split('-')[0] }}
        />
      </>,
    ],
    [editorProjectId],
  );

  const title = React.useMemo(() => {
    switch (renderingStatus) {
      case 'ready': {
        return (
          <span className="toast-download__title">
            <FormattedMessage id="Success" />
          </span>
        );
      }
      case 'error': {
        return (
          <span className="toast-download__title">
            <FormattedMessage id="Error" />
          </span>
        );
      }
      default: {
        return (
          <span className="toast-download__title">
            <FormattedMessage id="Rendering in progress" />
          </span>
        );
      }
    }
  }, [renderingStatus]);

  const toggleConfirmBox = React.useCallback(
    () => setConfirmIsOpen((state) => !state),
    [setConfirmIsOpen],
  );

  const confirmBox = React.useMemo(() => {
    if (!confirmIsOpen) return null;

    return (
      <div className="toast-download__confirm-delete">
        <div className="toast-download__confirm-message">
          <FormattedMessage id="Sure to delete?" />
        </div>
        <div className="toast-download__confirm-buttons">
          <button
            className="toast-download__confirm-cancel"
            onClick={toggleConfirmBox}
          >
            <FormattedMessage id="Cancel" />
          </button>
          <button className="toast-download__confirm-ok" onClick={closeToast}>
            <FormattedMessage id="OK" />
          </button>
        </div>
      </div>
    );
  }, [confirmIsOpen, toggleConfirmBox, closeToast]);

  const closeButton = React.useMemo(() => {
    if (['ready', 'error'].includes(renderingStatus)) {
      return (
        <button
          className={cx('toast-download__close', {
            'toast-download__close--success': renderingStatus === 'ready',
          })}
          onClick={toggleConfirmBox}
        />
      );
    }
    return null;
  }, [renderingStatus, toggleConfirmBox]);

  const downloadIconClassName = React.useMemo(() => {
    switch (renderingStatus) {
      case 'ready':
        return 'toast-download__icon--success';
      case 'error':
        return 'toast-download__icon--error';
      default:
        return '';
    }
  }, [renderingStatus]);

  const percent = React.useMemo(
    () => `${renderingPercent || 0}%`,
    [renderingPercent],
  );

  return (
    <div className="toast-download__body">
      <div className={cx('toast-download__icon', downloadIconClassName)}>
        <div className="toast-download__percent">{percent}</div>
      </div>
      <div className="toast-download__content">
        <div className="toast-download__info">
          <div className="toast-download__info-text">
            {title}
            <Link to={toProjectLink} className="toast-download__description">
              {toProjectLinkText}
            </Link>
          </div>
          <div className="toast-download__actions">
            {/* <button*/}
            {/*  className={cx('toast-download__continue', {*/}
            {/*    'toast-download__continue--error': status === 'error',*/}
            {/*    'toast-download__continue--success': status === 'success',*/}
            {/*  })}*/}
            {/* />*/}
            {/* <button*/}
            {/*  className={cx('toast-download__pause', {*/}
            {/*    'toast-download__pause--pause': status === 'pause',*/}
            {/*    'toast-download__pause--success': status === 'success',*/}
            {/*  })}*/}
            {/* />*/}
            <div className="toast-download__button-wrapper">
              {closeButton}
              {confirmBox}
            </div>
          </div>
        </div>
        <div className="toast-download__progress-bar">
          <div
            className={cx('toast-download__progress', {
              'toast-download__progress--paused': renderingStatus === 'error',
              'toast-download__progress--success': renderingStatus === 'ready',
            })}
            style={{
              // @ts-ignore
              '--width-percent': percent,
            }}
          />
        </div>
        {/* <div className="toast-download__filesize">4500 kb of 10000 kb</div>*/}
      </div>
    </div>
  );
};

export default Download;
