import cx from 'classnames';
import {
  FC,
  HTMLProps,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';

import { InputField } from './field';

import { randomize } from 'utils';

import './styles.scss';

export const Select: FC<
  PropsWithChildren<
    HTMLProps<HTMLSelectElement> & {
      id?: string;
      className?: string;
      fieldClass?: string;
      fieldElement?: ReactElement;
    }
  >
> = ({ id, type, className, children, fieldClass, fieldElement, ...props }) => {
  const [inputId] = useState(() => id || randomize());
  return (
    <InputField className={fieldClass}>
      {fieldElement}
      <select
        {...props}
        id={inputId}
        className={cx('form__input', 'form__input__select', className)}
      >
        {children}
      </select>
    </InputField>
  );
};
