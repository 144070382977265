import React from 'react';
import { IMenu, IMenuItemProps, Menu } from '../menu';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { appStore } from '../../stores/app';

const NavMenuItem = React.forwardRef<HTMLAnchorElement, IMenuItemProps>(
  (
    {
      activity,
      displayTitle,
      className,
      iconClassName,
      iconClassNameActive,
      ...restProps
    },
    linkRef,
  ) => {
    return (
      <Link
        ref={linkRef}
        {...restProps}
        className="navigation__menu-item"
      >
        <div className={cx('navigation__menu-icon', iconClassName)} />
        <span>{displayTitle}</span>
      </Link>
    );
  },
);

export function NavigationMenu() {
  const navMenuSchema = React.useMemo<IMenu['routingSchema']>(
    () => (appStore.isCreatedFor('promo') ? ['Monitors'] : []),
    [],
  );

  return navMenuSchema.length ? (
    <Menu
      className="navigation__menu"
      routingSchema={navMenuSchema}
      ItemComponent={NavMenuItem}
    />
  ) : null;
}
