import { FC, useState, FormEvent, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Form } from 'components/forms';
import { Button, Layout } from 'components/common';

import './styles.scss';

const days = Array.from(Array(31).keys()).map((i) => i + 1);
const months = Array.from(Array(12).keys()).map((i) => i + 1);

const Billing: FC = () => {
  const [formState, setFormState] = useState(() => {
    const today = new Date();

    return {
      paymentNumber: '',
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: String(today.getFullYear()),
      comment: '',
    };
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handlePaymentNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      paymentNumber: event.target.value,
    });
  };

  const handleDayChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFormState({ ...formState, day: +event.target.value });
  };

  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFormState({ ...formState, month: +event.target.value });
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      year: event.target.value,
    });
  };

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFormState({
      ...formState,
      comment: event.target.value,
    });
  };

  const increaseDay = () => {
    setFormState((formState) => {
      return {
        ...formState,
        day: formState.day === 31 ? 1 : formState.day + 1,
      };
    });
  };

  const decreaseDay = () => {
    setFormState((formState) => {
      return {
        ...formState,
        day: formState.day === 1 ? 31 : formState.day - 1,
      };
    });
  };

  const increaseMonth = () => {
    setFormState((formState) => {
      return {
        ...formState,
        month: formState.month === 12 ? 1 : formState.month + 1,
      };
    });
  };

  const decreaseMonth = () => {
    setFormState((formState) => {
      return {
        ...formState,
        month: formState.month === 1 ? 12 : formState.month - 1,
      };
    });
  };

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <div className="payment__top-field">
        <div className="payment__light" />
        <span>Результат поиска</span>
        <input type="text" className="payment__search-input" />
        <Button primary className="payment__search-button">
          Поиск
        </Button>
      </div>
      <Form className="payment__form" onSubmit={handleSubmit}>
        <div className="payment__input-layout">
          <label htmlFor="number">Номер счёта</label>
          <input
            className="payment__input payment__number"
            id="number"
            type="number"
            name="payment number"
            value={formState.paymentNumber}
            onChange={handlePaymentNumberChange}
            required
          />
        </div>
        <div className="payment__input-layout">
          <label htmlFor="date">Дата</label>
          <div className="payment__date">
            <div className="payment__day-layout">
              <button
                className="payment__increase-date"
                type="button"
                onClick={increaseDay}
              />
              <select
                className="payment__select-date"
                value={formState.day}
                id="day"
                onChange={handleDayChange}
              >
                {days.map((day) => (
                  <option key={day}>{day}</option>
                ))}
              </select>
              <button
                className="payment__decrease-date"
                type="button"
                onClick={decreaseDay}
              />
              <label htmlFor="day" className="payment__date-label">
                день
              </label>
            </div>
            <div className="payment__month-layout">
              <button
                className="payment__increase-date"
                type="button"
                onClick={increaseMonth}
              />
              <select
                className="payment__select-date"
                value={formState.month}
                id="month"
                name="month"
                onChange={handleMonthChange}
              >
                {months.map((month) => (
                  <option key={month}>{month}</option>
                ))}
              </select>
              <button
                className="payment__decrease-date"
                type="button"
                onClick={decreaseMonth}
              />
              <label htmlFor="month" className="payment__date-label">
                мес.
              </label>
            </div>
            <input
              className="payment__year"
              type="number"
              id="year"
              name="year"
              value={formState.year.toString()}
              onChange={handleYearChange}
            />
            <label htmlFor="year" className="payment__date-label">
              год
            </label>
          </div>
        </div>
        <div className="payment__textarea-layout">
          <label htmlFor="comment" className="payment__textarea-label">
            Комментарий
          </label>
          <textarea
            className="payment__textarea"
            id="comment"
            rows={5}
            cols={33}
            value={formState.comment}
            onChange={handleCommentChange}
          />
        </div>
        <Button primary className="payment__submit-form">
          Сгенерировать
        </Button>
      </Form>
    </Layout>
  );
};

export default observer(Billing);
