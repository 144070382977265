import { ListOrder } from '../../utils/api/components';
import { IInvoicesStore } from '../../_types/stores/invoices';
import { downloadFile, handleError, swaggerApi, toast } from '../../utils';
import { action, makeObservable, observable } from 'mobx';
import { appStore } from '../app';
import { authStore } from '../auth';
import {
  InvoiceResponse,
  InvoiceStatus,
  SpecificFormat,
  UserRoleResponse,
} from '../../utils/api/api';

export class InvoicesStore extends ListOrder implements IInvoicesStore {
  @observable list: IInvoicesStore['list'] = [];
  @observable error: string | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @action getList: IInvoicesStore['getList'] = async (data) => {
    try {
      appStore.isLoading = true;

      const { data: invoicesData } = await this.getSortedList(
        swaggerApi.api.invoicesGet,
        {
          where: {},
          scope: {},
          ...data,
        },
      );
      this.list = invoicesData.data;

      return this.list;
    } catch (error) {
      toast.error(handleError(error));

      return this.list;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action create: IInvoicesStore['create'] = async (sum) => {
    if (!authStore.user?.company) {
      throw new Error(
        appStore.intl.formatMessage(
          {
            id: 'RequiredField',
            defaultMessage: 'Заполните поле "Наименование компании"',
          },
          {
            fieldName: appStore.intl.formatMessage({ id: 'app.field.company' }),
          },
        ),
      );
    }

    try {
      appStore.isLoading = true;

      await swaggerApi.api.invoiceCreate({
        sum,
        description: authStore.user.company,
      });
    } catch (e) {
      toast.error(handleError(e));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action.bound confirm: IInvoicesStore['confirm'] = async (invoice) => {
    if (
      authStore.restrictFrom([
        UserRoleResponse.MonitorOwner,
        UserRoleResponse.Advertiser,
      ]) &&
      invoice.status === InvoiceStatus.AWAITING_CONFIRMATION
    ) {
      try {
        await swaggerApi.api.invoiceConfirmed(invoice.id);

        this.list.forEach((item) => {
          if (item.id === invoice.id) {
            item.status = InvoiceStatus.CONFIRMED_PENDING_PAYMENT;
          }
        });
      } catch (e) {
        toast.error(handleError(e));
      }
    }
  };

  @action.bound confirmPayment: IInvoicesStore['confirmPayment'] = async (
    invoice,
  ) => {
    if (
      authStore.restrictFrom([
        UserRoleResponse.MonitorOwner,
        UserRoleResponse.Advertiser,
      ])
    ) {
      try {
        await swaggerApi.api.invoicePayed(invoice.id);

        this.list.forEach((item) => {
          if (item.id === invoice.id) {
            item.status = InvoiceStatus.PAID;
          }
        });
      } catch (e) {
        toast.error(handleError(e));
      }
    }
  };

  download: IInvoicesStore['download'] = async (
    invoice,
    format = SpecificFormat.Xlsx,
  ) => {
    try {
      appStore.isLoading = true;

      const response = await swaggerApi.api.invoiceDownload(
        invoice.id,
        format,
        {
          format: 'blob',
          timeout: 0,
        },
      );

      return downloadFile(
        window.URL.createObjectURL(response.data as unknown as Blob),
        `${InvoicesStore.readableNameFromSum(invoice.sum)}.${
          SpecificFormat.Xlsx
        }`,
      );
    } catch (e) {
      toast.error(handleError(e));
    } finally {
      appStore.isLoading = false;
    }
  };

  static readableNameFromSum(sum: InvoiceResponse['sum']) {
    return `Счет на оплату MyScreen - ${Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
    }).format(sum)} (${Intl.DateTimeFormat('ru-RU', {
      dateStyle: 'long',
      timeStyle: 'long',
    }).format(Date.now())})`;
  }
}

export const invoicesStore = new InvoicesStore();
