import { Layout } from 'components/common';
import { preventMe } from 'utils';
import { observer } from 'mobx-react-lite';
import {
  ChangeEvent,
  ComponentProps,
  DragEvent,
  FC,
  useCallback,
  useMemo,
} from 'react';
import DragDropUploaderActive from './drag-drop-uploader-active';
import DragDropUploaderInProgress from './drag-drop-uploader-in-progress';
import DragDropUploaderResult from './drag-drop-uploader-result';
import './styles.scss';

const DragDropFileUploader: FC<
  ComponentProps<typeof DragDropUploaderActive> &
    ComponentProps<typeof DragDropUploaderInProgress> & {
      onChange: (files: File[] | null) => void;
      state: 'active' | 'uploading' | 'done' | 'error';
      fileName: string | null;
    }
  // eslint-disable-next-line max-lines-per-function
> = ({ allowedFiles, onChange, progress, state, fileName }) => {
  // Memoes
  const acceptFormat = useMemo(
    () => allowedFiles.map((fileType) => `.${fileType}`).join(','),
    [allowedFiles],
  );
  // Callbacks
  const handleDrop = useCallback(
    (event: DragEvent<HTMLLabelElement>) => {
      preventMe(event);

      onChange([...event.dataTransfer.files]);
    },
    [onChange],
  );

  const handleFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      onChange([...event.target.files]);

      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    [onChange],
  );

  return (
    <Layout flex className="drag-and-drop__file_uploader__wrapper">
      <input
        id="video-upload"
        type="file"
        name="video"
        className="drag-and-drop__file_uploader_input"
        accept={acceptFormat}
        onChange={handleFileInput}
        multiple
      />
      {state === 'active' && (
        <label
          htmlFor="video-upload"
          onDragEnter={preventMe}
          onDragOver={preventMe}
          onDragLeave={preventMe}
          onDrop={handleDrop}
        >
          <DragDropUploaderActive allowedFiles={allowedFiles} />
        </label>
      )}
      {state === 'uploading' && (
        <DragDropUploaderInProgress progress={progress} />
      )}
      {(state === 'done' || state === 'error') && (
        <label
          htmlFor="video-upload"
          onDragEnter={preventMe}
          onDragOver={preventMe}
          onDragLeave={preventMe}
          onDrop={handleDrop}
        >
          <DragDropUploaderResult state={state} fileName={fileName} />
        </label>
      )}
    </Layout>
  );
};

export default observer(DragDropFileUploader);
