import React from 'react';
import cx from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';

import { formatSeconds } from 'utils';

import { DEFAULT_SEC_TO_PX, ONE_SECOND_MS } from 'modules/video-editor-module';

import './styles.scss';

export interface IVideoEngine {
  _timelineAnimationFrame: number;
  _currentFrame: number;
  readonly currentSeconds: number;
  isPlayed: boolean;
  playbackRate: 1 | 2;
  renderCount: number;
  totalDuration: number;

  _animate(): ((frame: number) => void | null) | null;

  play(e?: React.MouseEvent<HTMLButtonElement>): void;

  pause(e?: React.MouseEvent<HTMLButtonElement>): void;

  moveTimelinePointer(
    initObj: Partial<{
      shiftSeconds: number;
      seconds: number;
    }>,
  ): boolean;

  setPlayback(rate: IVideoEngine['playbackRate']): void;

  setTotalDuration(duration: IVideoEngine['totalDuration']): void;
}

export interface IVideoProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  sources: HTMLVideoElement[];
  children?: (engine: IVideoEngine) => React.ReactNode | React.ReactChildren;
}

const NON_CLICK_TRACK_CHILDREN = ['.video__slider'];

const VideoComponent: React.FC<IVideoProps> = ({
  sources,
  className,
  children,
}) => {
  const { timeline, totalDuration } = React.useMemo(() => {
    if (!sources.length) {
      return { timeline: [], totalDuration: 0 };
    }
    const timeline = sources.reduce((acc, video, idx) => {
      const start = acc[idx - 1]?.end || 0;

      return acc.concat({
        start,
        end: start + video.duration,
      });
    }, [] as Array<{ start: number; end: number }>);

    return {
      timeline,
      totalDuration: timeline[timeline.length - 1].end,
    };
  }, [sources]);

  const engine = useLocalObservable<IVideoEngine>(() => ({
    _timelineAnimationFrame: 0,
    _currentFrame: 0,
    isPlayed: false,
    renderCount: 0,
    playbackRate: 1,
    totalDuration: totalDuration || 0,
    get currentSeconds() {
      return this._currentFrame / ONE_SECOND_MS;
    },
    setPlayback(rate) {
      this.playbackRate = rate;
      this.renderCount++;
    },
    setTotalDuration(duration) {
      this.totalDuration = duration;
      this.renderCount++;
    },
    moveTimelinePointer({
      shiftSeconds,
      seconds,
    }: Partial<{ shiftSeconds: number; seconds: number }>) {
      if (!Number.isNaN(shiftSeconds) && typeof shiftSeconds === 'number') {
        const frameShift = shiftSeconds * ONE_SECOND_MS;
        const currentFrame = this._currentFrame + frameShift;
        if (currentFrame >= 0) {
          const totalFrame = this.totalDuration * ONE_SECOND_MS;
          if (currentFrame <= totalFrame) {
            this._currentFrame = currentFrame;
          } else {
            this._currentFrame = totalFrame;
          }
          return true;
        } else if (this._currentFrame !== currentFrame) {
          this._currentFrame = 0;
          return true;
        }
      } else if (!Number.isNaN(seconds) && typeof seconds === 'number') {
        const currentFrame = seconds * ONE_SECOND_MS;
        if (currentFrame >= 0) {
          this._currentFrame = currentFrame;
          if (this.isPlayed) {
            // replay with current frame
            this.play();
          }
          return true;
        } else if (this._currentFrame !== currentFrame) {
          this._currentFrame = 0;
          return true;
        }
      }
      return false;
    },
    play() {
      if (this._timelineAnimationFrame) {
        this.pause();
      }
      const animate = this._animate();
      if (animate) {
        this.isPlayed = true;
        this._timelineAnimationFrame = requestAnimationFrame(animate);
      }
      this.renderCount++;
    },
    pause() {
      this.isPlayed = false;
      cancelAnimationFrame(this._timelineAnimationFrame);
      this._timelineAnimationFrame = 0;
      this.renderCount++;
    },
    _animate() {
      const fpsInterval = ONE_SECOND_MS / 60;
      const startTime =
        window.performance.now() - this._currentFrame / this.playbackRate;
      let then = startTime;

      const animate = (frame: number) => {
        this._timelineAnimationFrame = requestAnimationFrame(animate);

        const elapsed = frame - then;

        if (elapsed > fpsInterval) {
          then = frame - (elapsed % fpsInterval);
          this._currentFrame = (frame - startTime) * this.playbackRate;

          const end = this.currentSeconds >= this.totalDuration;
          if (end) {
            this.pause();
          }
        }
      };
      return animate;
    },
  }));

  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const sliderRef = React.useRef<HTMLDivElement | null>(null);
  const trackRef = React.useRef<HTMLDivElement | null>(null);
  const [grab, setGrab] = React.useState(false);

  const secondsToPixelFactor = React.useCallback(() => {
    const { current: trackDiv } = trackRef;
    if (trackDiv && totalDuration > 0) {
      return trackDiv.clientWidth / totalDuration;
    }
    return DEFAULT_SEC_TO_PX;
  }, [totalDuration]);

  const calcEventCursorX = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) =>
      e.clientX -
      e.currentTarget.getBoundingClientRect().left +
      e.currentTarget.scrollLeft,
    [],
  );

  const handleTrackClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        NON_CLICK_TRACK_CHILDREN.some((css) =>
          // @ts-ignore
          e.target.closest(css),
        )
      )
        return;
      const x = calcEventCursorX(e);
      engine.moveTimelinePointer({ seconds: x / secondsToPixelFactor() });
    },
    [engine.moveTimelinePointer, secondsToPixelFactor],
  );

  const handleSliderMouseDown = React.useCallback(
    (downEvent: React.MouseEvent<HTMLDivElement>) => {
      downEvent.preventDefault(); // предотвратить запуск выделения (действие браузера)
      const wasPlayed = engine.isPlayed;
      let startPointX = downEvent.clientX;
      const onMouseMove = (moveEvent: MouseEvent) => {
        const shiftX = moveEvent.clientX - startPointX;
        const moved = engine.moveTimelinePointer({
          shiftSeconds: shiftX / secondsToPixelFactor(),
        });
        if (moved) {
          if (engine.isPlayed) {
            engine.pause();
          }
          startPointX = moveEvent.clientX;
        }
      };

      const onMouseUp = (upEvent: MouseEvent) => {
        onMouseMove(upEvent);
        // Clear
        if (wasPlayed && !engine.isPlayed) {
          engine.play();
        }
        setGrab(false);
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', onMouseMove);
      };

      setGrab(true);
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [engine.moveTimelinePointer, secondsToPixelFactor],
  );

  const sliderShiftPx = React.useMemo(() => {
    return engine.currentSeconds * secondsToPixelFactor();
  }, [engine.currentSeconds, secondsToPixelFactor]);

  const curIdx = React.useMemo(() => {
    return timeline.findIndex(({ start, end }) => {
      return engine.currentSeconds >= start && engine.currentSeconds <= end;
    });
  }, [timeline, engine.currentSeconds]);

  const childrenForRender = React.useMemo(() => {
    return typeof children === 'function' ? children(engine) : children;
  }, [children, engine.renderCount]);

  React.useEffect(() => {
    if (engine.totalDuration !== totalDuration) {
      engine.setTotalDuration(totalDuration);
    }
  }, [totalDuration]);

  React.useEffect(() => {
    const { current: canvas } = canvasRef;
    if (canvas && curIdx !== -1) {
      const { start } = timeline[curIdx];
      const source = sources[curIdx];
      const ctx = canvas.getContext('2d');

      if (engine.playbackRate !== source.playbackRate) {
        source.playbackRate = engine.playbackRate;
      }
      if (!engine.isPlayed || engine.isPlayed !== !source.paused) {
        source.currentTime = engine.currentSeconds - start;
      }
      if (engine.isPlayed && source.paused) {
        source
          .play()
          .then(() => {
            sources
              .filter((v, idx) => curIdx !== idx)
              .forEach((v) => {
                v.pause();
              });
          })
          .catch(console.error);
      } else if (!engine.isPlayed && !source.paused) {
        source.pause();
      }
      canvas.width = source.videoWidth;
      canvas.height = source.videoHeight;

      ctx?.drawImage(source, 0, 0, source.videoWidth, source.videoHeight);
    }
  }, [curIdx, sources, timeline, engine.currentSeconds, engine.isPlayed]);

  React.useEffect(() => {
    const { current: slider } = sliderRef;
    const { current: track } = trackRef;

    if (slider?.parentElement && track) {
      slider.parentElement.style.transform = `translateX(${
        sliderShiftPx -
        (slider.clientWidth || 0) * (sliderShiftPx / (track.clientWidth || 1))
      }px)`;
    }
  }, [sliderShiftPx]);

  return (
    <>
      <div className={cx('video', className, { grab })}>
        <canvas ref={canvasRef} className="video__output" />
        <div ref={trackRef} className="video__track" onClick={handleTrackClick}>
          <div className="video__slider-layout">
            <div className="video__slider-before" />
            <div
              ref={sliderRef}
              className="video__slider"
              onMouseDown={handleSliderMouseDown}
            >
              {formatSeconds(engine.currentSeconds)}
            </div>
            <div className="video__slider-after" />
          </div>
        </div>
      </div>
      {childrenForRender}
    </>
  );
};

export const Video = observer(VideoComponent);
