import React from 'react';

import { mediaStore } from 'stores/media';
import { FileResponse } from 'utils/api/api';

export function useFileLink(fileId: FileResponse['id']) {
  const [fileLink, setFileLink] = React.useState('');

  React.useEffect(() => {
    mediaStore.getFileS3(fileId).then((blob) => {
      if (blob) {
        setFileLink(window.URL.createObjectURL(blob));
      }
    });
  }, [fileId]);

  return fileLink;
}
