import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteRedirect } from './route-redirect';

/**
 * @description
 * A wrapper for <Route> that redirects to the login screen
 * if not yet authenticated.
 */
const PrivateRouteComponent: React.FC<RouteProps> = ({ children, ...rest }) => {
  const childrenForRender = React.useMemo(() => {
    if (authStore.wasAuthenticated) {
      return authStore.user ? children : <RouteRedirect routeKey="Login" />;
    }
    return null;
  }, [children, authStore.wasAuthenticated, authStore.user]);

  return childrenForRender;
};

export const PrivateRoute = observer(PrivateRouteComponent);
