import ErrorComponent from 'components/error';
import { Button } from 'components/common';

import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { authStore } from 'stores/auth';

const NotFound: FC = () => {
  useEffect(() => {
    authStore.isNotFound = true;

    return () => {
      authStore.isNotFound = false;
    };
  }, [authStore.isNotFound]);

  return (
    <ErrorComponent
      title={
        <>
          <FormattedMessage id="Error" defaultMessage="Error" /> 404
        </>
      }
      renderButton={() => {
        return (
          <Button className="error__link" tag="a" href="/" secondary>
            <FormattedMessage id="Home page" defaultMessage="Home page" />
          </Button>
        );
      }}
    />
  );
};

export default observer(NotFound);
