import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import {
  ApplicationCreateMonitorRequest,
  UserRoleResponse,
} from 'utils/api/api';
import { resetDateTime } from 'utils/formatters/reset-date-time';
import { filterObject, toast } from 'utils';
import { IMonitorItem, IPlaylistItem } from '_types/stores';

import { authStore } from 'stores/auth';
import { monitorsStore } from 'stores/monitors/monitor-list';

import { IPopupFullscreenProps, PopupFullscreen } from 'components/popup';
import { DateInput, DateRangeInput, Form, Radio } from 'components/forms';
import { Button } from 'components/common';

import './styles.scss';

export interface IApplicationData
  extends Pick<ApplicationCreateMonitorRequest, 'dateWhen' | 'dateBefore'> {
  playQueue: 'ForcePlay' | 'ToQueue';
  periodType: 'both' | 'onlyWhen' | 'always';
}

export interface IApplicationFormProps
  extends Omit<IPopupFullscreenProps, 'onSubmit' | 'onSubmitCapture'> {
  monitorIds: Array<IMonitorItem['id']>;
  playlistId?: IPlaylistItem['id'] | null;
  onSubmit?: (application: ApplicationCreateMonitorRequest) => void;
}

const ApplicationSendFormComponent: React.FC<IApplicationFormProps> = ({
  className,
  monitorIds,
  playlistId,
  onSubmit,
  ...restProps
}) => {
  const intl = useIntl();
  const today = React.useMemo(() => resetDateTime(new Date()), []);
  const [applicationData, setApplicationData] = useState<IApplicationData>({
    dateWhen: today.toISOString(),
    periodType: authStore.userRole === 'advertiser' ? 'both' : 'always',
    playQueue: 'ToQueue',
  });
  const handleApplicationChange = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      setApplicationData((state) => {
        const { name, value } = e.target;
        let newStateData: Partial<typeof state> = {
          [name]: value,
        };

        if (name === 'periodDates') {
          if (value) {
            const [dateWhen, dateBefore] = JSON.parse(value);

            newStateData = {
              ...newStateData,
              dateWhen,
              dateBefore,
            };
          } else {
            return filterObject(state, {
              excludedKeys: ['dateWhen', 'dateBefore'],
            });
          }
        }

        return {
          ...state,
          ...newStateData,
        };
      });
    },
    [setApplicationData],
  );

  const handleApplicationSubmit = useCallback(
    (e: never) => {
      let { dateWhen, dateBefore } = applicationData;

      switch (applicationData.periodType) {
        case 'always':
          {
            dateWhen = today.toISOString();
            dateBefore = null;
          }
          break;
        case 'onlyWhen': {
          if (today > new Date(dateWhen)) {
            return toast.error(intl.formatMessage({ id: 'InvalidDateWhen' }));
          }
          dateBefore = null;
        }
      }
      // TODO@nikshirobokov: Introduces time support.
      [dateWhen, dateBefore = null] = [dateWhen]
        .concat(dateBefore || [])
        .map((v) => {
          return v && resetDateTime(v).toISOString();
        });

      const application: ApplicationCreateMonitorRequest = {
        dateWhen,
        dateBefore,
        playlistChange: applicationData.playQueue === 'ForcePlay',
      };

      if (onSubmit) {
        onSubmit(application);
      } else if (monitorIds.length && playlistId) {
        monitorsStore
          .linkMonitorPlaylistMap(monitorIds, playlistId, application)
          .then((result) => {
            if (result) {
              toast.success(intl.formatMessage({ id: 'ApplicationCreated' }));
              restProps.onOutsideClick(e);
            }
          });
      }
    },
    [monitorIds, playlistId, applicationData, onSubmit, today],
  );

  const calendar = React.useMemo(() => {
    switch (applicationData.periodType) {
      case 'always':
        return null;
      case 'onlyWhen': {
        return (
          <DateInput
            isOpen={true}
            minDate={today}
            name="dateWhen"
            value={new Date(applicationData.dateWhen)}
            closeCalendar={false}
          />
        );
      }
      case 'both': {
        const startDate = new Date(applicationData.dateWhen);
        return (
          <DateRangeInput
            isOpen={true}
            minDate={today}
            name="periodDates"
            value={[
              startDate,
              applicationData.dateBefore
                ? new Date(applicationData.dateBefore)
                : startDate,
            ]}
            closeCalendar={false}
          />
        );
      }
    }
  }, [
    applicationData.periodType,
    applicationData.dateWhen,
    applicationData.dateBefore,
  ]);
  const periodsList = React.useMemo(
    () =>
      authStore.userRole === 'advertiser'
        ? ['both']
        : ['always', 'onlyWhen', 'both'],
    [authStore.userRole],
  );

  return (
    <PopupFullscreen
      {...restProps}
      className={cx('application-form-popup', className)}
    >
      <Form onChange={handleApplicationChange} className="application-form">
        {authStore.userRole &&
          (['admin', 'monitor-owner'] as UserRoleResponse[]).includes(
            authStore.userRole,
          ) && (
            <section>
              <h3>
                <FormattedMessage id="ApplicationQueue" />
              </h3>
              <fieldset name="queue">
                {(
                  ['ForcePlay', 'ToQueue'] as Array<
                    IApplicationData['playQueue']
                  >
                ).map((v) => (
                  <label key={v} className="application-form__field">
                    <Radio
                      name="playQueue"
                      value={v}
                      checked={applicationData.playQueue === v}
                      label={
                        <div className="form__radio-mask form__radio-mask--gray" />
                      }
                    />
                    <FormattedMessage id={v} />
                  </label>
                ))}
              </fieldset>
            </section>
          )}
        <section>
          <h3>
            <FormattedMessage id="ApplicationStart" />
          </h3>
          <fieldset name="periods">
            {periodsList.map((v) => {
              let label;
              switch (v) {
                case 'always':
                  {
                    label = <FormattedMessage id="ApplicationNonStop" />;
                  }
                  break;

                case 'onlyWhen':
                  {
                    label = (
                      <span>
                        {applicationData.dateWhen
                          ? intl.formatDate(applicationData.dateWhen)
                          : intl.formatMessage({
                              id: 'app.field.selectDate',
                            })}
                      </span>
                    );
                  }
                  break;

                case 'both':
                  {
                    label = (
                      <span>
                        {[applicationData.dateWhen, applicationData.dateBefore]
                          .map((isoString, idx) => {
                            if (!isoString) {
                              return intl.formatMessage({
                                id:
                                  idx === 0
                                    ? 'app.field.dateWhen'
                                    : 'app.field.dateBefore',
                              });
                            }

                            return intl.formatDate(isoString);
                          })
                          .join(' -> ')}
                      </span>
                    );
                  }
                  break;
              }
              const checked = applicationData.periodType === v;

              return (
                <label
                  key={v}
                  className={cx('application-form__field', {
                    checked,
                  })}
                  data-name={v}
                >
                  <Radio
                    name="periodType"
                    value={v}
                    checked={checked}
                    label={
                      <div className="form__radio-mask form__radio-mask--gray" />
                    }
                  />
                  {label}
                </label>
              );
            })}
          </fieldset>
          <fieldset name="calendar">{calendar}</fieldset>
        </section>
        <Button primary onClick={handleApplicationSubmit}>
          <FormattedMessage
            id="ApplicationSend"
            defaultMessage="Send selected to device"
          />
        </Button>
      </Form>
    </PopupFullscreen>
  );
};

export const ApplicationSendForm = observer(ApplicationSendFormComponent);
