import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { applicationsStore } from 'stores/applications';
import { authStore } from 'stores/auth';

import { IApplicationsStore } from '_types/stores';

import { mediaStore } from 'stores/media';

import { formatSeconds } from 'utils';
import { ApplicationApproved } from 'utils/api/api';

import { IVideoEngine, IVideoProps, Video } from 'components/video';

export interface IApplicationsWindowProps {}

const ApplicationsWindowComponent: React.FC<IApplicationsWindowProps> = () => {
  const intl = useIntl();

  const isAdvertiser = React.useMemo(
    () => authStore.userRole === 'advertiser',
    [authStore.userRole],
  );

  const playButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const application = React.useMemo(() => {
    return applicationsStore.application;
  }, [applicationsStore.application]);
  const [sources, setSources] = React.useState<IVideoProps['sources']>([]);

  const createClickCallback = React.useCallback(
    (approved: Parameters<IApplicationsStore['decide']>[1]) => {
      return () => {
        if (
          !isAdvertiser &&
          application &&
          !applicationsStore.applicationProcessed
        ) {
          void applicationsStore.decide(application, approved);
        }
      };
    },
    [
      applicationsStore.decide,
      application,
      applicationsStore.applicationProcessed,
      isAdvertiser,
    ],
  );

  const createPlayToggle = React.useCallback(
    (engine: IVideoEngine) => {
      return () => {
        if (!sources.length) return;
        if (engine.isPlayed) {
          engine.pause();
        } else {
          engine.play();
        }
      };
    },
    [sources],
  );

  const createVideoTimeSetter = React.useCallback(
    (engine: IVideoEngine, shift: number) => {
      return () => {
        if (!sources.length) return;

        if (engine) {
          engine.moveTimelinePointer({ shiftSeconds: shift });
        }
      };
    },
    [sources],
  );

  React.useEffect(() => {
    if (applicationsStore.applicationPlaylist) {
      Promise.all(
        applicationsStore.applicationPlaylist.files.map(async (file) => {
          const blob = await mediaStore.getFileS3(file.id);
          if (blob) {
            const video = document.createElement('VIDEO') as HTMLVideoElement;

            video.src = window.URL.createObjectURL(blob);

            return new Promise((resolve) => {
              video.onloadedmetadata = () => {
                resolve(video);
              };
            });
          }
          return undefined;
        }),
      ).then((videos) =>
        setSources(videos.filter((v): v is HTMLVideoElement => Boolean(v))),
      );
    }
  }, [applicationsStore.applicationPlaylist]);

  React.useEffect(() => {
    if (sources.length) {
      playButtonRef.current?.click(); // autoPlay
    }
  }, [sources]);

  return (
    <div className="applications__window">
      <div className="applications__player-layout">
        <Video className="applications__player" sources={sources}>
          {(engine) => {
            return (
              <div className="applications__controls-layout">
                <div className="applications__info">
                  <span className="applications__info-name">
                    {application?.monitor.name}
                  </span>
                  <div className="applications__info-container">
                    <div className="applications__info-time">
                      {formatSeconds(engine.totalDuration)}
                    </div>
                    <div className="applications__info-price">
                      {application?.monitor.price1s
                        ? intl.formatNumber(application.monitor.price1s, { style: 'currency', currency: 'RUB' })
                        : intl.formatMessage({ id: 'PriceFree' })}
                    </div>
                  </div>
                </div>
                <div className="applications__controls">
                  <div className="applications__decor" />
                  <button
                    className="applications__controls-button applications__rewind"
                    onClick={createVideoTimeSetter(engine, -15)}
                  />
                  <button
                    ref={playButtonRef}
                    className={cx(
                      'applications__controls-button applications__play',
                      {
                        'applications__play--on': engine.isPlayed,
                      },
                    )}
                    disabled={!sources.length}
                    onClick={createPlayToggle(engine)}
                  />
                  <button
                    className="applications__controls-button applications__forward"
                    onClick={createVideoTimeSetter(engine, 15)}
                  />
                  <button
                    className="applications__controls-button applications__speed"
                    onClick={() =>
                      engine.setPlayback(engine.playbackRate === 2 ? 1 : 2)
                    }
                  >
                    x{engine.playbackRate}
                  </button>
                </div>
                <div className="applications__buttons">
                  {[
                    ApplicationApproved.Allowed,
                    ApplicationApproved.Denied,
                  ].map((status) =>
                    isAdvertiser ? (
                      <div
                        key={status}
                        className={cx({
                          'applications__buttons-approve':
                            status === ApplicationApproved.Allowed,
                          'applications__buttons-deny':
                            status === ApplicationApproved.Denied,
                        })}
                      >
                        <FormattedMessage id={status} defaultMessage={status} />
                      </div>
                    ) : (
                      <button
                        key={status}
                        className={cx({
                          'applications__buttons-approve':
                            status === ApplicationApproved.Allowed,
                          'applications__buttons-deny':
                            status === ApplicationApproved.Denied,
                        })}
                        onClick={
                          isAdvertiser ? undefined : createClickCallback(status)
                        }
                        disabled={applicationsStore.applicationProcessed}
                      >
                        <FormattedMessage id={status} defaultMessage={status} />
                      </button>
                    ),
                  )}
                </div>
              </div>
            );
          }}
        </Video>
        <button
          className="applications__close"
          onClick={() => applicationsStore.openApplication(null)}
        />
      </div>
    </div>
  );
};

export const ApplicationsWindow = observer(ApplicationsWindowComponent);
