import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Layout } from 'components/common';

const AddEditFormField: FC<
  PropsWithChildren<{
    title: string;
    fieldClass?: string;
    layoutClass?: string;
    labelClass?: string;
    row?: boolean;
  }>
> = ({ title, fieldClass, layoutClass, labelClass, row, children }) => (
  <Layout flex row className={cx('add__edit__field', fieldClass)}>
    <span className={cx('add__edit__label', labelClass)}>
      <FormattedMessage id={title} defaultMessage={title} />
    </span>
    <span className="add__edit__hr" />
    <Layout
      flex
      column={!row}
      row={row}
      className={cx('add__edit__field__input_wrapper', layoutClass)}
    >
      {children}
    </Layout>
  </Layout>
);

export default observer(AddEditFormField);
