import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';
import { configure } from 'mobx';
import { langs } from 'myscreen-common';
import { IntlProvider } from 'react-intl';

import reportWebVitals from './report-web-vitals';

import { Language } from './_types/constants';
import { WindowResize, WindowVisibility } from 'components/window';
import ErrorHandler from 'components/handlers/error-handler';
import Catcher from 'components/handlers/catcher';
import { Preloader } from 'components/preloader';

import { appStore } from 'stores/app';

import { App } from './app';

import { authStore } from './stores/auth';
import { crossTabSessionStorage } from './utils';

import './styles/defaults.scss';
import './styles/helpers.scss';
import './styles/main.scss';

configure({
  enforceActions: 'never',
});

crossTabSessionStorage.sync().then(() => {
  authStore.initAuth();
});

const root = createRoot(document.getElementById('root') || document.body);

root.render(
  <WindowResize>
    <WindowVisibility>
      <BrowserRouter>
        <Observer>
          {() => (
            <IntlProvider
              messages={
                langs[appStore.language] as unknown as Record<string, string>
              }
              locale={appStore.language}
              defaultLocale={Language.RU}
              // just to supress dozens of error
              // that doesnt allow us to debug properly
              onError={() => null}
            >
              <ErrorHandler>
                <Catcher>
                  <App />
                  <Preloader />
                </Catcher>
              </ErrorHandler>
            </IntlProvider>
          )}
        </Observer>
      </BrowserRouter>
    </WindowVisibility>
  </WindowResize>,
);

// Global listeners

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
