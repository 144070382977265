import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import cx from 'classnames';
import { Layout } from 'components/common';

const Dashboard: FC = () => (
  <Layout className={cx('content', 'content--with-padding')}>
    Dashboard page
  </Layout>
);

export default observer(Dashboard);
