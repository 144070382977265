import React, { useCallback, useState } from 'react';
import { Button } from 'components/common';
import { SubscriptionOptions } from 'components/subscription-options';
import './styles.scss';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../stores/auth';
import { UserPlan } from '../../utils/api/api';
import { PopupFullscreen } from '../popup';
import { FormattedMessage } from 'react-intl';
import { formatBytes } from '../../utils';

export interface IDemoBarProps extends React.HTMLAttributes<HTMLDivElement> {}

export const DemoBar: React.FC<IDemoBarProps> = observer(({ className }) => {
  const [planCompareOpen, setPlanCompareOpen] = useState(false);

  const openPopup = useCallback(() => {
    setPlanCompareOpen(true);
  }, []);

  const closePopup = useCallback(() => {
    setPlanCompareOpen(false);
  }, []);

  return (
    authStore.user?.plan === UserPlan.DEMO && (
      <>
        {planCompareOpen && (
          <PopupFullscreen onOutsideClick={closePopup}>
            <SubscriptionOptions />
          </PopupFullscreen>
        )}
        <div className={cx('demo-bar__layout', className)}>
          <span className="demo-bar__text">
            <FormattedMessage
              id="YouUseDemo"
              defaultMessage="Вы используете ДЕМО-версию"
            />
          </span>
          <div className="demo-bar__info">
            <div className="demo-bar__days">
              <FormattedMessage
                id="DaysLeft"
                defaultMessage="Осталось дней: {days}"
                values={{
                  days: authStore.user.planValidityPeriod,
                }}
              />
            </div>
            <div className="demo-bar__memory-layout">
              <div className="demo-bar__progress-bar">
                <div className="demo-bar__memory-taken">
                  {formatBytes(authStore.userMetrics.storageSpace.storage)}
                </div>
                <div className="demo-bar__serifs">
                  {Array.from(Array(5)).map((_, i) => (
                    <div className="demo-bar__serif" key={i} />
                  ))}
                </div>
              </div>
              <span className="demo-bar__memory">
                  {formatBytes(authStore.userMetrics.storageSpace.total)}
                </span>
            </div>
            <Button primary className="demo-bar__button" onClick={openPopup}>
              <FormattedMessage
                id="app.button.getFull"
                defaultMessage="Перейти на {planTitle}"
                values={{
                  planTitle: 'PRO',
                }}
              />
            </Button>
          </div>
        </div>
      </>
    )
  );
});
