import { IUser } from '../stores';
import { UserRoleResponse } from 'utils/api/api';

export type StoreViewMode = 'list' | 'add' | 'edit';

export enum Language {
  RU = 'ru',
  EN = 'en',
  TT = 'tt',
}

export const UserRole: Record<
  Exclude<keyof typeof UserRoleResponse, 'Monitor'>,
  IUser['role']
> = {
  Accountant: UserRoleResponse.Accountant,
  Administrator: UserRoleResponse.Administrator,
  Advertiser: UserRoleResponse.Advertiser,
  MonitorOwner: UserRoleResponse.MonitorOwner,
};

export const UserRoleReverse = Object.fromEntries(
  Object.entries(UserRole).map(([roleKey, role]) => [role, roleKey]),
);

export const REGISTER_USER_ROLES = [UserRole.Advertiser, UserRole.MonitorOwner];
