import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import TextareaAutosize from 'react-textarea-autosize';
import { observer } from 'mobx-react-lite';

import { mediaStore } from 'stores/media';

const NewFolder: React.FC = () => {
  const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    mediaStore.folderCreation.value = event.target.value;
  };

  const handleNameKeydown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      mediaStore.turnFolderCreationOff();
    }
  };

  return (
    <div className="folder__layout">
      <div className="folder__item library__item">
        <div className="folder__icon" />
        <OutsideClickHandler onOutsideClick={mediaStore.turnFolderCreationOff}>
          <TextareaAutosize
            className="folder__textarea"
            value={mediaStore.folderCreation.value}
            onChange={handleNameChange}
            onKeyDown={handleNameKeydown}
            maxRows={2}
          />
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default observer(NewFolder);
