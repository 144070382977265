import cx from 'classnames';
import {
  toast as ReactToastifyToast,
  ToastClassName,
  ToastOptions,
} from 'react-toastify';

import { Toast } from 'components/common';

type Method = (description: string, options?: ToastOptions) => void;
type Toast = {
  success: Method;
  info: Method;
  warning: Method;
  error: Method;
};

const toastClassNameToString = (tClassName?: ToastClassName) =>
  typeof tClassName === 'function' ? tClassName() : tClassName;

export const toast: Toast = {
  success: (description, options = {}) => {
    ReactToastifyToast.success(
      <Toast title="Success" description={description} />,
      {
        ...options,
        className: cx(
          'Toastify__toast-message',
          toastClassNameToString(options.className),
        ),
      },
    );
  },
  info: (description, options = {}) => {
    ReactToastifyToast.info(<Toast title="Info" description={description} />, {
      ...options,
      className: cx(
        'Toastify__toast-message',
        toastClassNameToString(options.className),
      ),
    });
  },
  warning: (description, options = {}) => {
    ReactToastifyToast.warning(
      <Toast title="Warning" description={description} />,
      {
        ...options,
        className: cx(
          'Toastify__toast-message',
          toastClassNameToString(options.className),
        ),
      },
    );
  },
  error: (description, options = {}) => {
    ReactToastifyToast.error(
      <Toast title="Error" description={description} />,
      {
        ...options,
        autoClose: options.autoClose || 15000,
        className: cx(
          'Toastify__toast-message',
          toastClassNameToString(options.className),
        ),
      },
    );
  },
};
