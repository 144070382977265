import React, { FC } from 'react';
import ReactTestUtils from 'react-dom/test-utils';
import { useIntl, FormattedMessage } from 'react-intl';
import { Placemark } from 'react-yandex-maps';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { Feature } from 'geojson';

import { RouteLink } from 'modules/routing-module';

import { MonitorOrientation } from 'utils/api/api';
import { stringToCoords, coordsToFeature, TCoords } from 'utils/geojson';
import { simulateChange } from 'utils/interceptors/simulate-change';
import {
  checkFeatureKind,
  geoSearch,
  parseFeatureToAddress,
  SEARCH_TEXT_SEPARATOR,
  throttledGeoSearch,
} from 'utils/yandex-api';
import { IMonitorItem } from '_types/stores';
import { FIELD_NAME_SEPARATOR, GREEN_COLOR } from '../../constants';
import {
  ADDRESS_TEXT_PARAMS,
  categoryOptions,
  monitorMatrixList,
  monitorResolutions,
  MOSCOW_COORDS,
} from './constants';

import { AddEditFormField as FormField } from 'components/add-edit-form';
import { Button, Layout } from 'components/common';
import {
  CustomMap,
  MIN_SEARCH_TEXT_LENGTH,
  TMapEvent,
} from 'components/custom-map';
import { Checkbox, Input, Radio, Select } from 'components/forms';

import './styles.scss';

const NameField: FC<Pick<IMonitorItem, 'name'>> = ({ name }) => (
  <FormField title="Name">
    <Input
      name="name"
      type="text"
      placeholder="Device name"
      fieldClass="add__edit__form__field"
      defaultValue={name}
      required
    />
  </FormField>
);

export interface ILocationFieldProps
  extends Pick<IMonitorItem, 'address' | 'location'> {}

const LocationField: FC<ILocationFieldProps> = ({ address, location }) => {
  const addressRef = React.useRef<HTMLInputElement | null>();
  const locationRef = React.useRef<HTMLInputElement | null>();

  const coordinates = React.useMemo(
    () => location && location.coordinates,
    [location],
  );

  const inputAddress = React.useCallback((f: Feature) => {
    const { current: input } = addressRef;

    if (input) {
      input.value = JSON.stringify(parseFeatureToAddress(f));

      ReactTestUtils.Simulate.change(input);
    }
  }, []);

  const inputGeometry = React.useCallback((g: Feature['geometry'] | null) => {
    const { current: input } = locationRef;

    if (input) {
      input.value = g ? JSON.stringify(g) : '';

      ReactTestUtils.Simulate.change(input);
    }
  }, []);

  const inputCoordsWithAddress = React.useCallback(
    (coords: TCoords, withThrottle = false) => {
      const feature = coordsToFeature(coords);

      inputGeometry(feature.geometry);

      (withThrottle ? throttledGeoSearch : geoSearch)(
        {
          text: coords.toString(),
          results: 1,
        },
        ({ features }) => {
          if (features.length) {
            const feature = features[0];

            if (
              checkFeatureKind(feature, [
                'locality',
                'province',
                'street',
                'house',
              ])
            ) {
              inputAddress(feature);
            }
          }
        },
      );
    },
    [inputAddress],
  );

  const handleMapClick = React.useCallback(
    (e: TMapEvent) => {
      inputCoordsWithAddress(e.get('coords'));
    },
    [inputCoordsWithAddress],
  );

  const handleAddressInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const [, addressPartName] = name.split(FIELD_NAME_SEPARATOR);
      const addressParts = ADDRESS_TEXT_PARAMS.map((key) => address[key]);

      switch (addressPartName) {
        case 'city':
          addressParts[0] = value;
          break;
        case 'street':
          addressParts[1] = value;
          break;
        case 'house':
          addressParts[2] = value;
      }

      const text = addressParts
        .filter((value): value is string => Boolean(value))
        .join(SEARCH_TEXT_SEPARATOR);

      if (text.length >= MIN_SEARCH_TEXT_LENGTH) {
        throttledGeoSearch(
          {
            text,
          },
          ({ features }) => {
            if (features.length) {
              inputGeometry(features[0].geometry);
            } else {
              inputGeometry(null);
            }
          },
        );
      }
    },
    [address.city, address.street, address.house, inputGeometry],
  );

  const handleCoordsInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const coords = (coordinates || [0, 0]).concat() as [number, number];

      switch (e.target.name) {
        case 'latitude':
          {
            if (-90 > +e.target.value || +e.target.value > 90) {
              return;
            }

            coords[0] = +e.target.value;
          }
          break;

        case 'longitude': {
          if (-180 > +e.target.value || +e.target.value > 180) {
            return;
          }

          coords[1] = +e.target.value;
        }
      }

      if (coords.length === 2 && coords.every((val) => !isNaN(val))) {
        inputCoordsWithAddress(coords, true);
      }
    },
    [coordinates, inputCoordsWithAddress],
  );

  const handleCoordsPaste = React.useCallback(
    (e: React.ClipboardEvent) => {
      const clipboardString = e.clipboardData.getData('text/plain');
      const [latitude, longitude] = stringToCoords(clipboardString);

      if (latitude && longitude) {
        e.preventDefault();
        inputCoordsWithAddress([latitude, longitude]);
      }
    },
    [inputCoordsWithAddress],
  );

  const dynamicZoom = React.useMemo(() => {
    let zoom = 9;

    if (address.street) {
      zoom = 13;
    }

    if (address.house) {
      zoom = 16;
    }

    return zoom;
  }, [address.street, address.house]);

  const mapState = React.useMemo(() => {
    if (!coordinates) return;

    return {
      center: coordinates,
      zoom: dynamicZoom,
    };
  }, [dynamicZoom, coordinates]);

  const addressFields = React.useMemo(
    () =>
      ADDRESS_TEXT_PARAMS.map((part) => {
        const name = `address${FIELD_NAME_SEPARATOR}${part}`;
        const value = address[part] || '';
        const placeholder = part[0].toUpperCase() + part.substring(1);

        return (
          <Input
            key={name}
            name={name}
            type="text"
            placeholder={placeholder}
            fieldClass="add__edit__form__field"
            value={value}
            onChange={handleAddressInputChange}
            required
          />
        );
      }),
    [address.city, address.street, address.house, handleAddressInputChange],
  );

  const coordsFields = React.useMemo(
    () =>
      ['latitude', 'longitude'].map((name, idx) => {
        const value = coordinates ? coordinates[idx] : '';
        const placeholder = name[0].toUpperCase() + name.substring(1);

        return (
          <Input
            key={name}
            name={name}
            type="number"
            step={'any'}
            placeholder={placeholder}
            fieldClass="add__edit__form__field monitor__field-coordinates"
            value={value}
            onChange={handleCoordsInputChange}
            onPaste={handleCoordsPaste}
            required
          />
        );
      }),
    [coordinates, handleCoordsPaste, handleCoordsInputChange],
  );

  return (
    <FormField title="Address" fieldClass="add__edit__address">
      {addressFields}
      <div className="add__edit__coords add__edit__form__field">
        {coordsFields}
      </div>
      <CustomMap
        defaultState={{
          center: MOSCOW_COORDS,
          zoom: 8,
        }}
        width="100%"
        height="300px"
        events={[['click', handleMapClick]]}
        state={mapState}
      >
        <Placemark
          geometry={coordinates}
          options={{
            iconColor: GREEN_COLOR,
          }}
        />
      </CustomMap>
      <input
        ref={(input) => (addressRef.current = input)}
        type="hidden"
        name="address"
      />
      <input
        ref={(input) => (locationRef.current = input)}
        type="hidden"
        name="location"
      />
    </FormField>
  );
};

const CategoryField: FC<
  Pick<IMonitorItem, 'category'> & { className: string }
> = ({ category, className }) => {
  const intl = useIntl();

  return (
    <FormField title="Category">
      <Select
        name="category"
        fieldClass="add__edit__form__field"
        className={cx('form__input_select', className)}
        defaultValue={category}
      >
        {categoryOptions.map((value) => (
          <option key={value} value={value}>
            {intl.formatMessage({ id: value || 'Select a category' })}
          </option>
        ))}
      </Select>
    </FormField>
  );
};

const ONE_DAY_SECONDS = 24 * 3600;

const PriceFields: FC<{ monitor: IMonitorItem }> = ({ monitor }) => {
  const { price1s, minWarranty, maxDuration } = monitor;
  const warrantyInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const duration = +e.target.value;
      simulateChange(warrantyInputRef, String(ONE_DAY_SECONDS / duration));
    },
    [],
  );

  // TODO@nikshirobokov: Ограничивать поле minWarranty в зависимости от результатов метода monitorGetApplications
  // const [calcMinWarranty, setCalcMinWarranty] =
  //   React.useState<IMonitorItem['minWarranty']>(minWarranty);
  //
  // React.useEffect(() => {
  //   setCalcMinWarranty((state) => {
  //     if (state === minWarranty) return state;
  //     return minWarranty;
  //   });
  // }, [minWarranty, setCalcMinWarranty]);

  return (
    <>
      <FormField title="Price of 1 second of impression">
        <Input
          name="price1s"
          type="number"
          step={'any'}
          rawPlaceholder="1000 (RUB)"
          fieldClass="add__edit__form__field"
          defaultValue={price1s}
        />
      </FormField>
      <FormField title="Max playlist duration">
        <Input
          name="maxDuration"
          type="number"
          step={'any'}
          rawPlaceholder="0"
          fieldClass="add__edit__form__field"
          defaultValue={maxDuration}
          onChange={handleInputChange}
        />
      </FormField>
      <FormField title="Min number of impressions per day">
        <input type="hidden" ref={warrantyInputRef} name="minWarranty" />
        <Input
          name="minWarranty"
          type="number"
          step={'any'}
          rawPlaceholder=""
          fieldClass="add__edit__form__field"
          value={minWarranty}
          disabled
        />
      </FormField>
    </>
  );
};

const OrientationField: FC<Pick<IMonitorItem, 'orientation' | 'id'>> = ({
  orientation,
  id,
}) => {
  if (!id) {
    return (
      <FormField
        title="Screen orientation"
        row
        layoutClass="monitor__orientation__layout"
      >
        <div className="monitor__orientation__icon_h">
          <Checkbox
            name="orientation"
            defaultValue={MonitorOrientation.Horizontal}
            checked={orientation === MonitorOrientation.Horizontal}
            label={
              <FormattedMessage id="Horizontal" defaultMessage="Horizontal" />
            }
            onChange={() => null}
            colorModifier="dark"
            sizeModifier="small"
          />
        </div>
        <div className="monitor__orientation__icon_v">
          <Checkbox
            name="orientation"
            defaultValue={MonitorOrientation.Vertical}
            checked={orientation === MonitorOrientation.Vertical}
            label={<FormattedMessage id="Vertical" defaultMessage="Vertical" />}
            onChange={() => null}
            colorModifier="dark"
            sizeModifier="small"
          />
        </div>
      </FormField>
    );
  }

  return (
    <FormField
      title="Screen orientation"
      row
      layoutClass="monitor__orientation__layout"
    >
      <Radio
        name="orientation"
        defaultValue={MonitorOrientation.Horizontal}
        checked={orientation === MonitorOrientation.Horizontal}
        label={
          <div className="monitor__orientation__icon_h">
            <FormattedMessage id="Horizontal" defaultMessage="Horizontal" />
          </div>
        }
        onChange={() => null}
        required
      />
      <Radio
        name="orientation"
        defaultValue={MonitorOrientation.Vertical}
        checked={orientation === MonitorOrientation.Vertical}
        label={
          <div className="monitor__orientation__icon_v">
            <FormattedMessage id="Vertical" defaultMessage="Vertical" />
          </div>
        }
        onChange={() => null}
        required
      />
    </FormField>
  );
};

const MonitorInformationField: FC<
  Pick<IMonitorItem, 'monitorInfo'>
  // eslint-disable-next-line max-lines-per-function
> = ({ monitorInfo }) => (
  <Layout flex column className="monitor__monitor__layout">
    <Input
      name="monitor.model"
      type="text"
      placeholder="Monitor Model"
      fieldClass="add__edit__form__field monitor__monitor__field monitor__monitor__field-model"
      defaultValue={monitorInfo.model}
    />
    <Layout flex row className="monitor__monitor__field__wrapper">
      <Select
        name="monitor.permission"
        fieldClass="add__edit__form__field monitor__monitor__field"
        className="form__input_select monitor__input-info"
        defaultValue={monitorInfo.resolution}
        fieldElement={
          <span className="monitor__monitor__field-label">
            <FormattedMessage id="Permission" defaultMessage="Permission" />
          </span>
        }
        required
      >
        {monitorResolutions.map((r) => (
          <option key={r} value={r}>
            {r}
          </option>
        ))}
      </Select>
      <Input
        name="monitor.angle"
        type="number"
        min={0}
        max={180}
        step={1}
        placeholder="Viewing angle"
        fieldClass="add__edit__form__field monitor__monitor__field"
        className="monitor__input-info monitor__input-info--angle"
        defaultValue={monitorInfo.angle}
      />
    </Layout>
    <Layout flex row className="monitor__monitor__field__wrapper">
      <Select
        name="monitor.matrix"
        fieldClass="add__edit__form__field monitor__monitor__field"
        className="form__input_select monitor__input-info"
        defaultValue={monitorInfo.matrix}
        fieldElement={
          <span className="monitor__monitor__field-label">
            <FormattedMessage
              id="The type of matrix"
              defaultMessage="The type of matrix"
            />
          </span>
        }
        required
      >
        {monitorMatrixList.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </Select>
      <Input
        name="monitor.brightness"
        type="number"
        min={0}
        max={100}
        step={1}
        placeholder="Brightness"
        fieldClass="add__edit__form__field monitor__monitor__field"
        className="monitor__input-info monitor__input-info--brightness"
        defaultValue={monitorInfo.brightness}
      />
    </Layout>
  </Layout>
);

const Actions: FC = () => (
  <Layout flex row className="add__edit__form__actions">
    <RouteLink routeKey="MonitorsList" className="button button-secondary">
      <FormattedMessage id="Cancel" defaultMessage="Cancel" />
    </RouteLink>
    <Button type="submit" primary>
      <FormattedMessage id="Save" defaultMessage="Save" />
    </Button>
  </Layout>
);

const NameFieldWrapped = observer(NameField);
const LocationFieldWrapped = observer(LocationField);
const CategoryFieldWrapped = observer(CategoryField);
const PriceFieldsWrapped = observer(PriceFields);
const OrientationFieldWrapped = observer(OrientationField);
const MonitorInformationFieldWrapped = observer(MonitorInformationField);
const ActionsWrapped = observer(Actions);

export {
  NameFieldWrapped as NameField,
  LocationFieldWrapped as LocationField,
  CategoryFieldWrapped as CategoryField,
  PriceFieldsWrapped as PriceFields,
  OrientationFieldWrapped as OrientationField,
  MonitorInformationFieldWrapped as MonitorInformationField,
  ActionsWrapped as Actions,
};
