import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/common';

import IconMonitorSearch from 'assets/icons/search-monitor.svg';

import './styles.scss';

export interface ILinksPlaylistMonitorProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  disabled: boolean;
}

const LinkPlaylistMonitor: React.FC<ILinksPlaylistMonitorProps> = ({
  disabled,
  ...restProps
}) => {
  return (
    <div className="link-monitor-playlist-button">
      <Button
        type="submit"
        primary
        className="playlists__link-button"
        disabled={disabled}
        {...restProps}
      >
        <img
          src={IconMonitorSearch}
          alt="Icon search"
          className="playlists__search-icon"
        />
        <span className="text">
          <FormattedMessage
            id="Send selected to device"
            defaultMessage="Send selected to device"
          />
        </span>
      </Button>
    </div>
  );
};

export default LinkPlaylistMonitor;
