import React from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

type ConfirmationProps = {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const { message, onConfirm, onCancel } = props;

  return (
    <div className="confirmation__layout">
      <div className="confirmation__window">
        <div>
          <p className="confirmation__text">
            <FormattedMessage id={message} defaultMessage={message} />
          </p>
          <div className="confirmation__buttons">
            <button
              className="confirmation__button confirmation__button--confirm"
              onClick={onConfirm}
            >
              <FormattedMessage id="Confirm" defaultMessage="Confirm" />
            </button>
            <button
              className="confirmation__button confirmation__button--cancel"
              onClick={onCancel}
            >
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
