import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import { Button, Layout } from 'components/common';
import { InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import SendIcon from 'assets/icons/send-email.svg';
import './styles.scss';

export const SendRegisterEmail: FC = () => {
  const location = useLocation();
  const { domain_web: domainWeb } = React.useMemo(
    () => queryString.parse(location.search),
    [location.search],
  );

  return (
    <AuthWrapper
      title="Email Delivered"
      subtitle="Check your email, you will now receive a confirmation email"
    >
      <Layout className="form" flex>
        <InputField>
          <img src={SendIcon} className="page-image  auth__icon" alt="Sent" />
        </InputField>
        <InputField>
          {domainWeb !== undefined ? (
            <Button
              tag="a"
              href={domainWeb as string}
              target="_blank"
              primary
              className="auth__button auth__button--margin"
            >
              <FormattedMessage id="Go to email" defaultMessage="Go to email" />
            </Button>
          ) : (
            <Link
              to="/login"
              className="button button-primary auth__button auth__button--margin"
            >
              <FormattedMessage id="Sign In" defaultMessage="Sign in" />
            </Link>
          )}
        </InputField>
      </Layout>
    </AuthWrapper>
  );
};
