import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { videoEditorStore } from 'modules/video-editor-module';

interface ITimelineScale extends React.HTMLAttributes<HTMLDivElement> {
  timeStamps: undefined[];
}

const TimelineScaleComponent: React.FC<ITimelineScale> = ({
  timeStamps,
  className,
  ...restProps
}) => {
  const timeScale = React.useMemo(() => {
    return timeStamps.map((nothing, idx) => {
      const seconds = (idx + 1) * videoEditorStore.scaleUnitSeconds; // skipping 0 index
      const time = moment
        .duration(seconds, 'seconds')
        .format(seconds > 59 ? 'mm:ss' : '00:ss');

      return (
        <div key={`${time}Unit`} className="timeline__unit">
          <div className="timeline__unit-time">
            <span>{time}</span>
          </div>
          <div className="timeline__unit-scale">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      );
    });
  }, [timeStamps, videoEditorStore.scaleUnitSeconds]);

  return (
    <div {...restProps} className={cx('timeline__stamps', className)}>
      {timeScale}
      <div className="timeline__line" />
    </div>
  );
};

export const TimelineScale = observer(TimelineScaleComponent);
