import Chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import React, { FC, ReactNode, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import kebabCase from 'lodash.kebabcase';

import { authStore } from 'stores/auth';

import { Layout } from 'components/common';
import { Checkbox } from 'components/forms';

import './styles.scss';
import { PagePlate } from '../../components/page-plate/page-plate';
import icons from '../../assets/icons/icons.module.scss';
import { IUser } from '../../_types/stores';
import { UserRoleReverse } from '../../_types/constants';
import { formatBytes } from '../../utils';

const UserInfoField: FC<{ name: keyof IUser }> = observer(({ name }) => {
  const intl = useIntl();

  const { messageId, value } = useMemo(() => {
    let messageId = `app.field.${name}`,
      value = (authStore.user && authStore.user[name]) as ReactNode;

    switch (name) {
      case 'updatedAt':
        {
          messageId = 'Last entry';
          value = intl.formatDate(authStore.user?.updatedAt, {
            dateStyle: 'medium',
            timeStyle: 'full',
          });
        }
        break;

      case 'role': {
        messageId = 'Role';
        value = <FormattedMessage id={UserRoleReverse[value as string]} />;
      }
    }

    return {
      messageId,
      value,
    };
  }, [authStore.user, name, intl]);

  return (
    <>
      <div className="current-user__info-field">
        <FormattedMessage id={messageId} defaultMessage={name} />
      </div>
      <div className="current-user__info-value">{value}</div>
    </>
  );
});

const UserInfoPlate: FC = () => {
  return (
    <PagePlate
      iconClassName={icons.User}
      name={
        <h2>
          <FormattedMessage id="About the user" defaultMessage="О пользователе" />
        </h2>
      }
    >
      <div className="current-user__info">
        {(['email', 'name', 'role', 'updatedAt'] as Array<keyof IUser>).map(
          (key) => (
            <UserInfoField key={key} name={key} />
          ),
        )}
      </div>
    </PagePlate>
  );
};

const CurrentUser: React.FC = () => {
  const [diskOptionsOpened, setDiskOptionsOpened] = useState(false);
  const [diskOptions, setDiskOptions] = useState({
    'is used': true,
    'is not used': true,
    busy: true,
    free: true,
  });
  // TODO@nikshirobokov: Сделать умнее показатели isUsed и Busy, isNotUsed и Free.
  const diskOptionsList = ['is used', 'is not used', 'busy', 'free'] as const;

  const renderDiskOptionsMenu = () => {
    if (!diskOptionsOpened) return null;

    return (
      <div className="current-user__disk-options-list">
        {diskOptionsList.map((option) => {
          const optionFormatted =
            option.charAt(0).toUpperCase() + option.slice(1);
          const checked = diskOptions[option];

          const handleChange = () => {
            setDiskOptions({
              ...diskOptions,
              [option]: !diskOptions[option],
            });
          };

          return (
            <div className="current-user__disk-option" key={option}>
              <Checkbox
                label=""
                name="disk-options"
                colorModifier="gray"
                className="current-user__disk-option-checkbox"
                checked={checked}
                onChange={handleChange}
              />
              <span>
                <FormattedMessage
                  id={optionFormatted}
                  defaultMessage={optionFormatted}
                />
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDiskOptions = () => {
    return diskOptionsList.map((option) => {
      if (!diskOptions[option]) return null;

      const optionFormatted = option.charAt(0).toUpperCase() + option.slice(1);

      let value;
      switch (option) {
        case 'busy':
        case 'is used':
          {
            value = authStore.userMetrics.storageSpace.storage;
          }
          break;

        case 'is not used':
        case 'free':
          {
            value =
              authStore.userMetrics.storageSpace.total -
              authStore.userMetrics.storageSpace.storage;
          }
          break;
      }

      return (
        <div
          className={`current-user__space-field current-user__space-field--${kebabCase(
            option,
          )}`}
          key={option}
        >
          <div
            className={`current-user__space-label current-user__space-label--${kebabCase(
              option,
            )}`}
          />
          <FormattedMessage
            id={optionFormatted}
            defaultMessage={optionFormatted}
          />{' '}
          -
          <span
            className={`current-user__space-value current-user__space-value--${kebabCase(
              option,
            )}`}
          >
            {formatBytes(value)}
          </span>
        </div>
      );
    });
  };

  const renderPieChart = () => {
    const getPieces = () => {
      const data = {
        ...authStore.userMetrics.storageSpace,
        get playing(): number {
          return this.storage;
        },
      };

      const playing = diskOptions['is used'] ? data.playing / data.total : 0;

      const notPlaying = diskOptions['is not used']
        ? (data.storage - data.playing) / data.total
        : 0;

      const storage = diskOptions.busy
        ? data.storage / data.total - playing - notPlaying
        : 0;

      const free = 1 - playing - notPlaying - storage;

      return {
        playing,
        notPlaying,
        storage,
        free,
      };
    };

    const pieces = getPieces();

    const data = {
      series: [
        {
          value: pieces.playing,
          className: 'current-user__pie--playing',
        },
        {
          value: pieces.notPlaying,
          className: 'current-user__pie--not-playing',
        },
        {
          value: pieces.storage,
          className: 'current-user__pie--used',
        },
        {
          value: pieces.free,
          className: 'current-user__pie--free',
        },
      ],
    };

    const options: Chartist.IPieChartOptions = {
      width: '252px',
      height: '252px',
      donut: true,
      donutWidth: 24,
      showLabel: false,
      total: 1,
    };

    return <ChartistGraph data={data} options={options} type="Pie" />;
  };

  return (
    <Layout className="content content--with-padding" id="metrics">
      <PagePlate
        className="counters"
        iconClassName={icons.Devices}
        name={
          <h2>
            <FormattedMessage id="Devices" defaultMessage="Devices" />
          </h2>
        }
        buttons={
          <div className="current-user__title-info">
            <span>
              <FormattedMessage
                id={
                  authStore.userRole === 'advertiser'
                    ? 'Devices purchased'
                    : 'Registered devices'
                }
              />
            </span>
            <div className="current-user__title-info-value">
              <span>{authStore.userMetrics.monitors.user}</span>
            </div>
          </div>
        }
      >
        <div className="current-user__section--devices">
          <div className="current-user__devices">
            <div className="current-user__devices-option">
              <div className="current-user__devices-label">
                <FormattedMessage id="Online" defaultMessage="Online" />
              </div>
              <div className="current-user__devices-line" />
              <div className="current-user__devices-value current-user__devices-value--online">
                {authStore.userMetrics.monitors.online}
              </div>
            </div>
            <div className="current-user__devices-option">
              <div className="current-user__devices-label">
                <FormattedMessage id="Offline" defaultMessage="Offline" />
              </div>
              <div className="current-user__devices-line" />
              <div className="current-user__devices-value current-user__devices-value--offline">
                {authStore.userMetrics.monitors.offline}
              </div>
            </div>
            <div className="current-user__devices-option">
              <div className="current-user__devices-label">
                <FormattedMessage
                  id="Without applications"
                  defaultMessage="Без заявок"
                />
              </div>
              <div className="current-user__devices-line" />
              <div className="current-user__devices-value current-user__devices-value--drafts">
                {authStore.userMetrics.monitors.empty}
              </div>
            </div>
          </div>
          <button className="current-user__add-create-button">
            <FormattedMessage id="Add" defaultMessage="Add" />
          </button>
        </div>
      </PagePlate>
      <UserInfoPlate />
      <PagePlate
        iconClassName={icons.Playlists}
        name={
          <h2 className="current-user__title current-user__title--playlists">
            <FormattedMessage id="Playlists" defaultMessage="Playlists" />
          </h2>
        }
        buttons={
          <div className="current-user__title-info">
            <span>
              <FormattedMessage
                id={
                  authStore.userRole === 'advertiser'
                    ? 'Created playlists'
                    : 'Created/Paid playlists'
                }
              />
            </span>
            <div className="current-user__title-info-value">
              <span>
                {[
                  authStore.userMetrics.playlists.played,
                  authStore.userMetrics.playlists.added,
                ].join('/')}
              </span>
            </div>
          </div>
        }
      >
        <div className="current-user__playlists">
          <div className="current-user__playlists-option">
            <div className="current-user__playlists-label">
              <FormattedMessage
                id="On the broadcast"
                defaultMessage="On the broadcast"
              />
            </div>
            <div className="current-user__playlists-line" />
            <div className="current-user__playlists-value current-user__playlists-value--on">
              {authStore.userMetrics.playlists.played}
            </div>
          </div>
          <div className="current-user__playlists-option">
            <div className="current-user__playlists-label">
              <FormattedMessage
                id="No broadcast"
                defaultMessage="No broadcast"
              />
            </div>
            <div className="current-user__playlists-line" />
            <div className="current-user__playlists-value current-user__playlists-value--off">
              {authStore.userMetrics.playlists.added -
                authStore.userMetrics.playlists.played}
            </div>
          </div>
        </div>
        <button className="current-user__add-create-button">
          <FormattedMessage id="Create" defaultMessage="Создать" />
        </button>
      </PagePlate>
      <div className="current-user__section current-user__section--disk">
        <div className="current-user__section-top">
          <h2 className="current-user__title current-user__title--disk">
            <FormattedMessage id="Disk space" defaultMessage="Disk space" />
          </h2>
          <OutsideClickHandler
            onOutsideClick={() => setDiskOptionsOpened(false)}
          >
            <div className="current-user__disk-options">
              <button
                className={
                  diskOptionsOpened
                    ? 'current-user__disk-options-button current-user__disk-options-button--opened'
                    : 'current-user__disk-options-button'
                }
                onClick={() => setDiskOptionsOpened(!diskOptionsOpened)}
              >
                <FormattedMessage
                  id="Display info"
                  defaultMessage="Display info"
                />
              </button>
              {renderDiskOptionsMenu()}
            </div>
          </OutsideClickHandler>
        </div>
        <div className="current-user__disk">
          <div className="current-user__pie">
            {renderPieChart()}
            <div className="current-user__free-space">
              <span>
                <FormattedMessage id="Free" defaultMessage="Free" />
              </span>
              <div className="current-user__free-space-value">
                {formatBytes(
                  authStore.userMetrics.storageSpace.total -
                    authStore.userMetrics.storageSpace.storage,
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="current-user__space-field current-user__space-field--total">
              <div className="current-user__space-label current-user__space-label--total" />
              <FormattedMessage id="Total" defaultMessage="Total" /> -
              <span className="current-user__space-value current-user__space-value--total">
                {formatBytes(authStore.userMetrics.storageSpace.total)}
              </span>
            </div>
            {renderDiskOptions()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(CurrentUser);
