import { IMapFilterData } from '../pages/monitors/monitors-map';
import { MonitorCategory } from '../utils/api/api';

export * from './users';

export type TAbbreviationKeys = keyof IMapFilterData | MonitorCategory;

export const ABBREVIATIONS: Record<TAbbreviationKeys, string> = {
  address: 'adr',
  selectedCategories: 'sc',
  radius: 'r',
  latitude: 'lat',
  longitude: 'lng',
  [MonitorCategory.GAS_STATION]: 'gas',
  [MonitorCategory.MALL]: 'mall',
  [MonitorCategory.FOOD]: 'food',
  [MonitorCategory.CAFES_AND_RESTAURANTS]: 'cafe',
  [MonitorCategory.PHARMACY]: 'pha',
  [MonitorCategory.ATM]: 'atm',
  [MonitorCategory.HOTEL]: 'hotl',
  [MonitorCategory.AUTO_REPAIR]: 'autrr',
  [MonitorCategory.CAR_WASHING]: 'carwsh',
  [MonitorCategory.HOSPITALS]: 'hsptl',
  [MonitorCategory.CINEMAS]: 'cnma',
  [MonitorCategory.SAUNAS]: 'sana',
  [MonitorCategory.BEAUTY_SALONS]: 'besalo',
};

export const ABBREVIATIONS_REVERSE = Object.fromEntries(
  Object.entries(ABBREVIATIONS).map(([key, abbr]) => [abbr, key]),
);

export const GRAY_COLOR = '#697185';
export const GREEN_COLOR = '#32c832';
export const GREEN_DARK_COLOR = '#00a800';

export const FIELD_NAME_SEPARATOR = '.';
export const ONE_GB_IN_BYTES = 1073741824;
export const TEN_GB_IN_BYTES = 10 * ONE_GB_IN_BYTES;
