import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import { videoEditorStore } from 'modules/video-editor-module';
import { routeKeyToMessageId } from 'modules/routing-module';

import { editorProjectsStore } from 'stores/editor-projects';

import { formatSeconds, toast } from 'utils';
import { EditorResponse } from 'utils/api/api';

import { Layout, Confirmation } from 'components/common';
import { ITable, Table } from 'components/table';

import './styles.scss';
import IconEditor from 'assets/icons/editor-gray.svg';
import IconPlaylistDelete from 'assets/icons/playlist-delete.svg';

const tableColumns: ITable['columns'] = [
  'id',
  { accessor: 'name', withSort: true },
  { accessor: 'totalDuration', withSort: true },
  { accessor: 'renderingStatus', withSort: true },
  { accessor: 'createdAt', withSort: true },
  { accessor: 'updatedAt', withSort: true },
  'actions',
];

const EditorProjectsComponent: React.FC = () => {
  const [project, setProject] = React.useState<null | EditorResponse>(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false);

  const intl = useIntl();

  React.useEffect(() => {
    editorProjectsStore.getList();
  }, [editorProjectsStore.order]);

  const handleDeleteClick = React.useCallback(
    (p: EditorResponse) => {
      setProject(p);
      setConfirmationIsOpen(true);
    },
    [project],
  );

  const renderConfirmation = () => {
    if (!confirmationIsOpen || !project) {
      return null;
    }

    const message = intl.formatMessage(
      {
        id: 'Deleting Confirm',
      },
      {
        it: project.name,
      },
    );

    const handleConfirm = () => {
      if (!project) {
        return;
      }

      videoEditorStore.deleteProject(project.id).then(() => {
        setConfirmationIsOpen(false);

        toast.success(
          intl.formatMessage(
            { id: 'Project Removed' },
            { projectName: project.name },
          ),
        );

        editorProjectsStore.getList();
      });
    };

    const handleCancel = () => {
      setConfirmationIsOpen(false);
    };

    return (
      <Confirmation
        message={message}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  };

  const tableData = React.useMemo(() => {
    return editorProjectsStore.list.map((p) => ({
      ...p,
      createdAt: intl.formatDate(p.createdAt),
      updatedAt: intl.formatDate(p.updatedAt),
      totalDuration: formatSeconds(p.totalDuration),
      actions: (
        <Fragment key={`table_row${p.id}_action`}>
          <Link
            to={`/editor/project/${p.id}`}
            title={intl.formatMessage({
              id: routeKeyToMessageId('Editor'),
            })}
          >
            <img
              src={IconEditor}
              alt="Open"
              className="table__icon table__icon__action"
            />
          </Link>
          <button onClick={() => handleDeleteClick(p)}>
            <img
              src={IconPlaylistDelete}
              alt="Delete"
              className="table__icon table__icon__action"
            />
          </button>
        </Fragment>
      ),
    }));
  }, [editorProjectsStore.list]);

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      {renderConfirmation()}
      <h1 className="editor-list__title">
        <FormattedMessage id="Video projects" defaultMessage="Video projects" />
      </h1>
      <div className="editor-list__table">
        <Table
          columns={tableColumns}
          data={tableData}
          onSortClick={editorProjectsStore.setOrder}
          order={editorProjectsStore.order}
        />
      </div>
    </Layout>
  );
};

export const EditorProjects = observer(EditorProjectsComponent);
