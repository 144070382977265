import {
  ICircleOptions,
  IDataManager,
  IGeoObject,
  IGeoObjectCollection,
} from 'yandex-maps';
import { Feature, FeatureCollection } from 'geojson';

import { IMonitorItem } from '_types/stores';
import { TGeoKind, TYMaps } from './types';
import { GRAY_COLOR, GREEN_DARK_COLOR } from '../../constants';

export function createCircle(ymaps: TYMaps) {
  return (
    center: number[],
    meters: number,
    properties?: IDataManager,
    options: ICircleOptions = {},
  ) => {
    return new ymaps.Circle([center, meters], properties, {
      fillColor: GRAY_COLOR,
      fillOpacity: 0.2,
      strokeColor: GREEN_DARK_COLOR,
      strokeOpacity: 0.6,
      ...options,
    });
  };
}

export function searchInside(
  objects: IGeoObjectCollection,
  targetObject: IGeoObject,
): IGeoObjectCollection {
  // @ts-ignore
  return objects.searchInside(targetObject);
}

export function createGeoObjects(ymaps: TYMaps) {
  return ({
    collection,
    objects,
  }: Partial<{
    collection: FeatureCollection;
    objects: IGeoObjectCollection;
  }>) => {
    // @ts-ignore
    const resultObjects = ymaps.geoQuery(
      collection || [],
    ) as IGeoObjectCollection;
    // @ts-ignore
    return objects ? resultObjects.add(objects) : resultObjects;
  };
}

export function checkFeatureKind(
  f: Feature,
  targetKind: TGeoKind[] | TGeoKind,
): boolean {
  if (!f.properties) {
    return false;
  }
  const { kind } = f.properties.GeocoderMetaData;

  if (Array.isArray(targetKind)) {
    return targetKind.includes(kind);
  }
  return f.properties.GeocoderMetaData.kind === targetKind;
}

export const SEARCH_TEXT_SEPARATOR = ', ';

export function parseFeatureToAddress(
  f: Feature,
): Partial<IMonitorItem['address']> {
  if (!f.properties) {
    return {};
  }
  const { name, description, GeocoderMetaData } = f.properties as {
    description: string;
    name: string;
    GeocoderMetaData: {
      text: string;
      kind: TGeoKind;
    };
  };
  const [country] = GeocoderMetaData.text.split(SEARCH_TEXT_SEPARATOR);
  let [city] = description.split(SEARCH_TEXT_SEPARATOR);
  let street, house;

  switch (GeocoderMetaData.kind) {
    case 'house':
      {
        const parts = name.split(SEARCH_TEXT_SEPARATOR);
        [house] = parts.splice(parts.length - 1);
        street = parts.join(SEARCH_TEXT_SEPARATOR);
      }
      break;

    case 'street':
      {
        street = name;
      }
      break;

    case 'locality':
      {
        city = name;
      }
      break;
  }

  return {
    country,
    city,
    street,
    house,
  };
}
