import { ChangeEvent, FC, FormEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { appStore } from 'stores/app';

import { Form, Input } from 'components/forms';

import IconSearch from 'assets/icons/search.svg';
import './styles.scss';

const SearchBarComponent: FC = () => {
  // States
  const [value, setValue] = useState('');
  // Handle callbacks
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      appStore.handleNavSearch(value);
    },
    [value, appStore.handleNavSearch],
  );
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);
  return (
    <Form
      onSubmit={handleSubmit}
      noPadding
      className="navigation__search__form"
    >
      <Input
        type="text"
        minLength={4}
        rawPlaceholder="Search"
        value={value}
        required
        onChange={handleChange}
        className="navigation__search__input"
      />
      <button type="submit" className="navigation__search__submit">
        <img
          src={IconSearch}
          alt="Search"
          className="navigation__search__icon"
        />
      </button>
    </Form>
  );
};

export const SearchBar = observer(SearchBarComponent);
