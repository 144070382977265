import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { Layout } from 'components/common';
import VideoUpload from './video-upload';
import Library from './library';

import './styles.scss';

const Page: FC = () => {
  return (
    <Layout className={cx('content', 'library__layout')}>
      <VideoUpload />
      <Library />
    </Layout>
  );
};

export default observer(Page);
