import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { IFileItem } from '_types/stores';
import { mediaStore } from 'stores/media';

import { FileInput } from 'components/forms';
import DocumentsList from './add-edit-form-documents-list';
import FormField from './add-edit-form-field';
import PhotoThumnailSlider from './add-edit-form-photos-slider';
import { FileCategory } from 'utils/api/api';

export interface IEditFilesListProps {
  files: IFileItem[];
  onDelete: (fileId: IFileItem['id']) => void;
}

const Files: FC<{
  id?: string;
  files: IFileItem[];
  addCallback?: (response: unknown) => void;
  deleteCallback?: (response: unknown) => void;
}> = ({ id, files, addCallback, deleteCallback }) => {
  const createFileHandler = useCallback(
    (category: IFileItem['category']) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (id && files && files.length > 0) {
          mediaStore
            .uploadMedia([files[0]], {
              category,
              monitorId: id,
            })
            .then(addCallback);
        }
        // Clears input, e.g.: https://stackoverflow.com/a/54633061
        e.target.value = '';
      },
    [id],
  );

  const handleDelete = useCallback<IEditFilesListProps['onDelete']>(
    (fileId) => {
      mediaStore.deleteMedia(fileId).then(deleteCallback);
    },
    [id],
  );

  const { photos, documents } = React.useMemo(
    () => ({
      photos: files ? files.filter((f) => f.category === 'photo') : [],
      documents: files ? files.filter((f) => f.category === 'ownership') : [],
    }),
    [files],
  );

  return (
    <>
      <FormField
        title="Photo"
        row
        fieldClass="form__padding__field files__field"
        layoutClass="add__edit__photos__layout"
      >
        {id && (
          <FileInput
            fieldClass="add__edit__form__field"
            name="photos"
            onChange={createFileHandler(FileCategory.Photo)}
            accept=".jpg, .jpeg, .png"
          />
        )}
        {id ? (
          <PhotoThumnailSlider files={photos} onDelete={handleDelete} />
        ) : (
          <span className="add__edit__form__require_edit">
            <FormattedMessage
              id="To add media content fill in the required fields and save the monitor"
              defaultMessage="To add media content fill in the required fields and save the monitor"
            />
          </span>
        )}
      </FormField>
      <FormField
        title="Ownership documents"
        row
        fieldClass="form__padding__field files__field"
        layoutClass="add__edit__documents__layout"
        labelClass="monitor__file-label"
      >
        {id && (
          <FileInput
            fieldClass="add__edit__form__field"
            name="documents"
            onChange={createFileHandler(FileCategory.Ownership)}
            accept=".pdf, .xls, .doc, .docx"
          />
        )}
        {id ? (
          <DocumentsList files={documents} onDelete={handleDelete} />
        ) : (
          <span className="add__edit__form__require_edit">
            <FormattedMessage
              id="To add media content fill in the required fields and save the monitor"
              defaultMessage="To add media content fill in the required fields and save the monitor"
            />
          </span>
        )}
      </FormField>
    </>
  );
};

export default observer(Files);
