export function resetDateTime(dateWithTime: Date | string) {
  const date = new Date(
    typeof dateWithTime === 'string'
      ? dateWithTime
      : dateWithTime.toISOString(),
  );
  // Reset hours, minutes, secs, millis
  date.setHours(0, 0, 0, 0);

  return date;
}
