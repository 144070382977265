export interface ICrossTabStore {
  key: string;
  value: string | null;
}

export type TCrossTabStorageChangeListener = (
  value: ICrossTabStore['value'],
) => void;

export interface ICrossTabSessionStorage {
  id: string;
  listeners: Record<ICrossTabStore['key'], TCrossTabStorageChangeListener>;

  handleStorage(e: StorageEvent): void;

  removeItem(key: ICrossTabStore['key']): void;

  setItem(key: ICrossTabStore['key'], value: ICrossTabStore['value']): void;

  _setItem: ICrossTabSessionStorage['setItem'];

  sync(): Promise<void>;

  setListener(
    key: ICrossTabStore['key'],
    callback: TCrossTabStorageChangeListener,
  ): void;
}

export const CROSS_TAB_SESSION_STORAGE = 'crossTabStore';
export const CROSS_TAB_SYNC = 'crossTabSync';
export const CROSS_TAB_SYNC_QUERY = 'crossTabSyncQuery';
export const CROSS_TAB_SYNC_TIMEOUT = 1000;

class CrossTabSessionStorage implements ICrossTabSessionStorage {
  id: ICrossTabSessionStorage['id'] = Date.now().toString();
  listeners: ICrossTabSessionStorage['listeners'] = {};

  constructor() {
    window.addEventListener('storage', this.handleStorage, true);
  }

  sync: ICrossTabSessionStorage['sync'] = async () => {
    return new Promise((resolve) => {
      const sync: ICrossTabSessionStorage['handleStorage'] = (e) => {
        if (e.key === CROSS_TAB_SYNC && e.newValue) {
          const storage = JSON.parse(e.newValue) as Record<string, string>;

          Object.entries(storage).forEach(([key, value]) =>
            sessionStorage.setItem(key, value),
          );

          window.removeEventListener('storage', sync);

          localStorage.removeItem(CROSS_TAB_SYNC);
          localStorage.removeItem(CROSS_TAB_SYNC_QUERY);

          resolve();
        }
      };
      window.addEventListener('storage', sync);

      localStorage.setItem(CROSS_TAB_SYNC_QUERY, this.id);

      setTimeout(resolve, CROSS_TAB_SYNC_TIMEOUT);
    });
  };

  handleStorage: ICrossTabSessionStorage['handleStorage'] = (e) => {
    if (e.key === CROSS_TAB_SESSION_STORAGE && e.newValue) {
      const { key, value } = JSON.parse(e.newValue) as ICrossTabStore;

      this._setItem(key, value);

      localStorage.removeItem(CROSS_TAB_SESSION_STORAGE);
    }

    if (
      e.key === CROSS_TAB_SYNC_QUERY &&
      e.newValue &&
      e.newValue !== this.id
    ) {
      localStorage.setItem(CROSS_TAB_SYNC, JSON.stringify(sessionStorage));
    }
  };

  removeItem: ICrossTabSessionStorage['removeItem'] = (key) => {
    this.setItem(key, null);
  };

  setItem: ICrossTabSessionStorage['setItem'] = async (key, value) => {
    this._setItem(key, value);

    localStorage.setItem(
      CROSS_TAB_SESSION_STORAGE,
      JSON.stringify({ key, value }),
    );
  };

  _setItem: ICrossTabSessionStorage['_setItem'] = (key, value) => {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }

    if (key in this.listeners) {
      this.listeners[key](value);
    }
  };

  setListener: ICrossTabSessionStorage['setListener'] = (key, callback) => {
    this.listeners[key] = callback;
  };
}

export const crossTabSessionStorage = new CrossTabSessionStorage();
