import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useIntl, FormattedMessage } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import cx from 'classnames';

import { monitorsStore } from 'stores/monitors/monitor-list';
import { mediaStore } from 'stores/media';
import {
  DRAFT_PROJECT_ID,
  videoEditorStore,
} from 'modules/video-editor-module';
import { IMonitorItem } from '_types/stores';

import { ApplicationCreateMonitorRequest, MonitorStatus } from 'utils/api/api';

import {
  CategoryOption,
  FavoriteIcon,
  NameWithIcon,
} from 'pages/monitors/list-components';
import { Checkbox } from 'components/forms';
import { Table } from 'components/table';
import { ChangeNameForm } from '../change-name-form';
import { ApplicationSendForm } from '../../../applications/application-send';
import { MONITOR_TABLE_COLUMNS } from '../../../monitors/constants';

const monitorColumns = [
  {
    accessor: 'checkbox',
    label: ' ',
  },
  ...MONITOR_TABLE_COLUMNS.filter((c) =>
    typeof c === 'string' ? c !== 'actions' : c.accessor !== 'actions',
  ),
];

const PlayerTopLineComponent: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className, ...restProps }) => {
  const [searchIsOpen, setSearchIsOpen] = React.useState(false);
  const [applicationIsOpen, setApplicationIsOpen] = React.useState(false);
  const [renameFormIsOpen, setRenameFormIsOpen] = React.useState(false);
  const [duplicateFormIsOpen, setDuplicateFormIsOpen] = React.useState(false);

  const intl = useIntl();

  const localStore = useLocalObservable(() => ({
    selectedDevices: [] as IMonitorItem[],
    selectDevice(device: IMonitorItem) {
      this.selectedDevices = this.selectedDevices
        .filter((d) => d.id !== device.id)
        .concat(device);
    },
    isSelectedDevice(deviceId: IMonitorItem['id']) {
      return this.selectedDevices.some((d) => d.id === deviceId);
    },
    unSelectDevice(deviceId: IMonitorItem['id']) {
      this.selectedDevices = this.selectedDevices.filter(
        (d) => d.id !== deviceId,
      );
    },
    unSelectAll() {
      this.selectedDevices = [];
    },
  }));

  const handleSaveAsClick = React.useCallback(() => {
    if (!videoEditorStore.project) {
      return;
    }

    if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
      return;
    }

    setDuplicateFormIsOpen(true);
  }, [videoEditorStore.project]);

  const handleTableRowClick = React.useCallback((device: IMonitorItem) => {
    if (localStore.isSelectedDevice(device.id)) {
      localStore.unSelectDevice(device.id);
    } else {
      localStore.selectDevice(device);
    }
  }, []);

  const handleSendSubmit = React.useCallback(
    (application: ApplicationCreateMonitorRequest) => {
      void videoEditorStore.exportToDevices(
        localStore.selectedDevices,
        application,
      );
      setApplicationIsOpen(false);
      setSearchIsOpen(false);
    },
    [localStore.selectedDevices],
  );

  const handleSendClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (searchIsOpen) {
        e.stopPropagation();
        if (!applicationIsOpen) {
          setApplicationIsOpen(true);
        }
      } else {
        setSearchIsOpen(true);
      }
    },
    [searchIsOpen, applicationIsOpen],
  );

  const handleRenameClick = React.useCallback(() => {
    if (!videoEditorStore.project) {
      return;
    }

    if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
      return;
    }

    setRenameFormIsOpen(true);
  }, [videoEditorStore.project]);

  const data = React.useMemo(
    () =>
      !monitorsStore.list || monitorsStore.list.length === 0
        ? []
        : monitorsStore.list.map((m) => {
            return {
              ...m,
              checkbox: (
                <Checkbox
                  label=" "
                  colorModifier={
                    m.status === MonitorStatus.Online ? 'green' : 'dark'
                  }
                  onClick={() => handleTableRowClick(m)}
                  checked={localStore.isSelectedDevice(m.id)}
                  readOnly={true}
                />
              ),
              favorite: <FavoriteIcon monitor={m} />,
              name: (
                <NameWithIcon
                  id={m.id}
                  name={m.name}
                  enabled={m.status === MonitorStatus.Online}
                />
              ),
              category: <CategoryOption id={m.id} category={m.category} />,
              address:
                m.address.city +
                (m.address.street ? `, ${m.address.street} ` : ' ') +
                m.address.house,
            };
          }),
    [monitorsStore.list, localStore.selectedDevices],
  );

  const devices = React.useMemo(() => {
    if (searchIsOpen) {
      return (
        <div className="player__devices">
          <Table
            columns={monitorColumns}
            data={data}
            onSortClick={monitorsStore.setOrder}
            order={monitorsStore.order}
          />
          <div className="player__devices-info">
            <div className="player__devices-left">
              <button className="player__devices-button" />
              <div className="player__devices-find">
                <FormattedMessage id="Find" defaultMessage="Find" />:{' '}
                <input className="player__devices-input" />
              </div>
              <button className="player__devices-find-button" />
            </div>
            <div className="player__devices-right">
              <div className="player__devices-total">
                <FormattedMessage id="Total" description="Total" /> :{' '}
                {data.length}
              </div>
              <div className="player__devices-selected">
                <FormattedMessage
                  id="Highlighted"
                  defaultMessage="Highlighted"
                />
                : 1
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }, [searchIsOpen, handleTableRowClick, data, monitorsStore.order]);

  const handleRenameFormClose = React.useCallback(() => {
    setRenameFormIsOpen(false);
  }, [setRenameFormIsOpen]);

  const handleRenameFormSave = React.useCallback(
    (name: string) => {
      videoEditorStore.renameProject(name).finally(() => {
        setRenameFormIsOpen(false);
      });
    },
    [setRenameFormIsOpen],
  );

  const renderRenameForm = () => {
    if (!videoEditorStore.project) {
      return null;
    }

    return (
      <ChangeNameForm
        open={renameFormIsOpen}
        status="edit"
        value={videoEditorStore.project.name}
        onClose={handleRenameFormClose}
        onSave={handleRenameFormSave}
      />
    );
  };

  const handleDuplicateFormClose = React.useCallback(() => {
    setDuplicateFormIsOpen(false);
  }, [setDuplicateFormIsOpen]);

  const handleDuplicateFormSave = React.useCallback(
    (name: string) => {
      if (!videoEditorStore.project) {
        return;
      }

      videoEditorStore.duplicateProject(name, videoEditorStore.project.id);
      setDuplicateFormIsOpen(false);
    },
    [videoEditorStore.project, setDuplicateFormIsOpen],
  );

  const renderDuplicateForm = () => {
    if (!videoEditorStore.project) {
      return null;
    }

    return (
      <ChangeNameForm
        open={duplicateFormIsOpen}
        status="create"
        value={`${videoEditorStore.project.name} Копия`}
        onClose={handleDuplicateFormClose}
        onSave={handleDuplicateFormSave}
      />
    );
  };

  const handleProjectCancel = React.useCallback(async () => {
    if (!videoEditorStore.project) {
      return;
    }

    if (videoEditorStore.project.id === DRAFT_PROJECT_ID) {
      return;
    }

    await videoEditorStore.cancelProjectCreation();
    mediaStore.clearStore();
  }, [videoEditorStore.project]);

  const handleRenderClick = React.useCallback(() => {
    videoEditorStore.compileProject();
  }, [videoEditorStore.compileProject]);

  return (
    <div {...restProps} className={cx('player__bar', className)}>
      {renderRenameForm()}
      {renderDuplicateForm()}
      {applicationIsOpen && (
        <ApplicationSendForm
          monitorIds={localStore.selectedDevices.map((d) => d.id)}
          onOutsideClick={() => setApplicationIsOpen(false)}
          onSubmit={handleSendSubmit}
        />
      )}
      <div className="player__bar-left">
        <div className="player__rename-project">
          <span className="player__rename-input">
            {videoEditorStore.project?.name}
          </span>
          <button className="player__rename-button" onClick={handleRenameClick}>
            <FormattedMessage id="Rename" defaultMessage="Rename" />
          </button>
          <button
            onClick={handleSaveAsClick}
            className="player__button player__save-as-button"
            title={intl.formatMessage({
              id: 'Save as',
              defaultMessage: 'Save as',
            })}
          />
          <button
            className="player__button player__cancel-project"
            onClick={handleProjectCancel}
            title={intl.formatMessage({
              id: 'Clear the project',
              defaultMessage: 'Clear the project',
            })}
          />
        </div>
      </div>
      <div className="player__bar-right">
        <OutsideClickHandler onOutsideClick={() => setSearchIsOpen(false)}>
          <div className="player__file-name">
            <button
              className="player__send"
              onClick={handleSendClick}
              disabled={videoEditorStore.isReadOnly}
            >
              <FormattedMessage id="Send" defaultMessage="Send" />
            </button>
          </div>
          {devices}
        </OutsideClickHandler>
        <button
          className="player__button-rendering"
          onClick={handleRenderClick}
          disabled={videoEditorStore.isReadOnly}
        >
          <FormattedMessage
            id="Video rendering"
            defaultMessage="Video rendering"
          />
        </button>
      </div>
    </div>
  );
};

export const PlayerTopLine = observer(PlayerTopLineComponent);
