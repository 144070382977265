import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer, useLocalObservable } from 'mobx-react-lite';
import useResizeObserver from 'use-resize-observer';
import cx from 'classnames';

import { appStore } from 'stores/app';
import { mediaStore } from 'stores/media';
import { playlistsStore } from 'stores/playlists';
import { IMediaItem } from '_types/stores';
import { VideoType } from 'utils/api/api';

import { Layout } from 'components/common';
import { Checkbox, Form } from 'components/forms';
import { MediaListItem } from 'components/media-list';
import { PlaylistTimeline } from 'components/playlist-timeline';
import { AddEditWrapper } from 'components/wrapper';
import { LibraryFolder } from 'components/library-folder';

import './styles.scss';

export interface IPlaylistAddEditStore {
  checkedMediaItems: IMediaItem[];

  checkMediaItems(mediaItems: IMediaItem[]): void;

  unCheckMediaItems(mediaIds?: Array<IMediaItem['id']>): void;

  isCheckedMediaItem(mediaItem: IMediaItem): boolean;
}

export type TMediaChangeHandler = (
  item: IMediaItem,
  e?: React.FormEvent<HTMLInputElement>,
) => void;

const MediaItemActions: React.FC<{
  localStore: IPlaylistAddEditStore;
  item: IMediaItem;
  handleMediaChange: TMediaChangeHandler;
}> = observer(({ item, handleMediaChange, localStore }) => {
  const isPlaylistMediaItem = React.useMemo(
    () => playlistsStore.editedPlaylistMediaIds.includes(item.id),
    [playlistsStore.editedPlaylistMediaIds],
  );

  const className = React.useMemo(
    () =>
      isPlaylistMediaItem
        ? 'media-list__item__button_remove'
        : 'media-list__item__button_add',
    [isPlaylistMediaItem],
  );

  const buttonDisabled = React.useMemo(
    () =>
      playlistsStore.editedPlaylistMediaIds.length <= 1 && isPlaylistMediaItem,
    [playlistsStore.editedPlaylistMediaIds, isPlaylistMediaItem],
  );

  const handleClick = React.useCallback(() => {
    const { editedPlaylist } = playlistsStore;

    if (!editedPlaylist) return;

    playlistsStore.updatePlaylist(
      isPlaylistMediaItem
        ? {
            files: editedPlaylist.files.filter((file) => file.id !== item.id),
          }
        : {
            files: editedPlaylist.files.concat(item),
          },
      true,
    );
  }, [playlistsStore.editedPlaylist, isPlaylistMediaItem]);

  return (
    <Layout flex row className="playlists__item__action">
      <button
        type="button"
        disabled={buttonDisabled}
        className={className}
        onClick={handleClick}
      >
        &nbsp;
      </button>
      <Checkbox
        checked={localStore.isCheckedMediaItem(item)}
        label=""
        onChange={(e) => handleMediaChange(item, e)}
      />
    </Layout>
  );
});

const PlaylistAddEdit: React.FC = () => {
  const localStore = useLocalObservable<IPlaylistAddEditStore>(() => ({
    checkedMediaItems: [],
    checkMediaItems(mediaItems) {
      const mediaIds = mediaItems.map((m) => m.id);

      this.checkedMediaItems = this.checkedMediaItems
        .filter((m) => !mediaIds.includes(m.id))
        .concat(mediaItems);
    },
    unCheckMediaItems(mediaIds) {
      if (mediaIds) {
        this.checkedMediaItems = this.checkedMediaItems.filter(
          (m) => !mediaIds.includes(m.id),
        );
      } else {
        this.checkedMediaItems = [];
      }
    },
    isCheckedMediaItem(mediaItem: IMediaItem) {
      return this.checkedMediaItems.some((m) => m.id === mediaItem.id);
    },
  }));

  const { ref: libraryRef, width: libraryWidth = 0 } =
    useResizeObserver<HTMLDivElement>();

  const params = useParams<{ id?: string; name?: string }>();

  const { id } = params;

  React.useEffect(() => {
    const { name = playlistsStore.editedPlaylistName } = params;

    if (id) {
      playlistsStore.loadPlaylist(id);
    } else {
      playlistsStore.createPlaylist({ name, files: [] }, true);
    }
  }, [id]);

  React.useEffect(() => {
    const source = mediaStore.getMediaList();

    return () => {
      source.cancel();
    };
  }, [mediaStore.currentFolderId]);

  React.useEffect(() => {
    if (!mediaStore.foldersList.length || mediaStore.currentFolderId !== null) {
      return;
    }

    mediaStore.setupNavigation(mediaStore.foldersList[0]);
  }, [mediaStore.foldersList]);

  React.useEffect(() => {
    return () => {
      playlistsStore.editedPlaylist = null;
      mediaStore.clearStore();
    };
  }, []);

  const handleMediaChange = React.useCallback<TMediaChangeHandler>(
    (item: IMediaItem) => {
      if (localStore.isCheckedMediaItem(item)) {
        localStore.unCheckMediaItems([item.id]);
      } else {
        localStore.checkMediaItems([item]);
      }
    },
    [
      localStore.isCheckedMediaItem,
      localStore.checkMediaItems,
      localStore.unCheckMediaItems,
    ],
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!playlistsStore.canSavePlaylist) return;

      const { editedPlaylist } = playlistsStore;

      if (!editedPlaylist) return;

      if (id) {
        playlistsStore.updatePlaylist(editedPlaylist).then(() => {
          appStore.navigate('/playlists/list');
        });
      } else {
        const data = {
          ...editedPlaylist,
          files: editedPlaylist.files.map((f) => f.id),
        };

        playlistsStore.createPlaylist(data).then(() => {
          appStore.navigate('/playlists/list');
        });
      }
    },
    [id, playlistsStore.editedPlaylist, playlistsStore.canSavePlaylist],
  );

  const emptyFolderList = React.useMemo(() => {
    const columnWidth = 140;
    const gapWidth = 20;

    const foldersAmount = mediaStore.foldersList.length;

    const fittingCellsAmount = Math.floor(
      (libraryWidth + gapWidth) / (columnWidth + gapWidth),
    );

    if (fittingCellsAmount === 0) {
      return null;
    }

    if (foldersAmount % fittingCellsAmount === 0) {
      return null;
    }

    const emptyItemsAmount =
      fittingCellsAmount - (foldersAmount % fittingCellsAmount);

    return Array.from(Array(emptyItemsAmount)).map((_, i) => (
      <div className="library__item--empty" key={i} />
    ));
  }, [mediaStore.foldersList, libraryWidth]);

  const emptyMediaList = React.useMemo(() => {
    const columnWidth = 140;
    const gapWidth = 20;

    const videoAmount = mediaStore.mediaList.filter(
      (video) => video.videoType === VideoType.Video,
    ).length;

    const fittingCellsAmount = Math.floor(
      (libraryWidth + gapWidth) / (columnWidth + gapWidth),
    );

    if (fittingCellsAmount === 0) {
      return null;
    }

    if (videoAmount % fittingCellsAmount === 0) {
      return null;
    }

    const emptyItemsAmount =
      fittingCellsAmount - (videoAmount % fittingCellsAmount);

    return Array.from(Array(emptyItemsAmount)).map((_, i) => (
      <div className="library__item--empty" key={`library__item-${i}`} />
    ));
  }, [mediaStore.mediaList, libraryWidth]);

  const folderList = React.useMemo(
    () =>
      mediaStore.foldersList.map((folder) => {
        const handleFolderClick = () => {
          mediaStore.navigateForward(folder);
        };

        return (
          <LibraryFolder
            folder={folder}
            key={folder.id}
            onClick={handleFolderClick}
          />
        );
      }),
    [mediaStore.foldersList],
  );

  const videoList = React.useMemo(
    () =>
      mediaStore.mediaList
        .filter((video) => video.videoType === VideoType.Video)
        .map((m) => (
          <MediaListItem
            key={m.id}
            item={m}
            actionElement={
              <MediaItemActions
                localStore={localStore}
                item={m}
                handleMediaChange={handleMediaChange}
              />
            }
          />
        )),
    [mediaStore.mediaList, handleMediaChange, localStore],
  );

  const renderBreadcrumbs = React.useMemo(() => {
    if (!mediaStore.breadcrumbs) {
      return null;
    }

    return (
      <div className="library__folder-path">
        {mediaStore.breadcrumbs.map((breadcrumb, i, breadcrumbs) => {
          const handleClick = () => {
            if (i + 1 === breadcrumbs.length) {
              return;
            }

            mediaStore.navigateBackwardTo(i + 1);
          };

          if (breadcrumb.parentFolderId === null) {
            return (
              <button
                className="library__folder-path-item"
                key={breadcrumb.id}
                onClick={handleClick}
                type="button"
              >
                <FormattedMessage id="Library" defaultMessage="Library" />
              </button>
            );
          }

          return (
            <button
              className="library__folder-path-item"
              key={breadcrumb.id}
              onClick={handleClick}
              type="button"
            >
              &nbsp;/&nbsp;{breadcrumb.name}
            </button>
          );
        })}
      </div>
    );
  }, [mediaStore.breadcrumbs]);

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <Form onSubmit={handleSubmit} noPadding>
        <PlaylistTimeline
          localStore={localStore}
          handleMediaChange={handleMediaChange}
        />
        <AddEditWrapper
          icon={null}
          className="playlists__wrapper"
          title="To manage files, select file"
        >
          <div className="library__folder-path-layout">
            <button
              className="library__path-circle"
              onClick={mediaStore.navigateBackward}
              type="button"
            />
            {renderBreadcrumbs}
          </div>
          <Layout flex row className="media__list" ref={libraryRef}>
            {folderList}
            {emptyFolderList}
            {videoList}
            {emptyMediaList}
          </Layout>
        </AddEditWrapper>
      </Form>
    </Layout>
  );
};

export default observer(PlaylistAddEdit);
