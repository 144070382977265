import React, { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { REGISTER_USER_ROLES, UserRoleReverse } from '_types/constants';
import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';

import { Button } from 'components/common';
import { Form, Input, InputField, Select } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import { UserRoleResponse } from 'utils/api/api';

import './styles.scss';
import { appStore } from '../../stores/app';

const RegisterComponent: React.FC = () => {
  const role = React.useMemo(() => authStore.role, [authStore.role]);
  const intl = useIntl();

  const roleOptions = React.useMemo<ReactElement[]>(
    () =>
      REGISTER_USER_ROLES.map((r) => (
        <option key={r} value={r}>
          {intl.formatMessage({ id: UserRoleReverse[r] })}
        </option>
      )),
    [],
  );

  const handleSetRole = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value as UserRoleResponse;

      authStore.setRole(value);
    },
    [authStore],
  );

  const validate = React.useCallback(() => {
    if (role) {
      return { success: true };
    } else {
      return { success: false, errorText: 'error message' };
    }
  }, [role]);

  return (
    <AuthWrapper
      title="Welcome to myscreen"
      /* note: missing translation */
      // subtitle="Register new account"
      subtitle="Регистрация"
    >
      <Form
        onSubmit={authStore.register}
        validate={appStore.isCreatedFor('promo') ? undefined : validate}
      >
        <Input
          type="email"
          name="email"
          value={authStore.email}
          onChange={authStore.setField}
          placeholder={
            <FormattedMessage
              id="Email Address"
              defaultMessage="Email Address"
            />
          }
          fieldClass="auth__field"
          required
        />
        <Input
          type="password"
          name="password"
          value={authStore.password}
          onChange={authStore.setField}
          placeholder={
            <FormattedMessage id="Password" defaultMessage="Password" />
          }
          fieldClass="auth__field"
          minLength={8}
          required
        />
        <Input
          type="text"
          name="name"
          value={authStore.name}
          onChange={authStore.setField}
          placeholder={<FormattedMessage id="Name" defaultMessage="Name" />}
          fieldClass="auth__field"
          required
        />
        <Input
          type="text"
          name="surname"
          value={authStore.surname}
          onChange={authStore.setField}
          placeholder="Фамилия"
          fieldClass="auth__field"
          required
        />
        <Input
          type="text"
          name="middleName"
          value={authStore.middleName}
          onChange={authStore.setField}
          placeholder="Отчество"
          fieldClass="auth__field"
          required
        />
        {appStore.isCreatedFor('default') && (
          <Select
            name="функции"
            fieldClass="add__edit__form__field auth__field"
            className="form__input_select"
            value={role === null ? '' : role}
            onChange={handleSetRole}
          >
            <option value="" disabled>
              Роль
            </option>
            {roleOptions}
          </Select>
        )}
        <InputField>
          <Button type="submit" primary className="auth__button">
            {/* note: missing translation */}
            {/* <FormattedMesssage id="Register" /> */}
            Зарегистрироваться
          </Button>
        </InputField>

        <InputField>
          <RouteLink routeKey="Login" className="link auth__link">
            <FormattedMessage id="Sign in" defaultMessage="Sign in" />
          </RouteLink>
        </InputField>
        <InputField>
          <RouteLink routeKey="ResetPassword" className="link auth__link">
            <FormattedMessage
              id="Forgot password"
              defaultMessage="Forgot password"
            />
          </RouteLink>
        </InputField>
      </Form>
    </AuthWrapper>
  );
};

export const Register = observer(RegisterComponent);
