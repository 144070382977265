import React from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { videoEditorStore } from 'modules/video-editor-module';

export interface ITimelinePointer
  extends React.HTMLAttributes<HTMLDivElement> {}

const TimelinePointerComponent: React.FC<ITimelinePointer> = ({
  className,
  style,
  onMouseDown,
  onDragStart,
  ...restProps
}) => {
  const transform = React.useMemo(
    () => `translateX(calc(${videoEditorStore.timelinePointerShift}px - 50%))`,
    [videoEditorStore.timelinePointerShift],
  );

  const dragStartHandler = React.useCallback<
    React.DragEventHandler<HTMLDivElement>
  >(
    (event) => {
      if (onDragStart) {
        return onDragStart(event);
      }
      return false;
    },
    [onDragStart],
  );

  const mouseDownHandler = React.useCallback(
    (
      downEvent: Parameters<NonNullable<ITimelinePointer['onMouseDown']>>[0],
    ) => {
      downEvent.preventDefault(); // предотвратить запуск выделения (действие браузера)
      const wasPlayed = videoEditorStore.isPlayed;
      let startPointX = downEvent.clientX;

      if (onMouseDown) {
        onMouseDown(downEvent);
      }

      const onMouseMove = (moveEvent: MouseEvent) => {
        const shiftX = moveEvent.clientX - startPointX;
        const moved = videoEditorStore.moveTimelinePointer({ shiftX });
        if (moved) {
          if (videoEditorStore.isPlayed) {
            videoEditorStore.pause();
          }
          startPointX = moveEvent.clientX;
        }
      };

      const onMouseUp = (upEvent: MouseEvent) => {
        onMouseMove(upEvent);
        // Clear
        if (wasPlayed && !videoEditorStore.isPlayed) {
          videoEditorStore.play();
        }
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('mousemove', onMouseMove);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [onMouseDown, videoEditorStore.isPlayed],
  );

  return (
    <div
      {...restProps}
      className={cx('timeline__pointer', className)}
      onDragStart={dragStartHandler}
      onMouseDown={mouseDownHandler}
      style={{
        ...style,
        transform,
      }}
    />
  );
};

export const TimelinePointer = observer(TimelinePointerComponent);
