import React from 'react';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Container from 'components/container';
import { Main } from 'components/main';

import { IAppRoute } from '../types';
import { PUBLIC_ROUTE_KEYS, BASE_ROUTE_KEYS } from '../constants';
import { routingStore } from '../store';
import { PrivateRoute } from './private-route';
import { appStore } from 'stores/app';

function toRouteObjects(routes: IAppRoute[]): RouteObject[] {
  return routes.map((r) => ({
    path: r.path,
    element: <r.Component store={appStore} />,
  }));
}

/**
 * @desc
 * Takes apart the routes.
 * Apart e.g: authentication routes.
 */
export const AppRoutesSwitchComponent: React.FC = () => {
  const [authRoutes, specificRoutes, mainRoutes] = React.useMemo(
    () =>
      Object.values(routingStore.routes).reduce<
        [IAppRoute[], IAppRoute[], IAppRoute[]]
      >(
        (acc, r) => {
          if (PUBLIC_ROUTE_KEYS.includes(r.routeKey)) {
            acc[0].push(r);
          } else if (BASE_ROUTE_KEYS.includes(r.routeKey)) {
            acc[1].push(r);
          } else {
            acc[2].push(r);
          }
          return acc;
        },
        [[], [], []],
      ),
    [routingStore.routes],
  );

  const routes = useRoutes([
    {
      element: (
        <Container>
          <Outlet />
        </Container>
      ),
      children: toRouteObjects(authRoutes),
    },
    {
      element: (
        <PrivateRoute>
          <Main>
            <Outlet />
          </Main>
        </PrivateRoute>
      ),
      children: toRouteObjects(mainRoutes),
    },
    ...toRouteObjects(specificRoutes),
  ]);

  return routes;
};

export const AppRouteSwitch = observer(AppRoutesSwitchComponent);
