/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BadRequestError {
  /** @example 400 */
  statusCode: number;
  /** @example "server-error.10004" */
  code: string;
  /** @example "Bad request" */
  message: string;
}

export interface UnauthorizedError {
  /** @example 401 */
  statusCode: number;
  /** @example "server-error.10001" */
  code: string;
  /** @example "Unauthorized request" */
  message: string;
}

export interface ForbiddenError {
  /** @example 403 */
  statusCode: number;
  /** @example "server-error.10002" */
  code: string;
  /** @example "Forbidden" */
  message: string;
}

export interface NotFoundError {
  /** @example 404 */
  statusCode: number;
  /** @example "server-error.10005" */
  code: string;
  /** @example "Not Found" */
  message: string;
}

export interface NotAcceptableError {
  /** @example 406 */
  statusCode: number;
  /** @example "server-error.10000" */
  code: string;
  /** @example "Not Acceptable" */
  message: string;
}

export interface FileIDResponse {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
  /**
   * Имя файла
   * @example "foo.mp4"
   */
  name: string;
}

export interface ConflictDataFile {
  /**
   * ID ссылки
   * @format uuid
   */
  id?: string;
  /**
   * Имя ссылки
   * @example "Имя ссылки"
   */
  name?: string;
  /** Файл */
  file?: FileIDResponse;
}

export interface ConflictData {
  /** Редакторы (видео) */
  video?: ConflictDataFile;
  /** Редакторы (аудио) */
  audio?: ConflictDataFile;
  /** Плэйлисты */
  playlist?: ConflictDataFile;
  /** Мониторы */
  monitor?: ConflictDataFile;
}

export interface ConflictError {
  /** @example 409 */
  statusCode: number;
  /** @example "server-error.10000" */
  code: string;
  /** @example "Conflict exists" */
  message: string;
  data: ConflictData;
}

export interface PreconditionFailedError {
  /** @example 412 */
  statusCode: number;
  /** @example "server-error.10002" */
  code: string;
  /** @example "User exists" */
  message: string;
}

export interface InternalServerError {
  /** @example 500 */
  statusCode: number;
  /** @example "server-error.10000" */
  code: string;
  /** @example "Server error" */
  message: string;
}

export interface NotImplementedError {
  /** @example 501 */
  statusCode: number;
  /** @example "server-error.10000" */
  code: string;
  /** @example "Server error" */
  message: string;
}

export enum Status {
  Success = 'success',
  Error = 'error',
}

export enum UserRoleResponse {
  Monitor = 'monitor',
  MonitorOwner = 'monitor-owner',
  Advertiser = 'advertiser',
  Administrator = 'administrator',
  Accountant = 'accountant',
}

export enum UserPlan {
  DEMO = 'DEMO',
  FULL = 'FULL',
  VIP = 'VIP',
}

export interface UserWallet {
  /**
   * Баланс
   * @example 0
   */
  total?: number;
}

export interface UserMetricsMonitors {
  /** Кол-во устройств с заявками на трансляции */
  online: number;
  /** Кол-во устройств с заявками, но выключенные */
  offline: number;
  /** Кол-во устройств без заявок */
  empty: number;
  /** Кол-во моих мониторов */
  user: number;
}

export interface UserMetricsStorage {
  /** Занятое место */
  storage: number;
  /** Максимальное место */
  total: number;
}

export interface UserMetricsPlaylists {
  /** Добавленные */
  added: number;
  /** Запущенные */
  played: number;
}

export interface UserMetrics {
  /** Статистика мониторов */
  monitors: UserMetricsMonitors;
  /** Дисковое пространство */
  storageSpace: UserMetricsStorage;
  /** Плейлисты */
  playlists: UserMetricsPlaylists;
}

export interface UserExtEntity {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id: string;
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email: string;
  /**
   * Фамилия
   * @example "Steve"
   */
  surname?: string | null;
  /**
   * Имя
   * @example "John"
   */
  name?: string | null;
  /**
   * Отчество
   * @example "Doe"
   */
  middleName?: string | null;
  /**
   * Телефон пользователя
   * @example "+78002000000"
   */
  phoneNumber?: string | null;
  /**
   * Город
   * @example "Krasnodar"
   */
  city?: string | null;
  /**
   * Страна
   * @example "RU"
   */
  country?: string | null;
  /**
   * Дисковое пространство
   * @example 20000000
   */
  storageSpace?: number;
  /**
   * Роль пользователя
   * @example "advertiser"
   */
  role: UserRoleResponse;
  /**
   * EMail подтвержден
   * @example true
   */
  verified?: boolean;
  /**
   * План пользователя
   * @example "FULL"
   */
  plan?: UserPlan;
  /**
   * Компания
   * @example "ACME corporation"
   */
  company?: string | null;
  /**
   * Юридический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyLegalAddress?: string;
  /**
   * Фактический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyActualAddress?: string;
  /**
   * Идентификационный номер налогоплательщика (ИНН)
   * @example "012345678901"
   */
  companyTIN?: string;
  /**
   * Код Причины Постановки на учет (КПП)
   * @example "012345678901"
   */
  companyRRC?: string;
  /**
   * Основной Государственный Регистрационный Номер (ОГРН)
   * @example "012345678901"
   */
  companyPSRN?: string;
  /**
   * Телефон организации
   * @example "+78002000000"
   */
  companyPhone?: string;
  /**
   * Email организации
   * @example "we@are.the.best"
   */
  companyEmail?: string;
  /**
   * Наименование банка
   * @example "Банк"
   */
  companyBank?: string;
  /**
   * Банковский идентификационный код (БИК)
   * @example "012345678"
   */
  companyBIC?: string;
  /**
   * Корреспондентский счет
   * @example "30101810400000000000"
   */
  companyCorrespondentAccount?: string;
  /**
   * Расчетный счет
   * @example "40802810064580000000"
   */
  companyPaymentAccount?: string;
  /**
   * Факс организации
   * @example "+78002000000"
   */
  companyFax?: string;
  /**
   * Представитель организации
   * @example "Тухбатуллина Юлия Евгеньевна"
   */
  companyRepresentative?: string;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Оставшийся срок оплаты */
  planValidityPeriod?: number;
  /** Баланс */
  wallet?: UserWallet;
  /** Метрика */
  metrics?: UserMetrics;
}

export interface UserGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Пользователь */
  data: UserExtEntity;
}

export enum UserRoleRequest {
  MonitorOwner = 'monitor-owner',
  Advertiser = 'advertiser',
}

export interface UserUpdateRequest {
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email?: string;
  /**
   * Фамилия
   * @example "Steve"
   */
  surname?: string | null;
  /**
   * Имя
   * @example "John"
   */
  name?: string | null;
  /**
   * Отчество
   * @example "Doe"
   */
  middleName?: string | null;
  /**
   * Телефон пользователя
   * @example "+78002000000"
   */
  phoneNumber?: string | null;
  /**
   * Город
   * @example "Krasnodar"
   */
  city?: string | null;
  /**
   * Страна
   * @example "RU"
   */
  country?: string | null;
  /**
   * EMail подтвержден
   * @example true
   */
  verified?: boolean;
  /**
   * Компания
   * @example "ACME corporation"
   */
  company?: string | null;
  /**
   * Юридический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyLegalAddress?: string;
  /**
   * Фактический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyActualAddress?: string;
  /**
   * Идентификационный номер налогоплательщика (ИНН)
   * @example "012345678901"
   */
  companyTIN?: string;
  /**
   * Код Причины Постановки на учет (КПП)
   * @example "012345678901"
   */
  companyRRC?: string;
  /**
   * Основной Государственный Регистрационный Номер (ОГРН)
   * @example "012345678901"
   */
  companyPSRN?: string;
  /**
   * Телефон организации
   * @example "+78002000000"
   */
  companyPhone?: string;
  /**
   * Email организации
   * @example "we@are.the.best"
   */
  companyEmail?: string;
  /**
   * Наименование банка
   * @example "Банк"
   */
  companyBank?: string;
  /**
   * Банковский идентификационный код (БИК)
   * @example "012345678"
   */
  companyBIC?: string;
  /**
   * Корреспондентский счет
   * @example "30101810400000000000"
   */
  companyCorrespondentAccount?: string;
  /**
   * Расчетный счет
   * @example "40802810064580000000"
   */
  companyPaymentAccount?: string;
  /**
   * Факс организации
   * @example "+78002000000"
   */
  companyFax?: string;
  /**
   * Представитель организации
   * @example "Тухбатуллина Юлия Евгеньевна"
   */
  companyRepresentative?: string;
  /**
   * Роль пользователя
   * @example "advertiser"
   */
  role?: UserRoleRequest;
  /**
   * План пользователя
   * @example "FULL"
   */
  plan?: UserPlan;
}

export interface LoginRequest {
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email: string;
  /**
   * Пароля пользователя (должен удовлетворять минимальным требованиям)
   * @minLength 8
   * @maxLength 30
   * @pattern /((?=.*d)|(?=.*W+))(?![.
   * ])(?=.*[A-Z])(?=.*[a-z]).*$/
   * @example "Secret~12345678"
   */
  password: string;
}

export interface AuthenticationPayload {
  /**
   * Тип: Bearer
   * @example "bearer"
   */
  type: string;
  /**
   * Токен, используемый в Authorization: Bearer
   * @example "eyJcbGciOcJIUcI1xxxxxxxxxxxxxxxx"
   */
  token: string;
  /**
   * Refresh токен, используемый для запросов /api/v2/refresh
   * @example "exJxcGxiOxJIxzIxNixsIxR5cxxxxxxxxxxx.E9jKilfGxxxxxxxxxxxxx"
   */
  refreshToken: string;
}

export interface AuthResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Возвращаемый токен */
  payload: AuthenticationPayload;
  /** Пользователь */
  data: UserExtEntity;
}

export interface RegisterRequest {
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email: string;
  /**
   * Фамилия
   * @example "Steve"
   */
  surname?: string | null;
  /**
   * Имя
   * @example "John"
   */
  name?: string | null;
  /**
   * Отчество
   * @example "Doe"
   */
  middleName?: string | null;
  /**
   * Телефон пользователя
   * @example "+78002000000"
   */
  phoneNumber?: string | null;
  /**
   * Город
   * @example "Krasnodar"
   */
  city?: string | null;
  /**
   * Страна
   * @example "RU"
   */
  country?: string | null;
  /**
   * Дисковое пространство
   * @example 20000000
   */
  storageSpace?: number;
  /**
   * Компания
   * @example "ACME corporation"
   */
  company?: string | null;
  /**
   * Юридический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyLegalAddress?: string;
  /**
   * Фактический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyActualAddress?: string;
  /**
   * Идентификационный номер налогоплательщика (ИНН)
   * @example "012345678901"
   */
  companyTIN?: string;
  /**
   * Код Причины Постановки на учет (КПП)
   * @example "012345678901"
   */
  companyRRC?: string;
  /**
   * Основной Государственный Регистрационный Номер (ОГРН)
   * @example "012345678901"
   */
  companyPSRN?: string;
  /**
   * Телефон организации
   * @example "+78002000000"
   */
  companyPhone?: string;
  /**
   * Email организации
   * @example "we@are.the.best"
   */
  companyEmail?: string;
  /**
   * Наименование банка
   * @example "Банк"
   */
  companyBank?: string;
  /**
   * Банковский идентификационный код (БИК)
   * @example "012345678"
   */
  companyBIC?: string;
  /**
   * Корреспондентский счет
   * @example "30101810400000000000"
   */
  companyCorrespondentAccount?: string;
  /**
   * Расчетный счет
   * @example "40802810064580000000"
   */
  companyPaymentAccount?: string;
  /**
   * Факс организации
   * @example "+78002000000"
   */
  companyFax?: string;
  /**
   * Представитель организации
   * @example "Тухбатуллина Юлия Евгеньевна"
   */
  companyRepresentative?: string;
  /**
   * Роль пользователя
   * @example "advertiser"
   */
  role: UserRoleRequest;
  /**
   * Пароля пользователя (должен удовлетворять минимальным требованиям)
   * @minLength 8
   * @maxLength 30
   * @pattern /((?=.*d)|(?=.*W+))(?![.
   * ])(?=.*[A-Z])(?=.*[a-z]).*$/
   * @example "Secret~12345678"
   */
  password: string;
}

export interface AuthRefreshRequest {
  /**
   * Refresh токен, используемый для запросов /api/v2/refresh
   * @example "exJxcGxiOxJIxzIxNixsIxR5cxxxxxxxxxxx.E9jKilfGxxxxxxxxxxxxx"
   */
  refreshToken: string;
}

export interface AuthRefreshResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Возвращаемый токен */
  payload: AuthenticationPayload;
}

export interface VerifyEmailRequest {
  /** @example "j481y1b" */
  verify: string;
}

export interface SuccessResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
}

export interface ResetPasswordInvitationRequest {
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email: string;
}

export interface ResetPasswordVerifyRequest {
  /** @example "j481y1b" */
  verify: string;
  /** @example "Secret~12345678" */
  password: string;
}

export interface AuthMonitorRequest {
  /**
   * Используется для обозначения монитора
   * @example "123-456-789"
   */
  code: string;
}

export interface Address {
  /**
   * Страна
   * @example "Россия"
   */
  country?: string;
  /**
   * Город
   * @example "Краснодар"
   */
  city?: string;
  /**
   * Улица
   * @example "Красная"
   */
  street?: string;
  /**
   * Дом
   * @example "1"
   */
  house?: string;
  /**
   * Комната
   * @example "1"
   */
  room?: string;
}

export enum MonitorCategory {
  FOOD = 'FOOD',
  CAFES_AND_RESTAURANTS = 'CAFES_AND_RESTAURANTS',
  PHARMACY = 'PHARMACY',
  ATM = 'ATM',
  HOTEL = 'HOTEL',
  AUTO_REPAIR = 'AUTO_REPAIR',
  CAR_WASHING = 'CAR_WASHING',
  HOSPITALS = 'HOSPITALS',
  CINEMAS = 'CINEMAS',
  MALL = 'MALL',
  SAUNAS = 'SAUNAS',
  BEAUTY_SALONS = 'BEAUTY_SALONS',
  GAS_STATION = 'GAS_STATION',
}

export enum MonitorOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export interface MonitorInfo {
  /**
   * Модель
   * @example "Samsung"
   */
  model?: string;
  /**
   * Разрешение
   * @example "3840x2190"
   */
  resolution?: string;
  /**
   * Угол обзора
   * @example 0
   */
  angle?: number;
  /**
   * Тип матрицы
   * @example "IPS"
   */
  matrix?: string;
  /**
   * Яркость
   * @example 0
   */
  brightness?: number;
}

export enum MonitorStatus {
  Offline = 'offline',
  Online = 'online',
}

export interface PointClass {
  /**
   * Point
   * @example "Point"
   */
  type: string;
  /**
   * [ Долгота, Широта ]
   * @example [38.97603,45.04484]
   */
  coordinates: number[];
}

export interface MonitorPartialRequest {
  /**
   * Идентификатор монитора
   * @format uuid
   */
  id?: string;
  /**
   * Имя
   * @example "имя монитора"
   */
  name?: string;
  /**
   * Адрес монитора
   * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
   */
  address?: Address;
  /**
   * Категория
   * @example "GAS_STATION"
   */
  category?: MonitorCategory;
  /**
   * Стоимость показа 1 секунды в рублях
   * @example 1
   */
  'price1s'?: number;
  /**
   * Гарантированное минимальное количество показов в день
   * @example 1
   */
  minWarranty?: number;
  /**
   * Максимальная длительность плэйлиста в секундах
   * @example 1
   */
  maxDuration?: number;
  /**
   * Ориентация экрана
   * @example "Horizontal"
   */
  orientation?: MonitorOrientation;
  /**
   * Модель и прочие характеристики монитора
   * @example {"model":"Samsung","resolution":"1920 x 1080 px","angle":0,"matrix":"IPS","brightness":0}
   */
  monitorInfo?: MonitorInfo;
  /**
   * Присоединен
   * @example false
   */
  attached?: boolean;
  /**
   * Есть звуковая дорожка
   * @example true
   */
  sound?: boolean;
  /**
   * Подключен
   * @example "offline"
   */
  status?: MonitorStatus;
  /**
   * Проигрывается плэйлист
   * @example false
   */
  playlistPlayed?: boolean;
  /**
   * Идентификатор устройства
   * @example "111-111-111"
   */
  code?: string | null;
  /** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
  location?: PointClass;
  /**
   * Избранный монитор
   * @example false
   */
  favorite?: boolean;
  /**
   * Время начала проигрывания
   * @example ["2021-01-01","2022-12-31"]
   */
  dateWhenApp?: any[][];
  /**
   * Время создания
   * @example ["2021-01-01","2022-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2022-12-31"]
   */
  updatedAt?: any[][];
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface LimitOrderRequest {
  id?: Order;
  name?: Order;
  description?: Order;
  extension?: Order;
  width?: Order;
  height?: Order;
  folder?: Order;
  folderId?: Order;
  monitor?: Order;
  monitorId?: Order;
  videoType?: Order;
  filesize?: Order;
  category?: Order;
  duration?: Order;
  files?: Order;
  monitors?: Order;
  playlists?: Order;
  parentFolder?: Order;
  parentFolderId?: Order;
  email?: Order;
  surname?: Order;
  middleName?: Order;
  phoneNumber?: Order;
  city?: Order;
  country?: Order;
  company?: Order;
  role?: Order;
  verified?: Order;
  isDemoUser?: Order;
  countUsedSpace?: Order;
  address?: Order;
  price?: Order;
  orientation?: Order;
  monitorInfo?: Order;
  attached?: Order;
  code?: Order;
  status?: Order;
  longitude?: Order;
  latitude?: Order;
  renderingStatus?: Order;
  fps?: Order;
  renderingPercent?: Order;
  renderingError?: Order;
  renderedFile?: Order;
  renderedFileId?: Order;
  keepSourceAudio?: Order;
  totalDuration?: Order;
  videoLayers?: Order;
  audioLayers?: Order;
  createdAt?: Order;
  updatedAt?: Order;
  seller?: Order;
  buyer?: Order;
  playlist?: Order;
  favorite?: Order;
}

export interface LimitRequest {
  /**
   * Лимит строк результатов
   * @example 20
   */
  limit?: number;
  /**
   * Страница результатов
   * @example 1
   */
  page?: number;
  /**
   * Порядок результатов
   * @example {"createdAt":"DESC"}
   */
  order?: LimitOrderRequest;
}

export interface MonitorsGetRequest {
  /** Запрос */
  where?: MonitorPartialRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: (
    | 'id'
    | 'name'
    | 'address'
    | 'category'
    | 'price1s'
    | 'minWarranty'
    | 'maxDuration'
    | 'orientation'
    | 'monitorInfo'
    | 'attached'
    | 'sound'
    | 'status'
    | 'playlistPlayed'
    | 'code'
    | 'lastSeen'
    | 'location'
    | 'favorite'
    | 'createdAt'
    | 'updatedAt'
  )[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export enum PlaylistStatus {
  Offline = 'Offline',
  Broadcast = 'Broadcast',
  NoBroadcast = 'NoBroadcast',
}

export enum VideoType {
  Video = 'video',
  Image = 'image',
  Audio = 'audio',
  Other = 'other',
}

export enum FileCategory {
  Ownership = 'ownership',
  Photo = 'photo',
  Media = 'media',
}

export interface FolderResponse {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
  /**
   * Наименование папки
   * @example "bar"
   */
  name: string;
  /**
   * Родительская папка
   * @format uuid
   */
  parentFolderId?: string | null;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /**
   * Подчиненные в этой папке
   * @example true
   */
  empty?: boolean;
}

export interface FileResponse {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
  /**
   * Имя файла
   * @example "foo.mp4"
   */
  name: string;
  /**
   * Расширение файла
   * @example "mp4"
   */
  extension: string;
  /**
   * Hash файла
   * @example "2b0439011a3a215ae1756bfc342e5bbc"
   */
  hash: string;
  /**
   * Тип файла
   * @example "video"
   */
  videoType: VideoType;
  /**
   * В какую категорию относить файл
   * @example "media"
   */
  category: FileCategory;
  /**
   * Размер файла
   * @example 210000
   */
  filesize: number;
  /**
   * Продолжительность видео
   * @example 10
   */
  duration: number;
  /**
   * Размер по горизонтали
   * @example 1024
   */
  width: number;
  /**
   * Размер по вертикали
   * @example 1024
   */
  height: number;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Папка */
  folder: FolderResponse;
  /** Мониторы */
  monitors?: MonitorResponse[];
  /** Плэйлисты */
  playlists?: PlaylistResponse[];
}

export interface PlaylistResponse {
  /**
   * Идентификатор плэйлиста
   * @format uuid
   */
  id: string;
  /**
   * Имя плэйлиста
   * @example "имя плэйлиста"
   */
  name: string;
  /**
   * Описание плэйлиста
   * @example "описание плэйлиста"
   */
  description?: string | null;
  /**
   * Статус
   * @example "Offline"
   */
  status: PlaylistStatus;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Мониторы */
  monitors: MonitorResponse[];
  /** Файлы */
  files: FileResponse[];
}

export interface MonitorResponse {
  /**
   * Идентификатор монитора
   * @format uuid
   */
  id: string;
  /**
   * Имя
   * @example "имя монитора"
   */
  name: string;
  /**
   * Адрес монитора
   * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
   */
  address?: Address;
  /**
   * Категория
   * @example "GAS_STATION"
   */
  category: MonitorCategory;
  /**
   * Стоимость показа 1 секунды в рублях
   * @example 1
   */
  'price1s'?: number;
  /**
   * Гарантированное минимальное количество показов в день
   * @example 1
   */
  minWarranty?: number;
  /**
   * Максимальная длительность плэйлиста в секундах
   * @example 1
   */
  maxDuration?: number;
  /**
   * Ориентация экрана
   * @example "Horizontal"
   */
  orientation: MonitorOrientation;
  /**
   * Модель и прочие характеристики монитора
   * @example {"model":"Samsung","resolution":"1920 x 1080 px","angle":0,"matrix":"IPS","brightness":0}
   */
  monitorInfo: MonitorInfo;
  /**
   * Присоединен
   * @example false
   */
  attached: boolean;
  /**
   * Есть звуковая дорожка
   * @example true
   */
  sound: boolean;
  /**
   * Подключен
   * @example "offline"
   */
  status: MonitorStatus;
  /**
   * Проигрывается плэйлист
   * @example false
   */
  playlistPlayed: boolean;
  /**
   * Последний раз виден
   * @format date-time
   * @example "2021-10-01T10:00:00.147Z"
   */
  lastSeen: string | null;
  /** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
  location?: PointClass;
  /**
   * Избранный монитор
   * @example false
   */
  favorite: boolean;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Плэйлист привязанный к монитору */
  playlist?: PlaylistResponse;
  /** Фото монитора. Документы на право владения. */
  files?: FileResponse[];
  /**
   * Идентификатор устройства
   * @example "111-111-111"
   */
  code: string;
}

export interface MonitorsGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество мониторов */
  count: number;
  /** Мониторы */
  data: MonitorResponse[];
}

export interface MonitorCreateRequest {
  /**
   * Имя
   * @example "имя монитора"
   */
  name: string;
  /**
   * Адрес монитора
   * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
   */
  address?: Address;
  /**
   * Категория
   * @example "GAS_STATION"
   */
  category: MonitorCategory;
  /**
   * Стоимость показа 1 секунды в рублях
   * @example 1
   */
  'price1s'?: number;
  /**
   * Гарантированное минимальное количество показов в день
   * @example 1
   */
  minWarranty?: number;
  /**
   * Максимальная длительность плэйлиста в секундах
   * @example 1
   */
  maxDuration?: number;
  /**
   * Ориентация экрана
   * @example "Horizontal"
   */
  orientation: MonitorOrientation;
  /**
   * Модель и прочие характеристики монитора
   * @example {"model":"Samsung","resolution":"1920 x 1080 px","angle":0,"matrix":"IPS","brightness":0}
   */
  monitorInfo: MonitorInfo;
  /**
   * Есть звуковая дорожка
   * @example true
   */
  sound: boolean;
  /** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
  location?: PointClass;
  /**
   * Идентификатор устройства
   * @example "111-111-111"
   */
  code: string;
}

export interface MonitorGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Монитор */
  data: MonitorResponse;
}

export interface ApplicationCreateMonitorRequest {
  /**
   * Время когда
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  dateWhen: string;
  /**
   * Время до
   * @format date-time
   * @example "2021-10-01T10:00:00.147Z"
   */
  dateBefore?: string | null;
  /**
   * Смена текущего плэйлиста: сразу/когда закончится
   * @example false
   */
  playlistChange: boolean;
}

export interface MonitorsPlaylistAttachRequest {
  /**
   * Плэйлист
   * @format uuid
   */
  playlistId: string;
  /** Мониторы */
  monitors: string[];
  /** Создание заявки */
  application: ApplicationCreateMonitorRequest;
}

export enum ApplicationApproved {
  NotProcessed = 'NotProcessed',
  Allowed = 'Allowed',
  Denied = 'Denied',
}

export interface ApplicationResponse {
  /**
   * Идентификатор взаимодействия
   * @format uuid
   */
  id: string;
  /** Покупатель */
  buyer: UserExtEntity;
  /** Продавец */
  seller: UserExtEntity;
  /** Монитор */
  monitor: MonitorResponse;
  /** Плэйлист */
  playlist: PlaylistResponse;
  /**
   * Не обработан / Разрешен / Запрещен
   * @example "NotProcessed"
   */
  approved: ApplicationApproved;
  /**
   * Время когда
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  dateWhen: string;
  /**
   * Время до
   * @format date-time
   * @example "2021-10-01T10:00:00.147Z"
   */
  dateBefore?: string | null;
  /**
   * Смена текущего плэйлиста: сразу/когда закончится
   * @example false
   */
  playlistChange: boolean;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
}

export interface ApplicationsGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество взаимодействий */
  count: number;
  /** Взаимодействия покупателей и продавца */
  data: ApplicationResponse[];
}

export interface MonitorUpdateRequest {
  /**
   * Идентификатор монитора
   * @format uuid
   */
  id?: string;
  /**
   * Имя
   * @example "имя монитора"
   */
  name?: string;
  /**
   * Адрес монитора
   * @example {"city":"Krasnodar","country":"Russia","street":"Krasnaya","house":"122","room":"1"}
   */
  address?: Address;
  /**
   * Категория
   * @example "GAS_STATION"
   */
  category?: MonitorCategory;
  /**
   * Стоимость показа 1 секунды в рублях
   * @example 1
   */
  'price1s'?: number;
  /**
   * Гарантированное минимальное количество показов в день
   * @example 1
   */
  minWarranty?: number;
  /**
   * Максимальная длительность плэйлиста в секундах
   * @example 1
   */
  maxDuration?: number;
  /**
   * Ориентация экрана
   * @example "Horizontal"
   */
  orientation?: MonitorOrientation;
  /**
   * Модель и прочие характеристики монитора
   * @example {"model":"Samsung","resolution":"1920 x 1080 px","angle":0,"matrix":"IPS","brightness":0}
   */
  monitorInfo?: MonitorInfo;
  /**
   * Присоединен
   * @example false
   */
  attached?: boolean;
  /**
   * Есть звуковая дорожка
   * @example true
   */
  sound?: boolean;
  /**
   * Подключен
   * @example "offline"
   */
  status?: MonitorStatus;
  /**
   * Проигрывается плэйлист
   * @example false
   */
  playlistPlayed?: boolean;
  /**
   * Идентификатор устройства
   * @example "111-111-111"
   */
  code?: string | null;
  /** @example {"type":"Point","coordinates":[38.97603,45.04484]} */
  location?: PointClass;
}

export enum RenderingStatus {
  Initial = 'initial',
  Ready = 'ready',
  Pending = 'pending',
  Error = 'error',
}

export interface EditorPartialRequest {
  /**
   * Идентификатор редактора
   * @format uuid
   */
  id?: string;
  /**
   * Имя редактора
   * @example "имя редактора"
   */
  name?: string;
  /**
   * Статус рендеринга
   * @example "initial"
   */
  renderingStatus?: RenderingStatus;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface EditorsGetRequest {
  /** Запрос */
  where?: EditorPartialRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: (
    | 'id'
    | 'name'
    | 'width'
    | 'height'
    | 'fps'
    | 'renderingStatus'
    | 'renderingPercent'
    | 'renderingError'
    | 'renderedFile'
    | 'keepSourceAudio'
    | 'totalDuration'
    | 'videoLayers'
    | 'audioLayers'
    | 'createdAt'
    | 'updatedAt'
  )[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface EditorLayerResponse {
  /**
   * Идентификатор слоя
   * @example "12345678"
   */
  id: string;
  /**
   * Индекс файла
   * @default 1
   * @example 1
   */
  index: number;
  /**
   * Длительность
   * @default 10
   * @example 10
   */
  duration: number;
  /**
   * С какой секунды начать вырезать клип
   * @default 0
   * @example 0
   */
  cutFrom: number;
  /**
   * До какой секунды вырезать клип
   * @default 10
   * @example 10
   */
  cutTo: number;
  /**
   * С какой секунды начинать воспроизводить клип
   * @default 0
   * @example 0
   */
  start: number;
  /**
   * Аудио дорожка из видео, 0-выключен, 1-включен
   * @default 1
   * @example 1
   */
  mixVolume: number;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Файл */
  file: FileResponse;
}

export interface EditorResponse {
  /**
   * Идентификатор редактора
   * @format uuid
   */
  id: string;
  /**
   * Имя редактора
   * @example "имя редактора"
   */
  name: string;
  /**
   * Ширина редактора
   * @example 1920
   */
  width: number;
  /**
   * Высота редактора
   * @example 1080
   */
  height: number;
  /**
   * Фреймрейт
   * @example 24
   */
  fps: number;
  /**
   * Статус рендеринга
   * @example "initial"
   */
  renderingStatus: RenderingStatus;
  /**
   * Процент рендеринга
   * @example 0
   */
  renderingPercent: number | null;
  /** Ошибка рендеринга */
  renderingError: string | null;
  /** Обработанный файл */
  renderedFile: FileResponse | null;
  /**
   * Воспроизводить музыку с видео
   * @example true
   */
  keepSourceAudio: boolean;
  /**
   * Общее время
   * @example 0
   */
  totalDuration: number;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
  /** Видео слой */
  videoLayers: EditorLayerResponse[];
  /** Аудио слой */
  audioLayers: EditorLayerResponse[];
}

export interface EditorsGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество редакторов */
  count: number;
  /** Оплаты */
  data: EditorResponse[];
}

export interface EditorCreateRequest {
  /**
   * Имя редактора
   * @example "имя редактора"
   */
  name: string;
  /**
   * Ширина редактора
   * @example 1920
   */
  width: number;
  /**
   * Высота редактора
   * @example 1080
   */
  height: number;
  /**
   * Фреймрейт
   * @example 24
   */
  fps: number;
  /**
   * Воспроизводить музыку с видео
   * @example true
   */
  keepSourceAudio: boolean;
}

export interface EditorGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Редактор */
  data: EditorResponse;
}

export interface EditorUpdateRequest {
  /**
   * Имя редактора
   * @example "имя редактора"
   */
  name?: string;
  /**
   * Ширина редактора
   * @example 1920
   */
  width?: number;
  /**
   * Высота редактора
   * @example 1080
   */
  height?: number;
  /**
   * Фреймрейт
   * @example 24
   */
  fps?: number;
  /**
   * Воспроизводить музыку с видео
   * @example true
   */
  keepSourceAudio?: boolean;
}

export interface EditorLayerCreateRequest {
  /**
   * Индекс файла
   * @default 1
   * @example 1
   */
  index?: number;
  /**
   * Длительность
   * @default 10
   * @example 10
   */
  duration?: number;
  /**
   * С какой секунды начать вырезать клип
   * @default 0
   * @example 0
   */
  cutFrom?: number;
  /**
   * До какой секунды вырезать клип
   * @default 10
   * @example 10
   */
  cutTo?: number;
  /**
   * С какой секунды начинать воспроизводить клип
   * @default 0
   * @example 0
   */
  start?: number;
  /**
   * Аудио дорожка из видео, 0-выключен, 1-включен
   * @default 1
   * @example 1
   */
  mixVolume?: number;
  /**
   * Файл
   * @format uuid
   */
  file: string;
}

export interface EditorLayerGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Слой редактора */
  data: EditorLayerResponse;
}

export interface EditorLayerUpdateRequest {
  /**
   * Индекс файла
   * @default 1
   * @example 1
   */
  index?: number;
  /**
   * Длительность
   * @default 10
   * @example 10
   */
  duration?: number;
  /**
   * С какой секунды начать вырезать клип
   * @default 0
   * @example 0
   */
  cutFrom?: number;
  /**
   * До какой секунды вырезать клип
   * @default 10
   * @example 10
   */
  cutTo?: number;
  /**
   * С какой секунды начинать воспроизводить клип
   * @default 0
   * @example 0
   */
  start?: number;
  /**
   * Аудио дорожка из видео, 0-выключен, 1-включен
   * @default 1
   * @example 1
   */
  mixVolume?: number;
}

export interface EditorRenderingStatusResponse {
  /**
   * Идентификатор редактора
   * @format uuid
   */
  id: string;
  /**
   * Статус рендеринга
   * @example "initial"
   */
  renderingStatus: RenderingStatus;
  /**
   * Процент рендеринга
   * @example 0
   */
  renderingPercent: number | null;
  /** Ошибка рендеринга */
  renderingError: string | null;
  /** Обработанный файл */
  renderedFile: FileResponse | null;
}

export interface EditorGetRenderingStatusResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Редактор */
  data: EditorRenderingStatusResponse;
}

export interface EditorExportRequest {
  /**
   * Перезапустить рендеринг
   * @default false
   * @example false
   */
  rerender?: boolean;
}

export interface FileUploadRequest {
  /**
   * Идентификатор папки
   * @format uuid
   */
  folderId?: string;
  /**
   * В какую категорию относить файл
   * @example "media"
   */
  category?: FileCategory;
  /**
   * Монитор
   * @format uuid
   */
  monitorId?: string;
}

export interface FilePartialRequest {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id?: string;
  /**
   * Идентификатор папки
   * @format uuid
   */
  folderId: string;
  /**
   * Тип файла
   * @example "video"
   */
  videoType?: VideoType;
  /**
   * В какую категорию относить файл
   * @example "media"
   */
  category?: FileCategory;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface FilesGetRequest {
  /** Запрос */
  where?: FilePartialRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: (
    | 'id'
    | 'folderId'
    | 'name'
    | 'extension'
    | 'hash'
    | 'videoType'
    | 'category'
    | 'filesize'
    | 'duration'
    | 'width'
    | 'height'
    | 'createdAt'
    | 'updatedAt'
  )[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface FilesGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество файлов */
  count: number;
  /** Файлы */
  data: FileResponse[];
}

export interface FilesUploadResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество файлов */
  count: number;
  /** Файлы */
  data: FileResponse[];
}

export interface FileUpdatesRequest {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
  /**
   * Имя файла
   * @example "foo.mp4"
   */
  name?: string;
  /**
   * Идентификатор папки
   * @format uuid
   */
  folderId?: string;
}

export interface FilesUpdateRequest {
  /** Файлы */
  files: FileUpdatesRequest[];
}

export interface FileGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Файл */
  data: FileResponse;
}

export interface FileCopyRequest {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
}

export interface FilesCopyRequest {
  /**
   * Папка, куда копировать
   * @format uuid
   */
  toFolder: string;
  /** Файлы */
  files: FileCopyRequest[];
}

export interface FileUpdateRequest {
  /**
   * Идентификатор папки
   * @format uuid
   */
  folderId?: string;
  /**
   * Имя файла
   * @example "foo.mp4"
   */
  name?: string;
}

export interface FilesDeleteRequest {
  /** Файлы для удаления */
  filesId: string[];
}

export interface FolderPartialRequest {
  /**
   * Наименование папки
   * @example "bar"
   */
  name?: string;
  /**
   * Идентификатор файла
   * @format uuid
   */
  id?: string;
  /**
   * Родительская папка
   * @format uuid
   */
  parentFolderId?: string | null;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface FoldersGetRequest {
  /** Запрос */
  where?: FolderPartialRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: ('id' | 'name' | 'parentFolderId' | 'createdAt' | 'updatedAt')[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface FoldersGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /**
   * Количество папок
   * @example 1
   */
  count: number;
  /** Папки */
  data: FolderResponse[];
}

export interface FolderCreateRequest {
  /**
   * Наименование папки
   * @example "bar"
   */
  name: string;
  /**
   * Родительская папка
   * @format uuid
   */
  parentFolderId?: string | null;
}

export interface FolderGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Папки */
  data: FolderResponse;
}

export interface FolderRequest {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
  /**
   * Наименование папки
   * @example "bar"
   */
  name?: string;
  /**
   * Родительская папка
   * @format uuid
   */
  parentFolderId?: string;
}

export interface FoldersUpdateRequest {
  /** Папки */
  folders: FolderRequest[];
}

export interface FolderCopyRequest {
  /**
   * Идентификатор файла
   * @format uuid
   */
  id: string;
}

export interface FoldersCopyRequest {
  /**
   * Папка, куда копировать
   * @format uuid
   */
  toFolder: string;
  /** Папки */
  folders: FolderCopyRequest[];
}

export interface FoldersDeleteRequest {
  /** Папки для удаления */
  foldersId: string[];
}

export interface FolderUpdateRequest {
  /**
   * Наименование папки
   * @example "bar"
   */
  name?: string;
  /**
   * Родительская папка
   * @format uuid
   */
  parentFolderId?: string;
}

export interface UserRequest {
  /**
   * Идентификатор пользователя
   * @format uuid
   */
  id?: string;
  /**
   * EMail пользователя
   * @format email
   * @example "foo@bar.baz"
   */
  email?: string;
  /**
   * Фамилия
   * @example "Steve"
   */
  surname?: string | null;
  /**
   * Имя
   * @example "John"
   */
  name?: string | null;
  /**
   * Отчество
   * @example "Doe"
   */
  middleName?: string | null;
  /**
   * Пароля пользователя (должен удовлетворять минимальным требованиям)
   * @minLength 8
   * @maxLength 30
   * @pattern /((?=.*d)|(?=.*W+))(?![.
   * ])(?=.*[A-Z])(?=.*[a-z]).*$/
   * @example "Secret~12345678"
   */
  password?: string;
  /**
   * Телефон пользователя
   * @example "+78002000000"
   */
  phoneNumber?: string | null;
  /**
   * Город
   * @example "Krasnodar"
   */
  city?: string | null;
  /**
   * Страна
   * @example "RU"
   */
  country?: string | null;
  /**
   * Дисковое пространство
   * @example 20000000
   */
  storageSpace?: number;
  /**
   * Роль пользователя
   * @example "advertiser"
   */
  role?: UserRoleResponse;
  /**
   * EMail подтвержден
   * @example true
   */
  verified?: boolean;
  /**
   * План пользователя
   * @example "FULL"
   */
  plan?: UserPlan;
  /**
   * Компания
   * @example "ACME corporation"
   */
  company?: string | null;
  /**
   * Юридический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyLegalAddress?: string;
  /**
   * Фактический адрес
   * @example "г. Краснодар, ул. Красная, д. 1"
   */
  companyActualAddress?: string;
  /**
   * Идентификационный номер налогоплательщика (ИНН)
   * @example "012345678901"
   */
  companyTIN?: string;
  /**
   * Код Причины Постановки на учет (КПП)
   * @example "012345678901"
   */
  companyRRC?: string;
  /**
   * Основной Государственный Регистрационный Номер (ОГРН)
   * @example "012345678901"
   */
  companyPSRN?: string;
  /**
   * Телефон организации
   * @example "+78002000000"
   */
  companyPhone?: string;
  /**
   * Email организации
   * @example "we@are.the.best"
   */
  companyEmail?: string;
  /**
   * Наименование банка
   * @example "Банк"
   */
  companyBank?: string;
  /**
   * Банковский идентификационный код (БИК)
   * @example "012345678"
   */
  companyBIC?: string;
  /**
   * Корреспондентский счет
   * @example "30101810400000000000"
   */
  companyCorrespondentAccount?: string;
  /**
   * Расчетный счет
   * @example "40802810064580000000"
   */
  companyPaymentAccount?: string;
  /**
   * Факс организации
   * @example "+78002000000"
   */
  companyFax?: string;
  /**
   * Представитель организации
   * @example "Тухбатуллина Юлия Евгеньевна"
   */
  companyRepresentative?: string;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface UsersGetRequest {
  /** Запрос */
  where?: UserRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: (
    | 'id'
    | 'email'
    | 'surname'
    | 'name'
    | 'middleName'
    | 'phoneNumber'
    | 'city'
    | 'country'
    | 'storageSpace'
    | 'role'
    | 'verified'
    | 'plan'
    | 'company'
    | 'companyLegalAddress'
    | 'companyActualAddress'
    | 'companyTIN'
    | 'companyRRC'
    | 'companyPSRN'
    | 'companyPhone'
    | 'companyEmail'
    | 'companyBank'
    | 'companyBIC'
    | 'companyCorrespondentAccount'
    | 'companyPaymentAccount'
    | 'companyFax'
    | 'companyRepresentative'
    | 'createdAt'
    | 'updatedAt'
  )[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface UsersGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество пользователей */
  count: number;
  /** Пользователи */
  data: UserExtEntity[];
}

export enum InvoiceStatus {
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  CONFIRMED_PENDING_PAYMENT = 'CONFIRMED_PENDING_PAYMENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export interface InvoicesRequest {
  /**
   * Идентификатор счёта
   * @format uuid
   */
  id?: string;
  /** Номер счета */
  seqNo?: number;
  /**
   * Описание заказа
   * @example "описание заказа"
   */
  description?: string;
  /**
   * Подтверждение/отклонение счёта
   * @example "AWAITING_CONFIRMATION"
   */
  status?: InvoiceStatus;
  /**
   * Сумма счета
   * @example 1000
   */
  sum?: number;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface InvoicesGetRequest {
  /** Запрос */
  where?: InvoicesRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: ('id' | 'seqNo' | 'description' | 'status' | 'sum' | 'createdAt' | 'updatedAt')[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface InvoiceResponse {
  /**
   * Идентификатор счёта
   * @format uuid
   */
  id: string;
  /** Номер счета */
  seqNo: number;
  /**
   * Описание заказа
   * @example "описание заказа"
   */
  description: string;
  /**
   * Подтверждение/отклонение счёта
   * @example "AWAITING_CONFIRMATION"
   */
  status: InvoiceStatus;
  /**
   * Сумма счета
   * @example 1000
   */
  sum: number;
  /**
   * Время создания
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  createdAt: string;
  /**
   * Время изменения
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  updatedAt: string;
}

export interface InvoicesGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество заказов */
  count: number;
  /** Заказы */
  data: InvoiceResponse[];
}

export interface InvoiceCreateRequest {
  /**
   * Сумма счета
   * @example 1000
   */
  sum: number;
  /**
   * Описание заказа
   * @example "описание заказа"
   */
  description?: string;
}

export interface InvoiceGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Счёт */
  data: InvoiceResponse;
}

export interface PlaylistPartialRequest {
  /**
   * Идентификатор плэйлиста
   * @format uuid
   */
  id?: string;
  /**
   * Имя плэйлиста
   * @example "имя плэйлиста"
   */
  name?: string;
  /**
   * Описание плэйлиста
   * @example "описание плэйлиста"
   */
  description?: string | null;
  /**
   * Статус
   * @example "Offline"
   */
  status?: PlaylistStatus;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface PlaylistsGetRequest {
  /** Запрос */
  where?: PlaylistPartialRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: ('id' | 'name' | 'description' | 'status' | 'files' | 'monitors' | 'createdAt' | 'updatedAt')[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface PlaylistsGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Количество плэйлистов */
  count: number;
  /** Плэйлисты */
  data: PlaylistResponse[];
}

export interface PlaylistCreateRequest {
  /**
   * Имя плэйлиста
   * @example "имя плэйлиста"
   */
  name: string;
  /**
   * Описание плэйлиста
   * @example "описание плэйлиста"
   */
  description?: string | null;
  /** Файлы */
  files: string[];
}

export interface PlaylistGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Плэйлист */
  data: PlaylistResponse;
}

export interface PlaylistUpdateRequest {
  /**
   * Имя плэйлиста
   * @example "имя плэйлиста"
   */
  name?: string;
  /**
   * Описание плэйлиста
   * @example "описание плэйлиста"
   */
  description?: string | null;
  /** Файлы */
  files?: string[];
}

export enum SpecificFormat {
  Xlsx = 'xlsx',
  Pdf = 'pdf',
}

export interface ReportDeviceStatusRequest {
  /**
   * Формат получаемого файла
   * @example "xlsx"
   */
  format: SpecificFormat;
  /** Мониторы ID (если не указан, то все мониторы) */
  monitorsId?: any[][];
  /**
   * Начальная дата
   * @format date-time
   * @example "2022-05-01"
   */
  dateFrom: string;
  /**
   * Конечная дата
   * @format date-time
   * @example "2023-05-01"
   */
  dateTo: string;
}

export interface ReportViewsRequest {
  /**
   * Формат получаемого файла
   * @example "xlsx"
   */
  format: SpecificFormat;
  /** Мониторы ID (если не указан, то все мониторы) */
  monitorsId?: any[][];
  /**
   * Начальная дата
   * @format date-time
   * @example "2022-05-01"
   */
  dateFrom: string;
  /**
   * Конечная дата
   * @format date-time
   * @example "2023-05-01"
   */
  dateTo: string;
}

export interface ApplicationsRequest {
  /**
   * Идентификатор взаимодействия
   * @format uuid
   */
  id?: string;
  /**
   * Покупатель ID
   * @format uuid
   */
  buyerId?: object | null;
  /**
   * Продавец ID
   * @format uuid
   */
  sellerId?: string;
  /**
   * Монитор ID
   * @format uuid
   */
  monitorId?: string;
  /**
   * Плэйлист ID
   * @format uuid
   */
  playlistId?: string;
  /**
   * Не обработан / Разрешен / Запрещен
   * @example ["NotProcessed","Allowed"]
   */
  approved?: ApplicationApproved[];
  /**
   * Время когда
   * @format date-time
   * @example "2021-01-01T10:00:00.147Z"
   */
  dateWhen?: string;
  /**
   * Время до
   * @format date-time
   * @example "2021-10-01T10:00:00.147Z"
   */
  dateBefore?: string | null;
  /**
   * Смена текущего плэйлиста: сразу/когда закончится
   * @example false
   */
  playlistChange?: boolean;
  /**
   * Время создания
   * @example ["2021-01-01","2021-12-31"]
   */
  createdAt?: any[][];
  /**
   * Время изменения
   * @example ["2021-01-01","2021-12-31"]
   */
  updatedAt?: any[][];
}

export interface ApplicationsGetRequest {
  /** Запрос */
  where?: ApplicationsRequest;
  /**
   * Выбрать поля
   * @example []
   */
  select?: (
    | 'id'
    | 'buyer'
    | 'buyerId'
    | 'seller'
    | 'sellerId'
    | 'monitor'
    | 'monitorId'
    | 'playlist'
    | 'playlistId'
    | 'approved'
    | 'dateWhen'
    | 'dateBefore'
    | 'playlistChange'
    | 'createdAt'
    | 'updatedAt'
  )[];
  /** Рамки для запроса */
  scope?: LimitRequest;
}

export interface ApplicationGetResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Взаимодействие покупателей и продавца */
  data: ApplicationResponse;
}

export interface ApplicationUpdateRequest {
  /**
   * Не обработан / Разрешен / Запрещен
   * @example "NotProcessed"
   */
  approved: ApplicationApproved;
}

export interface ApplicationPrecalculateRequest {
  /** Мониторы для расчета */
  monitorIds: string[];
  /**
   * Длительность плейлиста (в секундах)
   * @example 60
   */
  playlistDuration: number;
  /**
   * Дата и время начала
   * @format date-time
   * @example "2023-09-27T19:09:36.591Z"
   */
  dateFrom: string;
  /**
   * Дата и время окончания
   * @format date-time
   * @example "2023-09-28T19:09:36.591Z"
   */
  dateTo: string;
}

export interface ApplicationPrecalculateSumResponse {
  /** Итоговая сумма */
  sum: string;
}

export interface ApplicationPrecalculateResponse {
  /**
   * Статус операции
   * @example "success"
   */
  status: Status;
  /** Возвращаемое значение */
  data: ApplicationPrecalculateSumResponse;
}

export interface CrontabCreateRequest {
  /**
   * Crontab: "Second Minute Hour DayOfMonth Month DayOfWeek"
   * @example "0 0 0 * * *"
   */
  crontab?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title MyScreen backend API
 * @version 2.41.63
 * @externalDocs https://api.myscreen.ru
 * @contact Stanislav V Vyaliy <stanislav@wisekaa.dev> (https://wisekaa.dev)
 *
 * MyScreen backend API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthGet
     * @summary Проверяет, авторизован ли пользователь и выдает о пользователе полную информацию
     * @request GET:/api/v2/auth
     * @secure
     */
    authGet: (params: RequestParams = {}) =>
      this.request<
        UserGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUpdate
     * @summary Изменение аккаунта пользователя
     * @request PATCH:/api/v2/auth
     * @secure
     */
    authUpdate: (data: UserUpdateRequest, params: RequestParams = {}) =>
      this.request<
        UserGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthLogin
     * @summary Авторизация пользователя
     * @request POST:/api/v2/auth/login
     */
    authLogin: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<
        AuthResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegister
     * @summary Регистрация пользователя
     * @request POST:/api/v2/auth/register
     */
    authRegister: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<
        UserGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name Refresh
     * @summary Обновление токена
     * @request POST:/api/v2/auth/refresh
     */
    refresh: (data: AuthRefreshRequest, params: RequestParams = {}) =>
      this.request<
        AuthRefreshResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/refresh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthEmailVerify
     * @summary Подтвердить email пользователя
     * @request POST:/api/v2/auth/email-verify
     */
    authEmailVerify: (data: VerifyEmailRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/email-verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthResetPassword
     * @summary Отправить на почту пользователю разрешение на смену пароля
     * @request POST:/api/v2/auth/reset-password
     */
    authResetPassword: (data: ResetPasswordInvitationRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthResetPasswordVerify
     * @summary Меняет пароль пользователя по приглашению из почты
     * @request POST:/api/v2/auth/reset-password-verify
     */
    authResetPasswordVerify: (data: ResetPasswordVerifyRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/reset-password-verify`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthDisable
     * @summary Скрытие аккаунта пользователя
     * @request PATCH:/api/v2/auth/disable
     * @secure
     */
    authDisable: (params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/disable`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthMonitor
     * @summary Авторизация монитора
     * @request POST:/api/v2/auth/monitor
     */
    authMonitor: (data: AuthMonitorRequest, params: RequestParams = {}) =>
      this.request<
        AuthRefreshResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/auth/monitor`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorsGet
     * @summary Получение списка мониторов
     * @request POST:/api/v2/monitor
     * @secure
     */
    monitorsGet: (data: MonitorsGetRequest, params: RequestParams = {}) =>
      this.request<
        MonitorsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorCreate
     * @summary Создание монитора
     * @request PUT:/api/v2/monitor
     * @secure
     */
    monitorCreate: (data: MonitorCreateRequest, params: RequestParams = {}) =>
      this.request<
        MonitorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorPlaylistCreate
     * @summary Создание связки плэйлиста и монитора
     * @request PATCH:/api/v2/monitor/playlist
     * @secure
     */
    monitorPlaylistCreate: (data: MonitorsPlaylistAttachRequest, params: RequestParams = {}) =>
      this.request<
        MonitorsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/playlist`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorPlaylistDelete
     * @summary Удаление связки плэйлиста и монитора
     * @request DELETE:/api/v2/monitor/playlist
     * @secure
     */
    monitorPlaylistDelete: (data: MonitorsPlaylistAttachRequest, params: RequestParams = {}) =>
      this.request<
        MonitorsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/playlist`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorGet
     * @summary Получение монитора
     * @request GET:/api/v2/monitor/{monitorId}
     * @secure
     */
    monitorGet: (monitorId: string, params: RequestParams = {}) =>
      this.request<
        MonitorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorUpdate
     * @summary Изменение монитора
     * @request PATCH:/api/v2/monitor/{monitorId}
     * @secure
     */
    monitorUpdate: (monitorId: string, data: MonitorUpdateRequest, params: RequestParams = {}) =>
      this.request<
        MonitorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorDelete
     * @summary Удаление монитора
     * @request DELETE:/api/v2/monitor/{monitorId}
     * @secure
     */
    monitorDelete: (monitorId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorFavoritePlus
     * @summary Избранное '+'
     * @request GET:/api/v2/monitor/{monitorId}/favoritePlus
     * @secure
     */
    monitorFavoritePlus: (monitorId: string, params: RequestParams = {}) =>
      this.request<
        MonitorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}/favoritePlus`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorFavoriteMinus
     * @summary Избранное '-'
     * @request GET:/api/v2/monitor/{monitorId}/favoriteMinus
     * @secure
     */
    monitorFavoriteMinus: (monitorId: string, params: RequestParams = {}) =>
      this.request<
        MonitorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}/favoriteMinus`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags monitor
     * @name MonitorGetApplications
     * @summary Получение плэйлиста монитора
     * @request GET:/api/v2/monitor/{monitorId}/applications
     * @secure
     */
    monitorGetApplications: (monitorId: string, params: RequestParams = {}) =>
      this.request<
        ApplicationsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/monitor/${monitorId}/applications`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorsGet
     * @summary Получение списка редакторов
     * @request POST:/api/v2/editor
     * @secure
     */
    editorsGet: (data: EditorsGetRequest, params: RequestParams = {}) =>
      this.request<
        EditorsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorCreate
     * @summary Создание редактора
     * @request PUT:/api/v2/editor
     * @secure
     */
    editorCreate: (data: EditorCreateRequest, params: RequestParams = {}) =>
      this.request<
        EditorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorGet
     * @summary Получение редактора
     * @request GET:/api/v2/editor/{editorId}
     * @secure
     */
    editorGet: (editorId: string, params: RequestParams = {}) =>
      this.request<
        EditorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/${editorId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorUpdate
     * @summary Изменить редактор
     * @request PATCH:/api/v2/editor/{editorId}
     * @secure
     */
    editorUpdate: (editorId: string, data: EditorUpdateRequest, params: RequestParams = {}) =>
      this.request<
        EditorGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/${editorId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorDelete
     * @summary Удаление редактора
     * @request DELETE:/api/v2/editor/{editorId}
     * @secure
     */
    editorDelete: (editorId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/${editorId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorLayerCreate
     * @summary Создание слоя редактора
     * @request PUT:/api/v2/editor/layer/{editorId}
     * @secure
     */
    editorLayerCreate: (editorId: string, data: EditorLayerCreateRequest, params: RequestParams = {}) =>
      this.request<
        EditorLayerGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/layer/${editorId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorLayerGet
     * @summary Получение слоя редактора
     * @request GET:/api/v2/editor/layer/{editorId}/{layerId}
     * @secure
     */
    editorLayerGet: (editorId: string, layerId: string, params: RequestParams = {}) =>
      this.request<
        EditorLayerGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/layer/${editorId}/${layerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorLayerUpdate
     * @summary Изменить слой редактора
     * @request PATCH:/api/v2/editor/layer/{editorId}/{layerId}
     * @secure
     */
    editorLayerUpdate: (
      editorId: string,
      layerId: string,
      data: EditorLayerUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        EditorLayerGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/layer/${editorId}/${layerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorLayerDelete
     * @summary Удаление слоя редактора
     * @request DELETE:/api/v2/editor/layer/{editorId}/{layerId}
     * @secure
     */
    editorLayerDelete: (editorId: string, layerId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/layer/${editorId}/${layerId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorLayerMove
     * @summary Изменить очередь слоя редактора
     * @request PUT:/api/v2/editor/layer/{editorId}/{layerId}/{moveIndex}
     * @secure
     */
    editorLayerMove: (editorId: string, layerId: string, moveIndex: number, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/layer/${editorId}/${layerId}/${moveIndex}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorFrameGet
     * @summary Получение кадра из редактора
     * @request POST:/api/v2/editor/frame/{editorId}/{time}
     * @secure
     */
    editorFrameGet: (editorId: string, time: number, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/frame/${editorId}/${time}`,
        method: 'POST',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorExportStatus
     * @summary Узнать статус экспорта видео из редактора
     * @request GET:/api/v2/editor/export/{editorId}
     * @secure
     */
    editorExportStatus: (editorId: string, params: RequestParams = {}) =>
      this.request<
        EditorGetRenderingStatusResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/export/${editorId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags editor
     * @name EditorExport
     * @summary Экспорт видео из редактора
     * @request POST:/api/v2/editor/export/{editorId}
     * @secure
     */
    editorExport: (editorId: string, data: EditorExportRequest, params: RequestParams = {}) =>
      this.request<
        EditorGetRenderingStatusResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/editor/export/${editorId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FilesGet
     * @summary Получение списка файлов
     * @request POST:/api/v2/file
     * @secure
     */
    filesGet: (data: FilesGetRequest, params: RequestParams = {}) =>
      this.request<
        FilesGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileUpload
     * @summary Загрузка файлов
     * @request PUT:/api/v2/file
     * @secure
     */
    fileUpload: (
      data: {
        /** Файл(ы) */
        files: File[];
        /** Параметры загрузки файла */
        param: FileUploadRequest;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        FilesUploadResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FilesUpdate
     * @summary Изменить файлы
     * @request PATCH:/api/v2/file
     * @secure
     */
    filesUpdate: (data: FilesUpdateRequest, params: RequestParams = {}) =>
      this.request<
        FileGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FilesDelete
     * @summary Удаление файла
     * @request DELETE:/api/v2/file
     * @secure
     */
    filesDelete: (data: FilesDeleteRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FilesCopy
     * @summary Скопировать файлы
     * @request PATCH:/api/v2/file/copy
     * @secure
     */
    filesCopy: (data: FilesCopyRequest, params: RequestParams = {}) =>
      this.request<
        FileGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/copy`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileDownload
     * @summary Скачивание файла
     * @request GET:/api/v2/file/{fileId}
     * @secure
     */
    fileDownload: (fileId: string, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/${fileId}`,
        method: 'GET',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileGet
     * @summary Получить файл
     * @request POST:/api/v2/file/{fileId}
     * @secure
     */
    fileGet: (fileId: string, params: RequestParams = {}) =>
      this.request<
        FileGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/${fileId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileUpdate
     * @summary Изменить файл
     * @request PATCH:/api/v2/file/{fileId}
     * @secure
     */
    fileUpdate: (fileId: string, data: FileUpdateRequest, params: RequestParams = {}) =>
      this.request<
        FileGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/${fileId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileDelete
     * @summary Удаление файла
     * @request DELETE:/api/v2/file/{fileId}
     * @secure
     */
    fileDelete: (fileId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/${fileId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags file
     * @name FileDownloadPreview
     * @summary Получить файл превью
     * @request GET:/api/v2/file/{fileId}/preview
     * @secure
     */
    fileDownloadPreview: (fileId: string, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/file/${fileId}/preview`,
        method: 'GET',
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FoldersGet
     * @summary Получение списка папок
     * @request POST:/api/v2/folder
     * @secure
     */
    foldersGet: (data: FoldersGetRequest, params: RequestParams = {}) =>
      this.request<
        FoldersGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FolderCreate
     * @summary Создание новой папки
     * @request PUT:/api/v2/folder
     * @secure
     */
    folderCreate: (data: FolderCreateRequest, params: RequestParams = {}) =>
      this.request<
        FolderGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FoldersUpdate
     * @summary Изменение информации о папках
     * @request PATCH:/api/v2/folder
     * @secure
     */
    foldersUpdate: (data: FoldersUpdateRequest, params: RequestParams = {}) =>
      this.request<
        FolderGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FoldersDelete
     * @summary Удаление папок
     * @request DELETE:/api/v2/folder
     * @secure
     */
    foldersDelete: (data: FoldersDeleteRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FoldersCopy
     * @summary Копирование папок
     * @request PATCH:/api/v2/folder/copy
     * @secure
     */
    foldersCopy: (data: FoldersCopyRequest, params: RequestParams = {}) =>
      this.request<
        FolderGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder/copy`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FolderGet
     * @summary Получение информации о папке
     * @request GET:/api/v2/folder/{folderId}
     * @secure
     */
    folderGet: (folderId: string, params: RequestParams = {}) =>
      this.request<
        FolderGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder/${folderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FolderUpdate
     * @summary Изменение информации о папке
     * @request PATCH:/api/v2/folder/{folderId}
     * @secure
     */
    folderUpdate: (folderId: string, data: FolderUpdateRequest, params: RequestParams = {}) =>
      this.request<
        FolderGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder/${folderId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags folder
     * @name FolderDelete
     * @summary Удаление папки
     * @request DELETE:/api/v2/folder/{folderId}
     * @secure
     */
    folderDelete: (folderId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/folder/${folderId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersGet
     * @summary Получение информации о пользователях (только администратор)
     * @request POST:/api/v2/user
     * @secure
     */
    usersGet: (data: UsersGetRequest, params: RequestParams = {}) =>
      this.request<
        UsersGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserDisable
     * @summary Скрытие аккаунта пользователя (только администратор)
     * @request PATCH:/api/v2/user/disable/{userId}
     * @secure
     */
    userDisable: (userId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user/disable/${userId}`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserEnable
     * @summary Открытие аккаунта пользователя (только администратор)
     * @request PATCH:/api/v2/user/enable/{userId}
     * @secure
     */
    userEnable: (userId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user/enable/${userId}`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserGet
     * @summary Получение информации о пользователе (только администратор)
     * @request GET:/api/v2/user/{userId}
     * @secure
     */
    userGet: (userId: string, params: RequestParams = {}) =>
      this.request<
        UserGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserUpdate
     * @summary Изменение информации о пользователе (только администратор)
     * @request PATCH:/api/v2/user/{userId}
     * @secure
     */
    userUpdate: (userId: string, data: UserUpdateRequest, params: RequestParams = {}) =>
      this.request<
        UserGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserDelete
     * @summary Удаление аккаунта пользователя (только администратор)
     * @request DELETE:/api/v2/user/{userId}
     * @secure
     */
    userDelete: (userId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/user/${userId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice
     * @name InvoicesGet
     * @summary Получение списка счётов
     * @request POST:/api/v2/invoice
     * @secure
     */
    invoicesGet: (data: InvoicesGetRequest, params: RequestParams = {}) =>
      this.request<
        InvoicesGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/invoice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice
     * @name InvoiceCreate
     * @summary Выставление счетов
     * @request PUT:/api/v2/invoice
     * @secure
     */
    invoiceCreate: (data: InvoiceCreateRequest, params: RequestParams = {}) =>
      this.request<
        InvoiceGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/invoice`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice
     * @name InvoiceConfirmed
     * @summary Подтверждение/отклонение счёта (только бухгалтер)
     * @request GET:/api/v2/invoice/confirmed/{invoiceId}
     * @secure
     */
    invoiceConfirmed: (invoiceId: string, params: RequestParams = {}) =>
      this.request<
        InvoiceGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/invoice/confirmed/${invoiceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice
     * @name InvoicePayed
     * @summary Оплата по счету (только бухгалтер)
     * @request GET:/api/v2/invoice/payed/{invoiceId}
     * @secure
     */
    invoicePayed: (invoiceId: string, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/invoice/payed/${invoiceId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags invoice
     * @name InvoiceDownload
     * @summary Метод для скачивания файла excel/pdf/etc
     * @request GET:/api/v2/invoice/download/{invoiceId}/{format}
     * @secure
     */
    invoiceDownload: (invoiceId: string, format: string, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/invoice/download/${invoiceId}/${format}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags playlist
     * @name PlaylistsGet
     * @summary Получение списка плэйлистов
     * @request POST:/api/v2/playlist
     * @secure
     */
    playlistsGet: (data: PlaylistsGetRequest, params: RequestParams = {}) =>
      this.request<
        PlaylistsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/playlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags playlist
     * @name PlaylistCreate
     * @summary Создание плэйлиста
     * @request PUT:/api/v2/playlist
     * @secure
     */
    playlistCreate: (data: PlaylistCreateRequest, params: RequestParams = {}) =>
      this.request<
        PlaylistGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/playlist`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags playlist
     * @name PlaylistGet
     * @summary Получение плэйлиста
     * @request GET:/api/v2/playlist/{playlistId}
     * @secure
     */
    playlistGet: (playlistId: string, params: RequestParams = {}) =>
      this.request<
        PlaylistGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/playlist/${playlistId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags playlist
     * @name PlaylistUpdate
     * @summary Обновление плэйлиста
     * @request PATCH:/api/v2/playlist/{playlistId}
     * @secure
     */
    playlistUpdate: (playlistId: string, data: PlaylistUpdateRequest, params: RequestParams = {}) =>
      this.request<
        PlaylistGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/playlist/${playlistId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags playlist
     * @name PlaylistDelete
     * @summary Удаление плэйлиста
     * @request DELETE:/api/v2/playlist/{playlistId}
     * @secure
     */
    playlistDelete: (playlistId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/playlist/${playlistId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statistics
     * @name DeviceStatus
     * @summary Отчёт по статусу устройства
     * @request POST:/api/v2/statistics/deviceStatus
     * @secure
     */
    deviceStatus: (data: ReportDeviceStatusRequest, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/statistics/deviceStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags statistics
     * @name ReportViews
     * @summary Отчёт по показам
     * @request POST:/api/v2/statistics/reportViews
     * @secure
     */
    reportViews: (data: ReportViewsRequest, params: RequestParams = {}) =>
      this.request<
        void,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/statistics/reportViews`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags application
     * @name ApplicationsGet
     * @summary Получение списка заявок
     * @request POST:/api/v2/application
     * @secure
     */
    applicationsGet: (data: ApplicationsGetRequest, params: RequestParams = {}) =>
      this.request<
        ApplicationsGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/application`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags application
     * @name ApplicationGet
     * @summary Получение заявки
     * @request GET:/api/v2/application/{applicationId}
     * @secure
     */
    applicationGet: (applicationId: string, params: RequestParams = {}) =>
      this.request<
        ApplicationGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/application/${applicationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags application
     * @name ApplicationUpdate
     * @summary Изменить заявку
     * @request PATCH:/api/v2/application/{applicationId}
     * @secure
     */
    applicationUpdate: (applicationId: string, data: ApplicationUpdateRequest, params: RequestParams = {}) =>
      this.request<
        ApplicationGetResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/application/${applicationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags application
     * @name ApplicationDelete
     * @summary Удаление заявки
     * @request DELETE:/api/v2/application/{applicationId}
     * @secure
     */
    applicationDelete: (applicationId: string, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/application/${applicationId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags application
     * @name ApplicationPrecalculate
     * @summary Возвращает предрасчет мониторов
     * @request POST:/api/v2/application/precalculate
     * @secure
     */
    applicationPrecalculate: (data: ApplicationPrecalculateRequest, params: RequestParams = {}) =>
      this.request<
        ApplicationPrecalculateResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/application/precalculate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags crontab
     * @name CrontabCreateUsers
     * @summary Включение CronTab для пользователей (только администратор)
     * @request POST:/api/v2/crontab/create/users
     * @secure
     */
    crontabCreateUsers: (data: CrontabCreateRequest, params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/crontab/create/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags crontab
     * @name CrontabDeleteUsers
     * @summary Выключение CronTab для пользователей (только администратор)
     * @request POST:/api/v2/crontab/delete/users
     * @secure
     */
    crontabDeleteUsers: (params: RequestParams = {}) =>
      this.request<
        SuccessResponse,
        | BadRequestError
        | UnauthorizedError
        | ForbiddenError
        | NotFoundError
        | NotAcceptableError
        | ConflictError
        | PreconditionFailedError
        | InternalServerError
        | NotImplementedError
      >({
        path: `/api/v2/crontab/delete/users`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
