import { action, makeObservable, observable } from 'mobx';
import { filterObject, handleError, swaggerApi, toast } from 'utils';

import { IMonitorItem, IMonitorsStore } from '_types/stores';
import { StoreViewMode } from '_types/constants';

import { MonitorItem } from './monitor-item';
import { appStore } from 'stores/app';
import { ListOrder } from 'utils/api/components';
import { MonitorResponse } from 'utils/api/api';

const monitorRequestKeys: Array<keyof MonitorResponse> = [
  'name',
  'address',
  'category',
  'price1s',
  'maxDuration',
  'minWarranty',
  'monitorInfo',
  'orientation',
  'code',
  'location',
];

class MonitorsStore extends ListOrder implements IMonitorsStore {
  @observable list: IMonitorItem[] = [];

  @observable mode: StoreViewMode = 'list';

  @observable error: string | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @action getList: IMonitorsStore['getList'] = async (
    data = {
      where: {},
      scope: {},
    },
  ): Promise<void> => {
    appStore.isLoading = true;

    try {
      const { data: monitorsData } = await this.getSortedList(
        swaggerApi.api.monitorsGet,
        data,
      );

      this.count = monitorsData.count;
      // @ts-ignore
      this.list = monitorsData.data.map((m) => new MonitorItem(m));

    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action initializeMonitor: IMonitorsStore['initializeMonitor'] = (data) =>
    new MonitorItem(data as unknown as IMonitorItem);

  @action loadMonitor = async (id: string): Promise<MonitorItem> => {
    const { data: monitorData } = await swaggerApi.api.monitorGet(id);
    // @ts-ignore
    const monitorItem = new MonitorItem(monitorData.data);

    this.list = this.list.map((m) => (m.id === id ? monitorItem : m));

    return monitorItem;
  };

  @action createMonitor: IMonitorsStore['createMonitor'] = async (monitor) => {
    appStore.isLoading = true;

    try {
      const { data: monitorData } = await swaggerApi.api.monitorCreate(
        filterObject(monitor, {
          includedKeys: monitorRequestKeys,
        }),
      );

      await this.getList();

      appStore.navigate(`/monitors/edit/${monitorData.data.id}`);
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action updateMonitor: IMonitorsStore['updateMonitor'] = async (
    id,
    monitor,
  ) => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.monitorUpdate(
        id,
        filterObject(monitor, {
          includedKeys: monitorRequestKeys,
        }),
      );

      appStore.navigate('/monitors/list');
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action deleteMonitor: IMonitorsStore['deleteMonitor'] = async (id) => {
    appStore.isLoading = true;

    try {
      await swaggerApi.api.monitorDelete(id);
      await this.getList();
    } catch (error) {
      toast.error(handleError(error));
    } finally {
      appStore.isLoading = false;
    }
  };

  @action linkMonitorPlaylistMap: IMonitorsStore['linkMonitorPlaylistMap'] =
    async (monitors, playlistId, application) => {
      appStore.isLoading = true;

      try {
        const result = await swaggerApi.api.monitorPlaylistCreate({
          playlistId,
          monitors,
          application,
        });

        return result.data.data;
      } catch (error) {
        toast.error(handleError(error));
        return null;
      } finally {
        appStore.isLoading = false;
      }
    };

  @action favoriteToggle: IMonitorsStore['favoriteToggle'] = async (
    monitorId,
    isFavorite,
  ) => {
    try {
      const result = await (isFavorite
        ? swaggerApi.api.monitorFavoriteMinus(monitorId)
        : swaggerApi.api.monitorFavoritePlus(monitorId));
      // @ts-ignore
      const newIsFavorite = result.data.data.favorite;

      this.list = this.list.map((m) => {
        if (m.id === monitorId) {
          return new MonitorItem({ ...m, favorite: newIsFavorite });
        }
        return m;
      });

      return newIsFavorite;
    } catch (error) {
      toast.error(handleError(error));
      return isFavorite;
    }
  };
}

const monitorsStore = new MonitorsStore();

export { monitorsStore };
