import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { Layout } from 'components/common';
import { authStore } from '../../stores/auth';

import './styles.scss';

export const Monitors: FC = observer(() => {
  return (
    <Layout flex row>
      <div className="navigation__monitors-list">
        <div className="navigation__monitors-group">
          <span className="navigation__monitors-number">
            {authStore.userMetrics.monitors.online}
          </span>
          <div className="navigation__monitors-light navigation__monitors-light--green" />
        </div>
        <div className="navigation__monitors-group">
          <span className="navigation__monitors-number">
            {authStore.userMetrics.monitors.offline}
          </span>
          <div className="navigation__monitors-light navigation__monitors-light--red" />
        </div>
        <div className="navigation__monitors-group">
          <span className="navigation__monitors-number">
            {authStore.userMetrics.monitors.empty}
          </span>
          <div className="navigation__monitors-light navigation__monitors-light--gray" />
        </div>
      </div>
    </Layout>
  );
});
