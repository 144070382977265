import {
  Login,
  Logout,
  Register,
  ResetPassword,
  SendRegisterEmail,
  SendResetPassword,
  UpdateResetPassword,
  VerifyRegisterEmail,
} from 'pages/auth';
import Dashboard from 'pages/dashboard';
import { Desktop, ManagementControls, Stats } from 'pages/desktop';
import { Home } from 'pages/home';
import NotFound from 'pages/not-found/not-found';
import Library from 'pages/library';
import {
  MonitorAddEdit,
  MonitorDelete,
  Monitors,
  MonitorsList,
} from 'pages/monitors';
import { UserAddEdit, Users, UsersList } from 'pages/users';
import {
  MapPlaylistMonitorList,
  PlaylistAddEdit,
  PlaylistDelete,
  Playlists,
  PlaylistsList,
} from 'pages/playlists';
import { Applications } from 'pages/applications';
import Graphics from 'pages/graphics';
import Settings from 'pages/settings';
import { CurrentUser, Profile } from 'pages/current-user';
import { Billing, PaymentList } from 'pages/payment';
import { Privacy } from 'pages/docs/privacy';
import { EditorDraft, EditorProject, EditorProjects } from 'pages/video-editor';
import { Accountant, AccountantInvoices } from 'pages/accountant';

import { UserRole } from '_types/constants';
import { normalizeRoutes } from './engine';
import { UserRoleResponse } from '../../utils/api/api';
import { filterObject } from '../../utils';
import { BASE_ROUTE_KEYS, PUBLIC_ROUTE_KEYS } from './constants';

const draftRoutes = {
  Accountant: { path: '/accountant', Component: Accountant },
  AccountantInvoices: {
    path: '/accountant/invoices',
    Component: AccountantInvoices,
  },
  AccountantInvoicesPage: {
    path: '/accountant/invoices/:currentPage',
    Component: AccountantInvoices,
  },
  Applications: {
    path: '/applications',
    Component: Applications,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  Billing: {
    path: '/payment-billing',
    Component: Billing,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  Dashboard: {
    path: '/dashboard',
    Component: Dashboard,
  },
  Home: { path: '/', Component: Home },
  Library: {
    path: '/library',
    Component: Library,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  Desktop: {
    path: '/desktop',
    Component: Desktop,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  DesktopManagement: {
    path: '/desktop/management',
    Component: ManagementControls,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  DesktopStatistics: {
    path: '/desktop/statistics',
    Component: Stats,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  NotFound: { path: '/*', Component: NotFound, exact: false },
  Settings: { path: '/settings', Component: Settings },
  Profile: {
    path: '/settings/profile',
    Component: Profile,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PaymentList: {
    path: '/payment-list',
    Component: PaymentList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  CurrentUser: {
    path: '/current-user',
    Component: CurrentUser,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  // AUTH
  Login: {
    path: '/login',
    Component: Login,
    isPublic: true,
  },
  Register: {
    path: '/register',
    Component: Register,
    isPublic: true,
  },
  Logout: {
    path: '/logout',
    Component: Logout,
    isPublic: true,
  },
  ResetPassword: {
    path: '/reset-password',
    Component: ResetPassword,
    isPublic: true,
  },
  UpdateResetPassword: {
    path: '/reset-password-verify',
    Component: UpdateResetPassword,
    isPublic: true,
  },
  SendRegisterEmail: {
    path: '/send-register-email',
    Component: SendRegisterEmail,
    isPublic: true,
  },
  VerifyRegisterEmail: {
    path: '/verify-email',
    Component: VerifyRegisterEmail,
    isPublic: true,
  },
  SendResetPassword: {
    path: '/send-reset-password',
    Component: SendResetPassword,
    isPublic: true,
  },
  // MONITORS
  Monitors: {
    path: '/monitors',
    Component: Monitors,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  MonitorsList: {
    path: '/monitors/list',
    Component: MonitorsList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  MonitorsListPage: {
    path: '/monitors/list/:currentPage',
    Component: MonitorsList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  MonitorsAdd: {
    path: '/monitors/add',
    Component: MonitorAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant, UserRole.Advertiser],
  },
  MonitorsAddByCode: {
    path: '/monitors/add/:code',
    Component: MonitorAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant, UserRole.Advertiser],
  },
  MonitorsEdit: {
    path: '/monitors/edit/:id',
    Component: MonitorAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant, UserRole.Advertiser],
  },
  MonitorsDelete: {
    path: '/monitors/delete/:id',
    Component: MonitorDelete,
    restrictedFrom: [UserRoleResponse.Accountant, UserRole.Advertiser],
  },
  // PLAYLISTS
  Playlists: {
    path: '/playlists',
    Component: Playlists,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsList: {
    path: '/playlists/list',
    Component: PlaylistsList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsMapMonitorList: {
    path: '/playlists/map',
    Component: MapPlaylistMonitorList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsPage: {
    path: '/playlists/list/:currentPage',
    Component: PlaylistsList,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsAdd: {
    path: '/playlists/add',
    Component: PlaylistAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsAddByName: {
    path: '/playlists/add/:name',
    Component: PlaylistAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsEdit: {
    path: '/playlists/edit/:id',
    Component: PlaylistAddEdit,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  PlaylistsDelete: {
    path: '/playlists/delete/:id',
    Component: PlaylistDelete,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  // USERS
  Users: {
    path: '/users',
    Component: Users,
    restrictedFrom: [
      UserRoleResponse.Accountant,
      UserRole.Advertiser,
      UserRole.MonitorOwner,
    ],
  },
  UsersList: {
    path: '/users/list',
    Component: UsersList,
    restrictedFrom: [
      UserRoleResponse.Accountant,
      UserRole.Advertiser,
      UserRole.MonitorOwner,
    ],
  },
  UsersListPage: {
    path: '/users/list/:currentPage',
    Component: UsersList,
    restrictedFrom: [
      UserRoleResponse.Accountant,
      UserRole.Advertiser,
      UserRole.MonitorOwner,
    ],
  },
  UsersEdit: {
    path: '/users/edit/:id',
    Component: UserAddEdit,
    restrictedFrom: [
      UserRoleResponse.Accountant,
      UserRole.Advertiser,
      UserRole.MonitorOwner,
    ],
  },
  UsersDelete: {
    path: '/users/delete/:id',
    Component: UserAddEdit,
    restrictedFrom: [
      UserRoleResponse.Accountant,
      UserRole.Advertiser,
      UserRole.MonitorOwner,
    ],
  },
  Graphics: {
    path: '/graphics',
    Component: Graphics,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  // EDITOR
  Editor: {
    path: '/editor',
    Component: EditorDraft,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  EditorCreate: {
    path: '/editor/project',
    Component: EditorProject,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  EditorProject: {
    path: '/editor/project/:id',
    Component: EditorProject,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  EditorProjects: {
    path: '/editor/projects',
    Component: EditorProjects,
    restrictedFrom: [UserRoleResponse.Accountant],
  },
  // PUBLIC
  Privacy: { path: '/privacy', Component: Privacy, isPublic: true },
};

export type TRouteKey = keyof typeof draftRoutes;

let targetRoutes;

switch (process.env.REACT_APP_TARGET) {
  case 'promo':
    {
      targetRoutes = filterObject(draftRoutes, {
        includedKeys: [
          ...BASE_ROUTE_KEYS,
          ...PUBLIC_ROUTE_KEYS,
          'Accountant',
          'AccountantInvoices',
          'AccountantInvoicesPage',
          'Monitors',
          'MonitorsList',
          'MonitorsListPage',
          'Settings',
          'Profile'
        ],
      });
      targetRoutes.Home = {
        path: '/',
        Component: Monitors,
      };
    }
    break;

  default: {
    targetRoutes = draftRoutes;
  }
}

export default normalizeRoutes(targetRoutes);
