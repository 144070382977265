import React, { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { IMonitorItem } from '_types/stores';
import { categoryOptions } from './constants';

import { monitorsStore } from 'stores/monitors/monitor-list';

import IconConditionOff from 'assets/icons/item-status-off.svg';
import IconConditionOn from 'assets/icons/item-status-on.svg';
import IconMonitorDelete from 'assets/icons/monitor-delete.svg';
import IconMonitorEdit from 'assets/icons/monitor-edit.svg';
import IconMonitorOn from 'assets/icons/monitor-on.svg';
import IconMonitorOff from 'assets/icons/monitor-off.svg';
import FavoriteFill from 'assets/icons/favorite-fill.svg';
import FavoriteColor from 'assets/icons/favorite-color.svg';

import './styles.scss';

/**
 * Todo: icon change logic
 */
const NameWithIcon: FC<{
  id: string;
  name: string;
  enabled: boolean;
}> = ({ id, name, enabled }) => (
  <Fragment key={`table_row${id}_name_img`}>
    <img
      src={enabled ? IconMonitorOn : IconMonitorOff}
      alt="Monitor condition"
      className="table__icon table__icon-name"
    />
    {name}
  </Fragment>
);

const CategoryOption: FC<{
  id: string;
  category: IMonitorItem['category'];
}> = ({ id, category }) => {
  const cOption = React.useMemo(
    () => categoryOptions.find((cOption) => cOption === category),
    [category],
  );

  return cOption ? (
    <FormattedMessage
      id={cOption}
      defaultMessage={cOption}
      key={`table_row${id}_name_category`}
    />
  ) : (
    <span key={`table_row${id}_name_category`}>--</span>
  );
};

const FavoriteIcon: React.FC<{ monitor: IMonitorItem }> = ({ monitor }) => {
  const handleClick = React.useCallback(() => {
    void monitorsStore.favoriteToggle(monitor.id, monitor.favorite);
  }, [monitor.id, monitor.favorite]);

  return monitor.favorite ? (
    <img
      src={FavoriteColor}
      alt="❤"
      className="table__icon table__icon-favorite"
      onClick={handleClick}
    />
  ) : (
    <img
      src={FavoriteFill}
      alt="❤"
      className="table__icon table__icon-favorite"
      onClick={handleClick}
    />
  );
};

const StatusIcon: FC<{ id: string; enabled: boolean }> = ({ id, enabled }) => (
  <Fragment key={`table_row${id}_status_img`}>
    <img
      src={enabled ? IconConditionOn : IconConditionOff}
      alt="Monitor condition"
      className="table__icon table__icon-condition"
    />
    {enabled ? 'On' : 'Off'}
  </Fragment>
);

const Coordinate: React.FC<Pick<IMonitorItem, 'id' | 'location'>> = ({
  id,
  location,
}) => {
  const coordsString = React.useMemo(
    () => (location ? location.coordinates.join(', ') : ''),
    [location],
  );

  return <Fragment key={`table_row${id}_coordinate`}>{coordsString}</Fragment>;
};

const Actions: FC<{ id: string }> = ({ id }) => (
  <Fragment key={`table_row${id}_action`}>
    <Link to={`/monitors/edit/${id}`}>
      <img
        src={IconMonitorEdit}
        alt="Monitor edit"
        className="table__icon table__icon__action"
      />
    </Link>
    <Link to={`/monitors/delete/${id}`}>
      <img
        src={IconMonitorDelete}
        alt="Monitor delete"
        className="table__icon table__icon__action"
      />
    </Link>
  </Fragment>
);

const NameWithIconWrapped = observer(NameWithIcon);
const CategoryOptionWrapped = observer(CategoryOption);
const FavoriteIconWrapped = observer(FavoriteIcon);
const StatusIconWrapped = observer(StatusIcon);
const CoordinateWrapped = observer(Coordinate);
const ActionsWrapped = observer(Actions);

export {
  NameWithIconWrapped as NameWithIcon,
  CategoryOptionWrapped as CategoryOption,
  FavoriteIconWrapped as FavoriteIcon,
  StatusIconWrapped as StatusIcon,
  CoordinateWrapped as Coordinate,
  ActionsWrapped as Actions,
};
