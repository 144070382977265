import { ITable } from 'components/table';

const constants = {
  columns: [
    { accessor: 'N/п', withSort: true },
    'ID',
    'description',
    { accessor: 'sum', withSort: true },
    { accessor: 'status', withSort: true },
    {
      accessor: 'type',
      messageId: 'Тип платежа',
    },
    { accessor: 'creation-date', withSort: true },
  ] as ITable['columns'],

  list: [
    {
      'N/п': 1,
      ID: '000000123',
      description: 'текст',
      sum: 999,
      status: 'оплачен',
      type: 'тип платежа',
      'creation-date': '01/01/2021',
    },
    {
      'N/п': 2,
      ID: '000000246',
      description: 'текст',
      sum: 999,
      status: 'оплачен',
      type: 'тип платежа',
      'creation-date': '02/01/2021',
    },
  ],
};

export default constants;
