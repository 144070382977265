import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import { constants } from './constants';
import { applicationsStore } from 'stores/applications';
import { ApplicationApproved, ApplicationsRequest } from 'utils/api/api';
import { TOrderAccessor } from 'utils/api/components';
import { monitorAddress } from 'utils/formatters/monitor-address';

import { Layout } from 'components/common';
import { Checkbox, Form } from 'components/forms';
import { Table } from 'components/table';
import { ApplicationsWindow } from './applications-window';

import './styles.scss';

export const ApplicationsComponent: React.FC = () => {
  const intl = useIntl();

  const [listWhereState, setListWhereState] =
    React.useState<ApplicationsRequest>({});

  const data = React.useMemo(() => {
    return applicationsStore.list.map((application) => {
      const idParts = application.id.split('-');
      const number = idParts[idParts.length - 1];

      return {
        ...application,
        number,
        date: intl.formatDate(application.createdAt),
        status: (
          <span
            className={cx({
              'applications__status-ok': application.approved === 'Allowed',
            })}
          >
            {intl.formatMessage({ id: application.approved })}
          </span>
        ),
        period: (
          <span>
            <FormattedDate value={application.dateWhen} />
            {' -> '}
            {application.dateBefore ? (
              <FormattedDate value={application.dateBefore} />
            ) : (
              <FormattedMessage id="NoLimit" />
            )}
          </span>
        ),
        monitor: (
          <span className="applications__monitor">
            {application.monitor.name}
          </span>
        ),
        address: <span>{monitorAddress(application.monitor)}</span>,
        playlist: (
          <button
            className="applications__playlist"
            onClick={() => applicationsStore.openApplication(application)}
          >
            <FormattedMessage id="View" defaultMessage="View" />
          </button>
        ),
        playlistChange: (
          <FormattedMessage
            id={application.playlistChange ? 'ForcePlay' : 'ToQueue'}
          />
        ),
        actions: (
          <>
            <button
              className="applications__confirm"
              title={intl.formatMessage({
                id: 'Confirm',
                defaultMessage: 'Confirm',
              })}
              onClick={() =>
                applicationsStore.decide(
                  application,
                  ApplicationApproved.Allowed,
                )
              }
              disabled={
                application.approved !== ApplicationApproved.NotProcessed
              }
            />
            <button
              className="applications__deny"
              title={intl.formatMessage({
                id: 'Dismiss',
                defaultMessage: 'Dismiss',
              })}
              onClick={() =>
                applicationsStore.decide(
                  application,
                  ApplicationApproved.Denied,
                )
              }
              disabled={
                application.approved !== ApplicationApproved.NotProcessed
              }
            />
          </>
        ),
      };
    });
  }, [applicationsStore.list]);

  const handleSortClick = React.useCallback(
    (
      accessor:
        | 'number'
        | 'date'
        | 'playlist'
        | 'monitor'
        | 'period'
        | 'status'
        | 'address'
        | 'actions'
        | string,
    ) => {
      let orderAccessor: TOrderAccessor | undefined;
      switch (accessor) {
        case 'number':
          {
            orderAccessor = 'id';
          }
          break;

        case 'date':
          {
            orderAccessor = 'createdAt';
          }
          break;

        case 'status':
          {
            orderAccessor = 'approved';
          }
          break;
      }

      if (orderAccessor) {
        applicationsStore.setOrder(orderAccessor);
      }
    },
    [applicationsStore.setOrder],
  );

  const handleFilterFormChange = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      setListWhereState((state) => {
        const { name, value, checked } = e.target;
        const newStateData: Partial<typeof state> = {};

        if (name === 'approved') {
          newStateData.approved = state.approved?.filter((v) => v !== value);

          if (checked) {
            newStateData.approved = (newStateData.approved || []).concat(value);
          }

          if (!newStateData.approved?.length) {
            delete state.approved;
            delete newStateData.approved;
          }
        }

        return {
          ...state,
          ...newStateData,
        };
      });
    },
    [setListWhereState],
  );

  React.useEffect(() => {
    applicationsStore.getList({ where: listWhereState, scope: {} });
  }, [applicationsStore.order, listWhereState]);

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      {applicationsStore.application && <ApplicationsWindow />}
      <Form
        onChange={handleFilterFormChange}
        name="applications-filter"
        className="applications__categories"
      >
        {[
          ApplicationApproved.Allowed,
          ApplicationApproved.NotProcessed,
          ApplicationApproved.Denied,
        ].map((value) => {
          return (
            <div
              key={value}
              className={cx('applications__categories-item', value)}
            >
              <Checkbox
                name="approved"
                colorModifier={
                  ApplicationApproved.Allowed === value ? 'green' : 'gray'
                }
                label={intl.formatMessage({
                  id: value,
                  defaultMessage: 'Archived',
                })}
                value={value}
                checked={listWhereState.approved?.includes(value)}
                onChange={() => null}
              />
            </div>
          );
        })}
      </Form>
      <div className="applications__table">
        <Table
          columns={constants.columns}
          data={data}
          onSortClick={handleSortClick}
          order={applicationsStore.order}
        />
      </div>
    </Layout>
  );
};

export const Applications = observer(ApplicationsComponent);
