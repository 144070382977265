import { ChangeEvent } from 'react';
import lodashSet from 'lodash.set';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  monitorMatrixList,
  monitorResolutions,
} from 'pages/monitors/constants';
import { IMonitorItem } from '_types/stores';

import {
  MonitorCategory,
  MonitorOrientation,
  MonitorStatus,
  PlaylistStatus,
} from 'utils/api/api';

import { monitorsStore } from './monitor-list';

// TODO@nikshirobokov: Remove MonitorItem class. Move observer to the MonitorsStore.
export class MonitorItem implements IMonitorItem {
  favorite: IMonitorItem['favorite'] = false;
  @observable id: IMonitorItem['id'] = '';
  @observable name: IMonitorItem['name'] = '';
  @observable address: IMonitorItem['address'] = {
    city: '',
    country: 'Russia',
    street: '',
    house: '',
  };
  @observable location: IMonitorItem['location'];
  @observable price1s: IMonitorItem['price1s'];
  @observable minWarranty: IMonitorItem['minWarranty'];
  @observable maxDuration: IMonitorItem['maxDuration'];
  @observable monitorInfo: IMonitorItem['monitorInfo'] = {
    model: '',
    resolution: monitorResolutions[0],
    angle: 0,
    matrix: monitorMatrixList[0],
    brightness: 100,
  };
  @observable playlist: IMonitorItem['playlist'] = {
    id: '',
    name: '',
    description: '',
    // @ts-ignore
    monitors: [],
    // @ts-ignore
    files: [],
    createdAt: '',
    updatedAt: '',
    status: PlaylistStatus.Offline,
  };
  @observable orientation: IMonitorItem['orientation'] =
    MonitorOrientation.Horizontal;
  @observable category: IMonitorItem['category'] = MonitorCategory.GAS_STATION;
  @observable code: IMonitorItem['code'] = '';
  @observable status: IMonitorItem['status'] = MonitorStatus.Offline;
  @observable files: IMonitorItem['files'] = [];
  @observable attached: IMonitorItem['attached'] = false;
  @observable lastSeen: IMonitorItem['lastSeen'] = '';
  @observable createdAt = '';
  @observable updatedAt = '';
  @observable playlistPlayed = false;
  @observable sound = false;

  @computed get getAddress() {
    return `${this.address.city}, ${this.address.street}, ${this.address.house}`;
  }

  constructor(data?: Partial<IMonitorItem>) {
    if (data) {
      const newData = {
        ...this,
        ...data,
        address: { ...this.address, ...data.address },
        monitorInfo: { ...this.monitorInfo, ...data.monitorInfo },
      };
      Object.assign(this, newData);
    }

    makeObservable(this);
  }

  // Actions
  @action.bound setField = (event: ChangeEvent<unknown>): void => {
    // TODO: Bug, `HTMLSelectElement` and `HTMLInputElement` are not work together
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { name, value, type } = event.target as any;
    let newValue = value;
    // TODO: Later we need find better solution to this bug
    if (type === 'number') {
      newValue = +value;
    }

    if (name === 'address' && typeof value === 'string') {
      newValue = (
        value ? JSON.parse(value) : undefined
      ) as IMonitorItem['address'];
    }

    if (name === 'location' && typeof value === 'string') {
      newValue = (
        value ? JSON.parse(value) : undefined
      ) as IMonitorItem['location'];
    }

    if (name === 'minWarranty' && typeof value === 'string') {
      newValue = (value ? +value : undefined) as IMonitorItem['minWarranty'];
    }

    lodashSet(this, name, newValue);
  };

  @action.bound resetFields = (): void => {
    monitorsStore.error = null;
  };
}
