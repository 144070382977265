import { action, computed, makeObservable, observable } from 'mobx';

import { IApplicationsStore } from '_types/stores';
import { handleError, swaggerApi, toast } from 'utils';
import { ListOrder } from 'utils/api/components';
import { ApplicationApproved } from 'utils/api/api';

import { appStore } from '../app';
import { authStore } from '../auth';

class ApplicationsStore extends ListOrder implements IApplicationsStore {
  @observable application: IApplicationsStore['application'] = null;
  @observable list: IApplicationsStore['list'] = [];
  @observable error: string | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @computed get applicationPlaylist() {
    return this.application ? this.application.playlist : null;
  }

  @computed get applicationProcessed() {
    return this.application?.approved !== ApplicationApproved.NotProcessed;
  }

  @action getList: IApplicationsStore['getList'] = async (data) => {
    appStore.isLoading = true;

    try {
      const { data: editorData } = await this.getSortedList(
        swaggerApi.api.applicationsGet,
        {
          where: {},
          scope: {},
          ...data,
        },
      );
      this.list = editorData.data;

      return this.list;
    } catch (error) {
      toast.error(handleError(error));

      return this.list;
    } finally {
      appStore.isLoading = false;
    }
  };

  @action openApplication: IApplicationsStore['openApplication'] = (
    application,
  ) => {
    this.application = application;
  };

  @action decide: IApplicationsStore['decide'] = async (
    application,
    approved,
  ) => {
    if (
      authStore.userRole === 'advertiser' ||
      application.approved !== ApplicationApproved.NotProcessed
    ) {
      return this.list;
    }
    await swaggerApi.api.applicationUpdate(application.id, {
      approved,
    });
    return this.getList();
  };
}

export const applicationsStore = new ApplicationsStore();
