import React from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import routes, { TRouteKey } from '../routes';

interface IRouteRedirect extends Omit<NavigateProps, 'to'> {
  routeKey: TRouteKey;
}

/**
 * @description
 * A wrapper for <Redirect> that redirects to the {routes}
 * of the routing module
 */
export const RouteRedirect: React.FC<IRouteRedirect> = ({
  routeKey,
  ...restProps
}) => {
  const to = React.useMemo(() => routes[routeKey].path, [routeKey]);

  return <Navigate {...restProps} to={to} />;
};
