import cx from 'classnames';
import icons from '../../assets/icons/icons.module.scss';
import { FC, ButtonHTMLAttributes } from 'react';

export const PlateSaveButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  ...restProps
}) => {
  return (
    <button
      type="submit"
      {...restProps}
      className={cx(
        'button button-primary button-rounded page-plate__button',
        className,
      )}
    >
      <div className={cx('page-plate__icon', icons.SaveWhite)} />
    </button>
  );
};
