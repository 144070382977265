import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Layout } from '../common';
import { IMenuItemProps, menuIconsByRouteKey } from '../menu';

export const SidebarMenuItem = React.forwardRef<
  HTMLAnchorElement,
  IMenuItemProps
>(
  (
    {
      displayTitle,
      activity,
      iconClassName,
      iconClassNameActive,
      children,
      className,
      ...restProps
    },
    linkRef,
  ) => {
    /* TODO@livelife26: Delete this useMemo after resolving a Design issue: non-unique icons "*-settings" by default. */
    const [iconClassNameForRender, iconClassNameActiveForRender] =
      React.useMemo(() => {
        if (iconClassName) {
          return [iconClassName, iconClassNameActive];
        }
        const menuIcons = menuIconsByRouteKey.Settings;

        return [menuIcons?.iconClassName, menuIcons?.iconClassNameActive];
      }, [iconClassName, iconClassNameActive]);

    return (
      <Layout className={cx('menu__item__wrapper')}>
        <Link
          ref={linkRef}
          {...restProps}
          className={cx(className, 'button button-secondary', {
            'menu__item-active': activity,
          })}
        >
          <span
            className={cx(
              'menu__item__icon',
              iconClassNameForRender,
              activity ? iconClassNameActiveForRender : '',
            )}
          />
          <span className="menu__item__title">{displayTitle}</span>
          <span
            className={cx('menu__item__arrow', {
              'menu__item__arrow-mock': !children,
            })}
          />
        </Link>
        {children}
      </Layout>
    );
  },
);

export const SidebarSubmenuItem = React.forwardRef<
  HTMLAnchorElement,
  IMenuItemProps
>(
  (
    {
      displayTitle,
      activity,
      className,
      iconClassName,
      iconClassNameActive,
      ...restProps
    },
    linkRef,
  ) => {
    return (
      <Link
        ref={linkRef}
        {...restProps}
        className={cx(className, { 'menu__item__list-item--active': activity })}
      >
        {displayTitle}
      </Link>
    );
  },
);
