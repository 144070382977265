import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import cx from 'classnames';

import { Layout } from 'components/common';

const Settings: FC = () => (
  <Layout className={cx('content', 'content--with-padding')}>
    Settings page
  </Layout>
);

export default observer(Settings);
