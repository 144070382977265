import { DateRangeInput, Form } from '../../components/forms';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from '../../hooks';
import moment from 'moment';
import { handleError, swaggerApi, toast } from '../../utils';
import {
  ApplicationPrecalculateRequest,
  MonitorResponse,
} from '../../utils/api/api';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';
import { invoicesStore } from '../../stores/invoices';
import { useNavigate } from 'react-router-dom';

const PLAYLIST_DURATION_SEC = [5, 10, 15];

export interface IPrecalcForm {
  playlistDuration: ApplicationPrecalculateRequest['playlistDuration'];
  dateRange: string[];
}

export interface IMonitorPrecalcFromProps {
  monitorIds: Array<MonitorResponse['id']>;
}

export function MonitorsPrecalcForm({ monitorIds }: IMonitorPrecalcFromProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [sum, setSum] = useState<string>('');
  const [isCalc, setIsCalc] = useState(false);

  const initialDateRange = useMemo(
    () => [moment().toISOString(), moment().add(1, 'week').toISOString()],
    [],
  );

  const precalc = useCallback(
    async (
      values: IPrecalcForm,
      monitorIds: ApplicationPrecalculateRequest['monitorIds'],
    ) => {
      let sum = '';

      if (monitorIds.length) {
        try {
          setIsCalc(true);

          const { data: precalcData } =
            await swaggerApi.api.applicationPrecalculate({
              monitorIds,
              playlistDuration: values.playlistDuration,
              dateFrom: values.dateRange[0],
              dateTo: values.dateRange[1],
            });

          sum = precalcData.data.sum;
        } catch (e) {
          toast.error(handleError(e));
          sum = '';
        } finally {
          setIsCalc(false);
        }
      }

      setSum(sum);

      return sum;
    },
    [],
  );

  const [{ formProps, values, canSubmit }, { submit }] = useForm(
    {
      playlistDuration: 5,
      dateRange: initialDateRange,
    },
    {
      disableDeepEqual: true,
      required: ['playlistDuration', 'dateRange'],
      onChange: ({ playlistDuration, dateRange }) => {
        let parsedDateRange = dateRange;

        try {
          if (typeof dateRange === 'string') {
            parsedDateRange = JSON.parse(dateRange);
          }
        } catch (e) {
          console.error(e);
          parsedDateRange = initialDateRange;
        }

        return {
          playlistDuration: Number(playlistDuration),
          dateRange: parsedDateRange,
        };
      },
      onSubmit: async (data) => {
        if (isCalc) {
          return toast.warning('Подождите! Идёт расчёт стоимости...');
        }
        const sum = await precalc(data, monitorIds);

        invoicesStore
          .create(parseFloat(sum))
          .then(() => navigate('/accountant/invoices'))
          .catch((err) => {
            toast.error(handleError(err));
          });
      },
    },
  );

  const durationList = React.useMemo(
    () =>
      PLAYLIST_DURATION_SEC.map((seconds) => {
        const checked = seconds === values.playlistDuration;

        return (
          <label
            key={`seconds-${seconds}`}
            className={cx(['monitor__filter-radius-select', { checked }])}
          >
            <input
              name="playlistDuration"
              type="radio"
              value={seconds}
              checked={checked}
              onChange={() => {}}
            />
            {seconds} <FormattedMessage id="sec" defaultMessage="сек" />
          </label>
        );
      }),
    [values.playlistDuration],
  );

  useEffect(() => {
    void precalc(values, monitorIds);
  }, [values, monitorIds]);

  return (
    <Form {...formProps} className="monitor-precalc-form">
      <div className="monitor-precalc-form__calendar">
        <span className="monitor-precalc-form__calendar-title">
          <FormattedMessage id="app.table.period" defaultMessage="Период" />
        </span>
        <DateRangeInput
          minDate={moment().set('hours', 0).toDate()}
          className="inlined monitor-precalc-form__date-range"
          name="dateRange"
          value={[values.dateRange[0], values.dateRange[1]]}
          onChange={() => null}
        />
      </div>
      <div className="monitor__filter-radius-select-layout">{durationList}</div>
      <div className="monitor-precalc-form__output">
        <FormattedMessage id="app.field.sum" />:
        <span style={{ marginLeft: '10px' }}>
          {intl.formatNumber(parseFloat(sum) || 0, {
            style: 'currency',
            currency: 'RUB',
          })}
        </span>
      </div>
      <button
        type="submit"
        className="button button-primary button-rounded monitor-precalc-form__button"
        disabled={!canSubmit || isCalc}
        onClick={submit}
      >
        <FormattedMessage
          id="app.button.invoiceCreate"
          defaultMessage="Выставить счет"
        />
      </button>
    </Form>
  );
}
