import React from 'react';
import cx from 'classnames';

import { Layout } from 'components/common';

import './styles.scss';

export interface IMainWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const MainWrapper: React.FC<IMainWrapperProps> = ({
  children,
  className,
}) => {
  return (
    <Layout className={cx('content__wrapper', className)}>{children}</Layout>
  );
};
