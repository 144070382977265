import React, { FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { IMonitorItem } from '_types/stores';
import { filterObject } from 'utils';
import { monitorsStore } from 'stores/monitors/monitor-list';
import { AddEditFormFiles as Files, BigInput } from 'components/add-edit-form';
import { Form } from 'components/forms';
import { AddEditWrapper } from 'components/wrapper';
import { Button, Layout } from 'components/common';
import {
  LocationField,
  CategoryField,
  MonitorInformationField,
  NameField,
  OrientationField,
  PriceFields,
} from './add-edit-fields';

import IconSettings from 'assets/icons/added-monitor.svg';
import ScreenshotImage from 'assets/images/screenshot.jpg';

import './styles.scss';

const MonitorAddEdit: React.FC = () => {
  const { id, code } = useParams<{ id?: string; code?: string }>();
  const [data, setData] = React.useState<IMonitorItem | null>(null);

  const loadMonitor = React.useCallback(() => {
    if (id) {
      monitorsStore.loadMonitor(id).then((res) => {
        if (res) {
          setData(res);
        }
      });
    }
  }, [id]);

  React.useEffect(() => {
    if (id) {
      loadMonitor();
    } else {
      setData(monitorsStore.initializeMonitor({ code }));
    }
  }, [id, code]);

  const handleChange = React.useCallback<React.FormEventHandler>(
    (e: React.ChangeEvent<unknown>) => data?.setField(e),
    [data],
  );

  const handleSubmit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!data) return;

      if (id) {
        monitorsStore.updateMonitor(
          id,
          filterObject(data, { excludedKeys: ['createdAt'] }),
        );
      } else {
        monitorsStore.createMonitor(data);
      }
    },
    [id, data],
  );

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      {data && (
        <Form
          className="add__edit__form monitor__add-edit-layout"
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <BigInput name="code" value={data.code} disabled={!!id} />
          <AddEditWrapper
            icon={IconSettings}
            title={!id ? 'Add a monitor' : 'Edit the monitor'}
          >
            <div className="monitor__add-edit-form">
              <div className="monitor__form-column">
                <NameField name={data.name} />
                <LocationField
                  address={data.address}
                  location={data.location}
                />
                <CategoryField
                  category={data.category}
                  className="form__input-category"
                />
              </div>
              <div className="monitor__form-column">
                <PriceFields monitor={data} />
                <OrientationField orientation={data.orientation} id={data.id} />
                <Files
                  id={id}
                  files={data.files || []}
                  addCallback={loadMonitor}
                  deleteCallback={loadMonitor}
                />
                <MonitorInformationField monitorInfo={data.monitorInfo} />
              </div>
            </div>
          </AddEditWrapper>
        </Form>
      )}
      <div className="screenshot__layout">
        <img src={ScreenshotImage} className="screenshot__image" />
        <Button className="screenshot__button" secondary rounded>
          Сделать скриншот
        </Button>
      </div>
    </Layout>
  );
};

export default observer(MonitorAddEdit);
