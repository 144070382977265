import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { authStore } from 'stores/auth';
import { RouteLink } from 'modules/routing-module';

import { Button } from 'components/common';
import { Checkbox, Form, Input, InputField } from 'components/forms';
import { AuthWrapper } from 'components/wrapper';

import './styles.scss';

const LoginComponent: FC = () => (
  <AuthWrapper
    title="Welcome to myscreen"
    subtitle="Sign in to your account to continue"
  >
    <Form onSubmit={authStore.login}>
      <Input
        type="email"
        name="email"
        value={authStore.email}
        onChange={authStore.setField}
        placeholder={
          <FormattedMessage id="Email Address" defaultMessage="Email Address" />
        }
        fieldClass="auth__field"
        required
      />
      <Input
        type="password"
        name="password"
        value={authStore.password}
        onChange={authStore.setField}
        placeholder={
          <FormattedMessage id="Password" defaultMessage="Password" />
        }
        minLength={8}
        fieldClass="auth__field"
        required
      />
      <Checkbox
        name="remember_me"
        label={
          <FormattedMessage id="Remember me" defaultMessage="Remember me" />
        }
        checked={authStore.remember_me}
        onChange={authStore.setField}
        fieldClass="auth__field"
      />
      <InputField>
        <Button type="submit" primary className="auth__button">
          <FormattedMessage id="Sign in" defaultMessage="Sign in" />
        </Button>
      </InputField>
      <InputField>
        <RouteLink routeKey="Register" className="link auth__link">
          {/* note: missing translation */}
          {/* <FormattedMessage id="Register" /> */}
          Зарегистрироваться
        </RouteLink>
      </InputField>
      <InputField>
        <RouteLink routeKey="ResetPassword" className="link auth__link">
          <FormattedMessage
            id="Forgot password"
            defaultMessage="Forgot password"
          />
        </RouteLink>
      </InputField>
    </Form>
  </AuthWrapper>
);

export const Login = observer(LoginComponent);
