import { computed, makeObservable } from 'mobx';

import { fromEntries } from '_types/custom-utility';
import { authStore } from 'stores/auth';

import routes from './routes';
import { IAppRoute, IRoutingStore, TRouteKey } from './types';

class RoutingStore implements IRoutingStore {
  constructor() {
    makeObservable(this);
  }

  @computed get routes() {
    const { user } = authStore;

    return user
      ? fromEntries(
          Object.entries(routes).filter(
            (arr): arr is [TRouteKey, IAppRoute] => {
              const r = arr[1];

              return !r.restrictedFrom || !r.restrictedFrom.includes(user.role);
            },
          ),
        )
      : routes;
  }
}

export const routingStore = new RoutingStore();
