import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { ITable, ITableColumn } from './table';

import './styles.scss';

export interface ITableHead extends Pick<ITable, 'onSortClick' | 'order'> {
  columns: ITableColumn[];
}

export interface ITableHeadCell
  extends ITableColumn,
    Omit<ITableHead, 'columns'> {}

const TableHeadCell: React.FC<ITableHeadCell> = ({
  accessor,
  messageId,
  label,
  withSort,
  renderFilter,
  onSortClick,
  order,
}) => {
  const handleSortClick = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      if (!withSort) return;
      if (onSortClick) {
        onSortClick(accessor, e);
      }
    },
    [withSort, onSortClick],
  );

  const messageIdForRender = React.useMemo(
    () => messageId || `app.table.${accessor}`,
    [messageId, accessor],
  );

  const sortDirection = React.useMemo(
    () => order && order[accessor],
    [accessor, order],
  );

  return (
    <th
      className={cx({
        'no-arrow': !onSortClick || !withSort,
        'table__head--asc': sortDirection === 'ASC',
        'table__head--desc': sortDirection === 'DESC',
      })}
      data-accessor={accessor}
    >
      <div className="table__head-container">
        <FormattedMessage id={messageIdForRender} defaultMessage={label} />
        <div className="table__head-filters">
          {renderFilter && renderFilter()}
          <button
            type="button"
            className="table__head-sort"
            onClick={handleSortClick}
          />
        </div>
      </div>
    </th>
  );
};

const TableHeadComponent: React.FC<ITableHead> = ({
  columns,
  ...restProps
}) => (
  <thead className="table__head">
    <tr>
      {columns.map((column) => (
        <TableHeadCell
          key={`tablet_head${column.accessor}`}
          {...restProps}
          {...column}
        />
      ))}
    </tr>
  </thead>
);

export const TableHead = observer(TableHeadComponent);
