import cx from 'classnames';
import { Layout } from 'components/common';
import Container from 'components/container';
import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Actions } from 'pages/monitors/add-edit-fields';

import './styles.scss';

const AddEditWrapper: FC<
  PropsWithChildren<{
    icon: string | null;
    title?: string;
    action?: ReactElement;
    className?: string;
  }>
> = ({ icon, title, action, children, className }) => (
  <Container className={cx('add__edit__wrapper', className)}>
    <div className="add__edit__inner-wrapper">
      <Layout flex row className="add__edit__header">
        {icon && <img className="add__edit__icon" src={icon} alt="Icon" />}
        {title && !action && (
          <h2 className="add__edit__title">
            <FormattedMessage id={title} defaultMessage={title} />
          </h2>
        )}
        {!title && action}
      </Layout>
      {children}
    </div>
    <Actions />
  </Container>
);

export default observer(AddEditWrapper);
