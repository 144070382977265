import React, { FC } from 'react';
import {
  AccountantPlateField,
  PagePlate,
} from '../../components/page-plate/page-plate';
import icons from '../../assets/icons/icons.module.scss';
import { Form } from '../../components/forms';
import { Layout } from '../../components/common';

import './styles.scss';
import { PlateSaveButton } from '../../components/page-plate/plate-save-button';
import { observer } from 'mobx-react-lite';
import {
  authStore,
  REQUIRED_DETAILS_NAMES,
  USER_DETAILS_NAMES,
  USER_INFO_NAMES,
} from '../../stores/auth';
import { useForm } from '../../hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError, toast } from '../../utils';
import { invoicesStore } from '../../stores/invoices';
import { RouteLink } from '../../modules/routing-module';
import { useNavigate } from 'react-router-dom';
import { appStore } from '../../stores/app';
import { Language } from '../../_types/constants';

const formId = {
  USER_DETAILS: 'userDetails',
  USER_INFO: 'userInfo',
};
export const MIN_INVOICE_SUM = 100;

const UserInfoPlate: FC = observer(() => {
  const [{ values, formProps, canSubmit }, { submit }] = useForm(
    {
      surname: authStore.user?.surname || '',
      name: authStore.user?.name || '',
      middleName: authStore.user?.middleName || '',
      email: authStore.user?.email || '',
    },
    {
      onSubmit: (data) => {
        authStore.updateUser(data);
      },
    },
    [authStore.user],
  );

  return (
    <PagePlate
      iconClassName={icons.User}
      name={
        <h1>
          <FormattedMessage
            id="About the user"
            defaultMessage="О пользователе"
          />
        </h1>
      }
      buttons={
        canSubmit && (
          <PlateSaveButton form={formId.USER_INFO} onClick={submit} />
        )
      }
    >
      <Form {...formProps} className="page-plate__form" id={formId.USER_INFO}>
        <fieldset className="page-plate__fieldset">
          {USER_INFO_NAMES.map((name) => (
            <AccountantPlateField key={name} name={name} value={values[name]} />
          ))}
        </fieldset>
      </Form>
    </PagePlate>
  );
});

const UserDetailsPlate: FC = observer(() => {
  const [{ values, formProps, canSubmit }, { submit }] = useForm(
    {
      company: authStore.user?.company || '',
      companyPhone: authStore.user?.companyPhone || '',
      companyLegalAddress: authStore.user?.companyLegalAddress || '',
      companyActualAddress: authStore.user?.companyActualAddress || '',
      companyTIN: authStore.user?.companyTIN || '',
      companyRRC: authStore.user?.companyRRC || '',
      companyPSRN: authStore.user?.companyPSRN || '',
      companyEmail: authStore.user?.companyEmail || '',
      companyBank: authStore.user?.companyBank || '',
      companyBIC: authStore.user?.companyBIC || '',
      companyCorrespondentAccount:
        authStore.user?.companyCorrespondentAccount || '',
      companyPaymentAccount: authStore.user?.companyPaymentAccount || '',
    },
    {
      required: REQUIRED_DETAILS_NAMES,
      onSubmit: (data) => {
        authStore.updateUserDetails(data);
      },
    },
    [authStore.user],
  );

  return (
    <PagePlate
      iconClassName={icons.User}
      name={<h1>Реквизиты</h1>}
      className="details"
      buttons={
        canSubmit && (
          <PlateSaveButton form={formId.USER_DETAILS} onClick={submit} />
        )
      }
    >
      <Form
        {...formProps}
        className="page-plate__form"
        id={formId.USER_DETAILS}
      >
        <fieldset className="page-plate__fieldset">
          {USER_DETAILS_NAMES.map((name) => (
            <AccountantPlateField
              key={name}
              name={name}
              value={values[name]}
              required={REQUIRED_DETAILS_NAMES.includes(name)}
            />
          ))}
        </fieldset>
      </Form>
    </PagePlate>
  );
});

const InvoiceCreatePlate: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [{ values, formProps }, { submit }] = useForm(
    {
      sum: '',
    },
    {
      onChange(values) {
        if (Number(values.sum) > 0) {
          return values;
        }
        values.sum = String(MIN_INVOICE_SUM);

        return values;
      },
      onSubmit(data) {
        const sum = Number(data.sum);

        if (MIN_INVOICE_SUM > sum) {
          toast.error(
            intl.formatMessage(
              {
                id: 'InputValidNumber',
                defaultMessage: 'Введите корректное число в поле "Сумма"',
              },
              {
                fieldName: appStore.intl.formatMessage({ id: 'app.field.sum' }),
              },
            ),
          );
          return;
        }

        invoicesStore
          .create(sum)
          .then(() => navigate('/accountant/invoices'))
          .catch((err) => {
            toast.error(handleError(err));
          });
      },
    },
  );

  return (
    <PagePlate iconClassName={icons.User} name={<h1>Выставление счета</h1>}>
      <Form {...formProps} className="page-plate__form">
        <fieldset className="page-plate__fieldset">
          <AccountantPlateField
            type="number"
            name="sum"
            step="any"
            min={MIN_INVOICE_SUM}
            placeholder="0.00"
            desc={
              <FormattedMessage
                id="app.field.orderSum"
                values={{
                  unit: intl.locale === Language.EN ? 'RUB' : 'руб.',
                }}
              />
            }
            value={values.sum}
            autoFocus
          />
        </fieldset>
        <fieldset className="page-plate__fieldset buttons">
          <button
            type="submit"
            className="button button-primary button-rounded page-plate__button"
            onClick={submit}
          >
            <FormattedMessage
              id="app.button.invoiceCreate"
              defaultMessage="Выставить счет"
            />
          </button>
          <RouteLink
            type="button"
            className="button button-text-primary"
            routeKey="AccountantInvoices"
          >
            <FormattedMessage
              id="app.button.seeInvoiceHistory"
              defaultMessage="Смотреть историю счетов"
            />
          </RouteLink>
        </fieldset>
      </Form>
    </PagePlate>
  );
};

export const Profile: FC = () => {
  return (
    <Layout className="content content--with-padding" id="userProfile">
      <UserDetailsPlate />
      <UserInfoPlate />
      {appStore.isCreatedFor('default') && <InvoiceCreatePlate />}
    </Layout>
  );
};
