import { toast } from 'react-toastify';

import { Download } from 'components/common';
import { IDownload } from 'components/common/toast/download';

export const toastDownload = (
  projectId: IDownload['editorProjectId'],
  renderingPercent: IDownload['renderingPercent'],
  renderingStatus: IDownload['renderingStatus'],
) => {
  const toastId = `toast-download-${projectId}`;
  const render = (
    // @ts-ignore
    <Download
      editorProjectId={projectId}
      renderingPercent={renderingPercent}
      renderingStatus={renderingStatus}
    />
  );

  if (toast.isActive(toastId)) {
    toast.update(toastId, { render });
  } else {
    toast(render, {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      position: 'bottom-right',
      className: 'Toastify__toast-download-container',
      toastId,
    });
  }

  return toastId;
};
