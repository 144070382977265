import React from 'react';

import { mediaStore } from 'stores/media';
import { FileResponse, VideoType } from 'utils/api/api';

import ImagePreview from 'assets/images/image-preview.svg';
import AudioPreview from 'assets/images/audio-preview.svg';
import VideoPreview from 'assets/images/video-preview.svg';

const defaultPreview = (fileVideoType: FileResponse['videoType']) => {
  switch (fileVideoType) {
    case VideoType.Image:
      return ImagePreview;
    case VideoType.Audio:
      return AudioPreview;
    case VideoType.Video:
      return VideoPreview;
    default:
      return '';
  }
};

export function useFilePreview(file: FileResponse) {
  const [previewLink, setPreviewLink] = React.useState(() =>
    defaultPreview(file.videoType),
  );

  const setDefaultPreviewLink = React.useCallback(() => {
    setPreviewLink(defaultPreview(file.videoType));
  }, [file.videoType]);

  React.useEffect(() => {
    if (![VideoType.Image, VideoType.Video].includes(file.videoType)) return;

    try {
      mediaStore.getFilePreview(file.id).then((blob) => {
        if (blob) {
          setPreviewLink(window.URL.createObjectURL(blob));
        } else {
          setDefaultPreviewLink();
        }
      });
    } catch {
      setDefaultPreviewLink();
    }
  }, [file.id, file.videoType]);

  return previewLink;
}
