import { ChangeEvent, DragEvent } from 'react';

export const preventMe = (
  event:
    | DragEvent<HTMLDivElement | HTMLLabelElement>
    | ChangeEvent<HTMLInputElement>,
) => {
  event.preventDefault();
  event.stopPropagation();
};
