import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  InvoiceResponse,
  InvoiceStatus,
  UserRoleResponse,
} from '../../utils/api/api';
import { Button, Confirmation } from '../../components/common';
import { invoicesStore } from '../../stores/invoices';
import { observer } from 'mobx-react-lite';
import { authStore } from '../../stores/auth';
import { PopupFullscreen } from '../../components/popup';

export const InvoiceActions: FC<{ invoice: InvoiceResponse }> = observer(
  ({ invoice }) => {
    const handleDownloadClick = useCallback(() => {
      invoicesStore.download(invoice);
    }, [invoice]);

    return (
      <div className="invoice-actions">
        <Button primary onClick={handleDownloadClick}>
          <FormattedMessage
            id="app.button.invoiceDownload"
            defaultMessage="Скачать счет"
          />
        </Button>
      </div>
    );
  },
);

export const InvoiceTableStatus: FC<{ invoice: InvoiceResponse }> = observer(
  ({ invoice }) => {
    const intl = useIntl();
    const [message, setMessage] = useState('');

    const handleConfirmClick = useCallback(() => {
      setMessage(
        `Подтвердить правильность счета №${invoice.seqNo}?\nСчет будет отправлен пользователю на электронную почту.`,
      );
    }, [invoice]);

    const handlePaidClick = useCallback(() => {
      setMessage(
        `Подтвердить оплату счета №${
          invoice.seqNo
        } на сумму ${intl.formatNumber(invoice.sum, {
          style: 'currency',
          currency: 'RUB',
        })}?\nСредства будут начислены на баланс пользователя.`,
      );
    }, [invoice]);

    const confirmInvoice = useCallback(async () => {
      await invoicesStore.confirm(invoice);
      setMessage('');
    }, [invoice]);

    const confirmPayment = useCallback(async () => {
      await invoicesStore.confirmPayment(invoice);
      setMessage('');
    }, [invoice]);

    const cancelConfirmation = useCallback(() => {
      setMessage('');
    }, []);

    return (
      <div className="invoice-status">
        {message &&
          [
            InvoiceStatus.AWAITING_CONFIRMATION,
            InvoiceStatus.CONFIRMED_PENDING_PAYMENT,
          ].includes(invoice.status) && (
            <PopupFullscreen onOutsideClick={cancelConfirmation}>
              <Confirmation
                message={message}
                onConfirm={
                  invoice.status === InvoiceStatus.AWAITING_CONFIRMATION
                    ? confirmInvoice
                    : confirmPayment
                }
                onCancel={cancelConfirmation}
              />
            </PopupFullscreen>
          )}
        <FormattedMessage id={invoice.status} />
        {authStore.restrictFrom([
          UserRoleResponse.MonitorOwner,
          UserRoleResponse.Advertiser,
        ]) && (
          <>
            {invoice.status === InvoiceStatus.AWAITING_CONFIRMATION && (
              <Button primary onClick={handleConfirmClick}>
                <FormattedMessage
                  id="app.button.confirm"
                  defaultMessage="Подтвердить"
                />
              </Button>
            )}
            {invoice.status === InvoiceStatus.CONFIRMED_PENDING_PAYMENT && (
              <Button secondary onClick={handlePaidClick}>
                <FormattedMessage
                  id="app.button.invoicePaymentConfirm"
                  defaultMessage="Подтвердить приход"
                />
              </Button>
            )}
          </>
        )}
      </div>
    );
  },
);
