import { MonitorResponse } from '../api/api';

export function monitorAddress(monitor: MonitorResponse) {
  return (
    monitor.address &&
    monitor.address.city +
      (monitor.address.street ? `, ${monitor.address.street} ` : ' ') +
      monitor.address.house
  );
}
