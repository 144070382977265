import { IAppRoute, TRouteKey } from './types';

export const DEFAULT_ROUTE: Partial<IAppRoute> = {
  isPublic: false,
};

export const PUBLIC_ROUTE_KEYS: TRouteKey[] = [
  'Login',
  'Register',
  'Logout',
  'ResetPassword',
  'UpdateResetPassword',
  'SendRegisterEmail',
  'VerifyRegisterEmail',
  'SendResetPassword',
  'Privacy',
];

export const BASE_ROUTE_KEYS: TRouteKey[] = ['NotFound'];
