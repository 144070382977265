import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import { Actions, NameWithIcon } from './list-components';
import { tableConstants } from '../../constants';
import { Layout } from 'components/common';
import { Table } from 'components/table';
import { DEFAULT_PAGINATION_LIMIT, Pagination } from 'modules/pagination-module';

import { userStore } from 'stores/users';
import { UserRoleReverse } from '_types/constants';

import './styles.scss';
import { toJS } from 'mobx';

const FIRST_PAGE = 1;

const UsersList: React.FC = observer(() => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { currentPage = FIRST_PAGE } = useParams<{ currentPage?: string }>();
  const [page, setPage] = React.useState(Number(currentPage));

  React.useEffect(() => {
    userStore.getList({
      where: {},
      scope: {
        limit: DEFAULT_PAGINATION_LIMIT,
        page,
      },
    });
  }, [page, userStore.order]);

  const handlePaginate = React.useCallback(
    (paginatePage: number) => {
      setPage((state) => {
        if (paginatePage === state) {
          return state;
        }
        navigate(`/users/list/${page}`);
        return paginatePage;
      });
    },
    [history],
  );

  const data = React.useMemo(
    () =>
      userStore.list.map((user, i) => ({
        ...toJS(user),
        ID: <NameWithIcon id={user.id} fill={i % 2 ? '#32C832' : ' #888FA3'} />,
        role: (
          <FormattedMessage
            id={UserRoleReverse[user.role]}
            defaultMessage={user.role}
          />
        ),
        createdAt: intl.formatDate(user.createdAt),
        updatedAt: user.updatedAt ? intl.formatDate(user.updatedAt) : null,
        actions: <Actions id={user.id} />,
      })),
    [userStore.list],
  );

  return (
    <Layout className={cx('content', 'content--with-padding')}>
      <Layout className="user__table">
        <Table
          columns={tableConstants.columns}
          data={data}
          onSortClick={userStore.setOrder}
          order={userStore.order}
        />
      </Layout>
      <Pagination
        totalItems={userStore.count}
        currentPage={+currentPage}
        onPageChange={handlePaginate}
      />
    </Layout>
  );
});

export { UsersList };
