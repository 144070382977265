import {
  ChangeEvent,
  DependencyList,
  FormEvent,
  FormHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import deepEqual from 'deep-equal';

export type TFormValues = Record<string, unknown>;
export type TSetField<T extends TFormValues> = <N extends keyof T>(
  name: N,
  value: T[N],
) => void;

export interface IRequiredItem<
  T extends TFormValues,
  N extends keyof T = keyof T,
> {
  name: N;

  check(value: T[N]): boolean;
}

export interface IUseFormOptions<T extends TFormValues> {
  onSubmit: (data: T) => void;
  onChange: (values: T) => Partial<T> | void;
  required: Array<keyof T | IRequiredItem<T>>;
  disableDeepEqual: boolean;
}

function normalizeState<T extends TFormValues>(initial: T): T {
  return { ...initial };
}

export function useForm<T extends TFormValues>(
  initial: T,
  options: Partial<IUseFormOptions<T>> = {},
  deps: DependencyList = [],
): [
  {
    values: T;
    formProps: FormHTMLAttributes<HTMLFormElement>;
    canSubmit: boolean;
  },
  {
    setField: TSetField<T>;
    submit(): ReturnType<IUseFormOptions<T>['onSubmit']>;
  },
] {
  const [values, setValues] = useState(() => normalizeState<T>(initial));

  const noEqual = useMemo(
    () =>
      options.disableDeepEqual || !deepEqual(initial, values, { strict: true }),
    [initial, values, options.disableDeepEqual],
  );

  const setField = useCallback<TSetField<T>>(
    (name, value) => {
      let newState = { ...values };
      newState[name] = value;

      if (options.onChange) {
        const partialState = options.onChange(newState);

        if (partialState) {
          newState = {
            ...newState,
            ...partialState,
          };
        }
      }

      setValues(newState);
    },
    [values, options.onChange],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLFormElement>) => {
      e.stopPropagation();
      const { name, value } = e.target;

      setField(name, value);
    },
    [setField],
  );

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (options.onSubmit) {
        e.stopPropagation();
      }
    },
    [options.onSubmit],
  );

  const canSubmit = useMemo(() => {
    if (options.required) {
      return (
        noEqual &&
        options.required.every((item) => {
          if (typeof item === 'object') {
            return item.check(values[item.name]);
          }
          const value = values[item];

          switch (typeof value) {
            case 'string':
              return Boolean(value);

            case 'number': {
              return !isNaN(value);
            }

            default:
              return true;
          }
        })
      );
    }

    return noEqual;
  }, [noEqual, options.required, values]);

  const submit = useCallback(() => {
    if (canSubmit && options.onSubmit) {
      options.onSubmit(values);
    }
  }, [options.onSubmit, values, canSubmit]);

  useEffect(() => {
    setValues(normalizeState(initial));
  }, deps);

  return [
    {
      values,
      formProps: {
        onSubmit,
        onChange,
      },
      canSubmit,
    },
    { setField, submit },
  ];
}
