import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import cx from 'classnames';

// @ts-ignore
momentDurationFormatSetup(moment);

import { videoEditorStore } from 'modules/video-editor-module';

import { TimelinePointer } from './timeline-pointer';
import { TimelineScale } from './timeline-scale';
import { FileTrack } from '../file-track';

type TTimelineElement = HTMLDivElement;

export interface ITimelineComponent
  extends React.HTMLAttributes<TTimelineElement> {
  innerRef: React.RefObject<TTimelineElement>;
}

const TIMELINE_LENGTH_FACTOR = 2;
const NON_CLICK_TIMELINE_CHILDREN = [
  '.file-track__layout',
  '.timeline__pointer:not(.timeline__pointer--preview)',
];

const TimelineComponent: React.FC<ITimelineComponent> = ({
  className,
  style,
  innerRef,
  ...restProps
}) => {
  const localStore = useLocalObservable(() => ({
    pointerPreviewStyle: {},
    setPointerPreviewStyle(style: React.CSSProperties) {
      this.pointerPreviewStyle = style;
    },
  }));

  const minScaleLength = React.useMemo(
    () =>
      `${
        videoEditorStore.scaleUnitSeconds *
        videoEditorStore.secondsToPixelFactor
      }px`,
    [videoEditorStore.secondsToPixelFactor, videoEditorStore.scaleUnitSeconds],
  );

  const videoTrackList = React.useMemo(
    () =>
      videoEditorStore.layerTracks.map((layer) => (
        <FileTrack
          key={`${layer.id}:${layer.start}`}
          name={layer.mediaId}
          track={layer}
        />
      )),
    [videoEditorStore.layerTracks],
  );

  const audioTrackList = React.useMemo(
    () =>
      videoEditorStore.audioTracks.map((layer) => (
        <FileTrack
          key={`${layer.id}:${layer.start}`}
          name={layer.mediaId}
          track={layer}
        />
      )),
    [videoEditorStore.audioTracks],
  );

  const { timeStamps, bodyWidth } = React.useMemo(() => {
    const minTimelineDuration =
      window.innerWidth / videoEditorStore.secondsToPixelFactor;
    const totalTimelineDuration =
      videoEditorStore.totalDuration * TIMELINE_LENGTH_FACTOR;

    const timelineDuration =
      totalTimelineDuration > minTimelineDuration
        ? totalTimelineDuration
        : minTimelineDuration;

    const stampsNumber = Math.round(
      timelineDuration / videoEditorStore.scaleUnitSeconds,
    );

    return {
      timeStamps: Array.from(new Array(stampsNumber)),
      bodyWidth: `${
        timelineDuration * videoEditorStore.secondsToPixelFactor
      }px`,
    };
  }, [
    videoEditorStore.totalDuration,
    videoEditorStore.secondsToPixelFactor,
    videoEditorStore.scaleUnitSeconds,
  ]);

  const calcEventCursorX = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) =>
      e.clientX -
      e.currentTarget.getBoundingClientRect().left +
      e.currentTarget.scrollLeft,
    [],
  );

  const handleTimelineBodyClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        NON_CLICK_TIMELINE_CHILDREN.some((css) =>
          // @ts-ignore
          e.target.closest(css),
        )
      )
        return;
      const x = calcEventCursorX(e);
      videoEditorStore.moveTimelinePointer({ x });
    },
    [],
  );

  const handleTimelineBodyMove = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        NON_CLICK_TIMELINE_CHILDREN.some((css) =>
          // @ts-ignore
          e.target.closest(css),
        ) ||
        videoEditorStore.tracksDragging
      ) {
        localStore.setPointerPreviewStyle({});
        return;
      }
      const x = calcEventCursorX(e);
      localStore.setPointerPreviewStyle({
        transform: `translateX(calc(${x}px - 50%))`,
        visibility: 'visible',
      });
    },
    [videoEditorStore.tracksDragging],
  );

  const handleTimelineBodyLeave = React.useCallback(() => {
    if (Object.keys(localStore.pointerPreviewStyle).length) {
      localStore.setPointerPreviewStyle({});
    }
  }, []);

  return (
    <div
      ref={innerRef}
      {...restProps}
      className={cx('timeline timeline__layout', className)}
      style={{
        ...style,
        // @ts-ignore
        '--min-scale-length': minScaleLength,
        '--timeline-body-width': bodyWidth,
      }}
    >
      <div className="timeline__aside">
        <div className="timeline__track-layout">
          <div className="timeline__track-icon timeline__track-icon--video" />
        </div>
        <div className="timeline__track-layout">
          <div className="timeline__track-icon timeline__track-icon--audio" />
        </div>
      </div>
      <div
        className={cx('timeline__body')}
        onClick={handleTimelineBodyClick}
        onMouseMove={handleTimelineBodyMove}
        onMouseLeave={handleTimelineBodyLeave}
      >
        <div
          className="timeline__pointer timeline__pointer--preview"
          style={{ ...localStore.pointerPreviewStyle }}
        />
        <TimelinePointer />
        <TimelineScale timeStamps={timeStamps} />
        <div className="timeline__track-layout">
          <div className="timeline__track-body">{videoTrackList}</div>
        </div>
        <div className="timeline__track-layout">
          <div className="timeline__track-body">{audioTrackList}</div>
        </div>
      </div>
    </div>
  );
};

export const Timeline = observer(TimelineComponent);
